import DragCommodity from "./DragCommodity/DragCommodity";
import UploadModelAndCommodity from "../components/UploadModelAndCommodity"
import {useRef, forwardRef, useImperativeHandle, useState, useContext} from "react"
import styles from './index.module.scss'
import { Spin } from "antd";
import { GenerateContext } from "@utils/GenerateContext"

const SubCompCommodity = forwardRef((props, ref) => {
    const dragRef = useRef()
    const {
        globaluploadLoading, setGlobaluploadLoading,
        refVersion,
    } = useContext(GenerateContext)

    useImperativeHandle(ref, () => ({
        loadNewEdge: (url) => {
            if (!dragRef.current) return
            dragRef.current.loadNewEdge(url)
        },
    }))

    const [loading, setLoading] = useState(false)
    const reUpload = async (...args) => {
        setGlobaluploadLoading(true)
        setLoading(true)
        try {
            return await props.reUpload(...args)
        } catch {
            setGlobaluploadLoading(false)
        } finally {
            setLoading(false)
        }
    }
    
    return (
        props.segmentResult?.length > 0 ?
        <div className={styles.Container}>
            <DragCommodity
                ref={dragRef}
                imgRatio={props.imgRatio}
                imgWidth={props.imgWidth}
                imgHeight={props.imgHeight}
                aiInteractiveNpy={props.aiInteractiveNpy}
                segmentResult={props.segmentResult}
                dragScale={props.dragScale}
                onDragChange={props.onDragChange}
                reUpload={reUpload}
                deleteOpt={props.deleteOpt}
                mattingOpt={props.mattingOpt}
                composeOpt={props.composeOpt}
                onNpyChange={props.onNpyChange}
            />
            {
                refVersion == 3 &&
                <div className={styles.Tip_float}>
                    请尽量保持与原商品一致的大小和位置
                </div>
            }
            {
                globaluploadLoading &&
                <div className={styles.Loading}>
                    <Spin size="large"  />
                </div>
            }
        </div> :
        <UploadModelAndCommodity
            imageType={props.imageType}
            onBeforeUpload={props.onBeforeUpload}
        />
    )
})

export default SubCompCommodity