import React, { useState, useContext, useEffect, useImperativeHandle, forwardRef } from 'react';
import API from '@api/api.js';
import { message, Modal } from 'antd';
import MemberPackage from '../memberPackage/memberPackage';
import { useNavigate } from 'react-router-dom';
import eventBus from '@utils/eventBus';
import OneTimePay from "@view/compoents/oneTimePay/oneTimePay";
import PSAIModal from '@PSAIComponents/PSAIModal';
import Customized from '@view/compoents/customized';
import styles from './payModal.module.scss'

const PayModal = forwardRef((props, ref) => {
    const navigate = useNavigate()
    const [alipayOpen, setAlipayOpen] = useState(false)
    const [alipayItems, setAlipayItems] = useState()
    const [isPowerPackage, setIsPowerPackage] = useState(false)
    const [rechargeModel, setRechargeModel] = useState(false)
    const [alipayDefaultSelectedIndex, setAlipayDefaultSelectedIndex] = useState(0)

    useImperativeHandle(ref, () => ({
        openrechargeClick
    }));

    const openrechargeClick = () => {
        setRechargeModel(true)
    }

    const openModalToPay = (item) => {
        if (item.level != 'free') {
            eventBus.emit('pc_prepay', item, false, 0, () => eventBus.emit('updateUserInfo'))
        } else {
            setRechargeModel(false)
            message.info('欢迎免费试用')
        } 
    }
  
    return (
        <div>
            <PSAIModal
                open={rechargeModel}
                footer={null}
                maxWidth={1300}
                onCancel={() => setRechargeModel(false)}
            >
                <div className={styles.payModalWrap}>
                    <div className="_beauty_scrollbar" style={{paddingRight: '4px'}}>
                        <MemberPackage payFunc={openModalToPay} />
                        <Customized payFunc={openModalToPay}/>
                        <OneTimePay />
                    </div>
                </div>
            </PSAIModal>
        </div>
    )
    
})

export default PayModal