import React, {useState, useEffect} from 'react';
import './textTag.scss'
import {FACE_OPTIONS, HAIR_OPTIONS} from '@utils/CONST'

const TextTag = (props) => {
    const faceTagChange = (value, text) => {
        props.faceTagChange(value, text)
    }

    const hairTagChange = (value, text) => {
        props.hairTagChange(value, text)
    }

    return (
        <div className='textTag'>
            <div>
                <div className='title_'>表情</div>
                <p>
                    {
                        FACE_OPTIONS.map((item) => (
                            <span
                                className={props.faceTagText == item.text?'select_sty': null}
                                onClick={() => faceTagChange(item.value, item.text)}
                                key={item.value}
                            >
                                {item.text}
                            </span>
                        ))
                    }
                </p>
                
            </div>
            <div>
                <div className='title_'>发型</div>
                <p>
                    {
                        HAIR_OPTIONS.map((item) => (
                            <span
                                className={props.hairTagText == item.text?'select_sty': null}
                                onClick={() => hairTagChange(item.value, item.text)}
                                key={item.value}
                            >
                                {item.text}
                            </span>
                        ))
                    }
                </p>
               
            </div>
        </div>
    )
}

export default TextTag