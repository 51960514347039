import { useState, useRef, useEffect, useMemo } from 'react';
import axios from 'axios';
import Rcslider from '../../components/rcslider';
import { Button, message, Tooltip, Dropdown, Divider, Spin } from 'antd';
import {forwardRef, useImperativeHandle} from 'react'
import icon_Face from '@assets/images/icon_Face.svg'
import icon_Body from '@assets/images/icon_Body.svg'
import { useContext } from 'react'
import { AIEditContext } from '../../AIEditContext';
import { Segmented } from 'antd';
import '../../aiEdit.scss'

const AIBeauty = forwardRef((props, ref) => {
    const {initialized, nFace} = useContext(AIEditContext)
    const [segmentedKey, setSegmentedKey] = useState('脸部')
    return (
        <div className='edit_cont aibeauty'>
            <div className={`edit_center ${!props.url && 'disabled'}`}>
                <Button disabled={!initialized} type="primary"  className='beauty_btn' onClick={props.oneClickBeauty}>一键美化</Button>
                <Segmented
                    options={['脸部', '身体']}
                    defaultValue="脸部"
                    onChange={(value) => {setSegmentedKey(value)}}
                />
                {
                    segmentedKey == '脸部'? <div className='edit_center_'>
                    {
                        props.faceArray?.map(item => (
                            <Rcslider disabled={!initialized || nFace == 0} key={item.name} value={item.value} getRcsliderValue={(value) => { props.saveUndoList(); item.onChange(value) }} title={item.label} min={-100} max={100}></Rcslider>
                        ))
                    }
                    </div>: <div className='edit_center_' >
                        {
                            props.bodyArray?.map(item => (
                                <Rcslider disabled={!initialized} key={item.name} value={item.value} getRcsliderValue={(value) => { props.saveUndoList(); item.onChange(value) }} title={item.label} min={-100} max={100}></Rcslider>
                            ))
                        }
                    </div>
                } 
            </div>
        </div>
    )
})

export default AIBeauty;
