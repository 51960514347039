import { useEffect, useState } from 'react'
import './processImg.scss'

function ProcessImg (props) {
    const { taskStatus, imgSrc, } = props

    const [process, setProcess] = useState(10) // 进度 0-100； -1表示失败
    const [showProcess, setShowProcess] = useState(false) // 显示进度
    const [showComplete, setShowComplete] = useState(false) // 显示完成标记
    const [showFailed, setShowFailed] = useState(false)

    useEffect(() => {
        if (taskStatus == 'failed') { // 失败
            setShowFailed(true)
            setShowComplete(false)
            setShowProcess(false)
        } else if (taskStatus == 'succeeded') { // 成功
            if (showProcess) {
                setShowComplete(true)
                setShowProcess(false)
                setShowFailed(false)
            }
        } else { // 进行中
            setProcess(taskStatus || '0%')
            setShowProcess(true)
            setShowFailed(false)
            setShowComplete(false)
        }
    }, [props.taskStatus])

    useEffect(() => {
        if (showComplete) {
            setTimeout(() => {
                setShowComplete(false)
            }, 3000)
        }
    }, [showComplete])

    return (
        <div className='process-img-box'>
            <img className='process-img' src={imgSrc} alt="" />
            { showProcess ? (
                <div className='process-contain'>
                    <div className='process-bar' style={{width: process}}></div>
                </div>
            ) : null }
            {
                showComplete ? (
                    <img className='completion-icon' src={require('@assets/images/icon_Completion.png')} alt="" />
                ) : null
            }
            {
                showFailed ? (
                    <img className='failed-icon' src={require('@assets/images/icon_Failed.png')} alt="" />
                ) : null
            }
        </div>
    )
}

export default ProcessImg