import { useCallback, useEffect, useRef, useState } from "react"
import styles from './index.module.scss'
import { PlusOutlined, LoadingOutlined, DeleteOutlined, CheckCircleFilled, CloseCircleFilled, RightOutlined } from '@ant-design/icons';
import PSAIModal from "@PSAIComponents/PSAIModal";
import { Form, Image, Input, Space, Upload, message } from "antd";
import * as _ from "underscore";
import Utils from '@utils/utils'
import {forwardRef, useImperativeHandle} from 'react'
import API from '@api/api'

const UploadRemark = () => {
    const rightList = [
        'https://photostudio-ai-oss.arcsoft.com.cn/photostudio-web/admin/assets/constantid/constantid_正脸.jpg',
        'https://photostudio-ai-oss.arcsoft.com.cn/photostudio-web/admin/assets/constantid/constantid_低头.jpg',
        'https://photostudio-ai-oss.arcsoft.com.cn/photostudio-web/admin/assets/constantid/constantid_仰视.jpg',
        'https://photostudio-ai-oss.arcsoft.com.cn/photostudio-web/admin/assets/constantid/constantid_正侧.jpg',
    ]
    const wrongList = [
        'https://photostudio-ai-oss.arcsoft.com.cn/photostudio-web/admin/assets/constantid/constantid_不是同一人.jpg',
        'https://photostudio-ai-oss.arcsoft.com.cn/photostudio-web/admin/assets/constantid/constantid_面部不清楚或有遮挡.jpg',
        'https://photostudio-ai-oss.arcsoft.com.cn/photostudio-web/admin/assets/constantid/constantid_光线过暗.jpg',
        'https://photostudio-ai-oss.arcsoft.com.cn/photostudio-web/admin/assets/constantid/constantid_没有人物.jpg',
    ]
    return (
        <div className={styles.UploadRemark}>
            <p className={styles.RightContent}>
                <CheckCircleFilled />
                <span>图片要求</span>
                <span>同一人不同角度的大头照，必须包含一张正脸照</span>
            </p>
            <div className={styles.RemarkList}>
            {
                rightList.map((item, index) => (
                    <div key={item}>
                        <Image
                            width={"100%"}
                            height={"100%"}
                            preview={false}
                            src={item}
                        />
                        {index == 0 && <p><span>* 必须包含正脸照</span></p>}
                    </div>
                ))
            }
            </div>
            <p className={styles.WrongContent}>
                <CloseCircleFilled />
                <span>不支持</span>
                <span>多人合照、公众人物、非同一人</span>
            </p>
            <div className={styles.RemarkList}>
            {
                wrongList.map(item => (
                    <div key={item}>
                        <Image
                            width={"100%"}
                            height={"100%"}
                            src={item}
                        />
                    </div>
                ))
            }  
            </div>
            <div className={styles.Disclaimer} onClick={() => window.open('/服务协议.htm')}>
                <span>免责说明</span>
                <span><i className={styles.arrow}></i></span>
            </div>
        </div>
    )
}

const ImageCard = (props) => {
    const handleRemove = () => {
        props.onRemove()
    }
    return (
        <div className={styles.ImageCard}>
            <Image
                preview={false}
                width={'100%'}
                height={'100%'}
                src={Utils.getImgUrlWithWebp(props.value)}
            />
            <i className={styles.del_btn} onClick={handleRemove}></i>
        </div>
    )
}

const UploadImage = (props) => {
    const [maxCount, setMaxCount] = useState(props.maxCount)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setMaxCount(props.maxCount)
    }, [props.maxCount])

    // 1.易盾审核
    // 2.公众人物检测（上传接口增加bool figureCheck）
    // 3.不支持多人照（接人脸检测/body检测，是否为单张人脸/body）——创建constantID时处理
    // 4.头肩照（需要接body检测）——创建constantID时处理
    // 5.为同一人（要接人脸相似度检测，face ID，判断是否为同一人）——创建constantID时处理
    const onUploadFileList = useCallback(_.debounce(async (fileList) => {
        setLoading(true)
        fileList = fileList.slice(0, maxCount)
        for (let i = 0; i < fileList.length; i++) {
            try {
                console.log(`处理第${i}张图片`)
                let file = fileList[i]
                // 必须上传图片
                const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/webp'|| file.type === 'image/avif';
                if (!isJpgOrPng) throw new Error('您只能上传JPG/PNG/WEBP/AVIF格式的文件')
                // 上传图片resize到2048
                const resizeRes = await Utils.resizeImageFileMaxByAliyunBy(file, 2048)
                if (!resizeRes || !resizeRes.success) continue
                file = resizeRes.file
                // 易盾审核+公众人物检测
                const res = await Utils.checkImageIslegal(file, 0, null, true)
                if (!res) continue
                if (!res.isLegal) throw new Error('经检测，您上传的内容涉嫌违规，已进行屏蔽处理，人工审核会对图片进行复核。')
                props.onUpload(res.data)
            } catch (err) {
                message.warning(err.message)
            }
        }
        setLoading(false)
    }, 200), [maxCount])

    const handleBeforeUpload = async (file, fileList) => {
        onUploadFileList(fileList)
        return false
    }
    const handleDrop = async (e) => {
        if ("getFilesAndDirectories" in e.dataTransfer) {
            const filesAndDirs = await e.dataTransfer.getFilesAndDirectories()
            const fileList = await Utils.getFilesFromDirs(filesAndDirs, "/")
            onUploadFileList(fileList)
        }
    }

    return (
        <Upload.Dragger
            disabled={loading}
            className={styles.UploadWrap}
            accept='image/jpeg,image/png,image/webp,image/avif'
            listType="picture-card"
            showUploadList={false}
            multiple
            maxCount={maxCount}
            beforeUpload={handleBeforeUpload}
            onDrop={handleDrop}
        > 
            <div className={styles.UploadContent}>
                {loading ? <LoadingOutlined /> : <PlusOutlined />}
                <p>上传图片</p>
            </div>
        </Upload.Dragger>
    )
}

const CustomModelForm = forwardRef((props, ref) => {
    const [form] = Form.useForm()
    const items = Form.useWatch(['items'], form)

    const initialValues = {
        name: "",
        items: []
    }

    useImperativeHandle(ref, () => ({
        onSubmit
    }))

    const onSubmit = () => {
        return form.validateFields().then(values => {
            return values
        }).catch(err => {
            console.error(err)
        })
    }

    return (
        <div className={styles.CustomModelFormWrap}>
            <Form
                autoComplete="off"
                form={form}
                initialValues={initialValues}
                layout="vertical"
            >
                <Form.Item
                    label="模特名称"
                    name="name"
                    rules={[
                        { required: true, message: '请输入模特名称', }
                    ]}
                >
                    <Input
                        placeholder="请输入您的模特名或风格类型"
                        allowClear
                        maxLength={5}
                    />
                </Form.Item>
                <Form.Item
                    label={<div><span>模特图</span> <span style={{fontSize: '12px', color: '#979797', marginLeft: '10px'}}>{items?.length||0} / 4张</span></div>}
                    required
                >
                    <Form.List
                        name="items"
                        rules={[{
                            validator: async (_, message) => {
                                if (!message || message.length == 0) return Promise.reject(new Error('请上传至少一张模特图'));
                            }
                        }]}
                    >
                    {(fields, opt, {errors}) => (
                        <>
                        <div style={{display: 'flex', columnGap: 8}}>
                        {
                            fields.map(field => (
                                <Form.Item key={field.key} noStyle name={[field.name, 'url']}>
                                    <ImageCard onRemove={() => opt.remove(field.name)} />
                                </Form.Item>
                            ))
                        }
                        {
                            fields.length < 4 &&
                            <UploadImage key="upload" maxCount={4-fields.length} onUpload={url => opt.add({url})} />
                        }
                        </div>
                        <div style={{position: "absolute", bottom: 0, transform: 'translate(0, 100%)'}}>
                            <Form.ErrorList style={{width: '100%'}} errors={errors}/>
                        </div>
                        </>
                    )}
                    </Form.List>
                </Form.Item>
            </Form>
            <UploadRemark />
        </div>
    )
})

export default function CustomModel (props) {
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const formRef = useRef()

    const handelOk = async () => {
        try {
            setLoading(true)
            const res = await formRef.current.onSubmit()
            if (res) {
                const { name, items } = res
                const res2 = await API.generateConstantId({
                    modelName: name,
                    imageUrls: items.map(item => item.url)
                })
                if (res2.code != 0) throw new Error(res2.message)
                message.success('定制模特添加成功')
                setOpen(false)
                props.onChange()
            }
        } catch (err) {
           message.warning(err.message) 
        } finally {
            setLoading(false)
        } 
    }

    return (
        <>
        <div className={styles.sceneItem} onClick={() => setOpen(true)}>
            <div className={styles.sceneItemContent}>
                {loading ? <LoadingOutlined /> : <PlusOutlined />}
                <p>定制模特</p>
            </div>
        </div>
        <PSAIModal
            title="定制模特"
            maxWidth={586}
            open={open}
            confirmLoading={loading}
            onOk={handelOk}
            onCancel={() => setOpen(false)}
        >
            <CustomModelForm ref={formRef} />
        </PSAIModal>
        </>
    )
}