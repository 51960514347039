import { Button, Form, Input, message } from "antd";
import { useEffect, useState } from "react";
import VerityCode from "../VerifyCode";
import Utils from '@utils/utils'
import eventBus from "@utils/eventBus";
import API from '@api/api'
import styles from './index.module.scss'
import PSAIModal from "@PSAIComponents/PSAIModal";

export default function MobileAndVerifyForm (props) {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const mobile = Form.useWatch(['mobile'], form)

    const initialValues = {
        mobile: props.mobile || '',
        verifyCode: "",
        invitationCode: String(sessionStorage.getItem('inviteCode') || '').toUpperCase()
    }

    useEffect(() => {
        props.onMobileChange(mobile)
    }, [mobile])

    const handleValidateMobile = () => {
        return form.validateFields(['mobile'])
    }

    const onSubmit = () => {
        return new Promise((resolve) => {
            form.validateFields().then(values => {
                if (Utils.getCurrentDomainAndCheckTiktok()) {
                    PSAIModal.confirm({
                        title: '用户须知',
                        content: (
                            <div>
                                <p style={{marginBottom: 10}}>您必须是年满18周岁且具有完全民事行为能力的人，在通过TikTok导航栏注册登录使用PhotoStudio<span style={{fontSize: '.8em'}} className="registered_trademark">®</span> AI（以下简称“PSAI”）的全部产品及服务之前，请您务必审慎阅读、充分理解本须知内容。</p>
                                <p style={{marginBottom: 10}}>您通过TikTok导航栏或其他PSAI官方合作授权渠道导航栏进入PSAI首页完成认证注册的账号相互独立。不会因为您注册认证登录使用的是同一手机号码、电子邮箱或其他可用于PSAI账号注册的信息而共享、存续您任一账号下购买的服务及相应的权益。</p>
                                <p>您通过TikTok导航栏进入PSAI首页完成认证即视为您已理解并接受本须知。您购买的产品和会员服务仅能通过TikTok导航栏登录使用。</p>
                            </div>
                        ),
                        okText: '已知晓并继续',
                        onOk () {
                            handelLogin(values)
                        }
                    })
                } else {
                    handelLogin(values)
                }
                resolve(true)
            })
        })
    }

    const handelLogin = (values) => {
        setLoading(true)
        API.userLogin({
            mobile: values.mobile,
            verifyCode: values.verifyCode,
            username: "",
            password: "",
            mode: 1,
            invitationCode: String(values.invitationCode).trim().toUpperCase(),
        }).then((res) => {
            if (res.code == 0) {
                setTimeout(() => props.loginCallback(res.data), 500)
            } else if (res.code == -5007) {
                eventBus.emit('suspenNotice', res.data.manualCheckNum, res.data.invalidDays, res.data.appealBeforeTime, res.data.token)
                setLoading(false)
            } else {
                message.error(res.message)
                setLoading(false)
            }
        })
    }

    return (
        <>
        <Form
            autoComplete="off"
            form={form}
            initialValues={initialValues}
            onFinish={onSubmit}
        >
            <Form.Item
                name="mobile"
                validateFirst
                validateTrigger="onBlur"
                rules={[
                    { required: true, message: '请输入手机号', },
                    () => ({
                        validator(_, value) {
                            if (!Utils.validateContact(value)) return Promise.reject(new Error('您输入的手机号错误'))
                            return Promise.resolve()
                        }
                    })
                ]}
            >
                <Input
                    tabIndex="1"
                    size="large"
                    placeholder="请输入手机号"
                    maxLength={11}
                    allowClear
                    prefix={<img style={{width: 16}} src={require('@assets/images/icon_phone.png')} alt="" />}
                />
            </Form.Item>
            <Form.Item
                name="verifyCode"
                rules={[ { required: true, message: '请输入验证码', }, ]}
            >
                <VerityCode mobile={mobile} onValidate={handleValidateMobile} />
            </Form.Item>
            <Form.Item
                name="invitationCode"
                rules={[ { required: false }, ]}
            >
                <Input
                    tabIndex="4"
                    size="large"
                    placeholder="请输入邀请码（选填）"
                    allowClear
                    prefix={<img style={{width: 16}} src={require('@assets/images/icon_Number.png')} alt="" />}
                />
            </Form.Item>
            <Form.Item>
                <Button
                    tabIndex="5"
                    size="large"
                    block
                    type="primary"
                    loading={loading}
                    htmlType="submit"
                >
                    登 录 / 注 册
                </Button>
            </Form.Item>
        </Form>
        {
            Utils.getCurrentDomainAndCheckTiktok() &&
            <div className={styles.EmailRegister}>
                <span onClick={props.onEmailRegiste}>邮箱注册</span>
            </div>
        }
        </>
    )
}