import { Spin, Button, Upload, message, Modal } from "antd"
import { useContext, useState, useMemo } from "react"
import Utils from '@utils/utils'
import styles from './index.module.scss'
import MaskDressform from "../components/MaskDressform/MaskDressform"
import { CLOTH_TYPE, IMAGE_TYPE } from "@utils/CONST"
import { GenerateContext } from "@utils/GenerateContext"
import ClothTabs from "../components/ClothTabs"
import Example from '@assets/json/example.json'
import useResizeObserver from "@utils/useResizeObserver"
import exampleJSON from '@assets/clothes_example/example.json'
import MaskClothes from "../components/MaskClothes/MaskClothes"
import {ExclamationCircleOutlined} from '@ant-design/icons';
import PSAIModal from "@PSAIComponents/PSAIModal"

const UploadContent = (props) => {
    const [exampleList] = useState(Example)
    
    const modelExamples = useMemo(() => {
        return exampleList
            .filter(item => {
                if (props.clothType == CLOTH_TYPE.SKIRT) {
                    return item.type == 'clothes_skirt'
                } else {
                    if (props.bodyPart == 1) {
                        return item.type == 'clothes_short' || item.type == 'clothes_longSleeve'
                    } else {
                        return item.type == 'clothes_pants'
                    }
                }
            })
            .filter(item => !item.disabled)
    }, [exampleList, props.clothType, props.bodyPart])

    const onClick = (e, example) => {
        e.stopPropagation()
        const {category, name, source, type} = example
        Utils.urlToBase64(source).then(base64 => {
            const file = Utils.convertBase64ToFile(base64, name)
            props.onUpload(file, name)
        })
    }

    const tipText = useMemo(() => {
        return props.clothType == CLOTH_TYPE.SKIRT ?
                "上传连体装" :
                props.clothType == CLOTH_TYPE.SUIT ?
                    props.bodyPart == 1 ?
                    "上传上装图" : 
                    " 上传下装图" :
                ""
    }, [props.clothType, props.bodyPart])
    
    return (
        <div className={styles.UploadContent}>
            <div className={styles.UploadHandle}>
                <Button className={styles.UploadBtn} type="primary">{tipText}</Button>
                <p className={styles.UploadTip2}>建议图片尺寸不小于500*500，长宽比不超过2:1</p>
                <div className={styles.UploadSample}>
                    <ul>
                    {
                        modelExamples.map((item, index) => (
                            <li key={item.name}>
                                <img src={Utils.getImgUrlWithWebp(item.source)} onClick={e => onClick(e, item)} />
                            </li>
                        ))
                    }
                    </ul>
                </div>
            </div>
        </div>
    )
}

const UploadClothes = (props) => {
    const [loading, setLoading] = useState(false)
    const {
        setClothLoadingFlag
    } = useContext(GenerateContext)

    const onBeforeUpload = async (file, name) => {
        setClothLoadingFlag(true)
        setLoading(true)
        try {
            await props.onUpload(file, name)
            setClothLoadingFlag(false)
        } finally {
            setLoading(false)
            setClothLoadingFlag(false)
            return false
        }
    }
    
    return (
        <div className={styles.UploadBox}>
            <Spin spinning={loading} size="large">            
                <Upload
                    accept={'image/jpeg,image/png,image/webp,image/avif'}
                    name="avatar"
                    listType="picture-card"
                    showUploadList={false}
                    className={styles.Upload}
                    beforeUpload={onBeforeUpload}
                    maxCount={1}
                >
                    <UploadContent
                        clothType={props.clothType}
                        bodyPart={props.bodyPart}
                        onUpload={onBeforeUpload}
                    />
                </Upload>
            </Spin>
        </div>
    )
}

const SkirtModule = (props) => {
    const onNpyChange = (npy) => {
        props.onNpyChange(npy, props.bodyPart)
    }
    
    return (
        props.segmentResult?.length > 0 ?
        <div className={`${styles.SkirtModule_mask} ${styles[`cloth_type_${props.clothType}`]}`}>
        {
            props.clothType == CLOTH_TYPE.SKIRT ?
            <MaskDressform
                border
                imageType={IMAGE_TYPE.CLOTHES}
                aiInteractiveNpy={props.aiInteractiveNpy}
                segmentResult={props.segmentResult}
                reUpload={(file) => props.reUpload(file, props.bodyPart)}
                deleteOpt={() => props.deleteOpt(props.bodyPart)}
                mattingOpt={() => props.mattingOpt(props.bodyPart)}
                composeOpt={() => props.composeOpt(props.bodyPart)}
                onNpyChange={onNpyChange}
            /> :
            <MaskClothes
                border
                bodyPart={props.bodyPart}
                aiInteractiveNpy={props.aiInteractiveNpy}
                segmentResult={props.segmentResult}
                reUpload={(file) => props.reUpload(file, props.bodyPart)}
                deleteOpt={() => props.deleteOpt(props.bodyPart)}
                mattingOpt={() => props.mattingOpt(props.bodyPart)}
                composeOpt={() => props.composeOpt(props.bodyPart)}
                onNpyChange={onNpyChange}
            />
        }
        {
            !props.sampleVisible &&
            <div className={styles.UploadExample} onClick={() => props.onExampleOpen(props.clothType)}>
                <ExclamationCircleOutlined />
                <span>图片要求</span>
            </div>
        }
        </div> :
        <div className={styles.SkirtModule_upload}>
            <UploadClothes
                clothType={props.clothType}
                bodyPart={props.bodyPart}
                onUpload={props.onUpload}
            />
        {
            !props.sampleVisible &&
            <div className={styles.UploadExample} onClick={() => props.onExampleOpen(props.clothType)}>
                <ExclamationCircleOutlined />
                <span>图片要求</span>
            </div>
        }
        </div>
    ) 
}

const ExampleContent = (props) => {
    const data = props.clothType == CLOTH_TYPE.SUIT ? exampleJSON.SUIT : exampleJSON.SKIRT

    return (
        <div className={`${styles.ExampleContent} ${styles[props.direction]}`}>
            <div>
                <p className={styles.ExampleTitle}>正确示例</p>
                <img src={require(`@assets/clothes_example/${data[`correct_img_${props.direction}`]}`)} />
                <p className={styles.ExampleTips}>{data.correct_texts.map(item => <span key={item}>{item}</span>)}</p>
            </div>
            <div>
                <p className={styles.ExampleTitle}>错误示例</p>
                <img src={require(`@assets/clothes_example/${data[`error_img_${props.direction}`]}`)} />
                <p className={styles.ExampleTips}>{data.error_texts.map(item => <span key={item}>{item}</span>)}</p>
            </div>
        </div>
    )
}

const SubCompClothes = (props) => {
    // AI试衣类型：0-上衣，1-下装，2-裙子，3-套装 
    /**
    （1）0.0.30和模型，更改tryon流程，支持上衣、下装、 裙子和套装。 self_defined 控制是否自定义模特【0-预置模特，1-自定义】；
    （2）启用cloth_type 参数，【0-上衣，1-下装，2-裙子，3-套装】；
    （3）套装的衣服图和mask url传输结构: "上衣url,下装url"
    （4）t_model = 1
     */
    const {
        clothType,
        setClothType,
        rightProjectId,
        setProjectId,
        setProjectName,
    } = useContext(GenerateContext)

    const onBeforeUpload = async (file, name, bodyPart=1) => {
        const res = await props.onBeforeUpload(file, name, bodyPart)
        if (!res) return
        console.log('===setProjectId null in SubCompClothes===')
        setProjectId()
        setProjectName('未命名')
    }

    const [wrapWidth, setWrapWidth] = useState(0)
    const [wrapHeight, setWrapHeight] = useState(0)
    const targetRef = useResizeObserver((entries) => {
        for (let entry of entries) {
            // 处理尺寸变化的逻辑
            setWrapWidth(entry.contentRect.width)
            setWrapHeight(entry.contentRect.height)
        }
    })

    const sampleVisible = useMemo(() => {
        if (rightProjectId) return false
        if (wrapWidth < 680) {
            return false
        } else return true
    }, [wrapWidth, rightProjectId])

    const [exampleOpen, setExampleOpen] = useState()
    const onExampleOpen = (clothType) => {
        setExampleOpen(clothType)
    }

    const SuitRender = (
        <div className={styles.Content}>
            <div className={styles.ContentUpload} id="tour_target_upload">
                <SkirtModule
                    key={1}
                    sampleVisible={sampleVisible}
                    clothType={clothType}
                    bodyPart={1}
                    aiInteractiveNpy={props.aiInteractiveNpy1}
                    segmentResult={props.segmentResult1}
                    reUpload={props.reUpload}
                    deleteOpt={props.deleteOpt}
                    mattingOpt={props.mattingOpt}
                    composeOpt={props.composeOpt}
                    onUpload={(file, name) => onBeforeUpload(file, name, 1)}
                    onNpyChange={props.onNpyChange}
                    onExampleOpen={onExampleOpen}
                />
                <SkirtModule
                    key={2}
                    sampleVisible={sampleVisible}
                    clothType={clothType}
                    bodyPart={2}
                    aiInteractiveNpy={props.aiInteractiveNpy2}
                    segmentResult={props.segmentResult2}
                    reUpload={props.reUpload}
                    deleteOpt={props.deleteOpt}
                    mattingOpt={props.mattingOpt}
                    composeOpt={props.composeOpt}
                    onUpload={(file, name) => onBeforeUpload(file, name, 2)}
                    onNpyChange={props.onNpyChange}
                    onExampleOpen={onExampleOpen}
                />
            </div>
            <div className={`${styles.ContentSample} ${!sampleVisible && styles.hidden}`}>
                <ExampleContent clothType={clothType} direction="vertical" />
            </div>
        </div>
    )
    const SkirtRender = (
        <div className={styles.Content}>
            <div className={styles.ContentUpload} id="tour_target_upload">
                <SkirtModule
                    key={3}
                    sampleVisible={sampleVisible}
                    clothType={clothType}
                    aiInteractiveNpy={props.aiInteractiveNpy}
                    segmentResult={props.segmentResult}
                    reUpload={props.reUpload}
                    deleteOpt={props.deleteOpt}
                    mattingOpt={props.mattingOpt}
                    composeOpt={props.composeOpt}
                    onUpload={(file, name) => onBeforeUpload(file, name)}
                    onNpyChange={props.onNpyChange}
                    onExampleOpen={onExampleOpen}
                />
            </div>
            <div className={`${styles.ContentSample} ${!sampleVisible && styles.hidden}`}>
                <ExampleContent clothType={clothType} direction="vertical" /> 
            </div>
        </div>
    )

    return (
        <div className={styles.Container} ref={targetRef}>
            <ClothTabs
                hasRightComp={rightProjectId}
                clothType={clothType}
                onChange={setClothType}
                onProjectChange={props.onResetProject}
                items={[{
                    label: '组合套装',
                    key: CLOTH_TYPE.SUIT,
                    children: SuitRender
                }, {
                    label: '连体装',
                    key: CLOTH_TYPE.SKIRT,
                    children: SkirtRender
                }]}
            />
            <PSAIModal
                title={"图片要求"}
                open={exampleOpen}
                maxWidth={760}
                footer={null}
                onCancel={() => setExampleOpen(false)}
            >
                <ExampleContent clothType={exampleOpen} direction="horizontal" />
            </PSAIModal>
        </div>
    )
}

export default SubCompClothes