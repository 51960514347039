import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle, useContext } from 'react';
import { fabric } from 'fabric';
import Utils from '@utils/utils'
import { message } from 'antd';
import './fillLight.scss'
import Module from '@utils/arcsoft_relight.js';
import FilterModule from '@utils/arcsoft_filter.js';
import { throttle } from 'lodash';
import API from '@api/api'
import axios from 'axios';
import { AppContext } from '@utils/AppContext';
import eventBus from '@utils/eventBus';
import { AIEditContext } from '../../AIEditContext';

const formartNumber = (number, digits=2) => {
    return Number(number.toFixed(digits))
}

let defaultHigh = 50
let defaultRange = 50
const LightCanvas = forwardRef((props, ref) => {
    const {addWatermark} = useContext(AIEditContext)
    const { setGlobalLoading } = useContext(AppContext);
    
    const imgRef = useRef()
    const canvasRef = useRef(null);
    const isLoopRef = useRef(false)
    const canvasRefMouse = useRef(null);
    const fabricCanvasRef = useRef()
    const [fabricCanvas, setFabricCanvas] = useState(null);
    const fabricCanvasMouseRef = useRef()
    const [fabricCanvasMouse , setFabricCanvasMouse] = useState(null);
    const [module , setModule] = useState(null);
    const [filterModule , setFilterModule] = useState(null);
    const lightFlagRef = useRef(true) // 避免多次点击
    const firstLightRef = useRef(true) // 判断是否是第一次打光
    const moveFlagRef = useRef(false) // 避免画布初始化时，触发打光
    const [img , setImg] = useState(null);
    const [highSize , setHighSize] = useState(50);
    const axisRef = useRef({left: 56, top: 50})
    const lightRef = useRef()

    const displayScaleRef = useRef(props.displayScale)
    const displayWidthRef = useRef(props.reswidth)
    const displayHeightRef = useRef(props.resheight)

    const imgResultRef = useRef()
    const hasAlphaRef = useRef(false)

    useEffect(() => {
        displayScaleRef.current = props.displayScale
        displayWidthRef.current = props.reswidth
        displayHeightRef.current = props.resheight
    }, [props.displayScale, props.reswidth, props.resheight])

    useImperativeHandle(ref, () => ({
        resetLight,
        changeLight,
        removeLight,
        filterLoadModule,
        lightLoadModule,
        aibeautyInit
    }));

    const getImageResult = (canvas) => {
        const base64Image = canvas.toDataURL(hasAlphaRef.current ? 'image/png' : 'image/jpeg')
        return base64Image
    }

    useEffect(() => {
        if (props.reset) {
            if (!fabricCanvasRef.current) return
            imgResultRef.current = ''
            fabric.Image.fromURL(props.orignPicBase64, (img) => {
                img.scale(displayScaleRef.current) // 背景展示完全
                fabricCanvasRef.current.setBackgroundImage(img, () => {
                    fabricCanvasRef.current.renderAll()
                })
            })
        }
    }, [props.reset, props.orignPicBase64])

    // 滤镜
    function filterLoadModule(style) {
        loadImg(props.orignPic).then((result) => {
                const {img, pixels} = result
                // console.log(filterModule)
                let pBuffer = filterModule._malloc(img.width*img.height*3)
                filterModule.writeArrayToMemory(pixels, pBuffer);

                fetch(`/resource/${style}.bin`).then(function(response) {
                    if (response.ok) {
                        return response.arrayBuffer();
                    }
                    throw new Error('Network response was not ok.');
                }).then(function(arrayBuffer) {
                    // 在此处处理二进制数据
                    // console.log(arrayBuffer);
                    var uint8Array = new Uint8Array(arrayBuffer);
        
                    var filter = filterModule._malloc(uint8Array.length)
                    filterModule.writeArrayToMemory(uint8Array, filter);
        
                    filterModule._setSize(img.width, img.height)
                    let resimg = filterModule._processImageFilter(pBuffer, filter, uint8Array.length)
                    if(resimg) {
                        // console.log("transform success");
                        var bgr = new Uint8Array(filterModule.HEAPU8.buffer, resimg, img.width*img.height*3);

                        const fabricImage = fabricCanvasRef.current.get('backgroundImage')
                        // 创建一个临时的Canvas元素
                        var tempCanvas = document.createElement('canvas');
                        var tempContext = tempCanvas.getContext('2d');
                        // 设置Canvas的宽度和高度与Fabric.Image对象相匹配
                        tempCanvas.width = fabricImage.width * img.width / fabricImage.width;
                        tempCanvas.height = fabricImage.height * img.width / fabricImage.width;
                        // 渲染Fabric.Image对象到Canvas上
                        fabricImage.render(tempContext);
                        // 获取Canvas上的图像数据
                        var imageData = tempContext.getImageData(0, 0, tempCanvas.width, tempCanvas.height);
                        const data = imageData.data;
        
                        for (let i = 0, j = 0; i < data.length; i += 4, j += 3) {
                            data[i] = bgr[j+2];
                            data[i + 1] = bgr[j + 1];
                            data[i + 2] = bgr[j];
                            data[i + 3] = 255;
                        }
                        // 绘制 ImageData 对象到 Canvas 上
                        drawImageDataOnCanvas(imageData, canvasRef.current, 'filter')
                    }
                }).catch(function(error) {
                    console.log('Error:', error.message);
                });
        })  
    }
    // 打光
    function lightLoadModule(x, y, name, high, range, flag) { // flag 是为了在初次点击光源按钮时保存base64,后面仅在移动光源抬起后保存
        if(!lightFlagRef.current) return
        console.log('high', high)
        console.log('range', range)
        if(firstLightRef.current)  {
            setGlobalLoading('智能补光初始化中，请稍后')
        }
        setTimeout(() => { // 这里加定时器  是为了先加载loading，否则会卡住导致loading不显示
            lightFlagRef.current = false
            // console.time('总时间');
            // console.time('处理前');
            const width = formartNumber(displayWidthRef.current / displayScaleRef.current)
            const height = formartNumber(displayHeightRef.current / displayScaleRef.current)
            let angleX = ((x - (width / 2)) / (width / 2)) * 90
            let angleY = ((y - (height / 2)) / (height / 2)) * 90
            let jsString = ''
            
            if(name == 'light_intensity') {
                jsString = `{"relight_modify_array":[{"name": "auto_angle", "value": 0},{"name":"light_area","value": ${range}},{"name":"x","value": ${x}},{"name":"y","value": ${y}},{"name":"colorR","value": 255},{"name":"colorG","value": 255},{"name":"colorB","value": 255},{"name": "light_intensity", "value": ${high}},{"name":"rotate_h","value": ${angleX}},{"name":"rotate_v","value": ${angleY}}]}`
            } else { // 自动补光
                jsString = `{"relight_modify_array":[{"name": "auto_angle", "value": 1},{"name": "light_intensity", "value": 100}, {"name":"rotate_h","value": 0},{"name":"rotate_v","value": 0}]}`
            }
            const strptr = module.stringToNewUTF8(jsString);
            // console.timeEnd('处理前');

            // console.time('处理中');
            let resimg = module._processRelight(strptr, jsString.length+1);
            // console.timeEnd('处理中');
            if(resimg) {
                // console.time('处理后');
                console.log("transform success");
                let bgr = new Uint8Array(module.HEAPU8.buffer, resimg, img.width*img.height*3);

                const fabricImage = fabricCanvasRef.current.get('backgroundImage')
                // 创建一个临时的Canvas元素
                var tempCanvas = document.createElement('canvas');
                var tempContext = tempCanvas.getContext('2d');
                // 设置Canvas的宽度和高度与Fabric.Image对象相匹配
                tempCanvas.width = fabricImage.width * img.width / fabricImage.width;
                tempCanvas.height = fabricImage.height * img.width / fabricImage.width;
                // 渲染Fabric.Image对象到Canvas上
                fabricImage.render(tempContext);
                // 获取Canvas上的图像数据
                var imageData = tempContext.getImageData(0, 0, tempCanvas.width, tempCanvas.height);
                const data = imageData.data;

                for (let i = 0, j = 0; i < data.length; i += 4, j += 3) {
                    data[i] = bgr[j+2];
                    data[i + 1] = bgr[j + 1];
                    data[i + 2] = bgr[j];
                    data[i + 3] = 255;
                }
                // console.log(imageData)
                drawImageDataOnCanvas(imageData, canvasRef.current, name == 'light_intensity'?'light': 'filter', flag) // 自动补光时也存一下base64
                // console.timeEnd('处理后');
            }
            moveFlagRef.current = true
            lightFlagRef.current = true
            props.getMoveFlag(true)
            
            if(firstLightRef.current) {
                firstLightRef.current = false
                setGlobalLoading(false)
            }
            // console.timeEnd('总时间');
        }, 150)
    }

    // 绘制结果
    function drawImageDataOnCanvas(imageData, targetCanvas, type, flag) {       
        const canvas = document.createElement('canvas');
        canvas.width = imageData.width;
        canvas.height = imageData.height;
    
        const context = canvas.getContext('2d');
        context.putImageData(imageData, 0, 0);
    
        const img = new fabric.Image(canvas)
        img.scale(displayScaleRef.current)
   
        fabricCanvasRef.current.setBackgroundImage(img, () => {
            fabricCanvasRef.current.renderAll()
        })

        setTimeout(() => {
            imgResultRef.current = getImageResult(canvas)
            props.getImgResult(imgResultRef.current)
        })
    }

    // 取3通道的pixels
    function loadImg(url) {
        return new Promise(function(resolve, reject) {
            let img = new Image();
            setImg(img)
            img.crossOrigin = 'anonymous'; // 设置跨域属性，避免污染 canvas
            imgRef.current = img
            img.addEventListener('load', function() {
                // 图像加载完成后，将Promise对象标记为已解决
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                canvas.width = img.width;
                canvas.height = img.height;
                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
  
                const imageData = ctx.getImageData(0, 0, img.width, img.height);
                const data = imageData.data;
                const size = data.length / 4 * 3;
  
                const pixels = new Uint8Array(size);
  
                for (let i = 0, j = 0; i < data.length; i += 4, j += 3) {
                    pixels[j] = data[i+2];
                    pixels[j + 1] = data[i + 1];
                    pixels[j + 2] = data[i];
                }
                resolve({img: img, pixels: pixels});
            });
            img.addEventListener('error', function() {
                // 图像加载失败时，将Promise对象标记为已拒绝
                reject();
            });
            img.src = url;
        });
    }
    // 取单通道的pixels
    function loadImg2(url) {
        return new Promise(function(resolve, reject) {
            let img = new Image();
            img.crossOrigin = 'anonymous'; // 设置跨域属性，避免污染 canvas
            imgRef.current = img
            img.addEventListener('load', function() {
                // 图像加载完成后，将Promise对象标记为已解决
                const canvas = document.createElement('canvas');
                // const canvas = canvasRef_i.current
                const ctx = canvas.getContext('2d');

                canvas.width = img.width;
                canvas.height = img.height;
                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
  
                const imageData = ctx.getImageData(0, 0, img.width, img.height);
                
                const data = imageData.data;
                const size = data.length / 4;

                const pixels = new Uint8Array(size);

                for (let i = 0, j = 0; i < data.length; i += 4, j += 1) {
                    pixels[j] = data[i];
                }

                resolve({img: img, pixels: pixels});
            });
            img.addEventListener('error', function() {
                // 图像加载失败时，将Promise对象标记为已拒绝
                reject();
            });
            img.src = url;
        });
    }
    // 初始化算法
    async function init(orignPic, landmarks, img1, img2, callback) {
        // 打光埋点
        Utils.useroplog(sessionStorage.getItem('oplog_imageType'), 'algorithm', 'aiFillLight', {'AI_edit_pic': orignPic})
        let module = await Module();
        module.print = console.log.bind(console);
        module._init();
        setModule(module)


        let filterModule = await FilterModule()
        filterModule._init()
        setFilterModule(filterModule)

  
        loadImg(orignPic).then((result) => {
                const {img, pixels} = result
                console.log('Image loaded!');
                const size = img.width * img.height * 3;
                const maskSize = img.width * img.height;
      
                let jsString = landmarks
                let strptr = module.stringToNewUTF8(jsString);
                let pBuffer = module._malloc(size)//通过emscripten分配C/C++中的堆内存
                module.writeArrayToMemory(pixels, pBuffer);//js中的数据拷贝到刚分配的C++缓存中；
      
                let bodyMask = '', hairMask = ''
    
                loadImg2(img1).then((result) => {
                    const {img, pixels} = result
                    bodyMask = module._malloc(maskSize)
                    module.writeArrayToMemory(pixels, bodyMask)
                })
    
                loadImg2(img2).then((result) => {
                    const {img, pixels} = result
                    hairMask = module._malloc(maskSize)
                    module.writeArrayToMemory(pixels, hairMask)
                    setTimeout(() => {
                        module._setSize(img.width, img.height)
                        module._fillArray(pBuffer, bodyMask, hairMask, strptr, jsString.length + 1)
                        setGlobalLoading(false)
                        callback(true);
                    }, 300)
                })
                console.log('Image success to load!');
        }).catch(function(reason) {
            console.log(reason);
            console.log('Image failed to load!');
        });
    }

    const aibeautyInit = (callback) => {
        if(fabricCanvasRef.current && props.orignPic) {
            setGlobalLoading('初始化中')
            API.aibeautyPrediction({
                img_path: props.orignPic,
                relight: 1,
            }).then(res => {
                if (res?.code != 0) {
                    message.error(res?.message)
                    setGlobalLoading(false)
                    return
                }
                const predictionId = res.data
                const reqUrl = `${API.aibeautyPredictionUrl}/${predictionId}`
                return loopGetAibeauty(reqUrl, 200).then(res => {
                    fetch(res[0])
                        .then(response => response.text())
                        .then(txtContent => {
                            init(props.orignPic, txtContent, res[1], res[2], res => {
                                callback(true)
                            })
                        }).catch(err => {
                            message.error('发生了错误'+ err.message)
                        })
                })
            }).catch((err) => {
                // message.error(err.message)
                console.error('============', err.message)
            }).finally(() => {
                console.log('set loop false')
                isLoopRef.current = false
            })
        }
    }

    const loopGetAibeauty = (url, time=100) => {
        return new Promise((resolve, reject) => {
            const loopFunc = () => {
                axios.post(url, {}, {
                    headers: {
                        'Content-type' : 'application/json',
                        'Ps-Auth-Token': localStorage.getItem('token')
                    }
                }).then(res => {
                    if((res?.data?.code === 0 && res?.data?.data?.status === 'succeeded')) {
                        resolve(res.data.data.output)
                    } else if(res?.data?.code === 0 && res?.data?.data?.errcode != 0) {
                        const errCode = res.data.data.errcode
                        const errMsg = Utils.parseErrorCode(`[${res.data.data.errcode}]`).message
                        const error = Utils.parseErrorCode(`[${res.data.data.errcode}]`)
                        if (errCode == '71007') {
                            props.errorCallback()
                        } else {
                            eventBus.emit('notification', '提醒', error.message, 'error', error.code)
                        }
                        setGlobalLoading(false)
                        reject(new Error(`${errMsg}[${errCode}]`))
                    } else {
                        if (fabricCanvasRef.current) {
                            setTimeout(() => {
                                loopFunc()
                            }, time)
                        }
                    }
                })
            }
            loopFunc()
        })
    }

    const throttledHandleDrag = throttle((event) => {
        if (lightRef.current) {
            const centerX = lightRef.current.left
            const centerY = lightRef.current.top
            const left = formartNumber(centerX / displayScaleRef.current)
            const top = formartNumber(centerY / displayScaleRef.current)
            if(moveFlagRef.current) {
                axisRef.current = {left: left, top: top}
                lightLoadModule(left, top, "light_intensity", defaultHigh, defaultRange);
            }
        }
    }, 200);

    const handleDrag = (ev) => {
        let canvasBoundaries = fabricCanvasMouseRef.current.calcViewportBoundaries()
        const boundingRect = { // 这样写刷新更加实时，采用getBoundingRect无法及时更新
            left: lightRef.current.left,
            top: lightRef.current.top,
            width: lightRef.current.width * lightRef.current.scaleX,
            height: lightRef.current.height * lightRef.current.scaleY
        }

        let changed = false

        if (boundingRect.left > canvasBoundaries.br.x - boundingRect.width / 2) {
            lightRef.current.set({ left: canvasBoundaries.br.x - boundingRect.width / 2 })
            changed = true
        }
        if (boundingRect.left < canvasBoundaries.tl.x + boundingRect.width / 2) {
            lightRef.current.set({ left: canvasBoundaries.tl.x + boundingRect.width / 2 })
            changed = true
        }
        if (boundingRect.top > canvasBoundaries.br.y - boundingRect.height / 2) {
            lightRef.current.set({ top: canvasBoundaries.br.y - boundingRect.height / 2})
            changed = true
        }
        if (boundingRect.top < canvasBoundaries.tl.y + boundingRect.height / 2) {
            lightRef.current.set({ top: canvasBoundaries.tl.y + boundingRect.height / 2})
            changed = true
        }
        
        if (changed) {
            lightRef.current.setCoords()
            fabricCanvasMouseRef.current.renderAll()
        }

        throttledHandleDrag()
    }

    useEffect(() => {
        // 马赛克
        const patternImageBase64 = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABHNCSVQICAgIfAhkiAAAADBJREFUOE9jfPP69X8GPEBYRASfNAPjqAHDIgz+AwG+iH7z5s1ZfPKMowYwDIMwAACuR1xZvSaHFwAAAABJRU5ErkJggg==';
        const pattern = new fabric.Pattern({
            source: patternImageBase64,
            repeat: 'repeat',
        })

        const image = new Image();

        Utils.hasAlphaChannel(props.orignPicBase64).then(res => hasAlphaRef.current = res)
        image.src = props.orignPicBase64;
        image.onload = () => {
            // console.log('init canvas', image.width, image.height)
            const fabricImage = new fabric.Image(image);
            fabricImage.scale(props.displayScale)
            const newFabricCanvas = new fabric.Canvas(canvasRef.current, {
                width: props.reswidth,
                height: props.resheight,
                backgroundImage: fabricImage,
                backgroundColor: pattern,
                stopContextMenu: true,
            });
            // 禁止对象的拖动和缩放
            fabricImage.set({
                lockMovementX: true,
                lockMovementY: true,
                lockRotation: true,
                lockScalingX: true,
                lockScalingY: true,
                hasControls: false,
                hasBorders: false,
            })
            // newFabricCanvas.add(fabricImage);
            newFabricCanvas.renderAll();

            addWatermark(newFabricCanvas)
            
            fabricCanvasRef.current = newFabricCanvas
            setFabricCanvas(newFabricCanvas);
        };
    }, []);

    useEffect(() => {
        if (!fabricCanvasRef.current) return
        if (!fabricCanvasMouseRef.current) return

        const canvasScaleX = props.reswidth / fabricCanvasRef.current.width
        const canvasScaleY = props.resheight / fabricCanvasRef.current.height

        const bgImage = fabricCanvasRef.current.get('backgroundImage')
        if (bgImage) bgImage.scale(props.displayScale)

        const objs = fabricCanvasRef.current.getObjects()
        objs.forEach(item => {
            item.set({
                scaleX: item.scaleX * canvasScaleX,
                scaleY: item.scaleY * canvasScaleY
            })
            item.setCoords()               
        })

        fabricCanvasMouseRef.current.viewportTransform[4] = fabricCanvasMouseRef.current.viewportTransform[4] * canvasScaleX
        fabricCanvasMouseRef.current.viewportTransform[5] = fabricCanvasMouseRef.current.viewportTransform[5] * canvasScaleY
        fabricCanvasMouseRef.current.setDimensions({ width: props.reswidth, height: props.resheight})
        
        fabricCanvasRef.current.viewportTransform[4] = fabricCanvasRef.current.viewportTransform[4] * canvasScaleX
        fabricCanvasRef.current.viewportTransform[5] = fabricCanvasRef.current.viewportTransform[5] * canvasScaleY
        fabricCanvasRef.current.setDimensions({ width: props.reswidth, height: props.resheight})

        const objs2 = fabricCanvasMouseRef.current.getObjects()
        objs2.forEach(item => {
            if (item.type == 'image') {
                item.scale(highSize/100 * props.displayScale)
                item.set({
                    left: formartNumber(item.left * canvasScaleX),
                    top: formartNumber(item.top * canvasScaleY),
                })
                item.setCoords()
            }         
        })

        fabricCanvasMouseRef.current.renderAll()
        fabricCanvasRef.current.renderAll()
    }, [props.reswidth, props.resheight, props.displayScale, highSize])

    // 创建鼠标样式画布
    useEffect(() => {
        const canvas = canvasRefMouse.current;
        // console.log('props.reswidth', props.reswidth, props.resheight)
        const newFabricCanvas = new fabric.Canvas(canvas, {
            width: props.reswidth,
            height: props.resheight,
            stopContextMenu: true,
        });
        newFabricCanvas.renderAll();

        fabricCanvasMouseRef.current = newFabricCanvas
        setFabricCanvasMouse(newFabricCanvas);
    }, []);


    useEffect(() => {
        if(fabricCanvas && fabricCanvasMouse) {
            props.getCanvasRefsFun(fabricCanvas, fabricCanvasMouse)
        }
    }, [fabricCanvas, fabricCanvasMouse])

    useEffect(() => {
        return () => {
            lightRef.current && lightRef.current.off('moving', handleDrag);
        }
    }, [])

    function removeLight () {
        if (lightRef.current) {
            lightRef.current.off('moving', handleDrag)
            fabricCanvasMouseRef.current.remove(lightRef.current)
            lightRef.current = null
            firstLightRef.current = true
        }
    }

    function changeLight(size, type) {
        if(type == 'range') {
            defaultRange = size
            const axis = axisRef.current
            lightLoadModule(axis['left'], axis['top'], "light_intensity", defaultHigh, size);
        } else if(type == 'high') {
            setHighSize(size)
            defaultHigh = size
            const axis = axisRef.current
            lightLoadModule(axis['left'], axis['top'], "light_intensity", size, defaultRange);
        }
    }

    useEffect(() => {
        if (lightRef.current) {
            const axis = axisRef.current
            createLight(highSize, axis['left'] * displayScaleRef.current, axis['top'] * displayScaleRef.current)
        }
    }, [highSize]);

    useEffect(() => {
        if (!lightRef.current) return
        lightRef.current.set({
            lockMovementX: props.moveable,
            lockMovementY: props.moveable
        })
        fabricCanvasMouseRef.current.renderAll()
    }, [props.moveable])

    const resetLight = (size, x, y) => {
        axisRef.current = {left: x, top: y}
        const axis = axisRef.current
        createLight(size, axis['left'] * displayScaleRef.current, axis['top'] * displayScaleRef.current)
    }

    // 创建指针圆形
    function createLight(size, x, y) {
        if (fabricCanvasMouseRef.current) {
            setHighSize(size)
            defaultHigh = size

            if (lightRef.current) {
                lightRef.current.off('moving', handleDrag);
                fabricCanvasMouseRef.current.remove(lightRef.current)
                lightRef.current = null
            }

            const initialPosition = { x: x, y: y }; // 光源初始位置

            return new Promise((resolve) => {
                fabric.Image.fromURL(require('@assets/images/light.png'), function(oImg) {
                    lightRef.current = oImg
                    const min = 0.3
                    const max = 0.6
                    const scale = size/100 * (max-min) + min
                    lightRef.current.scale(scale)
                    lightRef.current.set({
                        left: initialPosition.x,
                        top: initialPosition.y,
                        originX: 'center',
                        originY: 'center',
                        hasControls: false,
                        hasBorders: false,
                    })
                    fabricCanvasMouseRef.current.add(lightRef.current);
                    fabricCanvasMouseRef.current.renderAll()

                    lightRef.current.on('mousedown', () => {
                        console.log('==========mousedown=======')
                    })
                    lightRef.current.on('moving', handleDrag);

                    lightRef.current.on('mouseup', () => {
                        console.log('==========mouseup=======')
                    })

                    resolve()
                })
            })

            // const circle = new fabric.Circle({
            //     radius: size / 2,
            //     fill: colorStyle,
            //     left: initialPosition.x,
            //     top: initialPosition.y,
            //     originX: 'center',
            //     originY: 'center',
            //     hasControls: false,
            //     hasBorders: false,
            // });
            // fabricCanvasMouse.add(circle);
        }
    }

    return (
        <>
            <div className='lightCanvas' style={{width: props.reswidth, height: props.resheight, opacity: props.opacity}}>
                <div className='paint'>
                    <canvas ref={canvasRef} />
                </div>
                <div className='paintMouse'>
                    <canvas ref={canvasRefMouse} />
                </div>
            </div>
        </>
    )
})

export default LightCanvas;
