import { useRef, useEffect, useMemo, useState, useContext, forwardRef, useImperativeHandle } from 'react';
import { Button, Checkbox, Divider, Empty, Image, Modal, Popover, Spin, message } from 'antd';
import { AppContext } from '@utils/AppContext';
import Utils from '@utils/utils'
import { IMAGE_TYPE, CLOTH_TYPE } from "@utils/CONST"
import styles from './drawer.module.scss'
import { DoubleLeftOutlined } from '@ant-design/icons';
import ModelFilterSelection from '../ModelFilterSelection';
import { GenerateContext } from '@utils/GenerateContext';
import SelectionItem from '../SelectionItem';
import SceneTypeSelection from '../SceneTypeSelection';
import PSAIModal from '@PSAIComponents/PSAIModal';
import API from '@api/api'

const SelectItemSoftedge =  (props) => {
    const { item, getEdgeUrl } = props

    const selectImgClick = (imageUrl) => {
        getEdgeUrl(imageUrl)
        message.success('已添加！')
    }

    return (
        <div className={`${styles.softedge_item} ${styles.softedge_hover}`} onClick={() => selectImgClick(item.imageUrl)} key={item.name} >
            <div className={styles.softedge_item_img} >
                <img className={styles.softedge_styl} src={Utils.addVersionParamToURL(item.imageUrl, 'v', item?.attr?.version)} alt="" />
            </div>
        </div>
    )
}

const Drawer = forwardRef((props, ref) => {
    const {
        imageType,
        setKeepBkg,
        setKeepPose,
        softEdgeList,
        modelDate,
        poseDate,
        customPoseDate,
        scenelDate,
    } = useContext(GenerateContext)
    const {type, getEdgeUrl, setIsDrawerOpen} = props

    const showList = useMemo(() => {
        if (type == 'Model') {
            return modelDate || []
        } else if (type == 'Pose') {
            if (props.drawerPoseIsCustom) return customPoseDate || []
            else return poseDate || []
        } else if (type == 'Scene') {
            if (props.backgroundType == 0) return scenelDate?.filter(item => item.attr.backgroundType == props.backgroundType) || []
            else if (props.backgroundType == 1) return scenelDate?.filter(item => item.attr.backgroundType == props.backgroundType) || []
            else return scenelDate || []
        } else if (type == 'Softedge') {
            return softEdgeList || []
        }
    }, [type, modelDate, poseDate, customPoseDate, scenelDate, softEdgeList, props.backgroundType, props.drawerPoseIsCustom])

    const handelClick = (item) => {
        if (type == 'Model') {
            setKeepPose(false)
            props.onModelChange(item.name)
        } else if (type == 'Pose') {
            let isSelfDefined = item.attr.skin == 'custom'
            let selfDefinedImg = item.attr.tryOnImage
            props.onPoseChange(item.name, isSelfDefined, selfDefinedImg)
        } else if (type == 'Scene') {
            setKeepBkg(false)
            props.onSceneChange(item.name)
        }
    }

    const selectDoubleClick = (scene) => {
        if (scene?.subList && scene?.subList.length > 0) {
            return
        } else {
            handelClick(scene.name)
            setIsDrawerOpen(false)
        }
    }

    const onMoreSceneChange = (item) => {
        handelClick(item)
    }

    // 模特分组
    const customModelList = useMemo(() => {
        if (type == 'Model') {
            return showList.filter(item => item?.attr?.skin == 'custom')
        }else return []
    }, [showList, type])
    const yellowModelList = useMemo(() => {
        if (type == 'Model') {
            return showList.filter(item => item?.attr?.skin == 'yellow')
        }else return []
    }, [showList, type])
    const whiteModelList = useMemo(() => {
        if (type == 'Model') {
            return showList.filter(item => item?.attr?.skin == 'white')
        }else return []
    }, [showList, type])
    const blackModelList = useMemo(() => {
        if (type == 'Model') {
            return showList.filter(item => item?.attr?.skin == 'black')
        }else return []
    }, [showList, type])
    const brownModelList = useMemo(() => {
        if (type == 'Model') {
            return showList.filter(item => item?.attr?.skin == 'brown')
        }else return []
    }, [showList, type,])
    const groupModelList = useMemo(() => {
        const list = []
        if (customModelList.length > 0) list.push({ key: 'custom', title: '自定义', color: '#f3b99f', list: customModelList })
        if (yellowModelList.length > 0) list.push({ key: 'yellow', title: '黄种人', color: '#f3b99f', list: yellowModelList })
        if (whiteModelList.length > 0) list.push({ key: 'white', title: '白种人', color: '#fef6f2', list: whiteModelList })
        if (blackModelList.length > 0) list.push({ key: 'black', title: '黑种人', color: '#560808', list: blackModelList })
        // if (brownModelList.length > 0) list.push({ key: 'brown', title: '棕种人', color: '#ff0000', list: brownModelList })
        return list
    }, [customModelList, yellowModelList, whiteModelList, blackModelList, brownModelList])

    // softEdge分组
    const softEdgeGroupList = useMemo(() => {
        if (!softEdgeList) return []
        const list = [] // 展台 手部 绿植
        list.push({
            key: 'platform',
            title: '展台',
            list: softEdgeList.filter(item => item.attr.group == 'platform')
        }, {
            key: 'hand',
            title: '手部',
            list: softEdgeList.filter(item => item.attr.group == 'hand')
        }, {
            key: 'plant',
            title: '绿植',
            list: softEdgeList.filter(item => item.attr.group == 'plant')
        }, )
        return list
    }, [softEdgeList])

    // AI编辑的姿势分组
    const notCustomPoseList = useMemo(() => {
        if(type == 'Pose') {
            return showList.filter(item => item.attr.skin != 'custom')
        } else return []
    }, [type, showList])
    const customPoseList = useMemo(() => {
        if (type == 'Pose') {
            return showList.filter(item => item.attr.skin == 'custom')
        } else {
            return []
        }
    }, [type, showList])
    const groupPoseList = useMemo(() => {
        const list = []
        if (customPoseList.length > 0) list.push({ key: 'custom', title: '自定义模特', list: customPoseList })
        if (notCustomPoseList.length > 0) list.push({ key: 'not_custom', title: '预设模特', list: notCustomPoseList })
        return list
    }, [customPoseList, notCustomPoseList])

    const groupSceneList = useMemo(() => {
        // 使用/\d+_random$/过滤随机，避免将随机纯色和随机窗幔过滤掉
        if (!showList) return []
        const groupList = {
            '室内': {key: '室内', title: '室内', list: []},
            '户外': {key: '户外', title: '户外', list: []},
        }
        for (let item of showList) {
            let category = item?.attr?.sceneType
            if (!category) category = '户外'
            if (groupList[category]) {
                groupList[category].list.push(item)
            } else {
                groupList[category] = {
                    key: category,
                    title: category,
                    list: [item]
                }
            }
        }
        return Object.values(groupList)
    }, [showList])

    const Title = useMemo(() => {
        if (type == 'Model') {
            return imageType == IMAGE_TYPE.CLOTHES ? "更换容貌" : "选择模特"
        } else if (type == 'Pose') {
            return imageType == IMAGE_TYPE.CLOTHES ? "选择模特" : "选择模特"
        } else if (type == 'Scene') {
            return imageType == IMAGE_TYPE.CLOTHES ? "更换场景" : "选择场景"
        } else if (type == 'Softedge') {
            return "添加元素"
        }
    }, [type, imageType])

    const randomChecked = useMemo(() => {
        if (type == 'Model') {
            return props.modelStyle == '00_default_style'
        } else if (type == 'Pose') {
            return false
        } else if (type == 'Scene') {
            return props.sceneStyle == '00_random'
        } else if (type == 'Softedge') {
            return false
        }
    }, [type, props.modelStyle, props.sceneStyle])

    const randomVisible = useMemo(() => {
        if (type == 'Model') {
            return true
        } else if (type == 'Pose') {
            return false
        } else if (type == 'Scene') {
            return true
        } else if (type == 'Softedge') {
            return false
        }
    }, [type])

    const handleRandomChange = (e) => {
        if (e.target.checked) {
            if (type == 'Model') {
                setKeepPose(false)
                props.onModelChange('00_default_style')
            } else if (type == 'Scene') {
                setKeepBkg(false)
                props.onSceneChange('00_random')
            }
        }
    }

    const onRemove = (item) => {
        PSAIModal.confirm({
            title: '确定删除这个自定义模特吗？',
            onOk() {
                API.removeCustomTryOnModel({
                    id: item.id
                }).then(res => {
                    if (res?.code != 0) return message.warning(res?.message)
                    message.success('删除成功')
                    props.onRefresh()
                })
            },
        })
    }

    return (
        <div className={styles.Container} style={{width: '522px'}}>
            <div className={styles.pack_up}>
                <span>{Title}</span>
                <span onClick={() => setIsDrawerOpen(false)} className={styles.pack_up_text}><DoubleLeftOutlined /></span>
            </div>
            {
                (type == 'Pose' && props.drawerPoseIsCustom) ? null :
                <div className={styles.action_box} style={{padding: '0 15px', marginTop: '10px' }}>
                    <div style={{width: '300px'}}>
                    {
                        type == 'Model'?
                        <ModelFilterSelection/> :
                        (type == 'Scene' && imageType == IMAGE_TYPE.COMMODITY) ?
                        <SceneTypeSelection /> :
                        type == 'Pose' ?
                        <ModelFilterSelection/> :
                        null
                    }</div>
                    <div>{ randomVisible ? <Checkbox checked={randomChecked} onChange={handleRandomChange}>随机</Checkbox> : null }</div>
                </div>
            }
            <div className={styles.content_box}>
            {
                showList?.length == 0 ?
                <div className={styles.empty_wrap}><Empty /></div> :
                (type == 'Model') ? ( // 模特
                    groupModelList.map(group => (
                        <div style={{marginBottom: '10px'}} key={group.key}>
                            { group.title && <div className={styles.model_divider}><span>{group.title}</span></div> }
                            <div className={styles.model_list}>
                            {
                                group.list.map(item =>
                                    <SelectionItem
                                        key={item.id}
                                        showTitle
                                        col4
                                        selected={props.modelStyle}
                                        onClick={handelClick}
                                        onDoubleClick={selectDoubleClick}
                                        onMoreClick={onMoreSceneChange}
                                        record={item}
                                    />
                                )
                            }
                            </div>
                        </div>
                    ))
                ) :type == 'Pose'?(
                    groupPoseList.map(group => (
                        <div style={{marginBottom: '10px'}} key={group.key}>
                            {/* { group.title && <div className={styles.model_divider}><span>{group.title}</span></div> } */}
                            <div className={styles.model_list}>
                            {
                                group.list.map(item => (
                                    <SelectionItem
                                        key={item.id}
                                        isPose
                                        showTitle
                                        col4
                                        multiple
                                        selected={props.poseNameList}
                                        onClick={handelClick}
                                        onDoubleClick={selectDoubleClick}
                                        onMoreClick={onMoreSceneChange}
                                        onRemove={onRemove}
                                        record={item}
                                    />
                                ))
                            }
                            </div>
                        </div>
                        
                    ))
                ): type == 'Softedge'?(
                    softEdgeGroupList.map(group => 
                        <div style={{marginBottom: '10px'}} key={group.key}>
                            { group.title && <div className={styles.model_divider}><span>{group.title}</span></div> }
                            <div className={styles.model_list}>
                            {
                                group.list.map(item => (
                                    <SelectItemSoftedge
                                        key={item.id}
                                        item={item}
                                        getEdgeUrl={getEdgeUrl}
                                    />
                                ))
                            }
                            </div>
                        </div>    
                    )
                ): (type == 'Scene' && imageType != IMAGE_TYPE.COMMODITY) ? ( // 服饰场景，分为真人和人台
                    groupSceneList.map(group => (
                        group.list.length > 0 &&
                        <div style={{marginBottom: '10px'}}  key={group.key}>
                            { group.title && <div className={styles.model_divider}><span>{group.title}</span></div> }
                            <div className={styles.model_list}>
                            {
                                group.list.map(item =>
                                    <SelectionItem
                                        key={item.id}
                                        showTitle
                                        col4
                                        selected={props.sceneStyle}
                                        onClick={handelClick}
                                        onDoubleClick={selectDoubleClick}
                                        onMoreClick={onMoreSceneChange}
                                        record={item}
                                    />
                                )
                            }
                            </div>
                        </div>
                    ))
                ) : (type == 'Scene' && imageType == IMAGE_TYPE.COMMODITY) ? ( // 商品场景
                    <div style={{marginBottom: '10px'}}>
                        <div className={styles.model_list}>
                        {
                            showList.map(item =>
                                <SelectionItem
                                    key={item.id}
                                    showTitle
                                    col4
                                    selected={props.sceneStyle}
                                    onClick={handelClick}
                                    onDoubleClick={selectDoubleClick}
                                    onMoreClick={onMoreSceneChange}
                                    record={item}
                                />
                            )
                        }
                        </div>
                    </div>
                ) : ""
            }
            </div>
        </div>
    )
})

export default Drawer