import { Image, Popover } from 'antd'
import styles from './index.module.scss'
import MoreScenePopoverContent from '@view/compoents/MoreScenePopoverContent/MoreScenePopoverContent'
import { useContext, useMemo, useState } from 'react'
import Utils from '@utils/utils'
import { UploadOutlined, DeleteOutlined, CheckOutlined } from '@ant-design/icons';
import { GenerateContext } from '@utils/GenerateContext'
import eventBus from '@utils/eventBus'

export default function SelectionItem (props) {
    const { keepBkg, keepPose } = useContext(GenerateContext)
    const [morePopoverOpen, setMorePopoverOpen] = useState(false)
    // 主体点击
    const handleClick = () => {
        const subList = props.record?.subList
        if (subList && subList.length > 0) {
            setMorePopoverOpen(true)
        }
        props.onClick(props.record)
        eventBus.emit('TOUR_NEXT')
    }
    // 双击
    const handleDoubleClick = () => {
        props.onDoubleClick && props.onDoubleClick(props.record)
        eventBus.emit('TOUR_NEXT')
    }
    // 更多项点击
    const handleMoreClick = (item) => {
        props.onMoreClick(item)
        setMorePopoverOpen(false)
    }
    const PopoverContent = (
        <MoreScenePopoverContent
            multiple={props.multiple}
            sceneList={props.record?.subList}
            selectedName={props.selected}
            onChange={handleMoreClick}
        />
    )
    // 选择状态
    const selected = useMemo(() => {
        if (props.record.isKeepBkg) {
            return keepBkg
        } else if (props.record.isKeepPose) {
            return keepPose
        } else {
            return props.multiple ?
                props.selected.includes(props.record.name) :
                props.record.name == props.selected
        }
    }, [props.record, props.selected, props.multiple, keepBkg, keepPose])
    // 描述文案
    const formartTitle = useMemo(() => {
        if(props.isPose) {
            // if(poseType == 1) return <p className={styles.scene_title}>半身</p>
            // else return <p className={styles.scene_title}>全身</p>
        } else {
            const title = props.record?.attr?.title
            const arr = title?.split('-')
            return title && <p className={styles.itemTitle}>{arr?.length > 0 ? arr[arr.length - 1] : title}</p>
        }
    }, [props.isPose, props.record])

    const handleRemove = (e) => {
        e.stopPropagation()
        props.onRemove(props.record)
    }

    return (
        <Popover
            placement="topLeft"
            content={PopoverContent}
            open={morePopoverOpen}
            arrow={false}
            overlayInnerStyle={{ padding: 0 }}
            trigger={"click"}
            onOpenChange={value => !value && setMorePopoverOpen(value)}
        >
            <div
                className={`${styles.Container} ${props.col4 && styles.col_4} ${props.isPose && styles.Pose} ${selected && styles.selected}`}
                onClick={handleClick}
                onDoubleClick={handleDoubleClick}
            >
                <div className={styles.itemImg}>
                    <Image
                        width={"100%"}
                        height={"100%"}
                        style={{objectFit: 'cover'}}
                        preview={false}
                        src={Utils.addVersionParamToURL(props.record.imageUrl, 'v', props.record?.attr?.version)}
                        fallback={require('@assets/images/fallback.png')}
                    />
                </div>
                {
                    props.multiple && selected &&
                    <div className={styles.multiple_selected}>
                        <img src={require('@assets/images/icon_check.png')} />
                    </div>
                }
                { 
                    props.showTitle &&
                    formartTitle
                }
                {
                    props.record.attr.private == 1 ?
                    <i className={styles.private_icon}></i> :
                    props.record.attr.recent == 1 ?
                    <div className={styles.new_icon}><span>最近</span></div>:
                    null
                }
                {
                    props.record.subList?.length > 0 &&
                    <div className={styles.more_scene_handler}>
                        <i className={styles.more_scene_handler_img}></i>
                    </div>
                }
                {
                    // props.record.attr.skin == 'custom' &&
                    (props.record.attr.skin == 'custom' || !!props.record.attr.custom) &&
                    <i className={styles.del_btn} onClick={handleRemove}></i>
                }
            </div>
        </Popover>
    )
}