import { useEffect, useRef, useState } from 'react'
import { Button, Modal, Spin, message } from 'antd';
import { useNavigate }from "react-router-dom"; 
import Utils from '@utils/utils'
import API from '@api/api.js';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import './bindWechatModa.scss'
import { useLocation } from 'react-router-dom';
import PSAIModal from '@PSAIComponents/PSAIModal';

const BindWechatModal = (props) => {
    const location = useLocation();
    const { pathname } = location;
    const [open, setOpen] = useState(false)
    const timeoutRef = useRef(null);
    const timeoutRef2 = useRef(null);
    const [wechatUrl, setWechatUrl] = useState()
    const [sceneStr, setSceneStr] = useState()
    const [spanLoading, setSpanLoading] = useState(false)
    

    const wechatQrcode = () => {
        setSpanLoading(true)
        API.wechatQrcode().then((res) => {
            setSpanLoading(false)
            if(res.code == 0) {
                setWechatUrl(res.data.url)
                setSceneStr(res.data.sceneStr)
            } else {
                message.error(res.message)
            }
        })
    }

    useEffect(() => {
        if(!sceneStr || !/\/home\/homemain\/personalCenter/.test(pathname)) return
        bindStatus()
    }, [sceneStr])


    useEffect(() => {
        setSceneStr()
        clearInterval(timeoutRef.current)
        clearInterval(timeoutRef2.current)
        if(open && !props.thirdPartyLoginChannel) {
            wechatQrcode()
            timeoutRef.current = setInterval(() => {
                wechatQrcode()
            }, 120000)
        }
    }, [open, props.thirdPartyLoginChannel])


    const bindStatus = () => {
        API.bindStatus({
            sceneStr: sceneStr,
        }).then((res) => {
            if(res.code == 0) {
                clearInterval(timeoutRef.current)
                clearInterval(timeoutRef2.current)

                setTimeout(() => {
                    message.success('绑定成功！')
                    props.closeFunc()
                }, 500)
            } else if(res.code == -5036){
                timeoutRef2.current = setTimeout(() => {
                    bindStatus()
                }, 1000)
            } else {
                clearInterval(timeoutRef.current)
                clearInterval(timeoutRef2.current)
                props.closeFunc()
                message.warning(res.message == '二维码已过期或不存在'?null: res.message)
            }
        })
    }

    useEffect(() => {
        setOpen(props.open)
    }, [props.open])

    const okClick = () => {
        API.unbind({
        }).then((res) => {
            if(res.code == 0) {
                props.closeFunc()
                message.success('解除绑定成功！')
            } else {
                message.error(res.message)
            }
        })
    }

    return (
        <PSAIModal
            title={props.thirdPartyLoginChannel? '解除绑定': '绑定微信'}
            open={open}
            maxWidth={300}
            onCancel={() => {props.closeFunc(); clearInterval(timeoutRef.current); clearInterval(timeoutRef2.current)}}
            onOk={okClick}
        >
            {
                props.thirdPartyLoginChannel?
                <div style={{margin: '20px 0 0', textAlign: 'center'}}>
                    是否解除微信绑定？
                </div> :
                <div className='bindWechatModal_body'>
                    {
                        !spanLoading?<img src={wechatUrl} alt="" />: <div><Spin /></div>
                    }
                    <p>微信扫码关注公众号完成绑定</p>
                </div>
            }
        </PSAIModal>
    )
}

export default BindWechatModal