import { Routes, Route, Navigate } from "react-router-dom";
import { lazy } from 'react';
import MobileLayout from "../layout/mobile";

const Coupon = lazy(() => import('@view/Coupon'))

export default function ActivityRoute () {
    
    return (
        <Routes>
            <Route path="/" element={<MobileLayout />}>
                <Route path="coupon" element={<Coupon/>} />
                <Route path="*" element={<Navigate replace to="/" />} />
            </Route>
        </Routes>
    )
}