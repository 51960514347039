import { Tooltip, Upload } from 'antd';
import React, {useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from '@utils/AppContext';
import styles from './toolBox.module.scss'
import icon_before_and_after_disabled from '@assets/images/icon_before_and_after_disabled.svg'
import icon_before_and_after_normal from '@assets/images/icon_before_and_after_normal.svg'
import icon_before_and_after_active from '@assets/images/icon_before_and_after_active.svg'
import icon_Withdrawal_disabled from '@assets/images/icon_Withdrawal_disabled.svg'
import icon_Withdrawal_normal from '@assets/images/icon_Withdrawal_normal.svg'
import icon_Withdrawal_active from '@assets/images/icon_Withdrawal_active.svg'
import icon_Reset_Modify_disabled from '@assets/images/icon_Reset_Modify_disabled.svg'
import icon_Reset_Modify_normal from '@assets/images/icon_Reset_Modify_normal.svg'
import icon_Reset_Modify_active from '@assets/images/icon_Reset_Modify_active.svg'
import { AIEditContext } from '../../AIEditContext';

const ToolBox = (props) => {
    const {
        showCompare,
        compare,
        revoke,
        restore,
        reset,
        download,
        uploadCb,
    } = props

    const {needPay} = useContext(AIEditContext)

    const onUpload = (file) => {
        uploadCb(file)
        return false
    }

    const revokeDisabled = useRef(true)
    const revokeTrigger = useRef()
    useEffect(() => {
        if (revoke) {
            revokeDisabled.current = revoke.disabled
            revokeTrigger.current = revoke.trigger
        }
    }, [revoke])
    const handleUndo = (event) => {
        if (event.ctrlKey) {
            if (event.key == 'z') { // CTRL + Z 撤销
                if (revokeTrigger.current && !revokeDisabled.current) {
                    revokeTrigger.current()
                }
            }
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', handleUndo);

        return () => {
            document.removeEventListener('keydown', handleUndo);
        }
    }, [])

    return (
        <div className={`${styles.edit_right_r} ${styles.tool_box}`}>
            <div style={{width: '100px', position: 'absolute', top: '0'}}>
                {
                    compare &&
                    <div
                        className={[styles.btn_, compare.disabled ? styles.disabled : ''].join(' ')}
                        onClick={compare.trigger}
                    >
                        <i className={`${styles.img} ${styles.disabled}`} style={{backgroundImage: `url(${icon_before_and_after_disabled})`}} />
                        <i className={`${styles.img} ${styles.normal}`} style={{backgroundImage: `url(${icon_before_and_after_normal})`}} />
                        <i className={`${styles.img} ${styles.active}`} style={{backgroundImage: `url(${icon_before_and_after_active})`}} />
                        <span>{showCompare ? '关闭对比' : '前后对比' }</span>
                    </div>
                }
                {
                    revoke &&
                    <Tooltip placement="right" title="返回上一次操作">
                        <div
                            className={[styles.btn_, revoke.disabled ? styles.disabled : ''].join(' ')}
                            onClick={revoke.trigger}
                        >
                            <i className={`${styles.img} ${styles.disabled}`} style={{backgroundImage: `url(${icon_Withdrawal_disabled})`}} />
                            <i className={`${styles.img} ${styles.normal}`} style={{backgroundImage: `url(${icon_Withdrawal_normal})`}} />
                            <i className={`${styles.img} ${styles.active}`} style={{backgroundImage: `url(${icon_Withdrawal_active})`}} />
                            <span>撤销</span>
                        </div>
                    </Tooltip>
                }
                {
                    restore &&
                    <Tooltip placement="right" title="恢复上一次撤销的内容">
                        <div
                            className={[styles.btn_, restore.disabled ? styles.disabled : ''].join(' ')}
                            onClick={restore.trigger}
                        >
                            <i className={`${styles.img} ${styles.disabled}`} style={{transform: 'rotateY(-180deg)', backgroundImage: `url(${icon_Withdrawal_disabled})`}} />
                            <i className={`${styles.img} ${styles.normal}`} style={{transform: 'rotateY(-180deg)', backgroundImage: `url(${icon_Withdrawal_normal})`}} />
                            <i className={`${styles.img} ${styles.active}`} style={{transform: 'rotateY(-180deg)', backgroundImage: `url(${icon_Withdrawal_active})`}} />
                            <span>恢复</span>
                        </div>
                    </Tooltip>
                }
                {
                    reset &&
                    <Tooltip placement="right" title="返回初始的原图">
                        <div
                            className={[styles.btn_, reset.disabled ? styles.disabled : ''].join(' ')}
                            onClick={reset.trigger}
                        >
                            <i className={`${styles.img} ${styles.disabled}`} style={{backgroundImage: `url(${icon_Reset_Modify_disabled})`}} />
                            <i className={`${styles.img} ${styles.normal}`} style={{backgroundImage: `url(${icon_Reset_Modify_normal})`}} />
                            <i className={`${styles.img} ${styles.active}`} style={{backgroundImage: `url(${icon_Reset_Modify_active})`}} />
                            <span>重置修改</span>
                        </div>
                    </Tooltip>
                }
            </div>
            {
                download &&
                <div
                    className={[styles.vip_btn, download.disabled ? styles.disabled : ''].join(' ')}
                    style={{position: 'absolute', bottom: '0' }}
                    onClick={download.trigger}
                >
                    <span>下载</span>
                    {
                        needPay &&
                        <div style={{display: 'flex',alignItems: 'baseline'}}>
                        <span>（消耗&nbsp;</span>
                        <img style={{width: '10px', height: '10px'}} src={require('@assets/images/arcbean2.png')}/>&nbsp;
                        <span> 5）</span>
                        </div>
                    }
                </div>
            }
            <div className={styles.ReuploadBox}>
                <Tooltip color={'rgba(0, 0, 0, .6)' } placement="top" title="重新上传">
                    <Upload
                        accept='image/jpeg,image/png,image/webp,image/avif'
                        listType="picture-card"
                        className={styles.Uploader}
                        showUploadList={false}
                        beforeUpload={onUpload}
                    >
                        <div className={styles.UploaderIcon}></div>
                    </Upload>
                </Tooltip>
            </div>
        </div>
    )
}

export default ToolBox