import React, {useState, useEffect} from 'react';
import styles from './index.module.scss'
import { Collapse, theme } from 'antd';
import FillLightBar from './fillLightBar'
import AiBeautyBar from './aiBeautyBar'
import MagicEraseBar from './magicEraseBar'
import BlurBgCont from './blurBgCont'
import { useNavigate } from 'react-router-dom';

const EditLeftBar = (props) => {
    const navigate = useNavigate();
    const {selectPreset, url, lightLoadModule, lightSettingChange, oneClickBeauty, saveUndoList, faceArray, bodyArray, handleDraw, repairSizeChange,
        createClick, createClickBlur
    } = props
    const panelStyle = {
        marginBottom: 10,
        background: '#fff',
        borderRadius: '5px',
        border: 'none',
        with: '340px'
    };

    const mattingBox = (
        <div className={styles.mattingBox}>
            <i onClick={() => window.open(url ? `/home/image_extraction?url=${encodeURIComponent(url)}` : `/home/image_extraction`, '_blank')}></i>
            <p>智能抠图</p>
        </div>
    )

    const getItems = (panelStyle) => [
        {
            key: 'matting',
            label:<p className={`${styles.title} ${styles.title0}`}><i></i>推荐功能</p>,
            children: mattingBox,
            style: panelStyle,
        }, {
            key: 'filllight',
            label: <p className={`${styles.title} ${styles.title1}`}><i></i>智能补光</p>,
            children: <FillLightBar isFilter={false} selectPreset={selectPreset} url={url} lightLoadModule={lightLoadModule} lightSettingChange={lightSettingChange}/>,
            style: panelStyle,
        }, {
            key: 'beautify',
            label: <p className={`${styles.title} ${styles.title2}`}><i></i>智能美化</p>,
            children: <AiBeautyBar oneClickBeauty={oneClickBeauty} url={url} saveUndoList={saveUndoList} faceArray={faceArray}  bodyArray={bodyArray}/>,
            style: panelStyle,
        }, {
            key: 'magicErase',
            label: <p className={`${styles.title} ${styles.title3}`}><i></i>魔法擦除</p>,
            children: <MagicEraseBar url={url} handleDraw={handleDraw} repairSizeChange={repairSizeChange} createClick={createClick}/>,
            style: panelStyle,
        }, {
            key: 'imageUpgrade',
            label: <p className={`${styles.title} ${styles.title4}`}><i></i>画质升级</p>,
            children: <BlurBgCont url={url} createClickBlur={createClickBlur}/>,
            style: panelStyle,
        }, {
            key: 'filter',
            label: <p className={`${styles.title} ${styles.title5}`}><i></i>滤镜</p>,
            children: <FillLightBar isFilter={true} selectPreset={selectPreset} url={url} lightLoadModule={lightLoadModule} lightSettingChange={lightSettingChange}/>,
            style: panelStyle,
        }]

    const [activeKey, setActiveKey] = useState([props.activeKey])
    useEffect(() => {
        setActiveKey([props.activeKey])
    }, [props.activeKey])
    const collapseChange = (val) => {
        if(val.length == 0) return
        setActiveKey(val)
        props.onClick({key: val[0]})
    }

    return (
        <div className={styles.editLeftBar}>
            <Collapse
                accordion={true}
                bordered={false}
                activeKey={activeKey}
                expandIconPosition="end"
                expandIcon={({ isActive }) => <img className={isActive? styles.rotate_sty: null} src={require('@assets/images/collapseIcon.png')} />}
                style={{
                    background: '#fff',
                }}
                items={getItems(panelStyle)}
                onChange={collapseChange}
            />
        </div>
    )
    
}

export default EditLeftBar