import { useEffect, useRef, useState } from "react"
import Drag from '@view/page/components/Drag/Drag'
import styles from './DragCommodity.module.scss'
import ToolBox from "../../components/ToolBox/ToolBox"
import {forwardRef, useImperativeHandle} from "react"
import useResizeObserver from "@utils/useResizeObserver"
import { maskDispose } from "@utils/workflowOfPrediction"

const DragCommodity = forwardRef((props, ref) => {
    const isMounted = useRef(true)
    const [deskWidth, setDeskWidth] = useState(0)
    const [deskHeight, setDeskHeight] = useState(0)
    const [deskSize, setDeskSize] = useState({width: 0, height: 0}) // 工作台的尺寸
    const dragRef = useRef()

    useEffect(() => {
        return () => {
            isMounted.current = false
        }
    }, [])

    useImperativeHandle(ref, () => ({
        loadNewEdge: (url) => {
            dragRef.current.loadNewImageFunc(url)
        },
    }))

    useEffect(() => {
        if (props.segmentResult.length == 0) return
        if (!props.aiInteractiveNpy) {
            maskDispose(props.segmentResult, () => !isMounted.current).then(res => {
                props.onNpyChange(res?.npy)
            })
        }
    }, [props.segmentResult, props.aiInteractiveNpy])

    const targetRef = useResizeObserver((entries) => {
        for (let entry of entries) {
            // 处理尺寸变化的逻辑
            setDeskWidth(entry.contentRect.width)
            setDeskHeight(entry.contentRect.height)
        }
    })

    return (
        <div ref={targetRef} className={styles.DragDeskWrap}>
        {
            (deskWidth * deskHeight) ?
            <div className={styles.DragDesk}>
                <Drag
                    key={props.segmentResult[2]}
                    deskSize={{width: deskWidth, height: deskHeight}}
                    imgRatio={props.imgRatio}
                    imgWidth={props.imgWidth}
                    imgHeight={props.imgHeight}
                    ref={dragRef}
                    bgCoefficient={1}
                    maskUrl={props.segmentResult[2]}
                    dragScale={props.dragScale}
                    onDragChange={props.onDragChange}
                />
                <div className={styles.ToolBox_float}>
                    <ToolBox
                        composeDisabled
                        reUpload={props.reUpload}
                        mattingOpt={props.mattingOpt}
                        composeOpt={props.composeOpt}
                    />
                </div>
            </div> : ""
        }
        </div>
    )
})

export default DragCommodity