import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom/client';
import './index.scss';
import './customAntd.scss'
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { AppContextProvider, colorPrimary } from '@utils/AppContext';
import { ConfigProvider, Spin } from 'antd';
import zhCN from 'antd/locale/zh_CN';
import dayjs from 'dayjs';
import "./i18n/config";
import 'dayjs/locale/zh-cn';
import PSAISpinDot from './PSAIComponents/PSAISpinDot';
import Utils from './utils/utils';
import './upload-polyfill'
dayjs.locale('zh-cn');

// 移动端&PC端适配，相互跳转
if (Utils.isMobileDevice()) {
    if (!/^\/mobile/.test(location.pathname) && !/^\/activity/.test(location.pathname)) {
        window.location.replace('/mobile/' + location.search)
    }
} else {
    if (/^\/mobile/.test(location.pathname)) {
        window.location.replace('/' + location.search)
    }
}

Spin.setDefaultIndicator(<PSAISpinDot />)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
    <ConfigProvider locale={zhCN} theme={{token: { colorPrimary }}}>
      <AppContextProvider>
        <App />
      </AppContextProvider>      
    </ConfigProvider>

    </BrowserRouter>
);

reportWebVitals((res) => {
  // console.log(res.name, res.value)
});


