import DisabledScreenScal from '@utils/disabledScreenScal';
import * as DeviceDetect from 'react-device-detect';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Routes, Route, useLocation, useNavigate, Navigate } from 'react-router-dom';
import eventBus from '@utils/eventBus';
import { Spin, Tour, message, notification } from 'antd';
import styles from './App.module.scss'
import FloatHelper from './view/compoents/FloatHelper';
import BindMobileModal from '@view/compoents/personalCenterModal/bindMobileModal'
import BindEmailModal from '@view/compoents/personalCenterModal/bindEmailModal'
import Captcha from '@view/compoents/captcha'

import API from '@api/api'
import { AppContext } from './utils/AppContext';
import PSAIModal from './PSAIComponents/PSAIModal';
import AppealModal from './view/compoents/AppealModal';
import Vector from '@assets/images/Vector.svg'
import dayjs from 'dayjs';
import Utils from '@utils/utils'
import PcRoute from '@route/pc'
import MobileRoute from '@route/mobile'
import ActivityRoute from '@route/activity'

import Loading from '@view/compoents/loading/loading'
import Prepayment from './view/compoents/Prepayment';
import Payment from './view/compoents/Payment';

function checkBrowserVersion () {
    const {browserName, browserVersion, engineName, engineVersion, osName, osVersion} = DeviceDetect
    // alert(browserName+"_"+browserVersion+','+engineName+"_"+engineVersion+","+osName+"_"+osVersion)
    // setTimeout(() => console.log(DeviceDetect), 3000)

    // 浏览器版本必须需要兼容:where() CSS选择器
    if (browserName == 'Edge') return browserVersion >= 88
    if (browserName == 'IE') return false
    if (browserName == 'Firefox') return browserVersion >= 82
    if (browserName == 'Chrome') return browserVersion >= 88
    if (browserName == 'Opera') return browserVersion >= 74
    if (browserName == 'Safari') return browserVersion >= 14

    // 移动端浏览器
    // if (browserName == 'QQBrowser') return browserVersion >= 15
    // if (browserName == 'Quark') return browserVersion >= 7
    // if (browserName == 'Quark') return browserVersion >= 7
    // if (browserName == 'MIUI Browser') return browserVersion >= 9
    // if (browserName == 'WeChat') return browserVersion >= 8

    const mainEngineVersion = engineVersion.split('.')[0]
    if (engineName == "Blink") return mainEngineVersion >= 88
    // if (engineName == "Gecko") return mainEngineVersion >= 88
    // if (engineName == "WebKit") return mainEngineVersion >= 14
    // if (engineName == "Trident") return mainEngineVersion >= 88

    return true
}

// 自定义message弹窗
// 重写message.error方法：msg为空时不弹出error消息框，解决-4001重复弹框的问题
// 移动端自定义样式（未实现）
function useMessage ({messageApi}) {
    useEffect(() => {
        // const isMobile = Utils.isMobileDevice()
        const onError = (msg) => messageApi.open({type: 'error', content: msg})
        const onWarning = (msg) => messageApi.open({type: 'warning', content: msg})
        const onInfo = (msg) => messageApi.open({type: 'info', content: msg})
        const onSuccess = (msg) => messageApi.open({type: 'success', content: msg})
        message.error = msg => {
            console.error(msg)
            msg && onError(msg)
        }
        message.warning = msg => {
            console.error(msg)
            msg && onWarning(msg)
        }
        message.info = msg => msg && onInfo(msg)
        message.success = msg => msg && onSuccess(msg)
    }, [])
}

function App() {
    const location = useLocation()
    const navigate = useNavigate();
    const [messageApi, msgContextHolder] = message.useMessage()
    const [notificationApi, contextHolder] = notification.useNotification();
    const tokenExpiredRef = useRef(false)
    const {
        setGlobalBalance,
        setGlobalUser,
        setGlobalMemberList,
        setGlobalOrderList,
        globalLoading,
        setGlobalActivity,
    } = useContext(AppContext)

    // 定制message弹窗
    useMessage({ messageApi })

    // 全局loading遮罩
    const [loading, setLoading] = useState(globalLoading)
    const loadingDelayRef = useRef()
    useEffect(() => {
        clearTimeout(loadingDelayRef.current)
        loadingDelayRef.current = setTimeout(() => {
            setLoading(globalLoading)
        }, 100)
    }, [globalLoading])
    // toekn有效
    const handleTokenValid = () => {
        tokenExpiredRef.current = false
    }
    // token已过期，避免重复弹窗
    const handleTokenExpired = (msg) => {
        if (tokenExpiredRef.current) return
        tokenExpiredRef.current = true
        message.warning('凭证已过期，请重新登录')
        if (Utils.isMobileDevice()) {
            navigate(`/mobile?redirect=${encodeURIComponent(msg.pathname)}`)
        } else {
            navigate(`/?redirect=${encodeURIComponent(msg.pathname)}`)
        }
    }
    // 跳转页面
    const handleNavigateTo = (url, options) => {
        navigate(url, options)
    }
    // 全局提示
    const handleNotification = (title, message, type='info', key) => {
        notificationApi[type]({
            key,
            message: title,
            description: message,
        })
    }
    // 充值页面
    const handleRecharge = () => {
        navigate("/#membership")
    }
    // 赠送点弹窗
    const handleRewardMessage = (content, amount) => {
        messageApi.open({
            content: <p style={{display: 'flex', alignItems: 'center'}}>
                <img  style={{marginRight: '10px', width: '18px', height: '20px'}} src={Vector} alt="" />
                <span>温馨提示：{content}已到账。</span>
                <span style={{color: '#FF6332', marginLeft: '20px', display: 'flex', alignItems: 'baseline'}}>+ <span style={{fontSize: '24px'}}>{amount}</span>
                <img style={{marginLeft: '10px', width: '14px', height: '14px'}}  src={require('@assets/images/arcbean2.png')} alt="" />
                </span>
                </p>,
            duration: 3
        });
    }
    // 更新虹豆数
    const [acquirePower, setAcquirePower] = useState(false)
    useEffect(() => { // 提醒登录送点
        if (acquirePower) eventBus.emit('rewardMessage', '今日登录赠送豆数', 100)
    }, [acquirePower])
    const handleUpdatePower = () => {
        return API.getPowerBalance().then(res => {
            if (res.code != 0) return
            setGlobalBalance(res.data.balance)
            setAcquirePower(res.data.acquirePower)
        })
    }
    // 更新订单信息
    const updateOrderList = () => {
        API.getMembershipList({}).then(res => {
            // console.log('getMembershipList', res.data)
            if(res.code == 0) setGlobalMemberList([...res.data])
        })
        API.getOrderList({}).then((res) => {
            // console.log('getOrderList', res.data)
            if(res.code == 0) setGlobalOrderList([...res.data])
        })
    }
    // 更新用户信息
    const updateUserInfo = () => {
        if (!localStorage.getItem('token')) return
        API.userInfo({}).then((res) => {
            if(res.code == 0) setGlobalUser({
                ...res.data,
                // cmCount: 2, // mock数据
                // cmReadFlag: 0, // mock数据
                // appealBeforeTime: '2024-04-28' // mock数据
            })
        })
    }
    // 更新活动时间
    const updateActivityInfo = () => {
        API.getVersion().then(res => {
            setGlobalActivity({
                ACTIVITY_240711: { // 0-未开始；1-进行中；2-已结束
                    beginAt: dayjs(res.invite_cashback_start),
                    endAt: dayjs(res.invite_cashback_end),                    
                }
            })
        })
    }
    /****** 违规警告 ******/
    const [token, setToken] = useState()
    const tokenRef = useRef()
    const appealTitleRef = useRef()
    const [appealOpen, setAppealOpen] = useState(false)
    const modalInstance = useRef(null)
    const appealClick = (title) => {
        console.log('appealClick', title)
        // modalInstance.current.destroy()
        appealTitleRef.current = title
        setAppealOpen(true) 
    }
    const hadnleViolationWarn = (cmCount, appealBeforeTime) => {
        if (modalInstance.current) modalInstance.current.destroy()
        const today = dayjs()
        modalInstance.current = PSAIModal.confirm({
            content: (
                <div className='home_appeal'>
                    <p>经审核，您上传了涉嫌违规的内容，警告{cmCount}次。累计超过三次，将对账号进行封号1天的处理。</p>
                    {
                        dayjs(appealBeforeTime).isBefore(today, 'day') ? "" :
                        <p>请在{appealBeforeTime}前进行申诉，超过时间无法申诉。<span onClick={() => appealClick('账号警告申诉')}>去申诉</span></p>
                    }                    
                </div>
            ),
            okText: '确认',
            cancelText: '取消',
            onOk() {API.setCmRead()},
            onCancel() {API.setCmRead()},
        })
    }
 
    /******** 封号提醒 ********/
    const handleSuspenNotice = (num, invalidDays, appealBeforeTime, token) => {
        console.log('handleSuspenNotice===')
        if (modalInstance.current) modalInstance.current.destroy()
        setToken(token)
        tokenRef.current = token
        const today = dayjs()
        modalInstance.current = PSAIModal.confirm({
            content: (
                <div className='home_appeal'>
                    <p>经审核，您的账号已累计违规{num}次，现对您的账号进行封号{invalidDays}天的处理。</p>
                    {
                        dayjs(appealBeforeTime).isBefore(today, 'day') ? "" :
                        <p>请在{appealBeforeTime}前进行申诉，超过时间无法申诉。<span onClick={() => appealClick('账号解封申诉')}>去申诉</span></p>
                    }
                </div>
            ),
            okText: '确认',
            cancelText: '取消',
            onOk() {onAfterSuspendNotice(1, token)},
            onCancel() {onAfterSuspendNotice(1, token)},
        })
    }
    const onAfterSuspendNotice = (type, token) => {
        if (token) return
        API.setCmRead()
        if (type == 1) { // 封号后需要主动退出登录
            API.logout({}).finally(() => {
                navigate('/')
                localStorage.removeItem('token')
                sessionStorage.removeItem('taskCache_model')
                sessionStorage.removeItem('taskCache_dressform')
                sessionStorage.removeItem('taskCache_clothes')
                sessionStorage.removeItem('taskCache_commodity')
                sessionStorage.removeItem('taskCache_tryonVideo')
                sessionStorage.removeItem('taskCache_commodityVideo')
                localStorage.removeItem('channel')
            })
        }
    }

    // 绑定手机号/更换手机号
    const [mobileOpen, setMobileOpen] = useState(false)
    const handleBindOrChangeMobile = () => {
        setMobileOpen(true)
    }

    // 绑定邮箱/更换邮箱
    const [emailOpen, setEmailOpen] = useState(false)
    const handleBindOrChangeEmail = () => {
        setEmailOpen(true)
    }

    // 滑动验证
    const bizResultCallback = useRef()
    const handleCaptcha = (callback) => {
        let button = document.getElementById("captcha-button");
        let clickEvent = new Event("click");
        button.dispatchEvent(clickEvent);
        bizResultCallback.current = callback
    }
    const onBizResultCallback = () => {
        if (bizResultCallback.current) bizResultCallback.current()
    }

    // 支付前弹窗
    const [prepaymentOpen, setPrepaymentOpen] = useState(false) // 支付前置弹窗
    const [paymentItems, setPaymentItems] = useState() 
    const [isPowerPackage, setIsPowerPackage] = useState(false)
    const [packageIndex, setPackageIndex] = useState(0)
    const handlePcPrepay = (items, isPowerPackage=false, packageIndex=0, cb=null) => {
        setPrepaymentOpen(true)
        setPaymentItems(items)
        setIsPowerPackage(isPowerPackage) // 是否是加油包
        setPackageIndex(packageIndex) // 选择第几个加油包
        setOnPayedCb(cb)
    }
    const [paymentOpen, setPaymentOpen] = useState(false) // 支付二维码弹窗
    const [onPayedCb, setOnPayedCb] = useState()
    const [tradeId, setTradeId] = useState(false)
    const handlePcPay = (tradeId, cb=null) => {
        API.payForOrder({tradeId}).then(res => {
            if (res.code != 0) throw new Error(res.message)
            setPaymentOpen(true)
            setTradeId(tradeId)
            setOnPayedCb(cb)
        }).catch(err => {
            console.error(err)
            message.warning(err.message)
        })
    }

    // 能量点不足弹窗
    const handleNotEnoughPoints = () => {
        PSAIModal.confirm({
            title: "提醒",
            content: "可用虹豆不足，是否立即充值？",
            onOk() {
                eventBus.emit('recharge')
            },
            onCancel() {},
        })
    }
    
    // 新手引导
    const [tourOpen, setTourOpen] = useState(false)
    const tourStepsRef = useRef([])
    const [tourSteps, setTourSteps] = useState([])
    const [tourCurrent, setTourCurrent] = useState(1)
    const tourKeyRef = useRef() // tour_model / tour_commodity / tour_cloth
    const handleInitTour = (steps, key) => {
        setTourCurrent(0)
        tourKeyRef.current = key
        tourStepsRef.current = steps.map(item => ({
            title: item.title || ' ',
            description: item.description,
            target: item.target
        }))
        setTourSteps(tourStepsRef.current)
    }
    const handleNextTour = () => {
        const current = Number(localStorage.getItem(tourKeyRef.current) || 0)
        if (current >= tourStepsRef.current.length) return
        const toured = localStorage.getItem('toured')
        if (toured) return
        setTourOpen(true)
        const stepLength = tourStepsRef.current.length
        if (current >= stepLength - 1) {
            handleTourCloseOrFinish()
        } else {
            setTourCurrent(pre => {
                const current = pre+1
                localStorage.setItem(tourKeyRef.current, current)
                return current
            })
        }        
    }
    const handlePlayTour = () => {
        const current = Number(localStorage.getItem(tourKeyRef.current) || 0)
        if (current >= tourStepsRef.current.length) return
        const toured = localStorage.getItem('toured')
        if (toured) return
        setTourOpen(true)
    }
    const handlePauseTour = () => {
        setTourOpen(false)
    }
    const handleTourCloseOrFinish = () => {
        setTourOpen(false)
        localStorage.setItem(tourKeyRef.current, tourStepsRef.current.length)
        localStorage.setItem('toured', 1)
    }

    const [inited, setInited] = useState(false) // 需要等待eventBus初始化完成后，再加载内容
    useEffect(() => {
        eventBus.on('pc_pay', handlePcPay) // 支付弹窗
        eventBus.on('pc_prepay', handlePcPrepay) // 预支付弹窗
        eventBus.on('captcha', handleCaptcha) // 滑动验证码
        eventBus.on('bindOrChangeMobile', handleBindOrChangeMobile) // 绑定/更换手机号
        eventBus.on('bindOrChangeEmail', handleBindOrChangeEmail) // 绑定/更换邮箱
        eventBus.on('tokenValid', handleTokenValid);
        eventBus.on('tokenExpired', handleTokenExpired);
        eventBus.on('navigateTo', handleNavigateTo);
        eventBus.on('notification', handleNotification)
        eventBus.on('recharge', handleRecharge) // 充值
        eventBus.on('rewardMessage', handleRewardMessage) // 赠送点弹窗
        eventBus.on('updatePower', handleUpdatePower) // 更新虹豆数
        eventBus.on('updateActivityInfo', updateActivityInfo) // 更新活动信息
        eventBus.on('updateUserInfo', updateUserInfo) // 更新用户信息
        eventBus.on('violationWarn', hadnleViolationWarn) // 违规警告
        eventBus.on('suspenNotice', handleSuspenNotice) // 封号提醒
        eventBus.on('updateOrderList', updateOrderList) // 更新订单信息
        eventBus.on('not_enough_points', handleNotEnoughPoints) // 能量点不足
        eventBus.on('TOUR_INIT', handleInitTour)
        eventBus.on('TOUR_PLAY', handlePlayTour)
        eventBus.on('TOUR_NEXT', handleNextTour)
        eventBus.on('TOUR_PAUSE', handlePauseTour)
        setInited(true)

        return () => {
            eventBus.off('pc_pay', handlePcPay) // 支付弹窗
            eventBus.off('pc_prepay', handlePcPrepay) // 支付弹窗
            eventBus.off('captcha', handleCaptcha) // 滑动验证码
            eventBus.off('bindOrChangeMobile', handleBindOrChangeMobile)
            eventBus.off('bindOrChangeEmail', handleBindOrChangeEmail) // 绑定/更换邮箱
            eventBus.off('tokenValid', handleTokenValid);
            eventBus.off('tokenExpired', handleTokenExpired);
            eventBus.off('navigateTo', handleNavigateTo);
            eventBus.off('notification', handleNotification)
            eventBus.off('recharge', handleRecharge)
            eventBus.off('rewardMessage', handleRewardMessage)
            eventBus.off('updatePower', handleUpdatePower)
            eventBus.off('updateActivityInfo', updateActivityInfo)
            eventBus.off('updateUserInfo', updateUserInfo)
            eventBus.off('violationWarn', hadnleViolationWarn)
            eventBus.off('violationWarn', handleSuspenNotice)
            eventBus.off('updateOrderList', updateOrderList)
            eventBus.off('not_enough_points', handleNotEnoughPoints)
            eventBus.off('TOUR_INIT', handleInitTour)
            eventBus.off('TOUR_PLAY', handlePlayTour)
            eventBus.off('TOUR_NEXT', handleNextTour)
            eventBus.off('TOUR_PAUSE', handlePauseTour)
        }
    }, [])

    // 拦截url中携带的参数
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search)
        const sourceId = searchParams.get('source_id') // 由于记录来源
        if (sourceId) sessionStorage.setItem('sourceId', sourceId)
        const inviteCode = searchParams.get('invite_code') // 由于记录邀请码
        if (inviteCode) sessionStorage.setItem('inviteCode', inviteCode)
        const code = searchParams.get('code') // 由于tiktok一键登录
        if (code) sessionStorage.setItem('code', code)
        if (Utils.getCurrentDomainAndCheckTiktok()) { // https://tiktok.psai.cn/
            sessionStorage.setItem('platform', 'tiktok')
        } else if(Utils.getCurrentDomainAndCheckShein()) {
            sessionStorage.setItem('platform', 'shein')
        } else {
            sessionStorage.setItem('platform', 'psai')
        }
    }, [])

    // 校验浏览器是否适配
    const [browserIsAvailable] = useState(checkBrowserVersion())

    useEffect(() => {
        if(location.pathname == '/' || location.pathname == '/mobile/') {
            document.title = 'PhotoStudio AI'
        }
    }, [location]);

    const fallback = <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Spin size="large"/></div>
    const availableContent = (
        <>
        <React.Suspense fallback={fallback}>
            <Routes>
                <Route path="/activity/*" element={<ActivityRoute />} />
                <Route path="/mobile/*" element={<MobileRoute />} />
                <Route path="/*" element={<PcRoute />} />
                <Route path="*" element={<Navigate replace to="/" />} />
            </Routes>
        </React.Suspense>
        {Utils.isMobileDevice()?null: <FloatHelper />}
        </>
    )
    const notAvailableContent = (
        <div className="browser_not_available">
            <i></i>
            <span>您使用的浏览器不能正常浏览网站，请使用最新版本的浏览器以获得更好的网站浏览体验。</span>
        </div>
    )

    return (
        <div className="App" id="topView">
            {contextHolder}
            {msgContextHolder}
            {
                inited ?
                browserIsAvailable ?
                    availableContent:
                    notAvailableContent :
                null
            }
            {
                loading ?
                <Loading loadingText={typeof loading === 'boolean' ? '上传中' : loading} /> :
                null
            }
            <PSAIModal
                open={prepaymentOpen}
                maskClosable={false}
                footer={null}
                maxWidth={508}
                onCancel={() => setPrepaymentOpen(false)}
                getContainer={"body"}
            >
                <Prepayment
                    items={paymentItems}
                    isPowerPackage={isPowerPackage}
                    packageIndex={packageIndex}
                    onClose={() => setPrepaymentOpen(false)}
                    onPayedCb={onPayedCb}
                />
            </PSAIModal>
            <PSAIModal
                open={paymentOpen}
                maskClosable={false}
                footer={null}
                maxWidth={508}
                onCancel={() => setPaymentOpen(false)}
                getContainer={"body"}
            >
                <Payment
                    tradeId={tradeId}
                    onClose={() => setPaymentOpen(false)}
                    onPayedCb={onPayedCb}
                />
            </PSAIModal>
            <PSAIModal
                title="账号申诉"
                open={appealOpen}
                maskClosable={false}
                footer={null}
                maxWidth={664}
                onCancel={() => setAppealOpen(false)}
                getContainer={"body"}
            >
                <AppealModal token={token} onCancel={() => setAppealOpen(false)} />
            </PSAIModal>
            {/* 绑定手机号 */}
            <BindMobileModal open={mobileOpen} closeFunc={() => setMobileOpen(false)}/>
            {/* 绑定邮箱 */}
            <BindEmailModal open={emailOpen} closeFunc={() => setEmailOpen(false)} />
            {/* 验证码 */}
            <Captcha id={'#captcha-button'} onBizResultCallback={onBizResultCallback} />
            <div id="captcha-button"></div>
            {/* 使用引导 */}
            <Tour
                placement="rightTop"
                open={tourOpen}
                current={tourCurrent}
                steps={tourSteps}
                closeIcon={"关闭引导"}
                onClose={handleTourCloseOrFinish}
            />
        </div>
    );
}

// DisabledScreenScal.disabledZoom()


export default App;
