import { Button, Select, message} from 'antd'
import styles from './index.module.scss'
import API from '@api/api.js';
import { useContext, useEffect, useMemo, useState } from 'react'
import { GenerateContext } from '@utils/GenerateContext'
import eventBus from '@utils/eventBus';
import SceneSwitchTab from '../../selfModelFakeLeft/SceneSwitchTab';
import VideoSelection from '../../selfModelFakeLeft/VideoSelection';
import { PRIVATE_ABILITIES } from "@utils/CONST"
import { AppContext } from '@utils/AppContext';

const LeftModelVideo = (props) => {
    const {
        isProEnv,
        hasPrivateAbility,
    } = useContext(AppContext);
    const {modelVideoFrontPic, videoResolution,  modelVideo} = useContext(GenerateContext)
    const [consumAmount] = useState(80)
    const [loading, setLoading] = useState(false)
    const [videoList, setVideoList] = useState([])
    const [sex, setSex] = useState([])

    const handleClick = async () => {
        eventBus.emit('TOUR_NEXT')
        setLoading(true)
        await props.onCreate()
        eventBus.emit('updatePower')
        setLoading(false)
    }

    // 显示自定义姿势
    const showCustom = useMemo(() => {
        // return !isProEnv
        return !isProEnv || hasPrivateAbility(PRIVATE_ABILITIES.VIDEO_TRYON_DIY_KEY)
        // return true
    }, [hasPrivateAbility])

    const [videoTabKey, setVideoTabKey] = useState(1)
    const handleVideoTabChange = (key) => {
        setVideoTabKey(key)
    }
    const videoTabItems = [{
        key: 1,
        label: '预设动作',
        children: <VideoSelection key={1} />
    }, {
        key: 2,
        label: '自定义',
        children: <VideoSelection key={2} isCustomVideo />,
        disabled: !showCustom
    }]

    return (
        <div className={styles.Container}>
            <div className={styles.ResourceBox}>
                <SceneSwitchTab
                    activeKey={videoTabKey}
                    items={videoTabItems}
                    onChange={handleVideoTabChange}
                />
            </div>
            <div id="tour_target_create" className={styles.GenerateBox}>
                <p className={styles.use_point}>消耗 <img src={require('@assets/images/arcbean2.png')}/><span>{consumAmount}</span></p>
                <Button disabled={!modelVideoFrontPic || !modelVideo} loading={loading} type="primary" onClick={handleClick}>生成</Button>
            </div>
        </div>
    )
}

export default LeftModelVideo