import { useState, useContext, useRef, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Head from '@view/compoents/head/head'
import Loading from '@view/compoents/loading/loading'
import { AppContext } from '@utils/AppContext';
import Utils from '@utils/utils'

export default function PcLayout () {
    const location = useLocation();

    useEffect(() => {
        if(Utils.isMobileDevice()) return
        const html = document.querySelector('html')
        html.style.setProperty('--min-font-size', '50px');
        html.style.setProperty('--max-font-size', '999px');
        html.style.setProperty('--base-width', '1920');
    }, [])

    return (
        <div style={{height: '100%'}}>
            <Head />
            <Outlet />
        </div>
    )
}