import API from '@api/api'
import axios from 'axios'
import eventBus from '@utils/eventBus';
import Utils from '@utils/utils'
import { message } from 'antd';
import { IMAGE_TYPE, CLOTHES_SUBTYPE, CLOTH_TYPE, UPLOAD_SOURCE } from "@utils/CONST"

// tryon分割
const tryonMaskFun = async (filename) => {
    try {
        const res = await API.maskPredictionAicloth({
            image: filename,
            mask_style: 3,
        })
        if(res.code != 0) throw new Error(res.message)
        return await checkTryonMaskResult(res.data)
    } catch (err) {
        console.error('tryonMaskFun fail', err)
        const error = Utils.parseErrorCode(`[${err.message}]`)
        eventBus.emit('notification', '提醒', `服装类型不支持，请参照正确示例上传哦！`, 'error', error.code)
        return false
    }
}
// tryon分割状态检查
const checkTryonMaskResult = (id) => {
    return new Promise((resolve, reject) => {
        function loopCheck () {
            let url = API.maskPredictionAiclothUrl + id
            axios.post(url, {}, {
                headers: {
                    'Content-type' : 'application/json',
                    'Ps-Auth-Token': localStorage.getItem('token')
                }
            }).then((res) => {
                if((res.data.code == 0 && res.data.data.status == 'succeeded')) {
                    resolve({ id, input: res.data.data.input, output: res.data.data.output, })        
                } else if(res.data.code == 0 && res.data.data.status == 'failed') {
                    reject(new Error(res.data.data.errcode))
                } else {
                    setTimeout(() => {
                        loopCheck()
                    }, 400)
                }
            })
        }
        loopCheck()
    })
}

// model/dressform 分割
const maskFun = async (filename, type) => {
    try {
        const res = await API.handlePrediction({
            img_path: filename,
            ord_scale: 0.75,
            rentai_flag: type == IMAGE_TYPE.DRESSFORM? 1: 0
        })
        if(res.code != 0) throw new Error(res.message)
        const output =  await checkMaskResult(res.data)
        let filter = {}
        if (type == IMAGE_TYPE.MODEL && output.length > 6) {
            // 返回坐标追加一个维度，性别信息，0为male, 1为female，仅用于真人对web端模特的分类
            // 性别后面增加一个维度: age, 如果年龄15岁，判断为儿童，不再筛选性别
            const _ages = output[7]
            let sex = []
            let age = ['adult']
            let sexAvailable = true
            if (_ages) { // 如果年龄15岁，判断为儿童，不再筛选性别
                if (_ages[0] > 15) {
                    age = ['adult']
                } else {
                    age = ['children']
                    sexAvailable = false
                }
            }
            if (sexAvailable) {
                const _sex = output[6]
                if (_sex == 1) {
                    sex = ['female']
                } else {
                    sex = ['male']
                }
            }
            filter = { sex: sex, age: age }
        }
        return {
            output,
            filter
        }
    } catch (err) {
        console.error('maskFun fail', err)
        const error = Utils.parseErrorCode(`[${err.message}]`)
        eventBus.emit('notification', '提醒', error.message, 'error', error.code)
        return false
    }
}

const checkMaskResult = (id) => {
    return new Promise((resolve, reject) => {
        function loopCheck () {
            let url =  API.handlePredictionUrl + id
            axios.post(url,  {}, {
                headers: {
                    'Content-type' : 'application/json',
                    'Ps-Auth-Token': localStorage.getItem('token')
                }
            }).then((res) => {
                if((res.data.code == 0 && res.data.data.status == 'succeeded')) {
                    resolve(res.data.data.output)
                } else if(res.data.code == 0 && res.data.data.status == 'failed') {
                    reject(new Error(res.data.data.errcode))
                } else {
                    setTimeout(() => {
                        loopCheck(id)
                    }, 200)
                }
            })
        }

        loopCheck()
    })
}

export const workflowOfUpload = async (file, type, clothParam={}) => {
    // type == model / commodity / dressform / (clothes / clothes_short / clothes_longSleeve)
    if (type == IMAGE_TYPE.COMMODITY) {
        return
    } else if ([IMAGE_TYPE.MODEL, IMAGE_TYPE.DRESSFORM].includes(type)) {
        // 检查上传图片是否符合安全规范
        const legalRes = await Utils.checkImageIslegal(file, 1, type == IMAGE_TYPE.MODEL ? UPLOAD_SOURCE.MODEL : UPLOAD_SOURCE.DRESSFORM) //审核

        if (!legalRes) return false
        if (!legalRes.isLegal) {
            return {
                isLegal: false
            }
        } else {
            const res = await maskFun(legalRes.data, type)
            return res ?
                {
                    isTryon: false,
                    result: res.filter,
                    output: res.output,
                    imageAntispamId: legalRes.imageAntispamId || '',
                    filename: legalRes.data,
                    isLegal: true,
                } : null
        }
    } else if ([IMAGE_TYPE.CLOTHES, CLOTHES_SUBTYPE.SHORT, CLOTHES_SUBTYPE.LONG_SLEEVE, CLOTHES_SUBTYPE.SKIRT].includes(type)) {
        // 检查上传图片是否符合安全规范
        const { clothType, bodyPart } = clothParam
        // clothType: CLOTH_TYPE.SHIRT / CLOTH_TYPE.PANTS / CLOTH_TYPE.SKIRT / CLOTH_TYPE.SUIT
        // bodyPart: 1-上装；2-下装；
        let source
        if (clothType == CLOTH_TYPE.SUIT) { // 套装
            if (bodyPart == 1) source = UPLOAD_SOURCE.CLOTH_TOP
            else source = UPLOAD_SOURCE.CLOTH_BOTTOM
        } else {
            source = UPLOAD_SOURCE.CLOTH_DRESS
        }
        const legalRes = await Utils.checkImageIslegal(file, 1, source) //审核
        if (!legalRes) return false
        if (!legalRes.isLegal) {
            return {
                isLegal: false
            }
         } else {
            const res = await tryonMaskFun(legalRes.data)
            return res ?
                {
                    isTryon: true,
                    result: res,
                    imageAntispamId: legalRes.imageAntispamId || '',
                    filename: legalRes.data,
                    isLegal: true,
                } : null
        }
    }
}
