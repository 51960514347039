import PicView from "./picView"
import WaitView from "./waitView"
import styles from './taskItem.module.scss'
import { useEffect } from "react"

const TaskItem = (props) => {
    return (
        <div className={styles.Container}>
        {
            (!props.imageId || props.taskResult.hdStatus == 'started') ?
                <WaitView 
                    imageType={props.imageType}
                    status={props.taskResult.status} // 生成状态
                    waitingTime={props.taskResult.waitingTime} // 生成等待时间
                    progress={props.taskResult.progress} // 生成进度
                    loadingMask={props.loadingMask} // 生成等待mask
                    hdStatus={props.taskResult.hdStatus} // 高清生成中
                    commonImage={props.taskResult.image} // 普通生成图片
                /> :
                <PicView {...props} />
        }
        </div>
    )
}

export default TaskItem