import styles from './goBack.module.scss'
import {useNavigate} from 'react-router-dom'

const GoBack = (props) => {
    const navigate = useNavigate()

    const onClick = () => {
        if (props.goBack) {
            props.goBack()
        } else {
            navigate(-1)
        }
    }

    return (
        <div className={styles.head} onClick={onClick} style={props.style}>
            <div className={styles.arrow}></div>
            <div className={styles.back}>返回</div>
        </div>
    )
}

export default GoBack