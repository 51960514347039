import { Image, Segmented, Spin } from "antd"
import React, { useEffect, useMemo, useRef, useState, useContext, useCallback } from "react"
import Utils from "@utils/utils"
import './previewImage.scss'
import TaskTools from "../taskTools/taskTools"
import { AppContext } from '@utils/AppContext';
import SatisfactionSurvey from "./satisfactionSurvey"
import { IMAGE_TYPE } from "@utils/CONST"
import useResizeObserver from "@utils/useResizeObserver"
import PSAISpinDot from "@PSAIComponents/PSAISpinDot"

const fallback = require('@assets/images/fallback.png')

const ImageRender = (props) => {
    const [contentStyle, setContentStyle] = useState()
    const [containStyle, setContainStyle] = useState()
    const [isError, setIsError] = useState(false)
    const { globalUser } = useContext(AppContext);
    const imgSizeRef = useRef()

    const [wrapWidth, setWrapWidth] = useState(0)
    const [wrapHeight, setWrapHeight] = useState(0)
    
    const isHDPreview = useMemo(() => {
        return props.hdImage
    }, [props.hdImage])

    const [imageQuality, setImageQuality] = useState('hd')

    const showImageIsHD = useMemo(() => {
        return isHDPreview && imageQuality == 'hd'
    }, [isHDPreview, imageQuality])

    const showImageUrl = useMemo(() => {
        return showImageIsHD ? props.hdImage : props.image
    }, [showImageIsHD, props.image, props.hdImage])

    const isHDing = useMemo(() => {
        return props.hdStatus == 'started'
    }, [props.hdStatus, isHDPreview])

    useEffect(() => {
        if (!(wrapWidth * wrapHeight)) return
        if (!imgSizeRef.current) {
            (props.imageType == IMAGE_TYPE.COMMODITY_VIDEO || props.imageType == IMAGE_TYPE.MODEL_VIDEO ? 
            Utils.getVideoDimensions(props.image) :
            Utils.getImageDimensions(isError ? fallback : props.image)).then(({width, height}) => {
                imgSizeRef.current = {width, height}
                const size = Utils.getImgMaxSizeInContainer(
                    imgSizeRef.current.width,
                    imgSizeRef.current.height,
                    wrapWidth - 254,
                    wrapHeight
                )
                const sizeWidth = Math.max(size.width, 170)
                setContentStyle({
                    width: sizeWidth+'px',
                    height: size.height+'px',
                })
                setContainStyle({
                    width: sizeWidth+254+'px',
                    // height: size.height+'px',
                    height: '100%',
                    left: (wrapWidth - sizeWidth-254) / 2 + 'px'
                })
            })
        } else {
            const size = Utils.getImgMaxSizeInContainer(
                imgSizeRef.current.width,
                imgSizeRef.current.height,
                wrapWidth - 254,
                wrapHeight
            )
            const sizeWidth = Math.max(size.width, 170)
            setContentStyle({
                width: sizeWidth+'px',
                height: size.height+'px',
            })
            setContainStyle({
                width: sizeWidth+254+'px',
                // height: size.height+'px',
                height: '100%',
                left: (wrapWidth - sizeWidth-254) / 2 + 'px'
            })
        }
    }, [wrapWidth, wrapHeight, isError])


    const targetRef = useResizeObserver((entries) => {
        for (let entry of entries) {
            // 处理尺寸变化的逻辑
            setWrapWidth(entry.contentRect.width)
            setWrapHeight(entry.contentRect.height)
        }
    })

    useEffect(() => {
        imgSizeRef.current = null
    }, [isError])

    const imageIsIllegal = useMemo(() => {
        return isError || /out-error-default\.png/.test(showImageUrl) || props.reviewStatus == 2
    }, [isError, showImageUrl, props.reviewStatus])

    const videoRef = useRef()
    const [isPlaying, setIsplaying] = useState(false)
    const [currentTime, setCurrentTime] = useState(0)
    const [duration, setDuration] = useState(0)
    const triggerViderPlay = async () => {
        if (isPlaying) await videoRef.current.pause()
        else await videoRef.current.play()
    }
    const handelVideoPlay = async (play) => {
        try {
            if (play) await videoRef.current.play()
            else await videoRef.current.pause()
        } catch (err) {
            console.error(err)
        }
    }
    const handlPlay = () => setIsplaying(true)
    const handlePause = () => setIsplaying(false)
    const handleEnded = () => setIsplaying(false)
    const handleUpdateTime = (e) => {
        setCurrentTime(videoRef.current.currentTime)
        setDuration(videoRef.current.duration)
    }

    return (
        <>
        {
            props.moveable == 1 &&
            React.cloneElement(props.originalNode, {
                src: showImageUrl
            })
        }
        <div className="image-preview-custom-wrapper" ref={targetRef} style={{ userSelect: containStyle ? 'text' : 'none', display: props.moveable ==1 ? "none" : "flex" }}>
            <div className="image-preview-custom-body">
            {
                containStyle &&
                <>
                    <div className="image-preview-custom-imgwrap">
                    {
                        props.imageType == IMAGE_TYPE.COMMODITY_VIDEO || props.imageType == IMAGE_TYPE.MODEL_VIDEO ? 
                        <video
                            onClick={triggerViderPlay}
                            ref={videoRef}
                            onPlay={handlPlay}
                            onPause={handlePause}
                            onEnded={handleEnded}
                            onTimeUpdate={handleUpdateTime}
                            className="image-preview-custom-img"
                            style={{...contentStyle, cursor: 'pointer'}}
                            src={isError ? fallback : showImageUrl}
                            muted
                            loop
                            autoPlay
                        ></video> :
                        <Image
                            className="image-preview-custom-img"
                            style={contentStyle}
                            width={"100%"}
                            height={"100%"}
                            preview={false}
                            src={showImageUrl}
                            fallback={fallback}
                            onClick={() => props.onSwitch()}
                            onError={() => setIsError(true)}
                            placeholder={
                                <Image
                                    className="image-preview-custom-img"
                                    style={contentStyle}
                                    width={"100%"}
                                    height={"100%"}
                                    preview={false}
                                    src={Utils.getImgUrlWithWebp(showImageUrl, true, true)}
                                    fallback={fallback}
                                />
                            }
                        />
                    }
                    {
                        showImageIsHD &&
                        <span className="image-preview-custom-img_hdribbon">高清</span>
                    }
                    {
                        !imageIsIllegal &&
                        <div className="image-preview-custom-tools">
                            <TaskTools
                                imageType={props.imageType}
                                mainImage={isError ? fallback : showImageUrl}
                                taskId={props.taskId}
                                imageId={props.id}
                                bookmarked={props.bookmark}
                                showBookmark={props.showBookmark}
                                showHDPreview={!isHDing && !isHDPreview && props.imageType != IMAGE_TYPE.COMMODITY_VIDEO && props.imageType != IMAGE_TYPE.MODEL_VIDEO}
                                videoPlayer={
                                    props.imageType != IMAGE_TYPE.COMMODITY_VIDEO && props.imageType != IMAGE_TYPE.MODEL_VIDEO ? false :
                                    {
                                        isPlaying,
                                        currentTime,
                                        duration,
                                        showVideoBtn: true,
                                        showProgressBar: true,
                                        onPlay: handelVideoPlay
                                    }
                                }
                                showShare
                                showAIEditor={props.imageType != IMAGE_TYPE.COMMODITY_VIDEO && props.imageType != IMAGE_TYPE.MODEL_VIDEO}
                                showDownload
                                hdPayed={props.payed}
                                withoutMask
                            />
                        </div>
                    }
                    {
                        isHDing &&
                        <div className="image-preview-custom-loading">
                            <Spin size="large" indicator={<PSAISpinDot color="#FFFFFF" />} />
                            <span>高清预览生成中...</span>
                        </div>
                    }
                    </div>
                    <div className="image-preview-custom-satisfactionwrap">
                        <SatisfactionSurvey {...props} />
                    </div>
                </>
            }
            {
                containStyle &&
                <>
                <div className="image-preview-custom-close" onClick={props.closeFunc}></div>
                <div className="image-preview-custom-pre" onClick={props.preFunc}></div>
                <div className="image-preview-custom-next" onClick={props.nextFunc}></div>
                </>
            }
            </div>
            {
                isHDPreview &&
                <div className="image-preview-custom-segmented">
                    <Segmented
                        value={imageQuality}
                        options={[{label: '普通画质', value: 'common'}, {label: '高清画质', value: 'hd'}]}
                        onChange={setImageQuality}
                    />
                </div>
            }
        </div>
        </>
    )
}

const ToolRender = (props) => {
    const {
        current,
        items,
        onChange
    } = props

    const [carouselLeft, setCarouselLeft] = useState(0)
    const wrapRef = useRef()
    const carouselRef = useRef()
    const wrapWidth = useRef(0)
    const carouselWidth = useRef(0)
    const [errorList, setErrorList] = useState([])
    const imgListRef = useRef([])
    const imgIndexRef = useRef()
    
    useEffect(() => {
        if (imgListRef.current.length != items.length) { // items变化了，需要保持选中的img不变
            const item = imgListRef.current[imgIndexRef.current]
            const newItem = items[current]
            if (newItem != item) {
                for (let i = 0; i < items.length; i++) {
                    if (items[i] == item) {
                        onChange(i)
                        return
                    }
                }
            }
        }
        imgListRef.current = items
        imgIndexRef.current = current       
    }, [items, current])

    const itemsLength = useMemo(() => {
        return items?.length
    }, [items])

    useEffect(() => {
        wrapWidth.current = wrapRef.current.clientWidth
        carouselWidth.current = carouselRef.current.clientWidth
    }, [itemsLength])

    useEffect(() => {
        if (wrapWidth.current == carouselWidth.current) return

        const selectDom = document.getElementById(`preview-thumbnails-${current}`)
        if (!selectDom) return console.log('!!!!!!!')
        const domRect = selectDom.getBoundingClientRect()
        const wrapRect = wrapRef.current.getBoundingClientRect()
        const relateLen = domRect.right - wrapRect.left
        if (relateLen > wrapWidth.current) {
            setCarouselLeft(-10 - (current - 3) * (domRect.width + 20) - 10)
        } else if (relateLen < domRect.width) {
            setCarouselLeft(-10 - (current - 3) * (domRect.width + 20) - 10)
        }
    }, [current])

    const carouselStyle = useMemo(() => {
        return {
            left: Math.min(0, Math.max(wrapWidth.current - carouselWidth.current, carouselLeft))
        }
    }, [carouselLeft])

    return (
        <div className="image-preview-custom-carousel-wrap" ref={wrapRef}>
            <div className="image-preview-custom-carousel" ref={carouselRef} style={carouselStyle}>
            {
                items.map((item, index) => {
                    return (
                        props.imageType == IMAGE_TYPE.COMMODITY_VIDEO || props.imageType == IMAGE_TYPE.MODEL_VIDEO ?
                        <video
                            id={`preview-thumbnails-${index}`}
                            className={current == index ? 'actived' : ''}
                            key={item}
                            src={item}
                            onClick={() => onChange(index)}
                            onError={() => setErrorList(pre => [...pre, index])}
                        ></video> :
                        <Image
                            id={`preview-thumbnails-${index}`}
                            rootClassName={`preview-thumbnails-img ${current == index ? 'actived' : ''}`}
                            key={item}
                            preview={false}
                            src={Utils.getImgUrlWithWebp(item, true)}
                            fallback={fallback}
                            onClick={() => onChange(index)}
                            onError={() => setErrorList(pre => [...pre, index])}
                        />
                    )
                })
            }
            </div>
        </div>
    )
}

const PreviewImage = (props) => {
    const [visible, setVisible] = useState()
    const [current, setCurrent] = useState(1)
    const [items, setItems] = useState([])
    const itemsRef = useRef([])

    useEffect(() => {
        if(!props.open) return
        setCurrent(props.current)
    }, [props.current, props.open])

    useEffect(() => {
        // console.log('props', props)
        setVisible(props.open)
        itemsRef.current = props.items.map(item => item.image)
        setItems(props.items.map(item => item.image))
    }, [props.open, props.items])

    const currentResult = useMemo(() => {
        return props.items[current]
    }, [current, props.items])

    const handlePre = (e) => {
        e.stopPropagation()
        e.preventDefault()
        setCurrent(curr => {
            if (curr == 0) return items.length - 1
            else return curr - 1
        })
    }

    const handleNext = (e) => {
        e.stopPropagation()
        e.preventDefault()
        setCurrent(curr => {
            if (curr == items.length - 1) return 0
            else return curr + 1
        })
    }

    const [moveable, setMoveable] = useState(0) // 0-正常模式；1-可缩放模式
    const handleSwitch = () => {
        setMoveable(moveable == 0 ? 1 : 0)
    }
    const imageRender = ((originalNode, info) => {
        // const {current} = info
        // const img = itemsRef.current[current]
        return (
            props.open &&
            <ImageRender
                // key={img}
                imageType={props.imageType}
                showBookmark={props.showBookmark}
                projectName={props.projectName} 
                current={current}
                items={items}
                {...currentResult}
                closeFunc={() => props.closeFunc(false)}
                preFunc={handlePre}
                nextFunc={handleNext}
                moveable={moveable}
                originalNode={originalNode}
                onSwitch={handleSwitch}
            />
        )
    })

    const toolbarRender = (_, info) => {
        return (
            <>
            {moveable == 1 && <div className="image-preview-custom-goback" onClick={handleSwitch}>退出大图预览</div>}
            <ToolRender imageType={props.imageType} {...info} items={items} onChange={value => setCurrent(value)}/>
            </>
        )
    }

    const countRender = () => null

    return (
        <Image.PreviewGroup
            items={items}
            preview={{
                visible,
                getContainer: '',
                current: current,
                rootClassName: `${"previewImageRoot"} ${moveable == 0 ? "" : "original"}`,
                countRender,
                toolbarRender,
                imageRender,
                onChange: (current, prev) => setCurrent(current),
                // onVisibleChange: (value) => props.closeFunc(value), // 禁止点击mask关闭
            }}
        >
        </Image.PreviewGroup>
    )
}

export default PreviewImage