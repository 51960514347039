import styles from './index.module.scss'

export default function LeftBox () {
    const tips = [
        '丰富模特  效果真实',
        '海量场景  销量密码',
        '商业授权  使用无忧',
        '专属定制  创意出图',
        '图像编辑  质感加分'
    ]
    
    return (
        <div className={styles.LeftBox}>
            <ul>
            {
                tips.map((text, index) => <li key={index}>{text}</li>)
            }
            </ul>
        </div>
    )
}