import { useNavigate } from 'react-router-dom';
import { Badge, Row, Space, Button, message } from 'antd';
import ProcessImg from '../processImg/processImg'
import { AppContext } from '@utils/AppContext';
import './taskListItem.scss'
import API from '@api/api.js';
import { useContext } from 'react';
import {workflowOfRebuild} from '@utils/workflowOfBuildTask';

const TaskListItem = (props) => {
    const { forceUpdate, setGlobalLoading } = useContext(AppContext) // 触发更新flag
    const REDIRECT_URL = '/home/homemain/photoProjectDetail';



    const navigate = useNavigate()

    const todetail = () => {
        navigate(REDIRECT_URL, {state: {id: props?.id}})
        props.closeHandler && props.closeHandler()
    }

    const rebuildHandler = (imageType) => {
        setGlobalLoading('生成中')
        workflowOfRebuild(props.taskId, imageType, props.projectName).then(res => {
            if (res) forceUpdate({})
        }).finally(() => {
            setGlobalLoading(false)
        })
    }

    const onRebuild = (e) => {
        e.stopPropagation()
        API.getProjectDetail({
            projectId: props.id,
        }).then(res => {
            if (!res || res?.code !== 0 || !res?.data) {
                console.log('get projectList error');
                message.warning(res?.message)
                return
            }
            const imageType = res?.data?.imageType
            rebuildHandler(imageType)
        }).catch(err => {
            message.warning(err.message)
        })
    }

    return (
        <Row className="task-list-item" onClick={() => todetail()}>
            <Space>
                <Badge dot={!!props.unread} className='item-cover'>
                    <ProcessImg taskStatus={props.status} imgSrc={props.inputImage} />
                </Badge>
            </Space>
            <Space><span>{ props.projectName }</span></Space>
            <Space><span>{ props.createdTime }</span></Space>
            <Space><span>{ props.completedAt }</span></Space>
            <Space>
                <span>{ props.status == 'succeeded' ? '已完成' : props.status == 'failed' ? '生成失败' : (props.status || '0%') }</span>
                {props.status == 'failed' && <Button className='item-rebuild' onClick={onRebuild}>重新生成</Button>}
            </Space>
        </Row>
    )
}

export default TaskListItem