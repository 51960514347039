import TextTag from '@view/compoents/textTag/textTag'
import { Tag, Button, Dropdown, notification, Checkbox } from 'antd';
import { useContext, useState } from 'react';
import { GenerateContext } from '@utils/GenerateContext';
import styles from './index.module.scss'

export default function FeatureSelection (props) {
    const {
        faceTagText, setFaceTagText, // 特征属性：表情
        hairTagText, setHairTagText, // 特征属性：发型
    } = useContext(GenerateContext)
    const [dropFlag, setDropflag] = useState(false)
    const dropChange = (flag) => {
        setDropflag(flag)
    }
    const tagCloseClick = (e, T) => {
        e.preventDefault();
        if(T == 't1') {
            onFaceTagChange('default', '默认')
        } else {
            onHairTagChange('default', '默认')
        }
    }
    // 表情更改
    const onFaceTagChange = (val, text) => {
        setFaceTagText(text)
    }
    // 发型更改
    const onHairTagChange = (val, text) => {
        setHairTagText(text)
    }
    
    const FeatureCotent = () => {
        return (
          <div className={styles.feature_content}>
            <TextTag
                faceTagText={faceTagText}
                hairTagText={hairTagText}
                faceTagChange={onFaceTagChange}
                hairTagChange={onHairTagChange}
            />
          </div>
        );
    }
    
    return (
        <Dropdown
            trigger={['click']}
            onOpenChange={dropChange}
            dropdownRender={(menu) => <FeatureCotent/>}
        >
            <div className={styles.tag_styl}>
                <span className={styles.tag_title}>特征</span>
                <div className={styles.tag_box}>
                    <Tag closeIcon={faceTagText != '默认'} onClose={(e) => tagCloseClick(e, 't1')}>{faceTagText == '默认'? '默认表情': faceTagText}</Tag>
                    <Tag closeIcon={hairTagText != '默认'} onClose={(e) => tagCloseClick(e, 't2')}>{hairTagText == '默认'? '默认发型': hairTagText}</Tag>
                    <span className={styles.tag_create}><img className={dropFlag? styles.tag_img: null} src={require('@assets/images/Frame 746.png')} alt="" /></span>
                </div>
            </div>
        </Dropdown>
    )
}