/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useEffect, useState } from 'react';
import axios from 'axios';
import styles from './index.module.scss'
import API from '@api/api.js';
import Utils from '@utils/utils'

const CaptchaA = (props) => {
    let captcha;
    const getInstance = (instance) => {
        captcha = instance;
    };


    const captchaVerifyCallback = async (captchaVerifyParam) => {
        try {
            const response = await API.usercaptcha({
                captchaVerifyParam: captchaVerifyParam
            })
            // const response = await axios.post(API.url + '/ps/web/user/captcha', {
            //     captchaVerifyParam: captchaVerifyParam,
            // }, {
            //     headers: {'Content-Type': 'application/json'}
            // });
            return {
                captchaResult: response.data,
                bizResult: response.data,
            }
        
            } catch (error) {
            console.error(error);
        }
    }
    
    // 验证通过后调用
    const onBizResultCallback = (flag)  => {
        props.onBizResultCallback(flag)
    }
    

    useEffect(() => {
        window.initAliyunCaptcha({
            SceneId: Utils.isMobileDevice()?'7qmkws0i': 'l00zadro', // 场景ID。根据步骤二新建验证场景后，您可以在验证码场景列表，获取该场景的场景ID
            prefix: 'tr3q3u', // 身份标。开通阿里云验证码2.0后，您可以在控制台概览页面的实例基本信息卡片区域，获取身份标
            mode: 'popup', // 验证码模式。popup表示要集成的验证码模式为弹出式。无需修改
            element: '#captcha-element', // 页面上预留的渲染验证码的元素，与原代码中预留的页面元素保持一致。
            button: props.id, // 触发验证码弹窗的元素。button表示单击登录按钮后，触发captchaVerifyCallback函数。您可以根据实际使用的元素修改element的值
            captchaVerifyCallback: captchaVerifyCallback, // 业务请求(带验证码校验)回调函数，无需修改
            onBizResultCallback: onBizResultCallback, // 业务请求结果回调函数，无需修改
            getInstance: getInstance, // 绑定验证码实例函数，无需修改
            slideStyle: {
                width: 360,
                height: 40,
            }, // 滑块验证码样式，支持自定义宽度和高度，单位为px。其中，width最小值为320 px
            language: 'cn', // 验证码语言类型，支持简体中文（cn）、繁体中文（tw）、英文（en）
            immediate: true, //避免拼图对准仍然验证失败的情况
        });
        return () => {
            // 必须删除相关元素，否则再次mount多次调用 initAliyunCaptcha 会导致多次回调 captchaVerifyCallback
        document.getElementById('aliyunCaptcha-mask')?.remove();
        document.getElementById('aliyunCaptcha-window-popup')?.remove();
        }
    }, []);

    return (
    <div className="captcha-a">
        <div id="captcha-element"></div>
    </div>
    )
}

export default CaptchaA;