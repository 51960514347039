import React, { useImperativeHandle, forwardRef, useRef, useState, useEffect, useContext, useMemo, useCallback } from 'react';
import { Button, Empty, Upload, message } from 'antd';
import styles from './index.module.scss'
import PSAIModal from '@PSAIComponents/PSAIModal';
import API from '@api/api'
import { AppContext } from '@utils/AppContext';
import { HOME_PAGE_TYPE, IMAGE_TYPE } from '@utils/CONST';
import dayjs from 'dayjs';
import InfiniteScroll from 'react-awesome-infinite-scroll';
import Utils from '@utils/utils';
import useResizeObserver from '@utils/useResizeObserver';
import GoBack from '../goBack/goBack';
import PickFromProject from '../PickFromProject';

const WaitUploadPage = (props) => {
    const [uploading, setUploading] = useState(false)
    const [open, setOpen] = useState(false)

    const onPickImage = () => {
        setOpen(true)
    }

    const uploadDebounce = useCallback(_.debounce(async (file, fileList) => {
        setUploading(true)
        console.log('===uploadDebounce===')
        props.multiple ?
            await props.onUpload(fileList) :
            await props.onUpload(file)
        setUploading(false)
    }, 100), [])

    const beforeUpload = async (file, fileList) => {
        console.log('===beforeUpload===')
        uploadDebounce(file, fileList)
        return false
    }

    const onClick = async (e, example) => {
        e.stopPropagation()
        setUploading(true)
        const {category, name, source, sourceGroup, type} = example
        if (sourceGroup) {
            const base64List = await Promise.all(sourceGroup.map(source => Utils.urlToBase64(source)))
            const fileList = base64List.map(base64 => Utils.convertBase64ToFile(base64, name))
            await props.onUpload([...fileList], name)
        } else {
            const base64 = await Utils.urlToBase64(source)
            const file = Utils.convertBase64ToFile(base64, name)
            props.multiple ?
                await props.onUpload([file], name):
                await props.onUpload(file, name)
        }
        setUploading(false)
    }    

    const handleDrop = async (e) => {
        if ("getFilesAndDirectories" in e.dataTransfer) {
            const filesAndDirs = await e.dataTransfer.getFilesAndDirectories()
            const fileList = await Utils.getFilesFromDirs(filesAndDirs, "/")
            console.log('===handleDrop===')
            uploadDebounce(fileList[0], fileList)
            return false
        }
    }

    const defaultPageLeft = (
        <Upload.Dragger
            accept='image/jpeg,image/png,image/webp,image/avif'
            listType="picture-card"
            beforeUpload={beforeUpload}
            className={styles.defaultPageUploader}
            showUploadList={false}
            multiple={props.multiple}
            maxCount={props.maxCount || 1}
            onDrop={handleDrop}
        >
            <div className={styles.defaultPageLeft}>
                <div>
                <Button type="primary" loading={uploading}>
                    {props.multiple && <div className={styles.BatchIcon}><span>批量</span></div>}
                    {props.btnText}
                </Button>
                <p className={styles.UploadTips}>{props.uploadTips}</p>
                <ul>
                {
                    props.uploadExamples.map((item, index) => (
                        <li key={item.name}>
                        {
                            item.sourceGroup ?
                            <div className={styles.exampleGroup} onClick={e => onClick(e, item)} >
                            {
                                item.sourceGroup.map((source, index) => (
                                    <img key={index} src={Utils.getImgUrlWithWebp(source)} />
                                ))
                            }
                            </div> :
                            <div className={styles.exampleSingle} onClick={e => onClick(e, item)}>
                                <img src={Utils.getImgUrlWithWebp(item.source)} />
                            </div>
                        }
                        </li>
                    ))
                }
                </ul>
                </div>
            </div>
        </Upload.Dragger>
    )

    const projectListRef = useRef()
    const handleOk = async () => {
        const url = await projectListRef.current.onSubmit()
        if (!url) return message.info('请选择一张图片')
        if (props.onValidate) {
            const validate = await props.onValidate(url)
            if (!validate) return false
        }
        setOpen(false)
        props.onChange(url)
    }
    const onDoubleClick = async (url) => {
        if (props.onValidate) {
            const validate = await props.onValidate(url)
            if (!validate) return false
        }
        setOpen(false)
        props.onChange(url)
    }

    const [wrapWidth, setWrapWidth] = useState()
    const targetRef = useResizeObserver((entries) => {
        for (let entry of entries) {
            // 处理尺寸变化的逻辑
            setWrapWidth(entry.contentRect.width)
        }
    })

    return (
        <div className={`${styles.Container} ${wrapWidth < 778 && styles.Tiny}`} ref={targetRef}>
            {/* <p>请直接上传图片，或去我的项目中选图</p> */}
            <div id="tour_target_upload" className={styles.mainPart}>
                {defaultPageLeft}
                {
                    props.pickFromProject &&
                    <Button
                        className={styles.PickFromProject}
                        onClick={onPickImage}
                    >去【我的项目】中选图</Button>
                }
            </div>
            {
                props.children &&
                <div className={styles.rightPart}>{props.children}</div>
            }
            <PSAIModal
                open={open}
                maxWidth={980}
                title="选择图片"
                onOk={handleOk}
                onCancel={() => setOpen(false)}
            >
                <PickFromProject
                    ref={projectListRef}
                    onDoubleClick={onDoubleClick}
                    useImageType={props.useImageType}
                />
            </PSAIModal>
        </div>
    )
}

export default WaitUploadPage