import React, { useState, useMemo, useRef, useEffect } from 'react';
import '../../aiEdit.scss'
import './fillLight.scss'
import { message, Slider, Button, Spin} from 'antd';
import Utils from '@utils/utils'
import ImgComparer from '../imgComparer'
import LightCanvas from './lightCanvas'
import DragAndScale from '../dragAndScale/dragAndScale'
import ToolBox from '../toolBox/toolBox';
import {forwardRef, useImperativeHandle} from 'react'
import { AppContext } from '@utils/AppContext';
import { useContext } from 'react'
import { AIEDIT_SOURCE } from '@utils/CONST';
import { AIEditContext } from '../../AIEditContext';

const FillLight = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        onChange: () => {
            return imgResultRef.current || props.url
        },
        selectPreset,
        lightLoadModule,
        lightSettingChange
    }))

    const {
        needPay, setNeedPay, getImgWithWatermark,
        adjustLightFlag, setAdjustLightFlag,
        highDefult, setHighDefult,
        rangeDefult, setRangeDefult
    } = useContext(AIEditContext)
    const { aiEditDownloadWithLoadingFunc } = useContext(AppContext) // 触发更新flag

    const isMounted = useRef(true) // 判断组件是否已销毁
    const wrapRef = useRef()
    const dragScaleRef = useRef()
    const lightRef = useRef(null);
    const [orignPicBase64, setOrignPicBase64] = useState()
    const [orignPic, setOrignPic] = useState();
    const [compareBtn, setCompareBtn] = useState(false);
    const [reswidth, setWidth] = useState();
    const [resheight, setHeight] = useState()
    const [displayScale, setDisplayScale] = useState(1)
    const imgWidthRef = useRef()
    const [imgWidth, setImgWidth] = useState() // 图片原始宽度
    const imgHeightRef = useRef()
    const [imgHeight, setImgHeight] = useState() // 图片原始高度
    const predictList = [
        {url: 'automatic lighting.png', style: 'random', name: '自动补光'}, 
        {url: '002胶片.jpg', style: 'film', name: '胶片'}, 
        {url: '002奶油.jpg', style: 'milk', name: '奶油'}, 
        {url: '002黑白.jpg', style: 'black' , name: '黑白'}
    ];
    const [preset, setPreset] = useState()
    const [imgResultWithWatermark, setImgResultWithWatermark] = useState()
    const imgResultRef = useRef()
    const [imgResult, setImgResult] = useState()
    const [reset, setReset] = useState(false)
    const [isInit, setIsInit] = useState(false)
    const [moveFlag, setMoveFlag] = useState(false); // 记录是否是第一次打光
    const [canvasRef, setCanvasRef] = useState(null); 
    const [canvasRefMouse, setCanvasRefMouse] = useState(null);
    const [inited, setInited] = useState(false)

    const calcCanvasSize = () => {
        const containerWidth = wrapRef.current?.clientWidth - 120 - 150
        const containerHeight = wrapRef.current?.clientHeight
        const size = Utils.getImgMaxSizeInContainer(
            imgWidthRef.current,
            imgHeightRef.current,
            containerWidth,
            containerHeight
        )
        setDisplayScale(size.width / imgWidthRef.current)
        setWidth(size.width)
        setHeight(size.height)
    }

    useEffect(() => {
        const onWindowResize = (e) => {
            calcCanvasSize()
        }
        window.addEventListener('resize', onWindowResize)
        return () => {
            isMounted.current = false
            window.removeEventListener('resize', onWindowResize)
        }
    }, [])

    useEffect(() => {
        if(props.url) {
            Utils.urlToBase64(props.url).then(base64 => {
                setOrignPic(props.url)
                setOrignPicBase64(base64)                
            })            
        }
    }, [])

    useEffect(() => {
        if (!orignPic) return
        Utils.getImageDimensions(orignPic).then(({width, height}) => {
            imgWidthRef.current = width
            setImgWidth(width)
            imgHeightRef.current = height
            setImgHeight(height)
            calcCanvasSize()
            setInited(true)
        })
    }, [orignPic])

    const resetClick = () => {
        setCompareBtn(false)
        imgResultRef.current = null
        setImgResult()
        downloadUrl.current = null
        setPreset()
        setReset(!reset)
        lightRef.current.removeLight()
        dragScaleRef.current.resetCanvas()
        setAdjustLightFlag(true)
        setHighDefult(50)
        setRangeDefult(50)

    }

    const compareClick = () => {
        if (!compareBtn) {
            getImgWithWatermark(imgResult).then(base64 => {
                setImgResultWithWatermark(base64)
                setCompareBtn(true)
            })
        } else {
            setCompareBtn(false)
        }
    }

    const downloadUrl = useRef()
    async function commonDownloadFunc() {
        if (downloadUrl.current) {
            Utils.downUrl(downloadUrl.current, sessionStorage.getItem('oplog_imageType'), false)
            message.success('下载成功')
        } else {
            downloadUrl.current = await aiEditDownloadWithLoadingFunc(
                imgResult,
                AIEDIT_SOURCE.AI_LIGHT,
                sessionStorage.getItem('oplog_imageType'),
                needPay
            )
            if (downloadUrl.current) setNeedPay(false)
        }
    }

    const selectPreset = (index, style) => {
        if(!lightRef.current) return
        setPreset(index)
        setCompareBtn(false)
        lightRef.current.removeLight()
        if(style == 'random') {
            lightLoadModule('auto_angle', 1)
            setPreset(0)
        } else {
            if(isInit) {
                lightRef.current.filterLoadModule(style)
            } else {
                lightRef.current.aibeautyInit(initFlag => { // 先取landmarks
                    setIsInit(initFlag)
                    lightRef.current.filterLoadModule(style)
                })
            }
        }
    }

    const lightSettingChange = (val, text) => {
        if(!lightRef.current) return
        lightRef.current.changeLight(val, text)
    }

    const lightLoadModule = (type, value, flag) => { // value为1，type
        if(!lightRef.current) return
        if(isInit) {
            lightLoadModule_child(type, value, flag)
        } else {
            lightRef.current.aibeautyInit(initFlag => { // 先取landmarks
                setIsInit(initFlag)
                lightLoadModule_child(type, value, flag)
            })
        }
    }

    function lightLoadModule_child(type, value, flag) {
        setPreset()
        setCompareBtn(false)
        imgResultRef.current = null
        setImgResult()
        downloadUrl.current = null
        // if(moveFlag) lightRef.current.createLight(50, '#fff') // 当moveFlag为true,不是第一次打光，才重绘光源点
        if(type != 'auto_angle') {
            lightRef.current.resetLight(50, 56, 50);
            setAdjustLightFlag(false)
        }
        lightRef.current.lightLoadModule(56, 50, type, value, value, flag) // 光源初始位置（56, 50）
        setHighDefult(50)
        setRangeDefult(50)
    }

    const getImgResult = (base64) => {
        setReset(false)
        imgResultRef.current = base64
        setImgResult(base64)
        downloadUrl.current = null
    }

    const getMoveFlag = (val) => {
        setMoveFlag(val)
    }

    const getCanvasRefsFun = (canvasRef, canvasRefMouse) => {
        setCanvasRef(canvasRef)
        setCanvasRefMouse(canvasRefMouse)
    }

    const [moveable, setMoveable] = useState()
    const onDragAndScaleChange = ({zoom, moveable, zoomable}) => {
        setMoveable(moveable)
    }

    useEffect(() => {
        if(compareBtn) {
            dragScaleRef.current.resetCanvas()
        }

    }, [compareBtn])


    const rightContent = (
        <div className='edit_right_c' style={{position: 'relative'}} ref={wrapRef}>
        {
            inited ?
            <div className='wrap_box wrap_box_'>
                <div className='dragAndScale_box'>
                {
                    canvasRefMouse? <DragAndScale
                    ref={dragScaleRef}
                    min={1}
                    max={8}
                    onChange={onDragAndScaleChange}
                    canvasList={[canvasRefMouse, canvasRef]}
                    disabled={compareBtn? true: false}
                    options={[
                        { label: '适应屏幕', value: 1 },
                        // { label: '实际大小', value: Number((1 / displayScale).toFixed(2)) },
                        { label: '100%', value: 1 },
                        { label: '200%', value: 2 },
                        { label: '300%', value: 3 },
                        { label: '400%', value: 4 },
                    ]}
                    />: null
                }
                {
                    compareBtn?<p>对比时可直接使用滚轮缩放、按住鼠标移动</p>: null
                }
                </div>
                {
                    compareBtn?
                    <div  
                    style={{height: resheight, width: reswidth, zIndex: 9999}}
                    className='position_styl'>
                        <ImgComparer
                        leftImage={orignPic}
                        rightImage={imgResultWithWatermark}
                        ></ImgComparer>
                    </div>: null
                }
                <LightCanvas
                    className='position_styl'
                    opacity={compareBtn? 0:1}
                    reset = {reset}
                    ref = {lightRef}
                    orignPicBase64={orignPicBase64}
                    orignPic = {orignPic}
                    reswidth = {reswidth}
                    resheight = {resheight}
                    displayScale={displayScale}
                    getImgResult = {getImgResult}
                    getMoveFlag = {getMoveFlag}
                    getCanvasRefsFun={getCanvasRefsFun}
                    moveable={moveable}
                    errorCallback={props.errorCallback}
                />
                <ToolBox
                    showCompare={compareBtn}
                    compare={{disabled: !imgResult, trigger: compareClick}}
                    reset={{disabled: !imgResult, trigger: resetClick}}
                    download={{disabled: !imgResult, trigger: commonDownloadFunc}}
                    uploadCb={props.uploadCb}
                />
            </div> : 
            <div style={{display:"flex", justifyContent:"center", alignItems: "center", height: "100%"}}>
                <Spin size="large" />
            </div>
        }
        </div>
    )

    return (
        <div className='edit_cont FillLight'>
            <div className='edit_right'>
            {
                !props.url ?
                props.defaultPage:
                rightContent     
            } 
            </div>
        </div>
    )
})

export default FillLight;
