import { useState, useRef, useEffect, useContext, useMemo } from 'react'
import './taskList.scss'
import TaskListItem from "./taskListItem"
import InfiniteScroll from 'react-awesome-infinite-scroll';
import Utils from '@utils/utils'
import API from '@api/api.js';
import { AppContext } from '@utils/AppContext';
import { HOME_PAGE_TYPE, IMAGE_TYPE } from "@utils/CONST"

const TaskList = (props) => {
    const {globalHomePageType} = useContext(AppContext)
    const [list, setList] = useState([])
    const [hasMore, setHasMore] = useState()
    const [isRefreshing, setIsRefreshing] = useState()

    const PAGE_SIZE_NUM = 5;
    const [total, setTotal] = useState(0)
    const [pageIndex, setPageIndex] = useState(1)
    const [pageSize, setPageSize] = useState(PAGE_SIZE_NUM)    
    const [isCheckStatus, setIsCheckStatus] = useState(false)

    const fetchType = useMemo(() => {
        if (globalHomePageType == HOME_PAGE_TYPE.MERCHANDISE) {
            return IMAGE_TYPE.COMMODITY
        } else if (globalHomePageType == HOME_PAGE_TYPE.CLOTHING) {
            return IMAGE_TYPE.MODEL
        }
    }, [globalHomePageType])

    const fetchData = (pageIndex, pageSize) => {
        return API.getTaskListByUser({
            type: fetchType,
            pageIndex,
            pageSize
        }).then(res => {
            // console.log('fetchData res', res)
            if (!res || res?.code !== 0 || !res?.data?.items) {
                console.log('get getTaskList error');
                throw new Error('get getTaskList error')
            }
            setTotal(res?.data?.total)
            return {
                list: res?.data?.items,
                total: res?.data?.total
            }
        })
    }

    const loopFetchData = (index) => {
        const pageSize = index+1
        return API.getTaskListByUser({
            type: fetchType,
            pageIndex: 1,
            pageSize
        }).then(res => {
            if (!res || res?.code !== 0 || !res?.data?.items) {
                console.log('get getTaskList error');
                throw new Error('get getTaskList error')
            }
            const arr = res?.data?.items
            setList(pre => {
                const arr2 = [...pre]
                for (let i = 0; i < arr.length; i++) {
                    for (let j = 0; j < arr2.length; j++) {
                        if (arr2[j].id == arr[i].id && arr2[j].taskId == arr[i].taskId) {
                            arr2[j].completedAt = arr[i].completedAt
                            arr2[j].status = arr[i].status
                        }
                    }
                }
                return arr2
            })            
        }).catch(err => console.error(err))
    }

    const getLastUnsucceededIndex = () => {
        let lastUnsucceededIndex = -1
        for (let i = list.length - 1; i >= 0; i--) {
            if (list[i].status != 'succeeded' && list[i].status != 'failed') {
                lastUnsucceededIndex = i
                break
            }
        }
        return lastUnsucceededIndex
    }

    const checkStatus = () => {
        if (!isCheckStatus) {
            const index = getLastUnsucceededIndex()
            if (index < 0) return
            setIsCheckStatus(true)
            Utils.delayFunc(1000).then(() => {
                return loopFetchData(index)
            }).finally(() => {
                setIsCheckStatus(false)
            })
        }
    }

    useEffect(() => {
        // console.log('useEffect')
        fetchData(pageIndex, pageSize).then(res => {
            console.log('res.list', res.list)
            const list = res.list.map(item => {
                let inputImage = item.inputImage
                if (inputImage.split(',').length > 1) inputImage = inputImage.split(',')[0]
                return {...item, inputImage}
            })
            setList(list)
            setHasMore(pageSize < res.total)
        }).catch(err => {
            console.error(err)
        })
    }, [])

    useEffect(() => {
        checkStatus()
    }, [list])

    // loading more
    const fetchMoreData = () => {
        console.log('===fetchMoreData===')
        const nextPageIndex = pageIndex+1
        setPageIndex(nextPageIndex)
        fetchData(nextPageIndex, pageSize).then(res => {
            const next = list?.concat(res.list)
            if (next?.length >= res.total) {
                setHasMore(false)
            }
            console.log('next', next)
            const list2 = next.map(item => {
                let inputImage = item.inputImage
                if (inputImage.split(',').length > 1) inputImage = inputImage.split(',')[0]
                return {...item, inputImage}
            })
            setList(list2)
        }).catch(err => {
            console.error(err)
        })
    }

    // const reload = () => {
    //     setIsRefreshing(true)
    //     console.log('reload')
    //     const pageIndex = 1
    //     fetchData().then(res => {
    //         setOriginList(res)
    //         const total = res.length
    //         setList(res.slice((pageIndex-1)*pageSize, pageSize * pageIndex))
    //         setHasMore(pageSize < total)
    //         setIsRefreshing(false)
    //     }).catch(err => {
    //         console.error(err)
    //     })
    // }

    return (
        <div className="task-list">
            {/* { loading ? <Loading></Loading> : null } */}
            <div className="task-list-header">
                <span>商品图</span>
                <span>商品名称</span>
                <span>添加时间</span>
                <span>生成时间</span>
                <span>任务状态</span>
            </div>
            <div className="task-list-body">
                <InfiniteScroll
                    length={list?.length}
                    next={fetchMoreData}
                    hasMore={hasMore}
                    scrollableParent={document.querySelector(".task-list-body")}
                    // pullDownToRefresh
                    isRefreshing={isRefreshing}
                    // refreshFunction={reload}
                    pullDownComponent={<div style={{ height: "50px", background: "green" }}>下拉</div>}
                    releaseComponent={<div style={{ height: "50px", background: "red" }}>释放</div>}
                    refreshingComponent={<div style={{ height: "50px", background: "green" }}>加载中</div>}
                    refreshEndComponent={<div style={{ height: "50px", background: "red" }}>加载完成</div>}
                    loadingComponent={<div style={{ height: "50px", lineHeight: "50px", textAlign: 'center' }}><h4>加载中...</h4></div>}
                    endComponent={
                        (list?.length) ?
                            <div style={{ height: "30px", lineHeight: "30px", textAlign: 'center', fontWeight: 'normal', color: '#999' }}>
                                <span>没有更多内容了</span>
                            </div> : null
                    }
                >
                    {
                        list.map(item => (
                            <TaskListItem key={item.taskId} {...item} closeHandler={props.closeHandler}></TaskListItem>
                        ))
                    }
                </InfiniteScroll>
            </div>
        </div>
    )
}

export default TaskList