import { ButtonProps, Modal } from "antd"
import React, {ReactNode} from 'react';
// @ts-ignore
import styles from './index.module.scss'

type ComponentProps = {
    title?: string;
    closable?: boolean;
    maskClosable?: boolean;
    open: boolean;
    children?: ReactNode;
    okText?: string;
    cancelText?:string;
    maxWidth?: number;
    confirmLoading?: boolean;
    footer?: ReactNode;
    getContainer?: string|HTMLElement;
    cancelButtonProps?: ButtonProps;
    okButtonProps?: ButtonProps;
    afterOpenChange?: () => {};
    onOk?: () => {};
    onCancel?: () => {};
}

const PSAIModal = (props: ComponentProps) => {
    const {
        closable = true,
        maskClosable = true,
        okText = '确定',
        cancelText = '取消',
        maxWidth = 800,
        getContainer = '#root'
    } = props

    
    return (
        <Modal
            title={props.title}
            closable={closable}
            closeIcon={<img src={require('@assets/images/icon_close.png')} />}
            maskClosable={maskClosable}
            open={props.open}
            centered
            getContainer={getContainer}
            destroyOnClose
            className={styles.__pasi_modal}
            style={{maxWidth: maxWidth}}
            confirmLoading={props.confirmLoading}
            footer={props.footer}
            afterOpenChange={props.afterOpenChange}
            onCancel={props.onCancel}
            cancelButtonProps={props.cancelButtonProps}
            cancelText={cancelText}
            okText={okText}
            okButtonProps={props.okButtonProps}
            onOk={props.onOk}
            zIndex={1000}
        >
        {props.children}
        </Modal>
    )
}

// @ts-ignore
PSAIModal.confirm = (params) => {
    const {
        closable=true,
    } = params
    const handleClose = () => {
        modal.destroy()
        if (params.onClose) return params.onClose()
        else params.onCancel && params.onCancel()
    }

    const contentRender = (
        <div className={styles.__pasi_confirm_contentwrap}>
            <div className={styles.__pasi_confirm_header}>
                <span>提示</span>
                {
                    closable ?
                    <button className="ant-modal-close" onClick={handleClose}>
                        <span className="ant-modal-close-x">
                            <img src={require('@assets/images/icon_close.png')} />
                        </span>
                    </button> :
                    null
                }                
            </div>
            <div className={styles.__pasi_confirm_body}>
                <p className={styles.__pasi_confirm_title}>{params.title}</p>
                <div className={styles.__pasi_confirm_content}>{params.content}</div>
            </div>
        </div>
    )

    const modal = Modal.confirm({
        className: `${styles.__pasi_modal} ${styles.__pasi_modal_confirm}`,
        style: {maxWidth: 544},
        centered: true,
        icon: null,
        content: contentRender,
        cancelText: params.cancelText,
        okText: params.okText,
        onOk: params.onOk,
        onCancel: params.onCancel,
        zIndex: 1000,
    })

    return modal
}

export default PSAIModal