import { useEffect, useState } from 'react'
import { Button, Row, Select, Modal, Input, message } from 'antd';
import { ExclamationCircleOutlined, EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import API from '@api/api.js';
import PublicKey from '@utils/publicKey'
import { useNavigate }from "react-router-dom"; 
import './updatePasswordModal.scss'
import PSAIModal from '@PSAIComponents/PSAIModal';

const UpdatePasswordModal = (props) => {
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)
    const [noPassword, setNoPassword] = useState(localStorage.getItem('noPassword') == 'true')
    const [pwd, setPwd] = useState()
    const [pwdConfirm, setPwdConfirm] = useState()
    const [pwdOld, setPwdOld] = useState()
    const [pwdError, setPwdError] = useState()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setNoPassword(localStorage.getItem('noPassword') == 'true')
        setPwd('')
        setPwdConfirm('')
        setPwdOld('')
        setPwdError()
        setOpen(props.open)
    }, [props.open])

    const onOk = () => {
        if (noPassword) {
            if (!pwd || !pwdConfirm) return setPwdError('请输入密码')
            if (pwdError) return
            setLoading(true)
            API.setPassword({
                password: PublicKey.JSEncryptChange(pwd),
                repeat: PublicKey.JSEncryptChange(pwdConfirm),
                noPassword: true
            }).then(res => {
                if (res.code == 0) {
                    message.success('密码设置成功，请重新登录')
                    localStorage.removeItem('noPassword')
                    localStorage.removeItem('token')
                    sessionStorage.removeItem('taskCache_model')
                    sessionStorage.removeItem('taskCache_dressform')
                    sessionStorage.removeItem('taskCache_clothes')
                    sessionStorage.removeItem('taskCache_commodity')
                    sessionStorage.removeItem('taskCache_tryonVideo')
                    sessionStorage.removeItem('taskCache_commodityVideo')
                    navigate('/')
                } else {
                    setPwd('')
                    setPwdConfirm('')
                    setPwdError(res.message)
                }
            }).finally(() => {
                setLoading(false)
            })
        } else {
            if (!pwdOld) return setPwdError('请输入当前密码')
            if (!pwd || !pwdConfirm) return setPwdError('请输入新密码')
            if (pwdError) return
            setLoading(true)
            API.setPassword({
                password: PublicKey.JSEncryptChange(pwd),
                repeat: PublicKey.JSEncryptChange(pwdConfirm),
                noPassword: false,
                old: PublicKey.JSEncryptChange(pwdOld),
            }).then(res => {
                if (res.code == 0) {
                    message.success('密码更新成功，请重新登录')
                    localStorage.removeItem('noPassword')
                    localStorage.removeItem('token')
                    sessionStorage.removeItem('taskCache_model')
                    sessionStorage.removeItem('taskCache_dressform')
                    sessionStorage.removeItem('taskCache_clothes')
                    sessionStorage.removeItem('taskCache_commodity')
                    sessionStorage.removeItem('taskCache_tryonVideo')
                    sessionStorage.removeItem('taskCache_commodityVideo')
                    navigate('/')
                } else {
                    setPwd('')
                    setPwdConfirm('')
                    setPwdOld('')
                    setPwdError(res.message)
                }
            }).finally(() => {
                setLoading(false)
            })
        }
    }

    const onPwdBlur = () => {
        if (pwd && pwdConfirm && pwd != pwdConfirm) {
            setPwdError('两次密码不一致，请重新输入')
        } else {
            setPwdError()
        }
    }

    return (
        <PSAIModal
            title={noPassword ? '设置密码' : '更新密码'}
            open={open}
            confirmLoading={loading}
            onCancel={() => props.closeFunc()}
            onOk={onOk}
            maxWidth={520}
        >
            <div className='passwordModal_body'>
                {
                    noPassword ? (
                        <>
                            <div className='setPwd-box'>
                                <label htmlFor="pwd-new" className='setPwd_label'>设置密码</label>
                                <Input.Password
                                    id='pwd-new'
                                    className='setPwd_input'
                                    size='large'
                                    type='password'
                                    value={pwd}
                                    placeholder="请输入您的密码"
                                    onBlur={onPwdBlur}
                                    onChange={(e) => setPwd(e.target.value)}
                                    prefix={<img style={{width: 16}} src={require('@assets/images/icon_Password.png')} alt="" />}
                                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                />
                            </div>
                            <div className='setPwd-box'>
                                <label htmlFor="pwd-confirm" className='setPwd_label'>确认密码</label>
                                <Input.Password
                                    id='pwd-confirm'
                                    className='setPwd_input'
                                    size='large'
                                    type='password'
                                    value={pwdConfirm}
                                    placeholder="请再次输入您的密码"
                                    onBlur={onPwdBlur}
                                    onChange={(e) => setPwdConfirm(e.target.value)}
                                    prefix={<img style={{width: 16}} src={require('@assets/images/icon_Password.png')} alt="" />}
                                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                />
                                <p className='err_text' style={{opacity: pwdError? 1: 0}}><ExclamationCircleOutlined /> {pwdError}</p>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className='updatePwd-box'>
                                <label htmlFor="pwd-old" className='updatePwd_label'>输入当前密码</label>
                                <Input.Password
                                    id='pwd-old'
                                    className='updatePwd_input'
                                    size='large'
                                    type='password'
                                    value={pwdOld}
                                    placeholder="请输入您当前使用的密码"
                                    onBlur={onPwdBlur}
                                    onChange={(e) => setPwdOld(e.target.value)}
                                    prefix={<img style={{width: 16}} src={require('@assets/images/icon_Password.png')} alt="" />}
                                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                />
                            </div>
                            <div className='updatePwd-box'>
                                <label htmlFor="pwd-new" className='updatePwd_label'>设置新密码</label>
                                <Input.Password
                                    id='pwd-new'
                                    className='updatePwd_input'
                                    size='large'
                                    type='password'
                                    value={pwd}
                                    placeholder="请输入您的密码"
                                    onBlur={onPwdBlur}
                                    onChange={(e) => setPwd(e.target.value)}
                                    prefix={<img style={{width: 16}} src={require('@assets/images/icon_Password.png')} alt="" />}
                                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                />
                            </div>
                            <div className='updatePwd-box'>
                                <label htmlFor="pwd-confirm" className='updatePwd_label'>确认新密码</label>
                                <Input.Password
                                    id='pwd-confirm'
                                    className='updatePwd_input'
                                    size='large'
                                    type='password'
                                    value={pwdConfirm}
                                    placeholder="请再次输入您的密码"
                                    onBlur={onPwdBlur}
                                    onChange={(e) => setPwdConfirm(e.target.value)}
                                    prefix={<img style={{width: 16}} src={require('@assets/images/icon_Password.png')} alt="" />}
                                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                />
                                <p className='err_text' style={{opacity: pwdError? 1: 0}}><ExclamationCircleOutlined /> {pwdError}</p>
                            </div>
                        </>
                    )
                }
            </div>
        </PSAIModal>
    )
}

export default UpdatePasswordModal