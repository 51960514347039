import { useEffect, useRef, useState } from "react"
import { colorPrimary } from '@utils/AppContext';
const RADIS = 8
const FONT_SIZE = 10
const MAX_DISTANCE = 20 // 每对点的起始点和终点的像素距离限制在20像素(对应256x256尺寸)

const formartNumber = (number, digits=2) => {
    return Number(number.toFixed(digits))
}

// 两个坐标点之间的距离的平方
const calculateDistanceSquared = (x1, y1, x2, y2) => {
    const dx = x2 - x1;
    const dy = y2 - y1;
    return dx * dx + dy * dy
}

const getMaxDistancePoint = (x1, y1, x2, y2, distance) => {
    const vectorAB = {
        x: x2 - x1,
        y: y2 - y1
    }
    const lengthAB = Math.sqrt(vectorAB.x * vectorAB.x + vectorAB.y * vectorAB.y)
    const unitVectorAB = {
        x: vectorAB.x / lengthAB,
        y: vectorAB.y / lengthAB
    }
    return {
        x: x1 + unitVectorAB.x * distance,
        y: y1 + unitVectorAB.y * distance
    }
}

const useInteractive = (props) => {
    const { instance } = props
    const groupIndexRef = useRef(0) // 每组起始点的index
    const currentLineRef = useRef() // 当前的连线
    const endPointPosRef = useRef({x: 0, y: 0})
    const [rectX, setRectX] = useState(0)
    const [rectY, setRectY] = useState(0)
    const [points, setPoints] = useState([])
    const [showContextMenu, setShowContextMenu] = useState(false)
    const [mouseX, setMouseX] = useState(0)
    const [mouseY, setMouseY] = useState(0)

    // 正在变形，禁止交互
    const isLoadingRef = useRef(props.isLoading)
    useEffect(() => {
        isLoadingRef.current = props.isLoading
    }, [props.isLoading])

    // 监听displayScale
    const displayScaleRef = useRef(props.displayScale)
    useEffect(() => {
        displayScaleRef.current = props.displayScale
    }, [props.displayScale])

    // 是否可以移动
    const moveableRef = useRef(props.moveable)
    useEffect(() => {
        moveableRef.current = props.moveable
    }, [props.moveable])

    // 是否锁定区域
    const areaLockedRef = useRef(props.areaLocked)
    // 是否可以应用变形
    const shapeableRef = useRef(false)
    const [shapeable, setShapeable] = useState(false)
    const animationFrameRef = useRef(0) 
    const updateAnimation = () => {
        if (!isLoadingRef.current) return
        const frameRate = 30
        animationFrameRef.current++
        const x = Math.floor(animationFrameRef.current / frameRate)
        if (x == 0) {
            props.noticeInstance.item(1).set({ text: '4. 应用变形中' })
        } else if (x == 1) {
            props.noticeInstance.item(1).set({ text: '4. 应用变形中.' })
        } else if (x == 2) {
            props.noticeInstance.item(1).set({ text: '4. 应用变形中..' })
        } else if (x == 3) {
            props.noticeInstance.item(1).set({ text: '4. 应用变形中...' })
        } else {
            animationFrameRef.current = 0
        }
        instance.renderAll()
        // 请求下一帧动画
        fabric.util.requestAnimFrame(updateAnimation);
    }
    useEffect(() => {
        areaLockedRef.current = props.areaLocked
        if (!props.areaLocked) setShowContextMenu(false)
    }, [props.areaLocked])
    useEffect(() => {
        if (!instance) return
        if (props.isLoading) {
            updateAnimation()
            props.rectInstance.set({hoverCursor: 'wait'})
            props.buttonInstance.set({hoverCursor: 'wait'})
        } else if (shapeable) {
            props.noticeInstance.item(1).set({
                text: '3. 点击“应用变形”'
            })
            props.rectInstance.set({hoverCursor: 'pointer'})
            props.buttonInstance.set({hoverCursor: 'pointer'})
        } else if (props.areaLocked) {
            props.noticeInstance.item(1).set({
                text: '2. 在区域内标记变形的起点和终点'
            })
            props.rectInstance.set({hoverCursor: 'pointer'})
            props.buttonInstance.set({hoverCursor: 'pointer'})
        } else {
            props.noticeInstance.item(1).set({
                text: '1. 点击鼠标锁定区域'
            })
            props.rectInstance.set({hoverCursor: 'move'})
            props.buttonInstance.set({hoverCursor: 'pointer'})
        }
        const objects = instance.getObjects().filter(item => item.get('is_pickpoint'))
        objects.forEach(item => item.set({hoverCursor: 'move'}))
        instance.renderAll()
    }, [props.isLoading, props.areaLocked, shapeable, instance, props.moveable])

    const createLine = (x, y, zoom) => {
        const line = new fabric.Line([
            x, y, x, y
        ], {
            originY: 'center',
            stroke: 'white', // 笔触颜色
            strokeWidth: formartNumber(2 * displayScaleRef.current / zoom),
            selectable: false, // Set as not selectable
            evented: false // Set as not draggable
        })
        line.set('fixed_size', 2)
        line.set('is_pointline', true)
        line.set('pointline_group_index', groupIndexRef.current)
        return line
    }

    const createCircle = (left, top, zoom, isStart=true) => {
        // 应用变形 画出圆的半径

        // let circleFill = 'white'
        let circleFill = 'rgba(255, 36, 36, 1)'
        let circleStroke = 'blue'
        let textFill = 'blue'
        let textContent = '终'

        if (isStart) {
            // circleFill = 'blue'
            circleFill = colorPrimary
            textFill = 'white'
            textContent = '起'
        }
        const circle = new fabric.Circle({
            radius: formartNumber(RADIS * displayScaleRef.current / zoom), // 半径
            left: 0, // 圆形左上角坐标
            top: 0, // 圆形左上角坐标
            originX: 'center',
            originY: 'center',
            fill: circleFill,
            // stroke: circleStroke, // 圆形的边框颜色
            strokeWidth: formartNumber(2 * displayScaleRef.current / zoom), // 圆形的边框宽度
        })
        circle.set('fixed_size', RADIS)
        // const text = new fabric.Text(textContent, {
        //     left: 0, // 圆形左上角坐标
        //     top: 0, // 圆形左上角坐标
        //     originX: 'center',
        //     originY: 'center',
        //     fontSize: formartNumber(FONT_SIZE * displayScaleRef.current / zoom),
        //     fill: textFill
        // })
        // text.set('fixed_size', FONT_SIZE)
        // 合并圆形和文字为一个组合对象
        const group = new fabric.Group([circle], { 
            left: left,
            top: top,
            originX: 'center',
            originY: 'center',
            hasControls: false,
            hasBorders: false,
            selectable: true, // Set as not selectable
            evented: true // Set as not draggable
        })
        group.set('is_pickpoint', true)
        group.set('fixed_size', RADIS * 2)

        group.set('pickpoint_group_index', groupIndexRef.current)
        group.set('pickpoint_type', isStart ? 'start' : 'end')

        group.on('moving', () => {
            const maxLen = formartNumber(MAX_DISTANCE * displayScaleRef.current) // 每对点的起始点和终点的像素距离限制在20像素(对应256x256尺寸)

            const x = group.left
            const y = group.top

            const index = group.get('pickpoint_group_index')
            const type = group.get('pickpoint_type')
            const line = instance.getObjects().find(item => item.get('is_pointline') && item.get('pointline_group_index') == index)
            const anotherPoint = instance.getObjects()
                .find(item => (
                    item.get('is_pickpoint') &&
                    item.get('pickpoint_group_index') == index &&
                    item.get('pickpoint_type') == (type == 'start' ? 'end' : 'start')
                ))
            if (anotherPoint) {
                const distanceSquared = calculateDistanceSquared(x, y, anotherPoint.left, anotherPoint.top)
                if (distanceSquared > maxLen * maxLen) {
                    const maxDistancePoint = getMaxDistancePoint(anotherPoint.left, anotherPoint.top, x, y, maxLen)
                    group.set({
                        left: maxDistancePoint.x,
                        top: maxDistancePoint.y,
                    })
                    line.set({
                        x1: maxDistancePoint.x,
                        y1: maxDistancePoint.y,
                        x2: anotherPoint.left,
                        y2: anotherPoint.top
                    })
                } else {
                    line.set({
                        x1: x,
                        y1: y,
                        x2: anotherPoint.left,
                        y2: anotherPoint.top
                    })
                }
            } else {
                line.set({x1: x, y1: y, x2: x, y2: y})
            }
            group.setCoords()
            line.setCoords()
            instance.renderAll()
        })
        return group
    }

    const deletePointGroup = () => {
        // 正在变形
        if (isLoadingRef.current) return
        if (!instance) return
        const activeObject = instance.getActiveObject()

        if (activeObject && activeObject.get('is_pickpoint')) {
            const index = activeObject.get('pickpoint_group_index')
            const objs = instance.getObjects().filter(item => item.get('pickpoint_group_index') == index)
            objs.forEach(item => instance.remove(item))
            const line = instance.getObjects().find(item => item.get('is_pointline') && item.get('pointline_group_index') == index)
            if (currentLineRef.current == line) {
                currentLineRef.current = null
            }
            instance.remove(line)
        }
    }
    const setRectPosition = (left, top) => {
        // const canvasOffsetX = instance.viewportTransform[4]
        // const canvasOffsetY = instance.viewportTransform[5]
        const x = left * displayScaleRef.current
        const y = top * displayScaleRef.current

        props.rectInstance.set({left: formartNumber(x), top: formartNumber(y),})
        props.rectInstance.setCoords()

        props.noticeInstance.set({
            left: formartNumber(x),
            top: formartNumber(y - props.rectInstance.height / 2),
        })
        props.noticeInstance.setCoords()

        props.buttonInstance && props.buttonInstance.set({
            left: formartNumber(x),
            top: formartNumber(y + props.rectInstance.height / 2),
        })
        props.buttonInstance && props.buttonInstance.setCoords()

        instance.renderAll()
    }
    const clearPointGroup = () => {
        if (!instance) return
        console.log('clearPointGroup')
        const objects = instance.getObjects().filter(item => item.get('is_pickpoint') || item.get('is_pointline'))
        instance.remove(...objects)
        currentLineRef.current = null
        groupIndexRef.current = 0
        shapeableRef.current = false
        setShapeable(false)
    }

    useEffect(() => {
        if (!instance) return
        //
        instance.on('after:render', () => {
            if (!props.rectInstance) return
            const centerX = props.rectInstance.left
            const centetY = props.rectInstance.top
            // const size = props.rectInstance.width
            // const left = formartNumber((centerX - size / 2) / displayScaleRef.current)
            // const top = formartNumber((centetY - size / 2) / displayScaleRef.current)
            const left = formartNumber(centerX / displayScaleRef.current, 0)
            const top = formartNumber(centetY / displayScaleRef.current, 0)
            setRectX(left)
            setRectY(top)

            const pointObjs = instance.getObjects().filter(item => item.get('is_pickpoint'))
            setPoints(pointObjs.map(point => {
                const {left, top} = point
                return [
                    formartNumber(left / displayScaleRef.current, 0),
                    formartNumber(top / displayScaleRef.current, 0)
                ]
            }))
        })
        // 移动选择框
        instance.on('mouse:move', (ev) => {
            // 正在变形
            if (isLoadingRef.current) return
            // 区域锁定后不跟随
            if (areaLockedRef.current) return
            // 移动时不跟随
            if (moveableRef.current) return
            const { offsetX, offsetY } = ev.e
            const canvasOffsetX = instance.viewportTransform[4]
            const canvasOffsetY = instance.viewportTransform[5]
            const zoom = instance.getZoom()
            const x = (offsetX - canvasOffsetX) / zoom
            const y = (offsetY - canvasOffsetY) / zoom

            props.rectInstance.set({left: formartNumber(x), top: formartNumber(y),})
            props.rectInstance.setCoords()

            props.noticeInstance.set({
                left: formartNumber(x),
                top: formartNumber(y - props.rectInstance.height / 2),
            })
            props.noticeInstance.setCoords()

            props.buttonInstance && props.buttonInstance.set({
                left: formartNumber(x),
                top: formartNumber(y + props.rectInstance.height / 2),
            })
            props.buttonInstance && props.buttonInstance.setCoords()
            
            instance.renderAll()
        })
        // 监听鼠标按下事件
        instance.on('mouse:down', (ev) => {
            // 正在变形
            if (isLoadingRef.current) return
            // 移动时不能点击选择变形区域
            if (moveableRef.current) return
            // 仅在按下鼠标左键时触发
            if (ev.e.button === 0) {
                setShowContextMenu(false) // 隐藏右键菜单
                props.lockAreaFunc()
            } else {
                if (!areaLockedRef.current) return
                const {pointer} = ev
                const canvasOffsetX = instance.viewportTransform[4]
                const canvasOffsetY = instance.viewportTransform[5]
                const zoom = instance.getZoom()
                const x = (pointer.x - canvasOffsetX) / zoom
                const y = (pointer.y - canvasOffsetY) / zoom

                setShowContextMenu(true) // 显示右键菜单
                setMouseX(x)
                setMouseY(y)
            }
        })
        props.rectInstance.on('mousedown', (ev) => {
            // 正在变形
            if (isLoadingRef.current) return
            if (moveableRef.current) return
            if (!areaLockedRef.current) return
            const {pointer} = ev
            const canvasOffsetX = instance.viewportTransform[4]
            const canvasOffsetY = instance.viewportTransform[5]
            const zoom = instance.getZoom()
            const x = (pointer.x - canvasOffsetX) / zoom
            const y = (pointer.y - canvasOffsetY) / zoom

            if (ev.e.button === 0) { // 仅在按下鼠标左键时触发
                const points = instance.getObjects().filter(item => item.get('is_pickpoint'))
                
                if (points.length == 4) { // 用户最多选择两对点
                    const lines = instance.getObjects().filter(item => item.get('is_pointline'))
                    let minIndex = Infinity
                    points.forEach(item => {
                        let index = item.get('pickpoint_group_index')
                        minIndex = Math.min(index, minIndex)
                    })
                    points.forEach(item => {
                        let index = item.get('pickpoint_group_index')
                        if (index == minIndex) instance.remove(item)
                    })
                    lines.forEach(item => {
                        let index = item.get('pointline_group_index')
                        if (index == minIndex) instance.remove(item)
                    })
                }

                const waitEndPoint = points.length % 2 != 0
                if (waitEndPoint) { // 等待终点落下
                    const {x, y} = endPointPosRef.current
                    const circle = createCircle(x, y, zoom, false)
                    instance.add(circle)
                    currentLineRef.current = null
                    groupIndexRef.current++
                    shapeableRef.current = true
                    setShapeable(true)
                } else {
                    const circle = createCircle(x, y, zoom)
                    const line = createLine(x, y, zoom)
                    instance.add(line) // 必须用add，bringToFront和sendToBack会导致元素画质模糊
                    instance.add(circle)              
                    currentLineRef.current = line
                }
            }
        })
        props.rectInstance.on('mousemove', (opts) => {
            // 正在变形
            if (isLoadingRef.current) return
            if (moveableRef.current) return
            if (!areaLockedRef.current) return
            const {pointer} = opts
            const canvasOffsetX = instance.viewportTransform[4]
            const canvasOffsetY = instance.viewportTransform[5]
            const zoom = instance.getZoom()
            const x = (pointer.x - canvasOffsetX) / zoom
            const y = (pointer.y - canvasOffsetY) / zoom

            const points = instance.getObjects().filter(item => item.get('is_pickpoint'))
            const waitEndPoint = points.length % 2 != 0
            if (waitEndPoint) { // 等待终点落下
                const maxLen = formartNumber(MAX_DISTANCE * displayScaleRef.current) // 每对点的起始点和终点的像素距离限制在20像素(对应256x256尺寸)
                
                const startPoint = points.find(item => item.get('pickpoint_group_index') == groupIndexRef.current)
                if (startPoint) {
                    const pointX = startPoint.get('left')
                    const pointY = startPoint.get('top')
                    const distanceSquared = calculateDistanceSquared(x, y, pointX, pointY)
                    if (currentLineRef.current) {
                        if (distanceSquared > maxLen * maxLen) {
                            const maxDistancePoint = getMaxDistancePoint(pointX, pointY, x, y, maxLen)
                            endPointPosRef.current = {...maxDistancePoint}
                            currentLineRef.current.set({
                                x1: pointX,
                                y1: pointY,
                                x2: maxDistancePoint.x,
                                y2: maxDistancePoint.y
                            })
                        } else {
                            endPointPosRef.current = {x: x, y: y}
                            currentLineRef.current.set({
                                x1: pointX,
                                y1: pointY,
                                x2: x,
                                y2: y,
                            })
                        }
                        instance.renderAll()
                    }
                }
            }
        })
        props.buttonInstance && props.buttonInstance.on('mousedown', (ev) => {
            // 正在变形
            if (isLoadingRef.current) return
            if (moveableRef.current) return
            if (!areaLockedRef.current) return
            if (!shapeableRef.current) return
            props.shapeFunc()
        })
    }, [instance])

    return {
        rectX, rectY,
        points,
        shapeable,
        mouseX, mouseY,
        showContextMenu,
        deletePointGroup,
        clearPointGroup,
        setRectPosition
    }
}

export default useInteractive