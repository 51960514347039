import { useEffect, useMemo, useState } from 'react'
import styles from './waitView.module.scss'
import { Spin } from 'antd'
import PSAISpinDot from '@PSAIComponents/PSAISpinDot'
import { IMAGE_TYPE } from '@utils/CONST'

const WatiingRender = (props) => {
    const [key, setKey] = useState(Math.floor(new Date().getTime() / 1000) & 3)
    useEffect(() => {
        const timer = setInterval(() => {
            setKey(Math.floor(new Date().getTime() / 1000) & 3)
        }, 1000)
        return () => {
            clearInterval(timer)
        }
    }, [])
    return (
        <div className={styles.WatiingRender}>
            {
                !props.inProcess ?
                (
                    props.timeRender ?
                    <p style={{width: '60px', justifyContent: "flex-start"}}>生成中{key == 1 ? '.' : key == 2 ? '..' : key == 3 ? '...' : ''}</p> :
                    <p style={{width: '80px', justifyContent: "flex-start"}}>准备生成{key == 1 ? '.' : key == 2 ? '..' : key == 3 ? '...' : ''}</p>
                ) : ""
            }
            {props.timeRender}
        </div>
    )
}

const WaitView = (props) => {
    const { imageType, status, waitingTime, progress, loadingMask, hdStatus, commonImage } = props

    const waitingTimeRender = (waitingTime) => {
        return <span>{waitingTime || '1秒'}</span>
    }

    const stage1 = useMemo(() => {
        return (status == 'pending' || status == 'started') && !waitingTime
    }, [status, waitingTime, progress])

    const stage2 = useMemo(() => {
        return (status == 'pending' || status == 'started') && !!waitingTime
    }, [status, waitingTime, progress])

    const stage3 = useMemo(() => {
        return (status != 'pending' && status != 'started')
    }, [status, waitingTime, progress])

    return (
        hdStatus == 'started' ? 
        <div className={styles.Placeholder}>
            {/* result.loadingMask */}
            {commonImage && <img src={commonImage} alt="" />}
            <div className={styles.Mask}></div>
            <div style={{color: '#FFF', display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center', zIndex: 1}}>
                <Spin indicator={<PSAISpinDot color="#FFFFFF" />} />
                <span style={{marginTop: '16px', color: '#FFFFFF', fontSize: '12px'}}>高清预览生成中...</span>
            </div>
        </div> :
        // imageType == IMAGE_TYPE.MODEL_VIDEO ? 
        // <div className={styles.Placeholder}>
        //     {/* result.loadingMask */}
        //     {loadingMask && <img src={loadingMask} alt="" />}
        //     <WatiingRender timeRender={waitingTime ? <p>预计等待{waitingTimeRender(waitingTime)}</p> : ""} />
        // </div> : 
        (
            stage1 ?
            <div className={styles.Placeholder}>
                {loadingMask && <img src={loadingMask} alt="" />}
                <WatiingRender />
            </div> :
            stage2 ?
            <div className={styles.Placeholder}>
                {loadingMask && <img src={loadingMask} alt="" />}
                <WatiingRender timeRender={<p>预计等待{waitingTimeRender(waitingTime)}</p>} />
            </div> :
            stage3 ? 
            <div className={styles.Placeholder}>
                {loadingMask && <img src={loadingMask} alt="" />}
                <WatiingRender inProcess timeRender={<p>正在生成<span>{progress || '0%'}</span></p>} />
            </div> : ""
        )
        // (
        //     (status == 'pending' || status == 'started') ? 
        //     <div className={styles.Placeholder}>
        //         {loadingMask && <img src={loadingMask} alt="" />}
        //         <WatiingRender inProcess={imageType != IMAGE_TYPE.MODEL_VIDEO} timeRender={waitingTime ? <p>预计等待{waitingTimeRender(waitingTime)}</p> : ""} />
        //     </div> : 
        //     <div className={styles.Placeholder}>
        //         {loadingMask && <img src={loadingMask} alt="" />}
        //         <WatiingRender inProcess timeRender={<p>正在生成<span>{progress || '0%'}</span></p>} />
        //     </div>
        // )
    )
}

export default WaitView