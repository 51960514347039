import { useState, useRef, useEffect, useContext, useMemo } from "react"
import LeftComp from "./LeftComp/LeftComp"
import MiddleComp from "./MiddleComp/MiddleComp"
import RightComp from "./RightComp/RightComp"
import styles from './index.module.scss'
import { AppContext } from '@utils/AppContext';
import {workflowOfBuild} from '@utils/workflowOfBuildTask';
import { maskDispose, maskPrediction } from "@utils/workflowOfPrediction"
import Utils from '@utils/utils'
import { message } from "antd"
import API from '@api/api'
import { IMAGE_TYPE } from "@utils/CONST"
import { GenerateContext, GenerateContextProvider } from "@utils/GenerateContext"
import LeftCompVideo from "./LeftCompVideo"
import MiddleCompVideo from "./MiddleCompVideo"
import MiddleModelVideo from "./MiddleModelVideo"
import LeftModelVideo from "./LeftModelVideo"
import PSAIModal from '@PSAIComponents/PSAIModal';
import eventBus from '@utils/eventBus';

const SelfModelFakeChildren = (props) => {
    const {
        updateConfig,
        filteredModelListRef,
        filteredBgListRef,
        forceUpdate,
        setGlobalLoading,
    } = useContext(AppContext);

    const {
        commodityFilter,
        modelFilter,
        imageType, // model/dressform/clothes...
        inputImage, // 原始输入图： 真人/人台/AI试衣-套装
        inputImageTop, // 原始输入图：AI试衣-上装
        inputImageBottom, // 原始输入图：AI试衣-下装
        dragScaleRef, // 商品+softedge布局等
        maskAlgoIdRef, // mask算法id-套装
        maskAlgoId1Ref, // mask算法id-上身
        maskAlgoId2Ref, // mask算法id-下身
        segmentResult, // 分割结果列表-套装
        segmentResult1, // 分割结果列表-上身
        segmentResult2, // 分割结果列表-下身
        modelStyle, // 选择的模特
        poseStyleList, // 选择的姿势列表
        customPoseStyleList, // 选择的自定义姿势列表
        bgStyle, // 选择的场景
        outputMaxSize, // 决定商品生成参数upscale_enable是1/0
        projectId, setProjectId, // 生成的项目id
        rightProjectId, setRightProjectId, // 右侧的项目id
        projectName, // 生成的项目名
        createNum, // 生成数量
        clothType, // 来自服装类型的选择，仅用于衣服图的区分
        subBgId, // 场景内部子index，目前只能为-1
        faceTagText, // 特征属性：表情
        hairTagText, // 特征属性：发型
        // 商品自定义尺寸
        imgWidthRef,
        imgHeightRef,
        imgRatioRef,
        // 自定义场景字段
        definedFlag,
        positivePrompt,
        refImg,
        refImgMask,
        refImgErased,
        refVersion,
        refStrength,
        defaultPositivePrompt,
        defaultNegativePrompt,
        defaultBkgUrl,
        imageAntispamId,
        // 商品视频
        img4Video, videoResolution,
        // 模特视频
        modelVideo,
        modelVideoFrontPic,
        modelVideoBackPic,
        scaleRateRef, ltXRef, ltYRef,
        coordDragRef,
        imgClipSize,
        isSceneFracturing, // 场景裂变
        isSameStyle, // 拍同款
        isSimilarStyle, // 相似风格
        maskMoveable,
        isCustomScene,
        dpScale,
        keepPose,
        keepBkg,
    } = useContext(GenerateContext)

    const isMounted = useRef(true) // 判断组件是否已销毁

    useEffect(() => {
        // 设置sidebar key
        updateConfig({sidebarKey: imageType})
        return () => {
            isMounted.current = false
        }
    }, [])

    const onCreate = async () => {
        // console.log('==生成==')
        setGlobalLoading('生成中')
        try {
            const ver = await API.getVersion()
            const version = imageType == IMAGE_TYPE.COMMODITY ? // 商品
                                ver?.commodityVersion :
                                imageType == IMAGE_TYPE.CLOTHES ? // tryon
                                    ver?.tryOnVersion :
                                    ver?.modelVersion
            let res
            if (imageType == IMAGE_TYPE.CLOTHES) {
                let _projectId = projectId
                for (let poseStyle of [...poseStyleList, ...customPoseStyleList]) {
                    res = await inpaintPrediction(version, poseStyle.name, poseStyle.isSelfDefined, poseStyle.selfDefinedImg, _projectId)
                    _projectId = res
                }
            } else {
                res = await inpaintPrediction(version)
            }
            if (!res) return
            // console.log('projectid', res2)
            setProjectId(res)
            setRightProjectId(res)
            forceUpdate({jumpOnce: true})
        } catch (err) {
            message.warning(err.message)
        } finally {
            setGlobalLoading(false)
        }
    }

    const inpaintPrediction = async (version, poseStyle, isSelfDefined, selfDefinedImg, _projectId=projectId) => {
        if (
            (/_default_style$/.test(modelStyle) && filteredModelListRef.current.length == 0 && imageType != IMAGE_TYPE.COMMODITY) ||
            (/_random$/.test(bgStyle) && filteredBgListRef.current.length == 0)
        ) {
            throw new Error('请选择一个模特/场景')
        }

        const data = {
            projectId: _projectId,
            projectName,
            version,
            inputImage,
            inputImageTop,
            inputImageBottom,
            segmentResult,
            segmentResult1,
            segmentResult2,
            maskAlgoId: maskAlgoIdRef.current,
            maskAlgoId1: maskAlgoId1Ref.current,
            maskAlgoId2: maskAlgoId2Ref.current,
            modelStyle,
            modelKeyList: filteredModelListRef.current,
            bgStyle,
            bgKeyList: filteredBgListRef.current,
            poseStyle,
            isSelfDefined: isSelfDefined,
            selfDefinedImg: selfDefinedImg,
            keepPose,
            keepBkg,
            hairTagText,
            faceTagText,
            positivePrompt: imageType == IMAGE_TYPE.DRESSFORM ? (positivePrompt || defaultPositivePrompt.current) : positivePrompt,
            negativePrompt: defaultNegativePrompt.current,
            refImg: imageType == IMAGE_TYPE.DRESSFORM ? (refImg || defaultBkgUrl.current) : refImg,
            refImgMask,
            refImgErased,
            refStrength,
            createNum,
            imgWidth: imgWidthRef.current,
            imgHeight: imgHeightRef.current,
            definedFlag,
            refVersion,
            dpScale,
            clothType,
            imgRatio: imgRatioRef.current,
            scaleRate: scaleRateRef.current,
            ltX: ltXRef.current,
            ltY: ltYRef.current,
            coordDrag: coordDragRef.current,
            subBgId,
            dragScale: dragScaleRef.current,
            outputMaxSize,
            commodityFilter,
            modelFilter,
            imageAntispamId,
        }
        const interactOption = {
            isCustomScene,
            isSceneFracturing,
            isSameStyle,
            isSimilarStyle,
            maskMoveable,
        }
        const extraData = {
            imgClipSize: imgClipSize,
        }

        return await workflowOfBuild({
            imageType,
            data,
            option: interactOption,
            extraData,
        })
    }

    const middleRef = useRef()
    const onEdgeChange = (url) => {
        middleRef.current.loadNewEdge(url)
    }

    const onCreateCommodityVideo = async () => {
        console.log('生成商品视频')
        // /ps/web/algo/doCommodityVideo 商品视频生成
        // img4Video, videoResolution
        try {
            const res = await maskPrediction(img4Video, IMAGE_TYPE.COMMODITY, () => !isMounted.current)
            // console.log('maskPrediction', res)
            if (!res) return false
            const {id, input, output} = res
            const predictRes = await API.commodityVideoPrediction({
                projectId: projectId,
                img_path: img4Video,
                mask_path: output[4],
                resolution: videoResolution,
                attr: {}
            })
            console.log('predictRes', predictRes)
            if (predictRes.code != 0) throw new Error(predictRes.message)
            message.info('生成中，请稍等片刻')
            setProjectId(predictRes.data.id)
            setRightProjectId(predictRes.data.id)
            forceUpdate({jumpOnce: true})
        } catch (err) {
            message.warning(err.message)
            console.error(err)
        }
    }


    const onCreateModelVideo = async () => {
        try {
            const version =  await API.getVersion()
            if(version) {
                const res = await API.doTryonVideo({
                    projectId: projectId,
                    imageUrls: modelVideoBackPic ? [modelVideoFrontPic, modelVideoBackPic] : [modelVideoFrontPic],
                    ctl_videoKey: modelVideo?.name,
                    version: version.videoTryonVersion,
                    custom: modelVideo?.isCustom ? true : false,
                    num: 1
                })
                if (res.code != 0) {
                    if (res.code == -5012) {
                        eventBus.emit('not_enough_points')
                        return false
                    } else throw new Error(res.message)
                }
                setProjectId(res.data.id)
                setRightProjectId(res.data.id)
                forceUpdate({jumpOnce: true})
            }
        } catch (err) {
            message.warning(err.message)
            console.error(err)
        }
    }

    // 唤起构图优化
    const onTriggerComposeOpt = () => {
        if (middleRef.current) middleRef.current.composeOpt()
    }

    return (
        <div className={styles.Container}>
            <div className={styles.LeftComp}>
            {
                imageType == IMAGE_TYPE.COMMODITY_VIDEO ?
                <LeftCompVideo onCreate={onCreateCommodityVideo} /> :
                imageType == IMAGE_TYPE.MODEL_VIDEO ?
                <LeftModelVideo onCreate={onCreateModelVideo} />:
                <LeftComp
                    segmentResult={segmentResult}
                    onCreate={onCreate}
                    onEdgeChange={onEdgeChange}
                    onTriggerComposeOpt={onTriggerComposeOpt}
                />
            }
            </div>
            <div className={`${styles.MiddleComp} ${!rightProjectId && styles.hidden}`}>
            {
                imageType == IMAGE_TYPE.COMMODITY_VIDEO ?
                <MiddleCompVideo /> :
                imageType == IMAGE_TYPE.MODEL_VIDEO ?
                <MiddleModelVideo />: 
                <MiddleComp ref={middleRef} />
            }
            </div>
            <div className={`${styles.RightComp} ${!rightProjectId && styles.hidden} ${styles[imageType]}`}>
                <RightComp />
            </div>
        </div>
    )
}

const SelfModelFake = (props) => {
    return (
        <GenerateContextProvider imageType={props.imageType}>
            <SelfModelFakeChildren />
        </GenerateContextProvider>
    )
}

export default SelfModelFake