import React, {useState, useEffect} from 'react';
import styles from './loading.module.scss'
import Utils from '@utils/utils'
import { Alert, Flex, Spin } from 'antd';
import PSAISpinDot from '@PSAIComponents/PSAISpinDot';

const Loading = (props) => {
    return (
        <div className={styles.app_loading}>
            <Spin
                className={styles.app_loading_icon}
                size="large"
                indicator={<PSAISpinDot />}
            />
            <span className={styles.app_loading_tip}>{props.loadingText}...</span>
        </div>
        // <div className={styles.loading} style={{width: Utils.isMobileDevice()?'1000px': '100%'}}>
        //     <div>
        //     {
        //         props.loadingText == '智能补光初始化中，请稍后' ?
        //         null :
        //         // <div className="parentBox">
        //         //     <svg className="scalableBox" viewBox="0 0 128 256" width="100%" height="100%">
        //         //     <defs>
        //         //         <linearGradient id="ap-grad1" x1="0" y1="0" x2="0" y2="1">
        //         //         <stop offset="0%" stopColor="hsl(223,90%,55%)" />
        //         //         <stop offset="100%" stopColor="hsl(253,90%,55%)" />
        //         //         </linearGradient>
        //         //         <linearGradient id="ap-grad2" x1="0" y1="0" x2="0" y2="1">
        //         //         <stop offset="0%" stopColor="hsl(193,90%,55%)" />
        //         //         <stop offset="50%" stopColor="hsl(223,90%,55%)" />
        //         //         <stop offset="100%" stopColor="hsl(253,90%,55%)" />
        //         //         </linearGradient>
        //         //     </defs>
        //         //     <circle className="apringBox" r="56" cx="64" cy="192" fill="none" stroke="#ddd" strokeWidth="15" strokeLinecap="round" />
        //         //     <circle className="apwormOneBox" r="56" cx="64" cy="192" fill="none" stroke="url(#ap-grad1)" strokeWidth="16" strokeLinecap="round"
        //         //         strokeDasharray="87.96 263.89" />
        //         //     <path className="apwormTwoBox" d="M120,192A56,56,0,0,1,8,192C8,161.07,16,8,64,8S120,161.07,120,192Z" fill="none" stroke="url(#ap-grad2)"
        //         //         strokeWidth="16" strokeLinecap="round" strokeDasharray="87.96 494" />
        //         //     </svg>
        //         // </div>
        //         <Spin 
        //             size="large"
        //             wrapperClassName={styles.loadingBox}
        //             tip={`${props.loadingText}...`}>
        //             <div></div>
        //         </Spin>
                

        //     }
        //     </div>
        // </div>
    )
    
}

export default Loading