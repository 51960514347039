import styles from './selectModal.module.scss'
import { useRef, useEffect, useMemo, useState, useContext } from 'react';
import { Button, Divider, Empty, Modal, Popover } from 'antd';
import API from '@api/api'
import ModelFilterSelect from '../modelFilterSelect/modelFilterSelect';
import MoreScenePopoverContent from '@view/compoents/MoreScenePopoverContent/MoreScenePopoverContent'
import { AppContext } from '@utils/AppContext';
import Utils from '@utils/utils'
import { IMAGE_TYPE } from "@utils/CONST"
import PSAIModal from '@PSAIComponents/PSAIModal';

const SelectItem = (props) => {
    const { item, type, selected, tryOn, setSelected, selectDoubleClick, showTitle, isModel, onMoreSceneChange } = props
    const [moreSceneModalOpen, setMoreSceneModalOpen] = useState(false)

    const selectClick = () => {
        if (item?.subList && item?.subList.length > 0) {
            setMoreSceneModalOpen(true)
            setSelected(item)
        } else {
            setSelected(item)
        }
    }

    const onChange = (item) => {
        onMoreSceneChange(item)
        setMoreSceneModalOpen(false)
    }

    const onOpenChange = (value) => {
        if (!value) setMoreSceneModalOpen(false)
    }

    const formartTitle = () => {
        const title = item.attr.title
        if (isModel) {
            const arr = title?.split('-')
            return arr && <p>{arr.length > 0 ? arr[arr.length - 1] : title}</p>
        } else {
            return <p>{title}</p>
        }
    }

    return (
        <Popover
            placement="topLeft"
            content={<MoreScenePopoverContent tryOn={tryOn} sceneList={item?.subList} selectedName={item.name} onChange={onChange}/>}
            open={moreSceneModalOpen}
            arrow={false}
            overlayInnerStyle={{ padding: 0 }}
            trigger={"click"}
            onOpenChange={onOpenChange}
        >
            <div
                className={[
                    styles.select_item,
                    selected?.name == item.name && styles.selected_item,
                    type == 'Model' && tryOn && styles.tryOn
                ].join(' ')}
                onClick={selectClick}
                onDoubleClick={() => selectDoubleClick(item)}
            >
                <img src={Utils.addVersionParamToURL(item.imageUrl, 'v', item?.attr?.version)}></img>
                {
                    item.attr.private === 1 ?
                    <i className={styles.private_icon}></i> :
                    item.attr.recent === 1 ?
                    <div className={styles.new_icon}><span>最近</span></div>:
                    null
                }
                {   
                    showTitle &&
                    item.attr?.title && 
                    formartTitle()
                }
                {
                    item.subList?.length > 0 &&
                    <div className={styles.more_scene_handler}>
                        {/* <img className='more_scene_handler_img' src={require('@assets/images/icon_gallery.png')} /> */}
                        <i className={styles.more_scene_handler_img}></i>
                    </div>
                }
            </div>
        </Popover>
    )
}

const SelectModal = (props) => {
    const { globalModelFilter, } = useContext(AppContext);
    const {type, selectedName, sceneType, onSave} = props

    const title = type == 'Model' ?
        (
            sceneType == IMAGE_TYPE.CLOTHES ?
            '为你的服装选择容貌' :
            '为你的服装选择模特'
        ) :
        (
            sceneType != IMAGE_TYPE.COMMODITY ?
            '为你的服装选择场景' :
            '为你的商品选择场景'
        )

    const [open, setOpen] = useState(false)
    const [skin, setSkin] = useState([...globalModelFilter?.skin || []]);
    const [sex, setSex] = useState([...globalModelFilter?.sex || []]);
    const [age, setAge] = useState([...globalModelFilter?.age || []]);
    const [list, setList] = useState([])
    const [selected, setSelected] = useState()
    const versionRef = useRef()

    const saveSelected = () => {
        onSave(selected, versionRef.current)
        props.closeFunc()
    }

    useEffect(() => {
        setOpen(props.open)
    }, [props.open])

    const selectDoubleClick = (scene) => {
        if (scene?.subList && scene?.subList.length > 0) {
            return
        } else {
            setSelected(scene)
            onSave(scene, versionRef.current)
            props.closeFunc()
        }
    }

    const onMoreSceneChange = ({id, name, imageUrl}) => {
        const arr = [...list];
    
        for (let item of arr) {
            if (item.id === id) {
                item.name = name;
                item.imageUrl = imageUrl;
                console.log('Found in main list:', item);
                setSelected(item);
                break;
            }
            
            if (Array.isArray(item.subList)) {
                for (let subItem of item.subList) {
                    if (subItem.id === id) {
                        item.name = name;
                        item.imageUrl = imageUrl;
                        setSelected(subItem);
                        break;
                    }
                }
            }
        }
    
        setList(arr);
    };
    

    const updateSelected = (arr) => {
        if (selectedName || selected) {
            const name = selectedName || selected.name
            for (let item of arr) {
                if (item.subList && item.subList.length > 0) {
                    for (let subitem of item.subList) {
                        if (subitem.name == name) {
                            item.name = name
                            item.imageUrl = subitem.imageUrl
                            return setSelected(item)
                        }
                    }
                }
                if (item.name == name) return setSelected(item)
            }
        }
        return setSelected(arr[0])
    }

    useEffect(() => {
        return () => {
            setSkin([])
            setSex([])
            setAge([])
            setList([])           
        }
    }, [])

    useEffect(() => {
        setSkin([...globalModelFilter?.skin || []])
        setSex([...globalModelFilter?.sex || []])
        setAge([...globalModelFilter?.age || []])
    }, [globalModelFilter])

    useEffect(() => {
        if (!open) return
        if (type == 'Model') {
            const getModelApi = props.tryOn ? API.modelTryOnList : API.modelPersonList
            getModelApi({
                age: age.join(),
                sex: sex.join(),
                skin: skin.join(),
            }).then((res) => {
                if(res.code == 0) {
                    let resList = props.tryOn?
                                    res.data.filter(item => item.attr.modelType != 'short') :
                                    res.data.filter(item => { // 非真人图需要排除constant_id资源
                                        if (sceneType != IMAGE_TYPE.MODEL) {
                                            return item.attr.algoModelType != "constant_id"
                                        } else {
                                            return true
                                        }
                                    })
                    setList(resList)
                    updateSelected(res.data)
                    // 得到version
                    for (let i = 0; i < res.data.length; i++) {
                        const item = res.data[i]
                        if (item.attr?.version) versionRef.current = item.attr?.version
                    }
                }
            }).catch((error) => {
                console.error(error)
            })
        } else if (type == 'Scene') {
            let api = sceneType == IMAGE_TYPE.COMMODITY ? 
                        API.commodityBackgroundList :API.modelBackgroundList
            api().then((res) => {
                if(res.code == 0) {
                    // 得到version
                    for (let i = 0; i < res.data.length; i++) {
                        const item = res.data[i]
                        if (item.attr?.version) versionRef.current = item.attr?.version
                    }
                    // 赛博朋克等特殊处理
                    let resList = [...res.data]
                    setList(resList)
                    updateSelected(resList)
                }
            }).catch((error) => {
                console.error(error)
            })
        } else {
            // 不需要为SoftEdge添加此组件
        }
    }, [skin, sex, age, open, sceneType])

    const customModelList = useMemo(() => {
        if (type == 'Model') {
            return list.filter(item => item?.attr?.skin == 'custom')
        } else return []
    }, [list, type])

    const yellowModelList = useMemo(() => {
        if (type == 'Model') {
            return list.filter(item => item?.attr?.skin == 'yellow')
        } else return []
    }, [list, type])
    
    const whiteModelList = useMemo(() => {
        if (type == 'Model') {
            return list.filter(item => item?.attr?.skin == 'white')
        } else return []
    }, [list, type])

    const blackModelList = useMemo(() => {
        if (type == 'Model') {
            return list.filter(item => item?.attr?.skin == 'black')
        } else return []
    }, [list, type])

    const brownModelList = useMemo(() => {
        if (type == 'Model') {
            return list.filter(item => item?.attr?.skin == 'brown')
        } else return []
    }, [list, type])

    const randomModel = useMemo(() => {
        if (type == 'Model') {
            return list.find(item => /_default_style$/.test(item.name))
        } else return null
    }, [list, type])

    const groupModelList = useMemo(() => {
        const list = []
        if (customModelList.length > 0) list.push({ key: 'custom', title: '自定义', color: '#f3b99f', list: customModelList })
        if (yellowModelList.length > 0) list.push({ key: 'yellow', title: '黄种人', color: '#f3b99f', list: yellowModelList })
        if (whiteModelList.length > 0) list.push({ key: 'white', title: '白种人', color: '#fef6f2', list: whiteModelList })
        if (blackModelList.length > 0) list.push({ key: 'black', title: '黑种人', color: '#560808', list: blackModelList })
        // if (brownModelList.length > 0) list.push({ key: 'brown', title: '棕种人', color: '#ff0000', list: brownModelList })
        if (list.length > 0 && randomModel) {
            list[0].list.unshift(randomModel)
        }
        return list
    }, [customModelList, yellowModelList, whiteModelList, blackModelList, brownModelList])

    const clothesCategoryGroup = useMemo(() => {
        // 使用/\d+_random$/过滤随机，避免将随机纯色和随机窗幔过滤掉
        const groupList = {
            '预设风格': {
                key: '预设风格',
                title: '预设风格',
                list: []
            },
            '预设参考图': {
                key: '预设参考图',
                title: '预设参考图',
                list: []
            },
        }
        const randomItem = list.find(item => /\d+_random$/.test(item.name))
        for (let item of list) {
            if (/\d+_random$/.test(item.name)) continue
            if (/\d+_keep$/.test(item.name)) continue
            const category = item?.attr?.backgroundType == 0 ? '预设风格' : '预设参考图'            
            if (groupList[category]) {
                groupList[category].list.push(item)
            } else {
                groupList[category] = {
                    key: category,
                    title: category,
                    list: [item]
                }
            }
        }
        const keys = Object.keys(groupList)
        if (keys.length > 0 && randomItem) {
            const key = keys[0]
            groupList[key].list.unshift(randomItem)
        }
        return Object.values(groupList)
    }, [list, sceneType])

    return (
        <PSAIModal
            title={title}
            closeIcon={null}
            open={open}
            maxWidth={1130}
            cancelText="放弃选择"
            onCancel={() => props.closeFunc()}
            okText="生成"
            onOk={() => saveSelected()}
            okButtonProps={{
                icon: <div style={{display: 'flex', alignItems: 'center'}}>
                    <img style={{width: '8px', height: '8px', marginRight: '5px'}} src={require('@assets/images/arcbean2.png')}/> 
                    {sceneType == IMAGE_TYPE.CLOTHES? 40: 10}</div>,
                iconPosition: 'end'
            }}
        >
            {
                type == 'Model' && 
                (
                    <div className={styles.selectModal_search_list}>
                        <ModelFilterSelect showLabel width={"500px"} />
                    </div>
                )
            }
            <div className={styles.selectModal_body}>
            {
                type == 'Model' ? ( // 模特
                    groupModelList.map(group => (
                        <div key={group.key}>
                            { group.title && <div className={styles.model_divider}><i style={{background: group.color}}></i><span>{group.title}</span></div> }
                            {
                                group.list.map(item =>
                                    <SelectItem
                                        key={item.id}
                                        type={type}
                                        item={item}
                                        selected={selected}
                                        tryOn={props.tryOn}
                                        selectDoubleClick={selectDoubleClick}
                                        showTitle={props.options?.showTitle}
                                        setSelected={setSelected}
                                        isModel={true}
                                        onMoreSceneChange={onMoreSceneChange}
                                    />
                                )
                            }
                        </div>
                    ))
                ) : sceneType != IMAGE_TYPE.COMMODITY ? ( // 服饰场景，分为真人和人台
                    clothesCategoryGroup.map(group => (
                        group.list.length > 0 &&
                        <div key={group.key}>
                            { group.title && <Divider style={{margin: '8px 0', padding: '0 17px'}} >{group.title}</Divider> }
                            {
                                group.list.map(item =>
                                    <SelectItem
                                        key={item.id}
                                        type={type}
                                        item={item}
                                        selected={selected}
                                        tryOn={props.tryOn}
                                        selectDoubleClick={selectDoubleClick}
                                        showTitle={props.options?.showTitle}
                                        setSelected={setSelected}
                                        onMoreSceneChange={onMoreSceneChange}
                                    />
                                )
                            }
                        </div>
                    ))
                ) : ( // 商品场景
                    list.map(item =>
                        <SelectItem
                            key={item.id}
                            type={type}
                            item={item}
                            selected={selected}
                            tryOn={props.tryOn}
                            selectDoubleClick={selectDoubleClick}
                            showTitle={props.options?.showTitle}
                            setSelected={setSelected}
                            onMoreSceneChange={onMoreSceneChange}
                        />
                    )
                ) 
                                
            }
            { list.length == 0 && <div className={styles.empty_wrap}><Empty /></div> }   
            </div>

        </PSAIModal>
    )
}

export default SelectModal