import { Button, Form, Input, message } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { useState, useEffect } from "react";
import Utils from '@utils/utils'
import eventBus from "@utils/eventBus";
import API from '@api/api'
import styles from './index.module.scss'
import PublicKey from '@utils/publicKey'
import PSAIModal from "@PSAIComponents/PSAIModal";

const PasswordInput = (props) => {
    return (
        <div className={styles.PasswordInput}>
            <Input.Password
                value={props.value}
                size="large"
                placeholder="请输入您的密码"
                allowClear
                prefix={<img style={{width: 16}} src={require('@assets/images/icon_Password.png')} alt="" />}
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                // onPressEnter={props.onPressEnter}
                onChange={props.onChange}
            />
            <div className={styles.ForgotPwd} onClick={props.onForgotPwd}>忘记密码?</div>
        </div>
    )
}

export default function AccountAndPwdForm (props) {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const username = Form.useWatch(['username'], form)

    const initialValues = {
        username: props.mobile || '',
        password: ""
    }

    useEffect(() => {
        if (Utils.validateContact(username)) props.onMobileChange(username)
    }, [username])

    const onSubmit = () => {
        return new Promise((resolve) => {
            form.validateFields().then(values => {
                if (Utils.getCurrentDomainAndCheckTiktok()) {
                    PSAIModal.confirm({
                        title: '用户须知',
                        content: (
                            <div>
                                <p style={{marginBottom: 10}}>您必须是年满18周岁且具有完全民事行为能力的人，在通过TikTok导航栏注册登录使用PhotoStudio<span style={{fontSize: '.8em'}} className="registered_trademark">®</span> AI（以下简称“PSAI”）的全部产品及服务之前，请您务必审慎阅读、充分理解本须知内容。</p>
                                <p style={{marginBottom: 10}}>您通过TikTok导航栏或其他PSAI官方合作授权渠道导航栏进入PSAI首页完成认证注册的账号相互独立。不会因为您注册认证登录使用的是同一手机号码、电子邮箱或其他可用于PSAI账号注册的信息而共享、存续您任一账号下购买的服务及相应的权益。</p>
                                <p>您通过TikTok导航栏进入PSAI首页完成认证即视为您已理解并接受本须知。您购买的产品和会员服务仅能通过TikTok导航栏登录使用。</p>
                            </div>
                        ),
                        okText: '已知晓并继续',
                        onOk () {
                            onBizResultCallback(values.username, values.password)
                            // eventBus.emit('captcha', () => onBizResultCallback(values.username, values.password))
                        }
                    })
                } else {
                    onBizResultCallback(values.username, values.password)
                    // eventBus.emit('captcha', () => onBizResultCallback(values.username, values.password))
                }
                resolve(true)
            })
        })
    }

    const onBizResultCallback = (username, password) => {
        setLoading(true)
        API.userLogin({
            username: username,
            password: PublicKey.JSEncryptChange(password),
            mode: 2,
        }).then((res) => {
            if (res.code == 0) {
                setTimeout(() => props.loginCallback(res.data), 500)
            } else if (res.code == -5007) {
                eventBus.emit('suspenNotice', res.data.manualCheckNum, res.data.invalidDays, res.data.appealBeforeTime, res.data.token)
                setLoading(false)
            } else {
                message.error(res.message)
                setLoading(false)
            }
        })
    }
    const placeholderMessage = Utils.getCurrentDomainAndCheckTiktok() ? '请输入手机号或者邮箱' : '请输入手机号';
    return (
        <>
        <Form
            autoComplete="off"
            form={form}
            initialValues={initialValues}
            onFinish={onSubmit}
        >
            <Form.Item
                name="username"
                validateFirst
                validateTrigger="onBlur"
                rules={[
                    { required: true, message: placeholderMessage,},
                    () => ({
                        validator(_, value) {
                            const isTikTokDomain = Utils.getCurrentDomainAndCheckTiktok();
                            if (isTikTokDomain){
                                if (!Utils.validateContact(value) && !Utils.validateContact2(value)) return Promise.reject(new Error('您输入的账号格式错误'))
                            } else{
                                if( !Utils.validateContact(value)) return Promise.reject(new Error('您输入的账号格式错误'))
                            }
                            return Promise.resolve()
                        }
                    })
                ]}
            >
                <Input
                    size="large"
                    placeholder={placeholderMessage}
                    allowClear
                    prefix={<img style={{width: 16}} src={require('@assets/images/icon_Number.png')} alt="" />}
                />
            </Form.Item>
            <Form.Item
                name="password"
                rules={[ { required: true, message: '请输入密码', }, ]}
            >
                <PasswordInput
                    // onPressEnter={onSubmit}
                    onForgotPwd={props.onForgotPwd}
                />
            </Form.Item>
            <Form.Item 
                style={{marginTop: 88}}
            >
                <Button
                    tabIndex="5"
                    size="large"
                    block
                    type="primary"
                    loading={loading}
                    htmlType="submit"
                >
                    登 录
                </Button>
            </Form.Item>
        </Form>
        </>
    )
}