import { Button, Radio, Select, Spin, message } from "antd"
import { useEffect, useMemo, useRef, useState } from "react"
import styles from './index.module.scss'
import Decimal from 'decimal.js'
import API from '@api/api'
import eventBus from "@utils/eventBus"

const formatAmount = (amount) => {
    // 使用正则表达式匹配非零小数
    return amount >= 1 ? parseFloat(amount) : amount
}

const PowerItem = (props) => {
    const couponPrice = useMemo(() => {
        if (!props.price || !props.orderAmount) return new Decimal('0')
        return new Decimal(props.price).sub(new Decimal(props.orderAmount))
    }, [props.price, props.orderAmount])

    // 折扣价格不是0
    const couponPriceNotEqZero = useMemo(() => {
        return !new Decimal(couponPrice).eq(new Decimal('0'))
    }, [couponPrice])

    return (
        <div className={`${styles.PowerItem} ${props.actived && styles.actived}`} onClick={props.onClick} >
            <div className={styles.powerAmount}>{props.powerAmount}</div>
            <div className={styles.tips}>{props.tips}</div>
            {
                (props.actived && !props.loading) ?
                <div className={styles.priceBox}>
                    <span>￥</span>
                    <span>{props.orderAmount ? formatAmount(props.orderAmount) : formatAmount(props.price)}</span>
                    {
                        couponPriceNotEqZero &&
                        <div className={styles.CouponPrice}>
                            <span>{ props.couponId ? '优惠' : '抵扣' } ￥ {`${formatAmount(couponPrice)}`}</span>
                        </div>
                    }
                </div> :
                <div className={styles.priceBox}>
                    <span>￥</span>
                    <span>{formatAmount(props.price)}</span>
                </div>
            }
        </div>
    )
}

const CouponSelect = (props) => {
    const [inited, setInited] = useState()
    const [list, setList] = useState([])
    const [defaultValue, setDefaultValue] = useState()

    useEffect(() => {
        API.getCouponListForPay({
            type: props.type, // =0 套餐 =1 加油包
            id: props.skuId, // 对应类型sku的id
        }).then(res => {
            if (res?.code != 0) throw new Error(res.message)
            setList(res.data)
            setDefaultValue(res.data[0]?.bindingId)
            props.onChange(res.data[0]?.bindingId)
        }).catch(err => {
            message.error(err.message)
        }).finally(() => {
            setInited(true)
        })
    }, [props.type, props.skuId])

    const onChange = (value) => {
        props.onChange(value)
    }

    const labelRender = (item) => {
        if (!item.value) return <span className={`${styles.CouponSelected} ${styles.none}`}>{item.label}</span>
        return <span className={styles.CouponSelected}>{item.label}</span>
    }

    return (
        <div className={styles.CouponSelectWrap}>
        {
            inited &&
            <Select
                defaultActiveFirstOption
                defaultValue={defaultValue}
                className={styles.CouponSelect}
                popupClassName={styles.CouponSelectPopup}
                fieldNames={{ label: 'name', value: 'bindingId' }}
                options={list}
                onChange={onChange}
                labelRender={labelRender}
            />
        }
        </div>
    )
}

const PayChannel = (props) => {
    const [value, setValue] = useState(props.value)

    useEffect(() => {
        setValue(props.value)
    }, [props.value])

    return (
        <div className={styles.PayChannel}>
            <span>支付方式</span>
            <Radio.Group onChange={e => props.onChange(e.target.value)} value={value}>
                <Radio className={styles.PayChannelRadio} value="alipay"><img className={styles.icon} src={require('@assets/images/icon_alipay.png')} />支付宝</Radio>
                <Radio className={styles.PayChannelRadio} value="wechat"><img className={styles.icon} src={require('@assets/images/icon_wxpay.png')} />微信</Radio>
            </Radio.Group>
        </div>
    )
}

const Tips = (props) => {
    const {
        unit, // 0-单月 1-限时特惠 2-包年
        isPowerPackage, // 加油包
        powerAmount,
    } = props

    if (isPowerPackage) return <p className={styles.Tips}>每个加油包有效期为30天，未用完的虹豆将在购买起第30天后清空</p>
    else return (
        <ul className={styles.Tips}>
            <li>支付完成后默认您同意<a target='_blank' href="/会员条款.htm">相关协议条例</a> </li>
            <li>虚拟商品，支付后无法退还</li>
            <li>{unit == 'Month' ? '包月' : unit == 'Quarter' ? '包季' : '包年'}服务有效期为{unit == 'Month' ? '30' : unit == 'Quarter' ? '90' : '365'}天</li>
            <li>会员订购当天可获得 <span>{powerAmount}</span> AI虹豆，服务周期内未用完的虹豆，将在当前服务周期结束时清空</li>
        </ul>
    )
}

export default function Prepayment (props) {
    const isMounted = useRef(true)
    useEffect(() => {
        return () => isMounted.current = false
    }, [])

    // 选择购买项
    const [selectedIndex, setSelectedIndex] = useState(props.packageIndex)
    const handleSelect = (index) => {
        if (selectedIndex == index) return
        setSelectedIndex(index)
        setOrderAmount()
    }
    // 优惠券
    const couponIdRef = useRef()
    const [couponId, setCouponId] = useState()
    const onCouponChange = (value) => {
        couponIdRef.current = value
        setCouponId(value)
    }
    // 选择的加油包/套餐
    const [diyPower] = useState(props.items?.diyPower || 0) // 随心配点数
    const [items] = useState(props.items?.list || [])
    const selectedItem = useMemo(() => {
        return items?.[selectedIndex]
    }, [items, selectedIndex])
    const skuId = useMemo(() => {
        return selectedItem?.id
    }, [selectedItem])
    const type = useMemo(() => {
        return selectedItem?.type
    }, [selectedItem])
    const unit = useMemo(() => {
        return selectedItem?.unit
    }, [selectedItem])

    const [loading, setLoading] = useState(false) // loading
    const [orderAmount, setOrderAmount] = useState() // 实际支付金额，用来显示和计算优惠金额
    const [payable, setPayable] = useState(false) // 可支付
    const [channel, setChannel] = useState('alipay') // 支付渠道 alipay or wechat

    // 根据所选择的优惠券计算订单金额
    useEffect(() => {
        if (couponId === undefined) return
        setLoading(true)
        setOrderAmount()
        const func = props.isPowerPackage ?
            API.subscribePowerPackageWithoutOrder({ // 加油包
                skuId: skuId,
                couponBindingId: couponId,
            }) :
            diyPower > 0 ?
            API.subscribeWithoutOrder({ // 套餐
                skuId: skuId,
                number: 1,
                type: type,
                couponBindingId: couponId,
                diyPower: diyPower,
            }) :
            API.subscribeWithoutOrder({ // 套餐
                skuId: skuId,
                number: 1,
                type: type,
                couponBindingId: couponId,
            })
        func.then(res => {
            if (res.code != 0) throw new Error(res.message)
            setOrderAmount(res.data) // 订单金额
            setPayable(true)
        }).catch(err => {
            message.warning(err.message)
            setPayable(false)
        }).finally(() => {
            setLoading(false)
        })
    }, [skuId, type, couponId, props.isPowerPackage, diyPower])

    const isDiyPower = useMemo(() => {
        return diyPower > 0
    }, [diyPower])

    const onPay = async () => {
        // const tradeId = "204890477521158144"
        // eventBus.emit('pc_pay', tradeId)
        // props.onClose()
        // return

        try {
            setLoading(true)
            const func = props.isPowerPackage ?
            API.subscribePowerPackage({ // 加油包
                skuId: skuId,
                couponBindingId: couponId,
                channel,
            }) :
            diyPower > 0 ?
            API.subscribePlan({ // 随心配
                skuId: skuId,
                number: 1,
                type: type,
                couponBindingId: couponId,
                diyPower: diyPower,
                channel,
            }) :
            API.subscribePlan({ // 套餐
                skuId: skuId,
                number: 1,
                type: type,
                couponBindingId: couponId,
                channel,
            })
            const res = await func
            if (res.code != 0) throw new Error(res.message)
            console.log('res', res.data)
            const tradeId = res.data.tradeId
            eventBus.emit('pc_pay', tradeId, props.onPayedCb)
            props.onClose()
        } catch (err) {
            console.error(err.message)
            message.warning(err.message)
        } finally {
            setLoading(false)
        }
    }
    

    return (
        <div className={styles.Container}>
            <div className={styles.Head}>
            {
                props.isPowerPackage ? <span>购买加油包</span>:
                isDiyPower ? <span>购买随心配</span> :
                <span>购买套餐</span>
            }
            </div>
            <Spin spinning={loading}>
                <div className={styles.Content}>
                    {items.map((item, index) => (
                        <PowerItem
                            key={item.id}
                            actived={selectedIndex == index}
                            {...item}
                            orderAmount={orderAmount}
                            couponId={couponId}
                            loading={loading}
                            onClick={() => handleSelect(index)}
                        />
                    ))}
                    <CouponSelect
                        type={props.isPowerPackage ? 1 : 0}
                        skuId={skuId}
                        onChange={onCouponChange}
                    />
                    <PayChannel value={channel} onChange={setChannel} />
                    <Button
                        disabled={!payable}
                        className={styles.PayBtn}
                        block
                        type="primary"
                        loading={loading}
                        onClick={onPay}
                    >
                        去支付 {orderAmount && <>￥{orderAmount}</>}
                    </Button>
                    <Tips
                        unit={unit}
                        isPowerPackage={props.isPowerPackage}
                        powerAmount={selectedItem?.powerAmount}
                    />
                </div>
            </Spin>
        </div>
    )
}