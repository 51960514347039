import { useEffect, useMemo, useRef, useState } from "react"
import { fabric } from 'fabric';
import styles from './MattingOpt.module.scss'

const formartNumber = (number, digits=2) => {
    return Number(number.toFixed(digits))
}

const MattingOptRightCanvas = (props) => {
    const bgCanvasRef = useRef()
    const fabricBgCanvasRef = useRef()
    const [fabricBgCanvas, setFabricBgCanvas] = useState()

    const canvasRef = useRef()
    const fabricCanvasRef = useRef()
    const [fabricCanvas, setFabricCanvas] = useState()
    
    const maskRef = useRef()

    useEffect(() => {
        // 马赛克
        const patternImageBase64 = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABHNCSVQICAgIfAhkiAAAADBJREFUOE9jfPP69X8GPEBYRASfNAPjqAHDIgz+AwG+iH7z5s1ZfPKMowYwDIMwAACuR1xZvSaHFwAAAABJRU5ErkJggg==';
        fabric.util.loadImage(patternImageBase64, function(img) {
            const pattern = new fabric.Pattern({
                source: img,
                repeat: 'repeat',
            })
            // 背景canvas
            const instance = new fabric.Canvas(canvasRef.current, {
                width: props.displayWidth,
                height: props.displayHeight,
                backgroundColor: pattern, // 加载马赛克背景
                fireRightClick: false, // 启用右键
                stopContextMenu: true, // 禁用右键菜单
                isDrawingMode: false, // 禁用绘图模式
                selection: false, // 禁用选中对象
            })
            // 马赛克的背景
            const rect1 = new fabric.Rect({
                left: 0,
                top: 0,
                width: props.displayWidth,
                height: props.displayHeight,
                fill: '#e8e9ee',
                selectable: false,
            })
            // 马赛克
            const rect2 = new fabric.Rect({
                left: 0,
                top: 0,
                width: props.displayWidth,
                height: props.displayHeight,
                fill: pattern,
                selectable: false,
            })
            instance.add(rect1, rect2)
            instance.includeDefaultValues = false
            fabricCanvasRef.current = instance
            setFabricCanvas(instance)

            // 初始化mask img
            const img1 = new fabric.Image()
            img1.scale(props.displayScale).set({
                top: 0,
                left: 0,
                originX: 'center',
                originY: 'center',
                hoverCursor: 'pointer',
                hasBorders: false,
                selectable: false,
                globalCompositeOperation: 'destination-out'
            }) // 背景不可选
            img1.clone((img2) => {
                img2.scale(props.displayScale)
                const group = new fabric.Group([img1, img2], {
                    objectCaching: false, // 不缓存
                    left: 0,
                    top: 0,
                    width: props.displayWidth,
                    height: props.displayHeight,
                    hoverCursor: 'pointer',
                    hasBorders: false,
                    selectable: false,
                    globalCompositeOperation: 'destination-out'
                })
                group.set({ globalCompositeOperation: 'destination-out' })
                instance.add(group)
                instance.renderAll()
                maskRef.current = group    
            })
        })
        
        return () => {
            if (fabricCanvasRef.current) fabricCanvasRef.current.dispose()
        }
    }, [])

    useEffect(() => {
        fabric.Image.fromURL(props.inputImageBase64, (img) => {
            img.scale(props.displayScale) // 背景展示完全
            // 背景canvas
            const instance = new fabric.Canvas(bgCanvasRef.current, {
                width: props.displayWidth,
                height: props.displayHeight,
                backgroundImage: img, // 加载默认背景
                fireRightClick: false, // 启用右键
                stopContextMenu: true, // 禁用右键菜单
                isDrawingMode: false, // 禁用绘图模式
                selection: false, // 禁用选中对象
            })
            // instance.add(img)
            fabricBgCanvasRef.current = instance
            setFabricBgCanvas(instance)
        })

        return () => {
            if (fabricCanvasRef.current) fabricBgCanvasRef.current.dispose()
        }
    }, [])

    useEffect(() => {
        if (!maskRef.current) return
        maskRef.current.getObjects().filter(item => item.isType('image')).forEach(item => {
            item.setSrc(props.blendedBlueMaskBase64, () => {
                fabricCanvasRef.current.renderAll()
            })
        })
    }, [props.blendedBlueMaskBase64])

    useEffect(() => {
        if (!fabricCanvas) return
        for (let i = 0; i < props.viewportOffset.length; i++) {
            fabricCanvas.viewportTransform[i] = formartNumber(props.viewportOffset[i])
        }
        fabricCanvas.renderAll();

        if (!fabricBgCanvas) return
        for (let i = 0; i < props.viewportOffset.length; i++) {
            fabricBgCanvas.viewportTransform[i] = formartNumber(props.viewportOffset[i])
        }
        fabricBgCanvas.renderAll();
    }, [fabricCanvas, fabricBgCanvas, props.viewportOffset])

    useEffect(() => {
        if (!fabricCanvas) return
        const canvasScaleX = props.displayWidth / fabricCanvas.width
        const canvasScaleY = props.displayHeight / fabricCanvas.height
        
        const bgImage = fabricCanvas.get('backgroundImage')
        if (bgImage) bgImage.scale(props.displayScale)
        fabricCanvas.forEachObject((item) => {
            if (item.type == 'rect') return
            item.set({
                width: props.displayWidth,
                height: props.displayHeight,
            })
            if (item.type == 'group') {
                item.forEachObject(obj => {
                    obj.scale(props.displayScale)
                    obj.setCoords()
                })
            }
            item.setCoords() 
        })
        fabricCanvas.viewportTransform[4] = fabricCanvas.viewportTransform[4] * canvasScaleX
        fabricCanvas.viewportTransform[5] = fabricCanvas.viewportTransform[5] * canvasScaleY
        fabricCanvas.setDimensions({ width: props.displayWidth, height: props.displayHeight})
        fabricCanvas.renderAll()

        if (!fabricBgCanvas) return
        const bgImage2 = fabricBgCanvas.get('backgroundImage')
        if (bgImage2) bgImage2.scale(props.displayScale)
        fabricBgCanvas.viewportTransform[4] = fabricBgCanvas.viewportTransform[4] * canvasScaleX
        fabricBgCanvas.viewportTransform[5] = fabricBgCanvas.viewportTransform[5] * canvasScaleX
        fabricBgCanvas.setDimensions({ width: props.displayWidth, height: props.displayHeight})
        fabricBgCanvas.renderAll()
    }, [fabricCanvas, fabricBgCanvas, props.displayScale, props.displayWidth, props.displayHeight])

    // useEffect(() => {
    //     if (!fabricCanvas) return
    //     fabricCanvas.zoomToPoint(props.zoomToPoint, props.zoom);
    //     fabricCanvas.renderAll();

    //     if (!fabricBgCanvas) return
    //     fabricBgCanvas.zoomToPoint(props.zoomToPoint, props.zoom);
    //     fabricBgCanvas.renderAll();
    // }, [fabricCanvas, fabricBgCanvas, props.zoom, props.zoomToPoint])

    return (
        <div className={styles.CanvasWrap}>
            <div>
                <div style={{position: 'absolute'}} ><canvas ref={bgCanvasRef} /></div>
                <canvas style={{position: 'absolute'}} ref={canvasRef} />
            </div>
        </div>
    )
}

export default MattingOptRightCanvas