import { useEffect, useRef, useState } from "react";
import API from '@api/api'
import { Spin, message } from "antd";
import styles from './index.module.scss'
import eventBus from "@utils/eventBus";
import QRCode from 'qrcode.react'

const formatAmount = (amount) => {
    // 使用正则表达式匹配非零小数
    return amount >= 1 ? parseFloat(amount) : amount
}

const Tips = (props) => {
    const {
        unit, // 0-单月 1-限时特惠 2-包年
        isPowerPackage, // 加油包
        powerAmount,
    } = props

    if (isPowerPackage) return <p className={styles.Tips}>每个加油包有效期为30天，未用完的虹豆将在购买起第30天后清空</p>
    else return (
        <ul className={styles.Tips}>
            <li>支付完成后默认您同意<a target='_blank' href="/会员条款.htm">相关协议条例</a> </li>
            <li>虚拟商品，支付后无法退还</li>
            <li>{unit == 'Month' ? '包月' : unit == 'Quarter' ? '包季' : '包年'}服务有效期为{unit == 'Month' ? '30' : unit == 'Quarter' ? '90' : '365'}天</li>
            <li>会员订购当天可获得 <span>{powerAmount}</span> AI虹豆，服务周期内未用完的虹豆，将在当前服务周期结束时清空</li>
        </ul>
    )
}

const AlipayQrcode = (props) => {
    const alipayFrameRef = useRef()

    useEffect(() => {
        alipayFrameRef.current.contentWindow.document.write(props.url)
    }, [])

    return (
        <div className={styles.PayContent}>
            <div className={styles.iframePlaceholderWrap}>
                <Spin wrapperClassName={styles.iframePlaceholderSpin} spinning>
                    <div className={`${styles.iframePlaceholder} ${styles.alipay}`}></div>
                </Spin>
            </div>
            <iframe ref={alipayFrameRef}
                className={styles.payFrame}
                style={{ display: 'block' }}
                // srcDoc={payUrl}
                width="200"
                height="200"
                marginWidth={0}
                marginHeight={0}
                frameBorder="0"
                border="0"
                scrolling="no"
            ></iframe>
        </div>
    )
}

const WechatQrcode = (props) => {
    // weixin://wxpay/bizpayurl?pr=aYnckdQz3
    return (
        <div className={styles.PayContent}>
            <QRCode
                id="qrcode"
                value={props.url}
                size={200}
                level="H"
                style={{margin: 'auto'}}
                imageSettings={{
                    src: require('@assets/images/icon_wxpay@2x.png'),
                    width: 50,
                    height: 50,
                    excavate: true,
                }}
            />
        </div>
    )
}

export default function Payment (props) {
    const isMounted = useRef(true)
    const [tradeId] = useState(props.tradeId)
    const [loading, setLoading] = useState(false)
    const [channel, setChannel] = useState('alipay') // 支付渠道 alipay or wechat
    const [payUrl, setPayUrl] = useState() // 支付链接
    const [dealAmount, setDealAmount] = useState(1579) // 支付金额
    const [unit, setUnit] = useState('Year') // 单位
    const [powerAmount, setPowerAmount] = useState(0) // 虹豆数
    const [isPowerPackage, setIsPowerPackage] = useState(false) // 加油包
    const [isDiyPower, setIsDiyPower] = useState(false)

    useEffect(() => {
        return () => isMounted.current = false
    }, [])

    const checkTradeStatus = () => {
        API.paymentOrderStatus({
            tradeList: [tradeId]
        }).then(res => {
            if (res.code != 0) return
            const item = res.data[0]
            if (item.status == 'success') {
                console.log('订单支付成功', item.tradeId)
                message.success('支付成功')
                eventBus.emit('updatePower')
                eventBus.emit('updateUserInfo')
                props.onClose()
                props.onPayedCb && props.onPayedCb()
                return
            } else if (item.status == 'closed') { 
                console.log('订单关闭，刷新订单', item.tradeId)
                message.success('订单已关闭')
                props.onClose()
                props.onPayedCb && props.onPayedCb()
                return
            } else {
                setTimeout(() => {
                    if (isMounted.current) checkTradeStatus()
                }, 400)
            }
        })
    }

    useEffect(() => {
        setLoading(true)
        API.payForOrder({tradeId}).then(res => {
            console.log('payfororder', res)
            if (res.code != 0) throw new Error(res.message)
            setPayUrl(res.data.payUrl)
            setDealAmount(res.data.dealAmount)
            setIsPowerPackage(res.data.type == "powerPackage")
            setIsDiyPower(res.data.type == "customPlan")
            setUnit(res.data.unit)
            setPowerAmount(res.data.powerAmount)
            const reg = /^([a-z]+)_?/.exec(res.data.channel)
            if (reg) setChannel(reg[1])
            // 启动检查订单状态
            checkTradeStatus()
        }).catch(err => {
            console.error(err)
            message.warning(err.message)
            props.onClose()
            props.onPayedCb && props.onPayedCb()
        }).finally(() => {
            setLoading(false)
        })
    }, [])

    return (
        <div className={styles.Container}>
            <div className={styles.Head}>
            {
                isPowerPackage ? <span>购买加油包</span>:
                isDiyPower ? <span>购买随心配</span> :
                <span>购买套餐</span>
            }
            </div>
            <Spin spinning={loading}>
            <div className={styles.Content}>
                <div className={styles.Channel}>
                { channel == 'alipay' && <span><img src={require('@assets/images/icon_alipay.png')} />支付宝扫码支付</span>}
                { channel == 'wechat' && <span><img src={require('@assets/images/icon_wxpay.png')} />微信扫码支付</span>}
                </div>
                <div className={styles.Qrcode}>
                {
                    payUrl &&
                    <>
                    { channel == 'alipay' && <AlipayQrcode url={payUrl} /> }
                    { channel == 'wechat' && <WechatQrcode url={payUrl} /> }
                    </>
                }
                </div>
                <div className={styles.PayPrice}>
                {
                    dealAmount && 
                    <>
                    <span>扫码支付：</span>
                    <span>￥</span>
                    <span>{formatAmount(dealAmount)}</span>
                    { !isPowerPackage && <span> / {unit == 'Month' ? '月' : unit == 'Quarter' ? '季' : unit == 'Year' ? '年' : ''}</span> }
                    </>
                }
                </div>
                <Tips
                    unit={unit}
                    isPowerPackage={isPowerPackage}
                    powerAmount={powerAmount}
                />
            </div>
            </Spin>
        </div>
    )
}