import React, { forwardRef, useImperativeHandle, useEffect, useRef, useState, useMemo, useContext } from "react";
import useInteractive from "./hooks/useInteractive"
import { fabric } from 'fabric';
import styles from './shapeShiftCanvas.module.scss'
import { Dropdown } from "antd";
import { AIEditContext } from "../../AIEditContext";
const rectSize = 256 // 1024px尺寸下

const formartNumber = (number, digits=2) => {
    return Number(number.toFixed(digits))
}

const ShapeShiftCanvas = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        reset, // 清除point并恢复原始图片
        getData, // 获得坐标点及rect位置
        loadData, // 恢复rect位置
        deletePointGroup, // 删除选中的坐标点
        clearPoints, // 清除所有坐标点
    }))

    // 作为背景的canvas
    const bgCanvasRef = useRef()
    const fabricBgCanvasRef = useRef()
    // 主体canvas
    const canvasRef = useRef()
    const fabricCanvasRef = useRef()
    const [fabricCanvas, setFabricCanvas] = useState()
    // 选择区域框
    const rectRef = useRef()
    // 选择框上的文字提示
    const noticeRef = useRef()
    // 选择框下方的按钮
    const buttonRef = useRef()
    // 监听displayScale
    // const displayScaleRef = useRef(props.displayScale)
    // useEffect(() => {
    //     displayScaleRef.current = props.displayScale
    // }, [props.displayScale])

    const {
        rectX,
        rectY,
        points,
        shapeable,
        mouseX, mouseY,
        showContextMenu,
        deletePointGroup,
        clearPointGroup,
        setRectPosition
    } = useInteractive({
        instance: fabricCanvasRef.current,
        noticeInstance: noticeRef.current,
        buttonInstance: buttonRef.current,
        rectInstance: rectRef.current,
        moveable: props.moveable,
        areaLocked: props.areaLocked,
        lockAreaFunc: props.lockAreaFunc,
        displayScale: props.displayScale,
        isLoading: props.isLoading,
        shapeFunc: props.shapeFunc
    })

    const rectXRef = useRef()
    const rectYRef = useRef()
    const pointsRef = useRef()
    useEffect(() => {
        rectXRef.current = rectX
        rectYRef.current = rectY
        pointsRef.current = points
        props.onChange && props.onChange({ rectX, rectY, points, shapeable})
    }, [rectX, rectY, points, shapeable])

    const getData = () => {
        // console.log('rect', rectXRef.current, 'rectY', rectYRef.current, 'rectSize', rectSize, 'points', pointsRef.current)
        return {
            area: [rectXRef.current, rectYRef.current, rectSize].join(','),
            coord_s: pointsRef.current.flat().join(','),
            areaData: [rectXRef.current, rectYRef.current, rectSize]
        }
    }
    const loadData = (data) => {
        if (!rectRef.current) return
        const {area, coord_s, areaData} = data
        setRectPosition(areaData[0], areaData[1])
    }
    const resetImage = () => {
        if (!fabricCanvasRef.current) return
        if (!fabricBgCanvasRef.current) return
        const bgImage = fabricCanvasRef.current.get('backgroundImage')
        if (bgImage) bgImage.setSrc(props.inputImage, () => fabricCanvasRef.current.renderAll(), {crossOrigin: 'anonymous'})
        const bgImage2 = fabricBgCanvasRef.current.get('backgroundImage')
        if (bgImage2) bgImage2.setSrc(props.inputImage, () => fabricBgCanvasRef.current.renderAll(), {crossOrigin: 'anonymous'})
    }
    const reset = () => {
        clearPointGroup()
        resetImage()
    }
    const clearPoints = () => {
        clearPointGroup()
    }

    const {addWatermark} = useContext(AIEditContext)

    // 初始化
    useEffect(() => {
        if (!props.inputImage) return
        // 马赛克
        const patternImageBase64 = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABHNCSVQICAgIfAhkiAAAADBJREFUOE9jfPP69X8GPEBYRASfNAPjqAHDIgz+AwG+iH7z5s1ZfPKMowYwDIMwAACuR1xZvSaHFwAAAABJRU5ErkJggg==';
        const pattern = new fabric.Pattern({
            source: patternImageBase64,
            repeat: 'repeat',
        })
        // 初始化canvas对象
        fabric.Image.fromURL(props.inputImage, (img) => {
            img.scale(props.displayScale) // 背景展示完全
            img.set({ // 背景不可选
                selectable: false,
                globalCompositeOperation: 'source-over'
            })

            // 背景canvas
            const bgInstance = new fabric.Canvas(bgCanvasRef.current, {
                width: props.displayWidth,
                height: props.displayHeight,
                backgroundColor: pattern, // 加载马赛克背景
                backgroundImage: img,
                fireRightClick: false, // 启用右键
                stopContextMenu: true, // 禁用右键菜单
                isDrawingMode: false, // 禁用绘图模式
                selection: false, // 禁用选中对象
                includeDefaultValues: false, // 序列化对象时是否包含默认值
            })
            addWatermark(bgInstance) // 添加水印

            // 主体canvas
            const img2 = fabric.util.object.clone(img)
            const instance = new fabric.Canvas(canvasRef.current, {
                width: props.displayWidth,
                height: props.displayHeight,
                backgroundColor: pattern, // 加载马赛克背景
                backgroundImage: img2, // 加载默认背景
                fireRightClick: true, // 启用右键
                stopContextMenu: false, // 禁用右键菜单
                isDrawingMode: false, // 禁用绘图模式
                selection: false, // 禁用选中对象
                includeDefaultValues: false, // 序列化对象时是否包含默认值
                // defaultCursor: props.moveable ? 'move' : 'default'
            })

            addWatermark(instance)

            // 灰色mask
            const maskRect = new fabric.Rect({
                left: 0,
                top: 0,
                width: props.displayWidth,
                height: props.displayHeight,
                selectable: false,
                fill: 'rgba(0,0,0,.5)',
            })
            instance.add(maskRect)
            
            // 选择框
            const rect = new fabric.Rect({
                width: formartNumber(rectSize * props.displayScale),
                height: formartNumber(rectSize * props.displayScale),
                left: formartNumber(props.displayWidth / 2),
                top: formartNumber(props.displayHeight / 2),
                originX: 'center',
                originY: 'center',
                stroke: 'white',
                strokeWidth: 0,
                // 固定边框宽度
                strokeUniform: true,
                selectable: false,
                globalCompositeOperation: 'destination-out',
            })
            rect.set('is_rect', true)
            instance.add(rect)
            rectRef.current = rect

            // 选择框上的提示
            const textWrap = new fabric.Rect({
                left: 0,
                top: 0,
                stroke: 'transparent',
                strokeWidth: 0,
                fill: '#4e4e4e',
                width: formartNumber(rectSize * props.displayScale),
                height: formartNumber(30 * props.displayScale),
            })
            const text = new fabric.Text('1. 点击鼠标锁定区域', {
                top: formartNumber(30 * props.displayScale / 2),
                left: formartNumber(10 * props.displayScale),
                originY: 'center',
                fontSize: formartNumber(15 * props.displayScale),
                textAlign: "left",
                fill: '#FFF',
                fontFamily: 'Source Han Sans CN',
                lockRotation: true, // 禁止旋转
                lockScalingY: true, // 禁止Y轴伸缩
                lockScalingFlip: true, // 禁止负值反转
                splitByGrapheme: false, // 拆分中文，可以实现自动换行
            })
            const group = new fabric.Group([textWrap, text], {
                left: rect.left,
                top: rect.top - rect.height / 2,
                originX: 'center',
                originY: 'bottom',
                evented: false,
            })
            group.set('is_notice', true)
            noticeRef.current = group
            instance.add(group)

            // 应用变形 按钮
            const buttonWrap = new fabric.Rect({
                left: 0,
                top: 0,
                stroke: 'transparent',
                strokeWidth: 0,
                fill: '#1677ff',
                width: formartNumber(rectSize * props.displayScale),
                height: formartNumber(36 * props.displayScale),
            })
            const buttonText = new fabric.Text('应用变形', {
                top: formartNumber(36 * props.displayScale / 2),
                left: formartNumber(rectSize / 2 * props.displayScale),
                originX: 'center',
                originY: 'center',
                fontSize: formartNumber(15 * props.displayScale),
                textAlign: "center",
                fill: '#FFF',
                fontFamily: 'Source Han Sans CN',
                lockRotation: true, // 禁止旋转
                lockScalingY: true, // 禁止Y轴伸缩
                lockScalingFlip: true, // 禁止负值反转
                splitByGrapheme: false, // 拆分中文，可以实现自动换行
            })
            const buttonGroup = new fabric.Group([buttonWrap, buttonText], {
                left: rect.left,
                top: rect.top + rect.height / 2,
                originX: 'center',
                originY: 'top',
                hoverCursor: 'pointer',
                evented: true,
                selectable: false,
            })
            buttonRef.current = buttonGroup
            instance.add(buttonGroup)
            
            fabricBgCanvasRef.current = bgInstance // 背景canvas
            fabricCanvasRef.current = instance // 主体canvas
            setFabricCanvas(instance)
            props.initCanvasFunc && props.initCanvasFunc([fabricCanvasRef.current, fabricBgCanvasRef.current])
        }, { crossOrigin: 'anonymous' })
    }, [])

    useEffect(() => {
        if (!props.shapeFrame) return resetImage()
        if (!fabricCanvasRef.current) return
        if (!fabricBgCanvasRef.current) return
        const bgImage = fabricCanvasRef.current.get('backgroundImage')
        if (bgImage) bgImage.setSrc(props.shapeFrame, () => fabricCanvasRef.current.renderAll(), {crossOrigin: 'anonymous'})
        const bgImage2 = fabricBgCanvasRef.current.get('backgroundImage')
        if (bgImage2) bgImage2.setSrc(props.shapeFrame, () => fabricBgCanvasRef.current.renderAll(), {crossOrigin: 'anonymous'})
    }, [props.shapeFrame])

    // 保证交互点绝对大小不变
    useEffect(() => {
        if (!fabricCanvasRef.current) return
        const objs = fabricCanvasRef.current.getObjects().filter(item => item.get('fixed_size'))
        objs.forEach(item => {
            if (item.type == 'group') {
                item.forEachObject(obj => {
                    const fixedSize = obj.get('fixed_size')
                    if (obj.type == 'text') {
                        obj.set({
                            fontSize: formartNumber(fixedSize * props.displayScale / props.zoom),
                        })
                    }
                    if (obj.type == 'circle') {
                        obj.set({
                            radius: formartNumber(fixedSize * props.displayScale / props.zoom),
                            strokeWidth: formartNumber(2 * props.displayScale / props.zoom),
                        })
                    }
                    obj.setCoords()
                })
                const fixedSize = item.get('fixed_size')
                item.set({
                    width: formartNumber(fixedSize * props.displayScale / props.zoom),
                    height: formartNumber(fixedSize * props.displayScale / props.zoom),
                })
            }
            if (item.type == 'line') {
                item.set({
                    strokeWidth: formartNumber(2 * props.displayScale / props.zoom),
                })
            }
            
            item.setCoords()
        })
        fabricCanvasRef.current.renderAll()
    }, [props.zoom, props.displayScale])

    // 保证元素size随窗口变化
    useEffect(() => {
        if (!fabricCanvasRef.current) return
        const canvasScaleX = props.displayWidth / fabricCanvasRef.current.width
        const canvasScaleY = props.displayHeight / fabricCanvasRef.current.height

        const bgImage = fabricCanvasRef.current.get('backgroundImage')
        if (bgImage) bgImage.scale(props.displayScale)
        const bgImage2 = fabricBgCanvasRef.current.get('backgroundImage')
        if (bgImage2) bgImage2.scale(props.displayScale)

        const objs1 = fabricBgCanvasRef.current.getObjects()
        objs1.forEach(item => {
            item.set({
                scaleX: item.scaleX * canvasScaleX,
                scaleY: item.scaleY * canvasScaleY
            })
            item.setCoords()               
        })

        const objs = fabricCanvasRef.current.getObjects()
        objs.forEach(item => {
            item.set({
                width: formartNumber(item.width * canvasScaleX),
                height: formartNumber(item.height * canvasScaleY),
                left: formartNumber(item.left * canvasScaleX),
                top: formartNumber(item.top * canvasScaleY),
            })
            if (item.type == 'group') {
                item.forEachObject(obj => {
                    if (obj.type == 'textbox' || obj.type == 'text') {
                        obj.set({
                            fontSize: formartNumber(obj.fontSize * canvasScaleX),
                        })
                        obj.set({
                            width: formartNumber(obj.width * canvasScaleX),
                            height: formartNumber(obj.height * canvasScaleY),
                        })
                    }
                    if (obj.type == 'circle') {
                        obj.set({
                            radius: formartNumber(obj.radius * canvasScaleX),
                        })
                    }
                    obj.set({
                        width: formartNumber(obj.width * canvasScaleX),
                        height: formartNumber(obj.height * canvasScaleY),
                        left: formartNumber(obj.left * canvasScaleX),
                        top: formartNumber(obj.top * canvasScaleY),
                    })
                })
            } else if (item.type == 'image') {
                item.set({
                    scaleX: item.scaleX * canvasScaleX,
                    scaleY: item.scaleY * canvasScaleY
                })
                item.setCoords()
            }
        })

        fabricBgCanvasRef.current.viewportTransform[4] = fabricBgCanvasRef.current.viewportTransform[4] * canvasScaleX
        fabricBgCanvasRef.current.viewportTransform[5] = fabricBgCanvasRef.current.viewportTransform[5] * canvasScaleY
        fabricBgCanvasRef.current.setDimensions({ width: props.displayWidth, height: props.displayHeight})
        fabricBgCanvasRef.current.renderAll()

        fabricCanvasRef.current.viewportTransform[4] = fabricCanvasRef.current.viewportTransform[4] * canvasScaleX
        fabricCanvasRef.current.viewportTransform[5] = fabricCanvasRef.current.viewportTransform[5] * canvasScaleY
        fabricCanvasRef.current.setDimensions({ width: props.displayWidth, height: props.displayHeight})
        fabricCanvasRef.current.renderAll()
    }, [props.displayWidth, props.displayHeight, props.displayScale])

    // 右键菜单
    const [open, setOpen] = useState(false)
    useEffect(() => {
        if (!showContextMenu) setOpen(showContextMenu)
    }, [showContextMenu])
    const menuItems = useMemo(() => {
        return [
            { label: '重选变形区域', key: 1, disabled: !props.areaLocked || props.isLoading },
            { label: '应用变形', key: 2, disabled: !shapeable || props.isLoading }
        ]
    }, [shapeable, props.areaLocked, props.isLoading])
    const onMenuClick = ({item, key}) => {
        if (key == 1) {
            props.unlockAreaFunc()
            setOpen(false)
        } else {
            props.shapeFunc()
            setOpen(false)
        }
    }

    return (
        <Dropdown
            overlayClassName="no-custom"
            destroyPopupOnHide
            menu={{
                theme: 'light',
                items: menuItems,
                onClick: onMenuClick
            }}
            dropdownRender={menus => React.cloneElement(menus, { onContextMenu: (e) => e.preventDefault() })}
            trigger={['contextMenu']}
            open={showContextMenu && open}
            onOpenChange={open => setOpen(open)}
        >
            
            <div className={styles.CanvasWrap} style={{width: props.displayWidth, height: props.displayHeight}}>
                <canvas ref={bgCanvasRef} />
                <canvas ref={canvasRef} />
            </div>
        </Dropdown>      
    )
})

export default ShapeShiftCanvas