import { Button, Empty, Input, Modal, Spin, message } from "antd";
import { useState, forwardRef, useImperativeHandle, useEffect, useMemo, useRef } from "react";
import { CloseOutlined, ExclamationCircleFilled, LoadingOutlined  } from '@ant-design/icons';
import {useLocation, useNavigate} from 'react-router-dom'
import styles from './couponModal.module.scss'
import API from '@api/api'
import dayjs from "dayjs";
import InfiniteScroll from 'react-awesome-infinite-scroll';
import eventBus from '@utils/eventBus';
import PSAIModal from "@PSAIComponents/PSAIModal";

const CouponCard = (props) => {
    const expired = useMemo(() => { // 已过期
        return dayjs().isAfter(dayjs(props.expiredTime), 'second')
    }, [props.expiredTime])
    const disabled = useMemo(() => { // 禁止使用
        return props.status == '已使用' || expired
    }, [props.status, expired])
    // 折扣券
    const isDiscount = useMemo(() => {
        return props.couponType == '折扣券'
    }, [props.couponType])

    // targetSkuType 1-套餐 2-能量包
    const leftBanner = (
        props.couponType == '兑换券' ?
            <p>
                <span className={props.showBanner.length > 2 ? styles.Middle : styles.Big}>{props.showBanner}</span>
                <span className={styles.Unit}>{props.targetSkuType == 1 ? '天' : '点'}</span>
            </p> :
            <p>
                <span className={props.showBanner.length > 2 ? styles.Middle : styles.Big}>{props.showBanner}</span>
                <span className={styles.Unit}>折</span>
            </p>
    )

    const onClick = () => {
        if (disabled) return
        props.setSelected(props.bindingId)
    }

    const onChangeCoupon = () => {
        if (isDiscount) { // 跳转到充值页面
            eventBus.emit('recharge') 
            props.closeFunc()
            return
        }
        PSAIModal.confirm({
            title: `确定使用这张兑换券？`,
            onOk() {
                API.changeCoupon({
                    bindingId: props.bindingId
                }).then(res => {
                    if (res?.code != 0) {
                        return message.warning(res?.message)
                    }
                    message.success(`${props.name}兑换成功`)
                    props.refresh()
                    eventBus.emit('updatePower')
                })
            }
        })        
    }

    return (
        <div
            className={`${styles.CouponCard} ${props.selected && styles.actived}`}
            onClick={onClick}
        >
            <div className={`${styles.CardContent} ${disabled && styles.disabled}`}>
                <div className={styles.CouponContent}>
                    <div className={styles.LeftBox}>
                        {leftBanner}
                    </div>
                    <div className={styles.RightBox}>
                        <p className={styles.name}>{props.name}</p>
                        <Button disabled={disabled}  className={props.selected && styles.actived} onClick={onChangeCoupon}>{isDiscount ? '立即使用' : '兑换'}</Button>
                        <p className={styles.expiredTime}>有效期至 {dayjs(props.expiredTime).format('YYYY年MM月DD日')}</p>
                    </div>
                </div>
                <div className={styles.CouponRibbon}>{props.couponType}</div>
            </div>
            {
                disabled &&
                <i className={`${styles.DisabledIcon} ${expired ? styles.expired : styles.used}`} ></i>
            }
        </div>
    )
}

const CouponList = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        initData
    }));

    const [inited, setInited] = useState(false)
    const [list, setList] = useState([])
    const [hasMore, setHasMore] = useState()
    const [isRefreshing, setIsRefreshing] = useState()
    const [pageIndex, setPageIndex] = useState(1)
    const [pageSize, setPageSize] = useState(4)
    const [total, setTotal] = useState(0)
    const [loading, setLoading] = useState(false)
    
    const [selected, setSelected] = useState()

    const fetchData = (pageIndex, pageSize) => {
        setLoading(true)
        return API.getCouponList({
            pageIndex: pageIndex,
            pageSize: pageSize
        }).then(res => {
            if (!res || res?.code !== 0 || !res?.data?.items) {
                console.log('get getTaskList error');
                throw new Error('get getTaskList error')
            }
            setTotal(res?.data?.total)
            return {
                list: res?.data?.items,
                total: res?.data?.total
            }
        }).finally(() => {
            setLoading(false)
        })
    }

    // loading more
    const fetchMoreData = () => {
        console.log('fetchMoreData')
        const nextPageIndex = pageIndex+1
        setPageIndex(nextPageIndex)
        fetchData(nextPageIndex, pageSize).then(res => {
            const next = list?.concat(res.list)
            if (next?.length >= res.total) {
                setHasMore(false)
            }
            setList(next)
        }).catch(err => {
            console.error(err)
        })
    }

    const initData = () => {
        setSelected()
        setPageIndex(1)
        return fetchData(1, pageSize).then(res => {
            setList(res.list)
            setHasMore(pageSize < res.total)
        }).catch(err => {
            console.error(err)
        })
    }

    useEffect(() => {
        initData().finally(() => setInited(true))
    }, [])

    return (
        <div className={`${styles.CouponContent} coupon_list_body _beauty_scrollbar`}>
            <InfiniteScroll
                length={list?.length}
                next={fetchMoreData}
                hasMore={hasMore}
                scrollableParent={document.querySelector(`.coupon_list_body`)}
                // pullDownToRefresh
                isRefreshing={isRefreshing}
                // refreshFunction={reload}
                pullDownComponent={<div style={{ height: "50px", background: "green" }}>下拉</div>}
                releaseComponent={<div style={{ height: "50px", background: "red" }}>释放</div>}
                refreshingComponent={<div style={{ height: "50px", background: "green" }}>加载中</div>}
                refreshEndComponent={<div style={{ height: "50px", background: "red" }}>加载完成</div>}
                loadingComponent={<div style={{ height: "50px", lineHeight: "50px", textAlign: 'center' }}><h4>加载中...</h4></div>}
                endComponent={
                    (list?.length) ?
                        <div style={{ height: "30px", lineHeight: "30px", textAlign: 'center', fontWeight: 'normal', color: '#999' }}>
                            <span>没有更多了</span>
                        </div> : null
                }
            >
                {
                    inited ?
                    (
                        <Spin spinning={loading} size="large">
                        {
                            list?.length ?
                            list.map(item => (
                                <CouponCard
                                    key={item.bindingId}
                                    {...item}
                                    selected={selected == item.bindingId}
                                    setSelected={setSelected}
                                    refresh={initData}
                                    closeFunc={props.closeFunc}
                                />
                            )) :
                            <Empty className={styles.Empty} description={<span style={{color: '#999'}}>没有优惠券哦</span>}/>
                        }
                        </Spin>
                    ) :
                    <div></div>
                }
            </InfiniteScroll>
        </div>
    )
})

const CouponModal = forwardRef((props, ref) => {
    const location = useLocation()
    const navigate = useNavigate()
    const couponRef = useRef()

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search)
        const showCoupon = searchParams.get('show_coupon')
        if (showCoupon) {
            navigate(location.pathname)
            setOpen(true)
        }
    }, [location])

    useImperativeHandle(ref, () => ({
        openClick
    }));

    const [open, setOpen] = useState()

    const openClick = () => {
        setCouponId('')
        setOpen(true)
    }

    // const ModalTitle = <p className={styles.ModalTitle}><span>我的优惠券</span><CloseOutlined onClick={() => setOpen(false)} /></p>

    const [couponId, setCouponId] = useState('')
    const onChangeCoupon = () => {
        if (!couponId) {
            message.warning('请输入兑换码')
            return
        }
        API.acquireCoupon({
            couponId: couponId.trim()
        }).then(res => {
            if (res?.code !== 0) {
                return message.warning(res?.message)
            }
            couponRef.current.initData()
            setCouponId('')
            message.success('兑换成功')
        })
    }
    
    const ModalFooter = (
        <div className={styles.ModalFooter}>
            <div className={styles.CouponInput}>
                <Input placeholder="如有兑换码请在此输入" variant="borderless" value={couponId} onChange={e => setCouponId(e.target.value)}/>
                <Button onClick={onChangeCoupon}>兑换</Button>
            </div>
        </div>
    )

    return (
        <PSAIModal
            title="我的优惠券"
            open={open}
            footer={null}
            maxWidth={430}
            onCancel={() => setOpen(false)}
        >
            <div>
                <div className={styles.CouponWrap}>
                    <CouponList ref={couponRef} closeFunc={() => setOpen(false)} />
                </div>
                {ModalFooter}
            </div>
        </PSAIModal>
    )
})

export default CouponModal