import React, { useState, useEffect, useContext, useRef, useMemo } from 'react';
import { Popover, Progress, Tooltip, message } from 'antd';
import { useLocation, useNavigate }from "react-router-dom"; 
import API from '@api/api.js';
import { AppContext, channel } from '@utils/AppContext';
import styles from './loggedHead.module.scss'
import CountUp from 'react-countup';
import eventBus from '@utils/eventBus';
import PayModal from '../payModal/payModal'
import CouponModal from '../couponModal/couponModal'
import SwitchHomePageType from '../SwitchHomePageType';

function LoggedHead (props) {
    const location = useLocation();
    const navigate = useNavigate();
    const payChildRef = useRef(null);
    const couponChildRef = useRef()
    const { globalUser, globalBalance, setHasToken, setGlobaluser } = useContext(AppContext);
    const [open, setOpen] = useState(false)
    const [powerBalance, setPowerBalance] = useState(globalBalance)

    useEffect(() => {
        setPowerBalance(globalBalance)
    }, [globalBalance])

    // 用来检查是否有虹豆/套餐到期需要提醒
    useEffect(() => {
        eventBus.emit('updateOrderList')
    }, [])
    
    // 多账号登录时更新账号信息
    useEffect(() => {
        eventBus.emit('updateUserInfo')
        eventBus.emit('updatePower')
        eventBus.emit('updateActivityInfo')
    }, [location])

    const goPerson = () => {
        window.open("/home/homemain/personalCenter", "_blank");
        // navigate("/home/homemain/personalCenter")
        setOpen(false)
    }

    const goBack = () => {
        API.logout({}).then(res => {
            navigate('/')
            setGlobaluser()
            localStorage.removeItem('token')
            sessionStorage.removeItem('taskCache_model')
            sessionStorage.removeItem('taskCache_dressform')
            sessionStorage.removeItem('taskCache_clothes')
            sessionStorage.removeItem('taskCache_commodity')
            sessionStorage.removeItem('taskCache_tryonVideo')
            sessionStorage.removeItem('taskCache_commodityVideo')
            localStorage.removeItem('channel')
            setHasToken(false)
            setOpen(false)
        }).catch((error) => {
            navigate('/')
            localStorage.removeItem('token')
            sessionStorage.removeItem('taskCache_model')
            sessionStorage.removeItem('taskCache_dressform')
            sessionStorage.removeItem('taskCache_clothes')
            sessionStorage.removeItem('taskCache_commodity')
            sessionStorage.removeItem('taskCache_tryonVideo')
            sessionStorage.removeItem('taskCache_commodityVideo')
            localStorage.removeItem('channel')
            setHasToken(false)
        })
    }
    const handleOpenChange = (newOpen) => {
        setOpen(newOpen)
        if(newOpen) {
            eventBus.emit('updateUserInfo')
            eventBus.emit('updatePower')
            eventBus.emit('updateActivityInfo')
        }
    }

    const openCouponClick = () => {
        couponChildRef.current.openClick()
        setOpen(false)
    }

    const content = (
        <div className='popover_box'>
            {
                globalUser?<div>
                    <div className={styles.popover_box_}>
                        <div className={styles.popover_box_top}>
                            <img className={styles.img1} src={require('@assets/images/icon_Avatar.png')} alt="" />
                            <ul>
                                <li className={styles.li1}>{globalUser?.mobile ? globalUser?.mobile.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2') : globalUser?.email}</li>
                                <li className={styles.li2}>
                                    {/* <i className={`${styles.vip_icon} ${globalUser?.membership?.level}`}></i> */}
                                    <Tooltip placement="left" title={globalUser?.membership?.plan}>
                                        {globalUser?.membership?.plan}
                                    </Tooltip>
                                </li>
                            </ul>
                        </div>
                        <p className={styles.balance_p}><span>剩余虹豆</span><span>{globalUser?.membership?.balance} / {globalUser?.membership?.amount}</span></p>
                        <Progress percent={Math.ceil(globalUser?.membership?.balance/ globalUser?.membership?.amount) * 100} showInfo={false} />
                        {
                            localStorage.getItem('channel') == 'taobao'?null: <>
                                <p className={styles.coupon_p} onClick={openCouponClick}>我的优惠券</p>
                                <p className={styles.center_p} onClick={goPerson}>个人中心</p>
                            </>
                        }
                    </div>
                    <p className={styles.logout_p} onClick={goBack}>退出登录</p>
                </div>: null
            }
        </div>
    );

    const [startBalance, setStartBalance] = useState(0)
    const [endBalance, setEndBalance] = useState(0)
    const endBalanceRef = useRef(0)

    useEffect(() => {
        endBalanceRef.current = powerBalance
        setEndBalance(powerBalance)
    }, [powerBalance])
    
    const countUpEnd = () => {
        setStartBalance(endBalanceRef.current)
    }

    const openPayClick = () => {
        if(localStorage.getItem('channel') == 'taobao') return
        payChildRef.current.openrechargeClick()
    }

    const showSwitchHomePageType = useMemo(() => { // 左上角logo显示品类
        return location.pathname != '/' && location.pathname != '/home/image_extraction' && location.pathname != '/home/homemain/personalCenter'
    }, [location.pathname])

    return (
         <div className={styles.LoggedHead}>
            {
                showSwitchHomePageType ?
                <SwitchHomePageType /> : <i></i>
            }
            <div style={{display: 'flex'}}>
                <div onClick={openPayClick} className={styles.LoggedHead_power}>
                    <span className={styles.LoggedHead_powerAmount}>{<CountUp start={startBalance} end={endBalance} separator=","  onEnd={countUpEnd}/>}</span>
                    {/* <div className={styles.LoggedHead_recharge}>
                        <span onClick={() => eventBus.emit('recharge')}>充值</span>
                    </div> */}
                </div>
                {
                    <Popover
                        overlayClassName='person_popover'
                        placement="bottomRight"
                        content={content}
                        trigger="click"
                        onOpenChange={handleOpenChange}
                        open={open}
                    >
                        <img className={styles.LoggedHead_photo} onClick={() => setOpen(true)} src={require('@assets/images/icon_Avatar.png')} alt="" />
                    </Popover>
                }
                    <PayModal ref={payChildRef}></PayModal>
                    <CouponModal ref={couponChildRef} />
            </div>
         
            
          
        </div>
       
    )
}

export default LoggedHead