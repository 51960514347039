import WaitUploadPage from '@view/compoents/WaitUploadPage2'
import styles from './index.module.scss'
import { useContext, useState } from 'react'
import { message } from 'antd'
import Utils from '@utils/utils'
import PSAIModal from '@PSAIComponents/PSAIModal'
import VideoImage from './VideoImage'
import { GenerateContext } from '@utils/GenerateContext'
import { IMAGE_TYPE } from '@utils/CONST'
import Example from '@assets/json/example.json'
import { AppContext } from '@utils/AppContext'

const MiddleCompVideo = () => {
    const {setGlobalLoading} = useContext(AppContext)
    const {img4Video, setImg4Video, setProjectId, setProjectName} = useContext(GenerateContext)
    const [imageAntispamId, setImageAntispamId] = useState()

    const [modelExamples] = useState(
        Example
        .filter(item => item.type == IMAGE_TYPE.COMMODITY)
        .filter(item => !item.disabled)
    )

    const beforeUpload = async (file) => {
        try {
            setGlobalLoading('上传中')
            // 检查图像是否符合要求
            const validate = await Utils.validateImageFile(file)
            if (!validate) return false
            const legalRes = await Utils.checkImageIslegal(file, 1) //审核
            if(legalRes) {
                if(!legalRes.isLegal) {
                    PSAIModal.confirm({
                        content: '经检测，您上传的内容涉嫌违规，已进行屏蔽处理，人工审核会对图片进行复核。',
                        okText: '重新上传',
                        cancelText: '取消',
                        onOk() {
                            Utils.openFile().then((res) => {
                                if(res) beforeUpload(res)
                            })
                        },
                        onCancel() {},
                    })
                } else {
                    setImageAntispamId(legalRes.imageAntispamId)
                    setImg4Video(legalRes.data)
                    setProjectId()
                    setProjectName('未命名')
                }
            } else {
                return false
            }
        } catch (err) {
            message.warning(err.message);
            console.error(err.message)
            return false
        } finally {
            setGlobalLoading(false)
        }
    }

    const handleImageChange = (url) => {
        setImg4Video(url)
        setProjectId()
        setProjectName('未命名')
    }

    return (
        <div className={styles.Container}>
        {
            img4Video ?
            <VideoImage key={img4Video} onUpload={beforeUpload} /> :
            <WaitUploadPage
                btnText={"上传商品图"}
                uploadTips={"建议图片尺寸不小于500*500，长宽比不超过2:1"}
                uploadExamples={modelExamples}
                pickFromProject={true}
                onUpload={beforeUpload}
                onChange={handleImageChange}
                useImageType
            />
        }
        </div>
    )
}

export default MiddleCompVideo