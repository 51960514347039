import React, {useState, useEffect, useMemo, useContext} from 'react';
import { message, Col, Row, Select, Carousel, Divider, Checkbox } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { AppContext } from '@utils/AppContext';
import './modelFilterSelect.scss'

const ModelFilterSelect = (props) => {
    const { globalModelFilter, setGlobalModelFilter } = useContext(AppContext);
    const [skin, setSkin] = useState([]);
    const [sex, setSex] = useState([]);
    const [age, setAge] = useState([]);
    const [posture, setPosture] = useState([])

    const selectOptions = {
        mode: "multiple",
        allowClear: true,
        showSearch: false,
        style: {width: props.width || 'calc(100% - 70px)'},
        size: 'small',
        maxTagCount: 'responsive',
        placeholder: '请选择模特类型',
        options: [{
            key: 1,
            label: '性别',
            options: [{
                value: 'female',
                label: '女',
            }, {
                value: 'male',
                label: '男',
            }]
        }, {
            key: 2,
            label: '年龄',
            options: [{
                value: 'adult',
                label: '成人',
            }, {
                value: 'children',
                label: '儿童',
            // },  {
            //     value: 'oldman',
            //     label: '老年',
            }],
        }, {
            key: 3,
            label: '肤色',
            options: [{
                label: '黄',
                value: 'yellow',
            }, {
                label: '白',
                value: 'white',
            }, {
                label: '黑',
                value: 'black',
            // }, {
            //     label: '棕',
            //     value: 'brown',
            }]
        }, {
            key: 0,
            label: '其他',
            options: [{ label: '全部', value: 'all' }]
        }]
    }

    const optFactory = (opt, placeholder) => {
        return {
            mode: "multiple",
            allowClear: true,
            showSearch: false,
            style: {width: props.width || props.showLabel ? 'calc(100% - 60px)' : '100%'},
            size: 'small',
            maxTagCount: 'responsive',
            placeholder: placeholder,
            options: opt
        }
    }

    const sexOpts = [{
        value: 'female',
        label: '女',
    }, {
        value: 'male',
        label: '男',
    }]

    const ageOpts = [{
        value: 'adult',
        label: '成人',
    }, {
        value: 'children',
        label: '儿童',
    // },  {
    //     value: 'oldman',
    //     label: '老年',
    }]

    const skinOpts = [{
        label: '黄',
        value: 'yellow',
    }, {
        label: '白',
        value: 'white',
    }, {
        label: '黑',
        value: 'black',
    // }, {
    //     label: '棕',
    //     value: 'brown',
    }]

    const postureOpts = [{
        label: '全部',
        value: ''
    }]

    const deleteFromList = (arr, target) => {
        return arr.filter(item => item != target)
    }

    const selectValue = useMemo(() => {
        return sex.concat(age).concat(skin)
    }, [sex, age, skin])

    const onSelectDeselect = (value) => {
        if (['male', 'female'].includes(value)) updateConfigModelFilter({sex: deleteFromList(sex, value)})
        else if (['adult', 'children'].includes(value)) updateConfigModelFilter({age: deleteFromList(age, value)})
        else updateConfigModelFilter({skin: deleteFromList(skin, value)})
    }

    const onSelectClear = () => {
        updateConfigModelFilter({
            sex: [],
            age: [],
            skin: []
        })
    }

    useEffect(() => {
        setSkin([...globalModelFilter?.skin || []])
        setSex([...globalModelFilter?.sex || []])
        setAge([...globalModelFilter?.age || []])
        setPosture([...globalModelFilter?.posture || []])
    }, [globalModelFilter])
    
    const updateConfigModelFilter = (filter) => {
        setGlobalModelFilter(pre => ({ ...pre, ...filter }))
    }

    const handleChange = (value, key) => {
        switch(key) {
            case 0: // all
                updateConfigModelFilter({
                    sex: ['male', 'female'],
                    age: ['adult', 'children'],
                    skin: ['yellow', 'white', 'black', 'brown']
                })
                break
            case 1:
                if (sex.includes(value)) updateConfigModelFilter({sex: deleteFromList(sex, value)})
                else updateConfigModelFilter({sex: [...sex, value]})
                break;
            case 2:
                if (age.includes(value)) updateConfigModelFilter({age: deleteFromList(age, value)})
                else updateConfigModelFilter({age: [...age, value]})
                break;
            case 3:
                if (skin.includes(value)) updateConfigModelFilter({skin: deleteFromList(skin, value)})
                else updateConfigModelFilter({skin: [...skin, value]})
                break;
            case 4:
                setType([value]);
                break;
            default:
                break;
        }
    };

    const handleChange2 = (value, key) => {
        switch(key) {
            case 1:
                updateConfigModelFilter({sex: [...value]})
                break;
            case 2:
                updateConfigModelFilter({age: [...value]})
                break;
            case 3:
                updateConfigModelFilter({skin: [...value]})
                break;
            case 4:
                updateConfigModelFilter({posture: [...value]})
                break;
        }
    }

    const tagRender = (props) => {
        const { label } = props;
        return <span className='ModelFilterSelect_tag'>{label}</span>
    }

    const checkboxOptFactory = (opts) => {
        return {
            options: opts
        }
    }


    // const selectRandomClick = () => {
    //     props.selectRandom()
        
    // }
    
    return (
        <Row className='ModelFilterSelect' style={{maxWidth: '800px'}} gutter={10}>
            {
                !props.showLabel &&
                <>
                <Col span={8}>
                    { props.showLabel && <label htmlFor="modal-sex">性别</label>}
                    <Select id='modal-sex' {...optFactory(sexOpts, '性别')} value={sex} onChange={v => handleChange2(v, 1)} tagRender={tagRender} />
                </Col>
                <Col span={8}>
                    { props.showLabel && <label htmlFor="modal-skin">肤色</label>}
                    <Select id='modal-skin' {...optFactory(skinOpts, '肤色')} value={skin} onChange={v => handleChange2(v, 3)} tagRender={tagRender} />
                </Col>
                <Col span={8}>
                    { props.showLabel && <label htmlFor="modal-age">年龄</label>}
                    <Select id='modal-age' {...optFactory(ageOpts, '年龄')} value={age} onChange={v => handleChange2(v, 2)} tagRender={tagRender} />
                </Col>
                {/* <Col span={6}>
                    { props.showLabel && <label htmlFor="modal-posture">体态</label>}
                    <Select id='modal-posture' {...optFactory(postureOpts, '体态')} value={posture} onChange={v => handleChange2(v, 4)} tagRender={tagRender} />
                </Col> */}
                {/* {
                    !props.tryOn?<Col span={6}>
                        <span onClick={selectRandomClick} style={{background: props.selectedImg == '00_default_style'?'blue': null}}>随机</span>
                    </Col>: null
                } */}
                
                </>
            }
            {
                props.showLabel &&
                <>
                <div className='checkbox-wrap'>
                    <img src={require('@assets/images/icon_gender.png')} alt="" />
                    <Checkbox.Group {...checkboxOptFactory(sexOpts, '性别')} value={sex} onChange={v => handleChange2(v, 1)} />
                </div>
                <div className='checkbox-wrap'>
                    <img src={require('@assets/images/icon_Color.png')} alt="" />
                    <Checkbox.Group {...checkboxOptFactory(skinOpts, '肤色')} value={skin} onChange={v => handleChange2(v, 3)} />
                </div>
                <div className='checkbox-wrap'>
                    <img src={require('@assets/images/icon_age group.png')} alt="" />
                    <Checkbox.Group {...checkboxOptFactory(ageOpts, '年龄')} value={age} onChange={v => handleChange2(v, 2)} />
                </div>
                <div className='checkbox-wrap'>
                    <img src={require('@assets/images/icon_Body.png')} alt="" />
                    <Checkbox.Group {...checkboxOptFactory(postureOpts, '体态')} value={posture} onChange={v => handleChange2(v, 4)} />
                </div>
                </>
            }
            
            {/* <label htmlFor="modal-type">模特类型</label>
            <Select
                id="modal-type"
                {...selectOptions}
                onDeselect={onSelectDeselect}
                onClear={onSelectClear}
                value={selectValue}
                dropdownRender={() => (
                    selectOptions.options.map((group, index) => (
                        <div key={group.key}>
                            {group.options.map(option => (
                                <div className={['custom_select_item', selectValue.includes(option.value) && 'active'].join(' ')}
                                    key={group.key+option.value}
                                    onClick={() => handleChange(option.value, group.key)}
                                >
                                    <span className='custom_select_item_content'>{option.label}</span>
                                    {selectValue.includes(option.value) && <CheckOutlined className="custom_select_item_state"/>}
                                </div>
                            ))}
                            {index != selectOptions.options.length - 1 && <Divider style={{ margin: '8px 0' }}/>}
                        </div>
                    ))
                )}
            /> */}
        </Row>
    )
}

export default ModelFilterSelect