
import { useEffect, useRef } from "react"
import styles from './MattingOpt.module.scss'
import { Button, Space } from "antd";
import { DragOutlined, MinusOutlined, PlusOutlined, ReloadOutlined } from '@ant-design/icons';

const DragAndScale = (props) => {
    const {
        moveable,
        zoom,
        onMoveableReverse,
        zoomOut,
        zoomIn,
        onReset,
    } = props

    const moveableRef = useRef(moveable)
    useEffect(() => {
        moveableRef.current = moveable
    }, [moveable])
    const handleKeydown = (ev) => {
        if (ev.key == ' ' || ev.code == 'Space' || ev.keyCode == 32) { // 空格键+鼠标 可拖动图片
            if (!moveableRef.current) onMoveableReverse()
        }
    }
    const handleKeyup = (ev) => {
        if (ev.key == ' ' || ev.code == 'Space' || ev.keyCode == 32) {
            if (moveableRef.current) onMoveableReverse()
        }
    }
    useEffect(() => {
        document.addEventListener('keydown', handleKeydown);
        document.addEventListener('keyup', handleKeyup)
        return () => {
            document.removeEventListener('keydown', handleKeydown);
            document.removeEventListener('keyup', handleKeyup)
        }
    }, [])

    return (
        <div className={styles.DragAndScale}>
            <Button type={moveable ? 'primary' : 'default'} size="small" icon={<DragOutlined />} onClick={onMoveableReverse} />
            <Space.Compact>
                <Button size="small" icon={<MinusOutlined />} onClick={zoomOut}></Button>
                <div className={styles.scale_label}>{Math.floor(zoom * 100)}%</div>
                <Button size="small" icon={<PlusOutlined />} onClick={zoomIn}></Button>
            </Space.Compact>
            <Button size="small" icon={<ReloadOutlined />} onClick={onReset} />
        </div>
    )
}

export default DragAndScale