import styles from './index.module.scss'
import { Upload, Button, Divider, Input, message, Carousel, Select, Checkbox } from 'antd';
import React, {useState, useEffect, useRef, useMemo, useContext} from 'react';
import API from '@api/api'
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import Utils from '@utils/utils'
import { IMAGE_TYPE } from "@utils/CONST"
import StrengthSlider from './components/StrengthSlider.jsx';
import VersionList from './components/VersionList.jsx';
import PSAIModal from '@PSAIComponents/PSAIModal';
import { GenerateContext } from "@utils/GenerateContext"
import CustomSceneForCommodity from './customSceneForCommodity';
import CustomSceneForModel from './customSceneForModel'
const { TextArea } = Input;

const CustomSceneForOld = (props) => {
    // 测试用
    const [positivePrompt, setPositivePrompt] = useState("")
    const [bkgUrl, setBkgUrl] = useState("")
    const defaultPositivePrompt = useRef("Tranquil countryside, (farmhouses), wide open spaces, unspoiled landscapes")
    const defaultBkgUrl = useRef("https://photostudio-ai.oss-cn-hangzhou.aliyuncs.com/photostudio-web/admin/default/bkg_url.png")
    const definedFlag = useMemo(() => {
        // 自定义提示词的地方
        // 0 都不改动
        // 1 只改背景
        // 2 只改提示词（负向提示词先注释掉）
        // 3 两个都改掉
        const bgChanged = (bkgUrl && bkgUrl != defaultBkgUrl.current)
        const promptChanged = (positivePrompt.trim() && positivePrompt.trim() != defaultPositivePrompt.current)
        let flag = 0
        if (bgChanged && promptChanged) {
            flag = 3
        } else if (bgChanged) {
            flag = 1
        } else if (promptChanged) {
            flag = 2
        }
        return flag
    }, [positivePrompt, bkgUrl])
    const submitPositivePrompt = useMemo(() => {
        if (!positivePrompt.trim()) {
            return defaultPositivePrompt.current
        }
        else {
            localStorage.setItem('positive_prompt_test', positivePrompt.trim())
            return positivePrompt.trim()
        }
    }, [positivePrompt])
    const submitBkgUrl = useMemo(() => {
        if (!bkgUrl) {
            return defaultBkgUrl.current
        }
        else {
            localStorage.setItem('bkg_url_test', bkgUrl)
            return bkgUrl
        }
    }, [bkgUrl])
    const getLastRecordPositivePrompt = () => {
        setPositivePrompt(localStorage.getItem('positive_prompt_test') || '')  
    }
    const getLastRecordBkgUrl = () => {
        setBkgUrl(localStorage.getItem('bkg_url_test') || '')  
    }
    const resetBkgUrl = () => {
        setBkgUrl('')
    }
    // 测试用-end

    useEffect(() => {
        return () => {
            props.onChange({
                definedFlag: 0,
                positivePrompt: '',
                refImg: '',
            })
        }
    }, [])
    useEffect(() => {
        props.onChange({
            definedFlag: definedFlag,
            positivePrompt: submitPositivePrompt,
            refImg: submitBkgUrl
        })
    }, [definedFlag, submitPositivePrompt, submitBkgUrl])

    // 上传bkg_url, 测试用
    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/webp';
        if (!isJpgOrPng) {
          message.warning('您只能上传JPG/PNG/WEBP格式的文件！');
        } else {
            Utils.uploadFile(file).then(url => {
                if (!url) return
                setBkgUrl(url)
            })
        }
        return false
    }

    return (
        <div className={styles.custom} >
            <p className={styles.custom_p}>
                defined_flag：{definedFlag}
            </p>
            <p className={styles.custom_p}>提示 <Button type="link" size='small' onClick={getLastRecordPositivePrompt}>last record</Button></p>
            <TextArea rows={6} placeholder="请输入提示" value={submitPositivePrompt} onChange={e => setPositivePrompt(e.target.value)}/>
            {
                props.sceneType != IMAGE_TYPE.COMMODITY &&
                <>
                    <div className={styles.custom_p}>bkg_url <Button type="link" size='small' onClick={getLastRecordBkgUrl}>last record</Button><Divider type="vertical" /><Button type="link" size='small' onClick={resetBkgUrl}>reset</Button></div>
                    <Upload
                        name="avatar"
                        listType="picture-card"
                        showUploadList={false}
                        beforeUpload={beforeUpload}
                    >
                        <img
                            src={submitBkgUrl}
                            alt="avatar"
                            style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                        />
                    </Upload>
                </>
            }
        </div>
    )
}

// const CustomSceneForModel = (props) => {
//     const [positivePrompt, setPositivePrompt] = useState(props.positivePrompt)
//     const [refImg, setRefImg] = useState(props.bkgUrl)
//     const [strength, setStrength] = useState(props.refStrength || 0.5)

//     useEffect(() => {
//         return () => {
//             props.onChange({
//                 definedFlag: 0,
//                 positivePrompt: '',
//                 refImg: '',
//                 refImgSize: null,
//                 refStrength: .5,
//             })
//         }
//     }, [])

//     useEffect(() => {
//         props.onChange({
//             definedFlag: 1,
//             positivePrompt: positivePrompt,
//             refImg: refImg,
//             refImgSize: null,
//             refStrength: strength,
//         })
//     }, [positivePrompt, refImg, strength])


//     const beforeUpload = async (file) => {
//         // AAA
//         const url = await Utils.uploadFile(file)
//         if (!url) return false
//         setRefImg(url)
//         image2Text(url)
//         return false
//     }

//     const image2Text = (image_path) => {
//         return API.image2Text({
//             image_path: image_path
//         }).then(res => {
//             if(res.code == 0) {
//                 setPositivePrompt(res.data)
//             } else {
//                 message.error(res.message)
//             }
//         })
//     }

//     return (
//         <div className={styles.custom} >
//             <p className={styles.custom_p}>提示</p>
//             <TextArea rows={6} placeholder="请输入提示" value={positivePrompt} onChange={e => setPositivePrompt(e.target.value)} />
//             <p className={styles.custom_p}>背景参考图</p>
//             <Upload
//                 name="avatar"
//                 listType="picture-card"
//                 showUploadList={false}
//                 beforeUpload={beforeUpload}
//             >
//             {
//                 refImg ? 
//                 <img
//                     src={refImg}
//                     alt="avatar"
//                     style={{ width: '100%', height: '100%', objectFit: 'contain' }}
//                 /> : 
//                 <span></span>
//             }
//             </Upload>
//             <p className={styles.custom_p}></p>
//             <StrengthSlider title="相似度" key={'strength'} version={2} value={strength} onChange={setStrength} showTooltip />
//         </div>
//     )
// }

const CustomScene = (props) => {
    // console.log('props.sceneType', props)
    return (
        props.sceneType == IMAGE_TYPE.COMMODITY ? 
            <CustomSceneForCommodity {...props} /> :
            props.sceneType == IMAGE_TYPE.MODEL || props.sceneType == IMAGE_TYPE.DRESSFORM?
                <CustomSceneForModel {...props} /> :
                <CustomSceneForOld {...props} />
            
    )
}

export default CustomScene