import React, { useState, useMemo, useRef, useEffect } from 'react';
import '../../aiEdit.scss'
import '../../components/fillLight/fillLight.scss'
import { Slider, Button} from 'antd';
import {forwardRef, useImperativeHandle} from 'react'
import { useContext } from 'react'
import { AIEditContext } from '../../AIEditContext';

const FillLightBar = forwardRef((props, ref) => {
    const {
        adjustLightFlag,
        highDefult, setHighDefult,
        rangeDefult, setRangeDefult
    } = useContext(AIEditContext)

    const predictList = [
        // {url: 'automatic lighting.png', style: 'random', name: '自动补光'}, 
        {url: '002胶片.jpg', style: 'film', name: '胶片'}, 
        {url: '002奶油.jpg', style: 'milk', name: '奶油'}, 
        {url: '002黑白.jpg', style: 'black' , name: '黑白'}
    ];
    const [preset, setPreset] = useState()

    const selectPreset = (index, style) => {
        setPreset(index)
        props.selectPreset(index, style)
    }

    return (
        <div className='edit_cont FillLight'>
            <div style={{paddingTop: '30px'}} className={`edit_center ${!props.url && 'disabled'}`}>
                {
                    props.isFilter?<>
                        <div className='operation_box'>
                            {
                                predictList.map((item, index) => {
                                    return <div key={item.url}
                                            className={preset == index? "operation_box_img operation_box_img_": "operation_box_img" }
                                            onClick={() => selectPreset(index, item.style)}>
                                        <img src={require(`@assets/images/${item.url}`)} alt="" />
                                        <p className='scene_title'>{item.name}</p>
                                    </div>
                                })
                            }
                        </div>
                    </>: <div style={{paddingRight: '5px'}}>
                        <div className='btn_box'>
                            <Button disabled={!props.url} className='operation_btn operation_btn_' type="primary" onClick={() => selectPreset(0, 'random')}>自动补光</Button>
                            <Button disabled={!props.url} className='operation_btn operation_btn_' type="primary" onClick={() => props.lightLoadModule("light_intensity", 50, 'click')}>手动调整</Button>
                        </div>
                        
                        <p className='small_title'>光源强度</p>
                        <Slider min={10} disabled={adjustLightFlag} value={highDefult} tooltip={{formatter: null}} onChange={setHighDefult} onChangeComplete={(vale) => props.lightSettingChange(vale, 'high')} />
                        <p  className='small_title'>光源范围</p>
                        <Slider min={10} disabled={adjustLightFlag} value={rangeDefult} tooltip={{formatter: null}} onChange={setRangeDefult} onChangeComplete={(vale) => props.lightSettingChange(vale, 'range')} />
                    </div>
                }
                
                
            </div>
        </div>
    )
})

export default FillLightBar;
