import { useEffect, useRef, useState } from "react";

import API from '@api/api.js';
import eventBus from "@utils/eventBus";
import utils from "@utils/utils";
import { message } from "antd";

function useProjectDetail(props) {

    const { projectId, update } = props;

    const [projectDetail, setProjectDetail] = useState({});
    const [loading, setLoading] = useState(true);

    const taskListInProgessingRef = useRef([])

    // 失败的任务弹窗提醒
    const checkAndNotifyFailedTask = (list) => {
        const taskList = list?.taskList
        if (taskList) {
            for (let i = taskListInProgessingRef.current.length - 1; i >= 0; i--) {
                const taskId = taskListInProgessingRef.current[i]
                const task = taskList.find(item => item.taskId == taskId)
                if (task) {
                    if (task.status == 'failed' || task.status == 'succeeded') {
                        taskListInProgessingRef.current.splice(i, 1)
                        if (task.status == 'failed') {
                            const error = utils.parseErrorCode(task.errCode)
                            eventBus.emit('notification', '生成失败', error.message, 'error', error.code)
                        }
                    }
                }
            }
            const newTaskListInProgressing = taskList.filter(task => {
                return task.status != 'failed' &&
                    task.status != 'succeeded' &&
                    !taskListInProgessingRef.current.find(taskId => task.taskId == taskId)
            })
            taskListInProgessingRef.current = [
                ...taskListInProgessingRef.current,
                ...newTaskListInProgressing.map(task => task.taskId)
            ]
        }
    }

    useEffect(() => {
        if (!projectId || !update) return
        API.getProjectDetail({
            projectId: projectId,
        }).then(res => {
            if (!res || res?.code !== 0 || !res?.data) {
                console.log('get ProjectDetail error', res?.code, res?.message);
                if (res?.message) {
                    message.error(res.message)
                }
                setLoading(false);
                return
            }
            setLoading(false);
            const projectDetail = res?.data
            projectDetail.taskList = projectDetail.taskList.map(item => ({
                ...item,
                imageType: projectDetail.imageType,
                projectName: projectDetail.projectName,
            }))
            setProjectDetail(projectDetail);
            checkAndNotifyFailedTask(projectDetail)
        }).catch((error) => {
            setLoading(false);
            console.log(error)
        })
    }, [projectId, update]);

    return {
        projectDetail,
        loading
    }

}

export default useProjectDetail;
