import TaskItem from '@view/compoents/taskItem/taskItem';
import { Button, Image, message, Tooltip } from 'antd'
import { useNavigate } from 'react-router-dom';
import { AppContext } from '@utils/AppContext';
import './projectTask.scss'
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import Utils from '@utils/utils.js'
import API from '@api/api.js';
import PreviewImage from '@view/compoents/previewImage/previewImage';
import {workflowOfRebuild, workflowOfChange} from '@utils/workflowOfBuildTask';
import SelectModal from '@view/compoents/selectModal/selectModal'
import { IMAGE_TYPE, CLOTH_TYPE } from "@utils/CONST"
import eventBus from '@utils/eventBus';
import PSAIModal from '@PSAIComponents/PSAIModal';
import ReplicaForm from '@view/compoents/ReplicaForm'
import GroupTitleContent from '../../compoents/GroupTitleContent';

const ImagePreview = (props) => {
    const [visible, setVisible] = useState(false);

    return (
        <div style={{display: 'inline-flex'}}>
            <img
                src={props.src + '?x-oss-process=image/resize,m_lfit,h_200,w_200'}
                style={{
                    width: props.width || 'auto',
                    height: props.height || 'auto',
                    cursor: 'pointer',
                    borderRadius: '4px',
                    objectFit: 'cover',
                    marginLeft: '4px'
                }}
                onClick={() => setVisible(true)}
            />
            <Image
                style={{display: 'none'}}
                preview={{
                    visible,
                    src: props.src,
                    toolbarRender: () => null,
                    onVisibleChange: (value) => {
                        setVisible(value);
                    },
                }}
            />
        </div>
    )
}

function ProjectTask (props) {
    const {
        index,
        taskResult,
        imageType,
        projectName,
        taskPose,
        taskModel,
        taskBg,
        taskVideoAction,
        definedFlag,
        refImg,
        taskId,
        projectId,
        isSelected,
        selectedPics,
        setSelectedPics,
        taskStatus,
        taskProgress,
        waitingTime,
        bookmark, // 收藏页面
        keepPose,
        keepBkg,
    } = props


    const { forceUpdate, setGlobalLoading, globalModelFilter } = useContext(AppContext) // 触发更新flag
    const [previewVisible, setPreviewVisible] = useState(false) // 预览图片\相册
    const [previewCurrent, setPreviewCurrent] = useState()

    const rebuild = async () => {
        // 生成更多埋点
        const successTaskList = taskResult?.filter(item => item.status == 'succeeded') || []
        if (successTaskList.length == 0) {
            // 没有成功的任务时，不能“生成更多”，提醒用户等一会
            return message.info('现在没有完成的任务，请稍后重新操作')
        }
        const reviewSuccessTaskList = successTaskList.filter(item => item.reviewStatus != 2)
        if (reviewSuccessTaskList.length == 0) {
            // 当前图片涉嫌违规
            return message.info('当前图片涉嫌违规，请更换其他图片')
        }
        const successTask = reviewSuccessTaskList[0]
        Utils.useroplog(imageType, 'function', 'generateMore', {'taskId': successTask.taskId})
        const _taskId = successTask.taskId
        if (imageType == IMAGE_TYPE.COMMODITY_VIDEO) {
            setGlobalLoading('生成中')
            API.commodityVideoPrediction({ taskId: _taskId }).then(res => {
                if (!res || res.code != 0) {
                    if (res.code == -5012) {
                        eventBus.emit('not_enough_points')
                        return false
                    } else return message.warning(res?.message || '算法生成失败，请联系客服')
                }
                forceUpdate({})
                eventBus.emit('updatePower')
                message.info('生成中，请稍等片刻')
            }).finally(() => {
                setGlobalLoading(false)
            })
        } else if(imageType == IMAGE_TYPE.MODEL_VIDEO) {
            const version =  await API.getVersion()
            if(version) {
                try {
                    const res = await API.doTryonVideo({
                        taskId: successTask.taskId,
                        version: version.videoTryonVersion,
                    })
                    if (res.code != 0) {
                        if (res.code == -5012) {
                            eventBus.emit('not_enough_points')
                            return false
                        } else throw new Error(res.message)
                    }
                    forceUpdate({})
                    eventBus.emit('updatePower')
                    message.info('生成中，请稍等片刻')
                } catch (err) {
                    message.warning(err.message)
                    console.error(err)
                }
            }
        } else {
            setGlobalLoading('生成中')
            workflowOfRebuild(_taskId, imageType, projectName).then(res => {
                if (res) forceUpdate({})
            }).finally(() => {
                setGlobalLoading(false)
            })
        }
    }

    const previewItems = useMemo(() => {
        return taskResult ? taskResult.filter(item => !!item.image) : []
    }, [taskResult])

    const clickFn = (result) => {
        // console.log('open preview', result)
        const items = taskResult.filter(item => !!item.image)
        setPreviewVisible(true)
        setPreviewCurrent(items.findIndex(item => item.key == result.key))
    }

    const [replicaNum, setReplicaNum] = useState(4)
    const [replicaOpen, setReplicaOpen] = useState()
    const [replicaOf, setReplicaOf] = useState()
    const [replicaFiles, setReplicaFiles] = useState([])
    const replicaFn = (result) => {
        setReplicaOpen(true)
        setReplicaOf(result)
    }
    const handleReplicaChange = (files, num) => {
        setReplicaFiles(files)
        setReplicaNum(num)
    }
    const handleReplicaOk = () => {
        props.replicaCb(replicaOf, replicaFiles, replicaNum)
        setReplicaOpen(false)
    }

    const setMainPic = (result) => {
        API.setProjectMainPic({
            projectId: projectId,
            taskId: result?.taskId,
            imageId: result?.id
        }).then(res => {
            if (res?.code === 0 && res?.message === 'succeed') {
                message.success('封面设置成功')
            } else {
                message.error(res?.message || '设置主图失败，请联系管理员');
            }
        })
    }

    const [selectModalType, setSelectModalType] = useState()
    const [selectModalValue, setSelectModalValue] = useState()
    const [selectModalOpen, setSelectModalOpen] = useState(false)
    const [selectActionOpen, setSelectActionOpen] = useState(false)
    const selectedTaskIdRef = useRef()

    const changeModelFn = (value, taskId) => {
        setSelectModalType('Model')
        setSelectModalValue(value)
        setSelectModalOpen(true)
        selectedTaskIdRef.current = taskId
    }

    const changeBgFn = (value, taskId) => {
        // console.log('changeBgFn')
        setSelectModalType('Scene')
        setSelectModalValue(value)
        setSelectModalOpen(true)
        selectedTaskIdRef.current = taskId
    }

    const changeActionFn = (taskId) => {
        setSelectActionOpen(true)
        setVideoKey()
        selectedTaskIdRef.current = taskId
    }

    const selectModalSave = (item) => {
        // console.log('selectModalSave', value, selectedTaskIdRef.current)
        // tryon暂不支持更换姿势，这里先注释掉
        // const isSelfDefined = item.attr.skin == 'custom'
        // const selfDefinedImg = item.attr.tryOnImage
        setGlobalLoading('生成中')
        workflowOfChange(
            selectedTaskIdRef.current,
            imageType,
            projectName,
            selectModalType == 'Model' ? item.name : '',
            selectModalType == 'Scene' ? item.name : '',
            globalModelFilter,
            // isSelfDefined,
            // selfDefinedImg,
        ).then(res => {
            if (res) forceUpdate({jumpOnce: true})
        }).finally(() => {
            setGlobalLoading(false)
        })
    }

    const navigate = useNavigate()
    const reselectModel = () => {        
        const successTaskList = taskResult?.filter(item => item.status == 'succeeded') || []
        if (successTaskList.length == 0) {
            // 没有成功的任务时，不能“生成更多”，提醒用户等一会
            return message.info('现在没有完成的任务，请稍后重新操作')
        }
        const reviewSuccessTaskList = successTaskList.filter(item => item.reviewStatus != 2)
        if (reviewSuccessTaskList.length == 0) {
            // 当前图片涉嫌违规
            return message.info('当前图片涉嫌违规，请更换其他图片')
        }
        const successTask = reviewSuccessTaskList[0]
        const _taskId = successTask.taskId  

        Utils.cacheTaskInfo(_taskId, projectName, imageType).then(() => {
            localStorage.setItem('toured', 1) // 从我的项目中进入生成页面，不再弹新手引导
            navigate(`/home/homemain/${imageType}`, { state: {rebuild: true}})
        })
    }

    const [videoList, setVideoList] = useState([])
    const [videoKey, setVideoKey] = useState()
    useEffect(() => {
        modelvideoList()
    }, [])
    const modelvideoList = () => {
        API.modelvideoList().then(res => {
            if (res.code == 0) {
                setVideoList(res.data)
            } else {
                message.warning(res.message)
            }
        })
    }
    const handleOk = async () => {
        try {
            setGlobalLoading('生成中')
            const version = await API.getVersion()
            const res = await API.doTryonVideo({
                taskId: selectedTaskIdRef.current,
                version: version.videoTryonVersion,
                ctl_videoKey: videoKey?.name,
                custom: videoKey?.attr.custom ? true : false,
            })
            if (res.code != 0) {
                if (res.code == -5012) {
                    eventBus.emit('not_enough_points')
                    return false
                } else throw new Error(res.message)
            }
            setSelectActionOpen(false)
            forceUpdate({jumpOnce: true})
            eventBus.emit('updatePower')
            message.info('生成中，请稍等片刻')
        } catch (err) {
            message.warning(err.message)
            console.error(err)
        } finally {
            setGlobalLoading(false)
        }
    }

    const replicaCost = useMemo(() => {
        return replicaFiles.length * replicaNum * 10
    }, [replicaFiles, replicaNum])

    return (
        <div className='ProjectTask'>
            <div className="ProjectTask_top" id={`task-group-${index}`}>
            {
                bookmark ?
                <div className='ProjectTask_top_content bookmark'>
                    <i className='bookmark_icon'></i>
                    <span>收藏</span>
                </div> :
                <div className='ProjectTask_top_content'>
                    <div className='ProjectTask_top_content_left'>
                    {
                        imageType == IMAGE_TYPE.COMMODITY_VIDEO ? 
                        <>
                        <span className='title'>商品视频</span>
                        <span className='feature'>随机</span>
                        </> : imageType == IMAGE_TYPE.MODEL_VIDEO ? 
                        <>
                        <span className='title'>模特视频</span>
                        {/* {groupTitle} */}
                        </> : 
                        <>
                        <span className='title'>{imageType == IMAGE_TYPE.COMMODITY ? '场景组合' : '模特场景组合'}{index}</span>
                        <GroupTitleContent
                            imageType={imageType}
                            personTitle={taskModel}
                            backgroundTitle={taskBg}
                            videoTryonTitle={taskVideoAction}
                            refImg={refImg}
                            definedFlag={definedFlag}
                            keepPose={keepPose}
                            keepBkg={keepBkg}
                        />
                        </>
                    }
                    </div>
                    {
                        (imageType == IMAGE_TYPE.COMMODITY_VIDEO || imageType == IMAGE_TYPE.MODEL_VIDEO) ? "" :
                        <Tooltip placement="top" title={`${imageType == IMAGE_TYPE.COMMODITY ? '重选场景进行再次生成' : '重选模特和场景进行再次生成'}`}>
                            <Button className='again_btn' onClick={reselectModel}>{imageType == IMAGE_TYPE.COMMODITY ? '重选场景' : '重选模特/场景'}</Button>
                        </Tooltip>
                    }
                    <Tooltip placement="top" title={`${imageType == IMAGE_TYPE.COMMODITY ? '使用当前的场景进行再次生成' : imageType == IMAGE_TYPE.MODEL_VIDEO ?'使用当前的动作进行再次生成': '使用当前的服装、模特和场景进行再次生成'}`}>
                        <Button onClick={() => rebuild()}>生成更多</Button>
                    </Tooltip>           
                </div>
            }                
            </div>
            <div className="ProjectTask_content">
                <div className='ProjectTask_content_body'>
                {
                    taskResult.map((result) => (
                        <div className='ProjectTask_content_result' key={result.key} data-task={result.taskId}>
                            <TaskItem
                                key={result.key}
                                projectId={projectId}
                                taskId={result.taskId}
                                imageId={result.id}
                                imageType={imageType}
                                projectName={projectName}
                                downloadCb={() => donwloadOneTaskPic(result)}
                                clickFn={() => clickFn(result)}
                                setMainImgCb={() => setMainPic(result)}
                                changeModelCb={changeModelFn}
                                changeBgCb={changeBgFn}
                                changeActionCb={changeActionFn}
                                isSelected={isSelected}
                                checked={selectedPics.includes(result?.id)}
                                bookmarked={result.bookmark}
                                setSelectedPics={setSelectedPics}
                                menuOptions={{
                                    aiEdit: false,
                                    replica: imageType != IMAGE_TYPE.COMMODITY && imageType != IMAGE_TYPE.CLOTHES && imageType != IMAGE_TYPE.COMMODITY_VIDEO && imageType != IMAGE_TYPE.MODEL_VIDEO,
                                    changeModel: imageType != IMAGE_TYPE.COMMODITY && imageType != IMAGE_TYPE.COMMODITY_VIDEO && imageType != IMAGE_TYPE.MODEL_VIDEO,
                                    changeBg: imageType != IMAGE_TYPE.COMMODITY && imageType != IMAGE_TYPE.COMMODITY_VIDEO && imageType != IMAGE_TYPE.MODEL_VIDEO,
                                    changeAction: imageType == IMAGE_TYPE.MODEL_VIDEO,
                                    share: true,
                                    deleteTask: true,
                                }}
                                taskToolOptions={{
                                    bookmark: true,
                                    aiEdit: imageType != IMAGE_TYPE.COMMODITY_VIDEO && imageType != IMAGE_TYPE.MODEL_VIDEO,
                                    download: true,
                                    videoPlayer: imageType == IMAGE_TYPE.COMMODITY_VIDEO ||  imageType == IMAGE_TYPE.MODEL_VIDEO,
                                    hdPreview: !result.hdImage && imageType != IMAGE_TYPE.COMMODITY_VIDEO && imageType != IMAGE_TYPE.MODEL_VIDEO
                                }}
                                replicaFn={() => replicaFn(result)}
                                isProject={false}
                                loadingMask={props.loadingMaskMap?.[result.loadingMask]}
                                taskResult={result}
                            />
                        </div>
                    ))
                }
                </div>
            </div>
            <PreviewImage
                open={previewVisible}
                closeFunc={() => setPreviewVisible(false)}
                items={previewItems}
                current={previewCurrent}
                showBookmark={!bookmark}
                imageType={imageType}
                projectName={projectName}
            />
            <SelectModal
                tryOn={imageType == IMAGE_TYPE.CLOTHES && selectModalType == 'Pose'}
                sceneType={imageType}
                open={selectModalOpen}
                type={selectModalType}
                selectedName={selectModalValue}
                options={{showTitle: true}}
                onSave={selectModalSave}
                closeFunc={() => setSelectModalOpen(false)}
            />
             <PSAIModal
                open={selectActionOpen}
                maxWidth={800}
                title="选择动作"
                onOk={handleOk}
                onCancel={() => setSelectActionOpen(false)}
                okButtonProps={{
                    icon: <div style={{display: 'flex', alignItems: 'center'}}><img style={{width: '8px', height: '8px', marginRight: '5px'}} src={require('@assets/images/arcbean2.png')}/> 80</div>,
                    iconPosition: 'end'
                }}
            >
                <div className='reelect_box'>
                    {
                        videoList?.map((item) => {
                            return <video
                            className={videoKey?.name == item.name ? 'video_select': null}
                            onClick={() => setVideoKey(item)}
                            src={item.imageUrl}
                            key={item.id}
                            loop
                            autoPlay
                            muted
                            type="video/mp4"></video>
                        })
                    }
                </div>
            </PSAIModal>
            <PSAIModal
                title="复刻模特场景"
                open={replicaOpen}
                onOk={handleReplicaOk}
                onCancel={() => setReplicaOpen(false)}
                okButtonProps={{
                    icon: (
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <img style={{width: '8px', height: '8px', marginRight: '5px'}} src={require('@assets/images/arcbean2.png')}/> {replicaCost}
                        </div>
                    ),
                    iconPosition: 'end',
                    disabled: replicaFiles.length == 0
                }}
            >
                <ReplicaForm record={replicaOf} onChange={handleReplicaChange} />
            </PSAIModal>
        </div>
    )
}

export default ProjectTask