import { AppContext } from '@utils/AppContext';
import React, { useState, useContext, useEffect, useImperativeHandle, forwardRef } from 'react';
import API from '@api/api.js';
import styles from './oneTimePay.module.scss';
import { CloseOutlined } from '@ant-design/icons';
import { message, Divider } from 'antd';
import MemberPackage from '../memberPackage/memberPackage';
import { useNavigate } from 'react-router-dom';
import eventBus from '@utils/eventBus';
import Utils from '@utils/utils';

const OneTimePay = forwardRef((props, ref) => {
    const navigate = useNavigate()
    const [alipayOpen, setAlipayOpen] = useState(false)
    const [alipayItems, setAlipayItems] = useState()
    const [isPowerPackage, setIsPowerPackage] = useState(false)
    const [powerList, setPowerList] = useState([]);
    const [alipayDefaultSelectedIndex, setAlipayDefaultSelectedIndex] = useState(0)

    useImperativeHandle(ref, () => ({
        openrechargeClick
    }));

    useEffect(() => {
        powerPackages()
    }, [])

    const openrechargeClick = () => {
    }

    function powerPackages()  {
        return API.powerPackages({}).then((res) => {
            if(res.code == 0) {
                setPowerList(res.data)
                return res.data
            } else {
                message.error(res.message)
            }
        })
    }

    const powerPackageClick = (id, index) => {
        if(id) {
            if(!localStorage.getItem('token')) {
                message.warning('请先登录账号！')
                eventBus.emit('login', {
                    callback: logged => {
                        if (logged) powerPackageClick(id, index)
                        else console.log('取消了登录')
                    }
                })
                return false
            }
            powerPackages().then(res => {
                if (res) {
                    const items = {
                        name: '加油包',
                        list: res.map(item => ({
                            ...item,
                            numberText: item.powerAmount + '虹豆',
                            tips: '30天内有效',
                        }))
                    }
                    eventBus.emit('pc_prepay', items, true, index, () => eventBus.emit('updateUserInfo'))
                }
            })            
        }
    }


  
    return (
        <div className={styles.oneTimePay}>  
            <Divider className={styles.title}>加油包</Divider>
            <div className={styles.pay_box}>
                {
                    powerList.map((item, index) => {
                        return <div key={item.id} onClick={() => powerPackageClick(item.id, index)}  className={`${styles.pay_box_item} ${props.isBorder? styles.pay_box_item_border: null }`}>
                            <div className={styles.pay_left}>
                                <div className={styles.count_box}>
                                    <img className={styles.img1} src={require('@assets/images/arcbean.png')} alt="" /> {Utils.addCommasToNumber(item.powerAmount)} 虹豆
                                </div>
                                <p className={styles.tip}>30天有效</p>

                            </div>
                            <div className={styles.pay_right}>
                                <span><span>￥</span>{Utils.addCommasToNumber(item.price)}</span>
                            </div>
                            {/* <span className={styles.span_1}>30天内有效</span>
                            <span className={styles.span_2}>¥ {Math.ceil(item.price)}</span>
                            <p className={styles.p_1}>
                                <span className={styles.span_3}></span>
                                <span className={styles.span_4}>{item.powerAmount}</span>
                                <span className={styles.span_5} onClick={() => powerPackageClick(item.id, index)} ><span></span>立即购买</span>
                            </p> */}
                        </div>
                    })
                }
            </div>
        </div>
    )
    
})

export default OneTimePay