import useResizeObserver from '@utils/useResizeObserver'
import { useContext, useEffect, useRef, useState } from 'react'
import styles from './index.module.scss'
import Utils from '@utils/utils'
import ToolBox from '../../components/ToolBox/ToolBox';
import Crop from '@view/compoents/crop/crop';
import PSAIModal from '@PSAIComponents/PSAIModal';
import { GenerateContext } from '@utils/GenerateContext';
import { Spin, message } from 'antd';

const VideoImage = (props) => {
    const [deskWidth, setDeskWidth] = useState(0)
    const [deskHeight, setDeskHeight] = useState(0)
    const [imgSize, setImgSize] = useState({ widt: 0, height: 0})
    const {img4Video, setImg4Video} = useContext(GenerateContext)

    const targetRef = useResizeObserver((entries) => {
        for (let entry of entries) {
            // 处理尺寸变化的逻辑
            setDeskWidth(entry.contentRect.width)
            setDeskHeight(entry.contentRect.height)
        }
    })

    useEffect(() => {
        if (!(deskWidth * deskHeight)) return
        Utils.getImageDimensions(Utils.getImgUrlWithWebp(img4Video)).then(({width, height}) => {
            const size = Utils.getImgMaxSizeInContainer(
                width,
                height,
                deskWidth,
                deskHeight
            )
            setCropParams({src: img4Video, width, height})
            setImgSize({
                width: size.width,
                height: size.height
            })
        })  
    }, [deskWidth, deskHeight, img4Video])

    const reUpload = (file) => {
        props.onUpload(file)
    }

    const [isModal, setIsModal] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false);
    const cropRef = useRef()
    const [cropParams, setCropParams] = useState({
        height: '',
        width: '',
        src: ''
    })
    const cropOk = async () => {
        console.log('cropOk')
        const {x, y, width, height} = cropRef.current.getCropResult()
        const cropResult = {x, y, width, height}
        setConfirmLoading(true)
        try {
            const base64 = await Utils.cropImageBase64(cropParams.src, cropResult.x, cropResult.y, cropResult.width, cropResult.height)
            let file = Utils.convertBase64ToFile(base64)
            const url = await Utils.uploadFile(file)
            if (!url) return false
            setImg4Video(url)
            setIsModal(false)
        } catch (err) {
            message.warning(err.message)
        } finally {
            setConfirmLoading(false)
        }
    }

    return (
        <div className={styles.Container}>
            <div className={styles.DeskWrap} ref={targetRef}>
            {
                (imgSize.width * imgSize.height) ?
                <div className={styles.ImgDesk} style={{ width: imgSize.width, height: imgSize.height }}>
                    <img src={Utils.getImgUrlWithWebp(img4Video)} />
                    <div className={styles.ToolBox}>
                        <ToolBox
                            mattingDisabled={true}
                            composeDisabled={true}
                            reUpload={reUpload}
                            composeOpt={() => setIsModal(true)}
                        />
                    </div>
                </div> :
                <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Spin size="large"/>
                </div>
            }
                <PSAIModal
                    title='编辑参考图尺寸'
                    open={isModal}
                    maskClosable={false}
                    closable={false}
                    maxWidth={548}
                    onCancel={() => setIsModal(false)}
                    confirmLoading={confirmLoading}
                    onOk={cropOk}
                >
                    <div style={{padding: '20px'}}>
                        <Crop ref={cropRef} cropParams={cropParams} ratioOnly />
                    </div>
                </PSAIModal>
            </div>
        </div>
    )
}

export default VideoImage