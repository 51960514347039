import React, { useState, useMemo, useRef, useEffect } from 'react';
import '../../aiEdit.scss'
import '../../components/magicErase/magicErase.scss'
import { Slider, Button} from 'antd';
import {forwardRef, useImperativeHandle} from 'react'
import icon_Eraser from '@assets/images/pen_2.png'
import { useContext } from 'react'
import { AIEditContext } from '../../AIEditContext';
import pen1 from '@assets/images/pen_1.png';
import pen2 from '@assets/images/pen_2.png';
import rect1 from '@assets/images/rect_1.png';
import rect2 from '@assets/images/rect_2.png';
import circle1 from '@assets/images/circle_1.png';
import circle2 from '@assets/images/circle_2.png';

const MagicErase = forwardRef((props, ref) => {
    const { eraserSize, operationKey, isDraw, isLoading2, setEraseType, eraseType} = useContext(AIEditContext)
    const btnPicList = [
        [pen1, pen2, '涂抹', 'draw'],
        [rect1, rect2, '框选', 'rect'],
        [circle1, circle2, '自由圈选', 'circle'],
    ];

    return (
        <div className='edit_cont MagicErase'>
            <div className={`edit_center ${!props.url && 'disabled'}`}>
                <div className='magicEraseBar_btn'>
                    {
                        btnPicList.map((item) => {
                            return  <div key={item[0]} onClick={() => setEraseType(item[3])} className={`magicEraseBar_btn_  ${eraseType == item[3]? 'magicEraseBar_btn_selected': null}`}>
                                <img src={eraseType == item[3]?item[1] :item[0]} alt="" />
                                <p>{item[2]}</p>
                            </div>
                        })
                    }
                   
                </div>
                <p className='title'>画笔大小</p>
                <div className='operation_box'>
                    <i onClick={() => props.handleDraw('eraser')} style={{backgroundImage: `url(${icon_Eraser})`}}></i>
                    <Slider value={eraserSize} min={1} defaultValue={eraserSize} tooltip={{formatter: null}} onChange={(vale) => props.repairSizeChange(vale, 'eraser')} />
                    <span className='size'>{eraserSize}</span>
                </div>
                <Button
                    disabled={isDraw.length == 0 || operationKey != 'eraser'}
                    className={isDraw.length > 0 && operationKey == 'eraser'?'operation_btn': 'operation_btn operation_btn_forbid'}
                    type="primary"
                    loading={isLoading2}
                    onClick={() => isDraw.length > 0  && operationKey == 'eraser'?props.createClick('0'): null}
                >
                    应用擦除
                </Button>
            </div>
        </div>
    )
})

export default MagicErase;
