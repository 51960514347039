import { useEffect, useRef, useState } from "react"

const useCanvasMove = (props) => {
    const { instance, otherInstances } = props
    // 是否可以移动
    const moveableRef = useRef(props.moveable)
    // 是否允许移动
    const moveAllowedRef = useRef(props.moveAllowed)
    // 是否允许缩放
    const zoomAllowedRef = useRef(props.zoomAllowed)
    // 定义变量来保存初始状态
    const isDraggingRef = useRef(false)
    const lastPosXRef = useRef(0)
    const lastPosYRef = useRef(0)
    const lastScaleRef = useRef(1)
    const [lastScale, setLastScale] = useState(1)
    const zoomToPointRef = useRef({x: 0, y: 0})

    useEffect(() => {
        if (!instance) return
        moveableRef.current = props.moveable
        if (props.moveable) {
            instance.defaultCursor = 'move'
            instance.getObjects().forEach(item => item.hoverCursor = 'move')
        } else {
            instance.defaultCursor = 'default'
            instance.getObjects().forEach(item => item.hoverCursor = 'default')
        }
    }, [props.moveable])

    useEffect(() => {
        moveAllowedRef.current = props.moveAllowed
    }, [props.moveAllowed])

    useEffect(() => {
        zoomAllowedRef.current = props.zoomAllowed
    }, [props.zoomAllowed])

    const resetCanvas = () => {
        if (!instance) return
        instance.viewportTransform = [1,0,0,1,0,0] // 重置canvas位置
        otherInstances.forEach(canvas => canvas.viewportTransform = [1,0,0,1,0,0]) // 重置canvas位置
        instance.forEachObject(obj => obj.setCoords())
        otherInstances.forEach(canvas => {
            canvas.forEachObject(obj => obj.setCoords())
        })
        instance.renderAll()
        otherInstances.forEach(canvas => canvas.renderAll())
        if (props.zoomResetFunc) props.zoomResetFunc()
    }

    const resizeCanvas = (viewportTransform) => {
        instance.viewportTransform = viewportTransform // 重置canvas位置
        otherInstances.forEach(canvas => canvas.viewportTransform = viewportTransform) // 重置canvas位置
        instance.forEachObject(obj => obj.setCoords())
        otherInstances.forEach(canvas => {
            canvas.forEachObject(obj => obj.setCoords())
        })
        instance.renderAll()
        otherInstances.forEach(canvas => canvas.renderAll())
    }

    useEffect(() => {
        if (!instance) return
        zoomToPointRef.current = { x: instance.width / 2, y: instance.height / 2 }
        // 监听鼠标按下事件
        instance.on('mouse:down', (ev) => {
            if (!moveAllowedRef.current) return
            if (!moveableRef.current) return
            // 仅在按下鼠标左键时触发
            if (ev.e.button === 0) {
                isDraggingRef.current = true;
                lastPosXRef.current = ev.e.clientX;
                lastPosYRef.current = ev.e.clientY;
                lastScaleRef.current = instance.getZoom();
                setLastScale(lastScaleRef.current)
            }
        })
        // 监听鼠标移动事件
        instance.on('mouse:move', function(event) {
            if (!moveAllowedRef.current) return
            if (!moveableRef.current) return
            if (isDraggingRef.current) {
                const currentPosX = event.e.clientX;
                const currentPosY = event.e.clientY;
            
                // 计算移动的距离
                const deltaX = currentPosX - lastPosXRef.current;
                const deltaY = currentPosY - lastPosYRef.current;
            
                // 移动画布
                instance.viewportTransform[4] += deltaX;
                instance.viewportTransform[5] += deltaY;

                otherInstances.forEach(canvas => {
                    canvas.viewportTransform[4] += deltaX;
                    canvas.viewportTransform[5] += deltaY;
                })

                instance.forEachObject(obj => obj.setCoords())
                otherInstances.forEach(canvas => {
                    canvas.forEachObject(obj => obj.setCoords())
                })
            
                // 更新画布显示
                instance.requestRenderAll();
                otherInstances.forEach(canvas => canvas.requestRenderAll())
            
                // 更新上一次位置
                lastPosXRef.current = currentPosX;
                lastPosYRef.current = currentPosY;
            }
        })
        // 监听鼠标释放事件
        instance.on('mouse:up', function(event) {
            // 仅在释放鼠标左键时触发
            if (event.e.button === 0) {
                isDraggingRef.current = false;
            }
        })
        // 监听鼠标滚轮事件
        instance.on('mouse:wheel', function(event) {
            if (!zoomAllowedRef.current) return

            // 设置缩放origin
            zoomToPointRef.current = { x: event.e.offsetX, y: event.e.offsetY }
            event.e.preventDefault();
            event.e.stopPropagation();
            
            // 根据滚轮滚动方向调整缩放比例
            let zoom = instance.getZoom()
            const delta = event.e.deltaY
            if (delta > 0) { // 缩小画布
                if (props.zoomOutFunc) props.zoomOutFunc()
            } else { // 放大画布
                if (props.zoomInFunc) props.zoomInFunc()
            }
        })
    }, [instance])


    useEffect(() => {
        if (!instance) return
        instance.zoomToPoint(zoomToPointRef.current, props.zoom);
        otherInstances.forEach(canvas => canvas.zoomToPoint(zoomToPointRef.current, props.zoom))

        instance.renderAll()
        otherInstances.forEach(canvas => canvas.renderAll())

        lastScaleRef.current = props.zoom
        setLastScale(props.zoom)
    }, [props.zoom, instance])

    return {
        zoom: lastScale,
        resetCanvas,
        resizeCanvas,
    }
}

export default useCanvasMove