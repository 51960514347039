import { useRef, useEffect } from "react";

const useResizeObserver = (callback) => {
    const targetRef = useRef()
  
    useEffect(() => {
        const resizeObserver = new ResizeObserver(callback);

        if (targetRef.current) {
            resizeObserver.observe(targetRef.current)
            // callback([{
            //     contentRect: {
            //         width: targetRef.current.clientWidth,
            //         heigth: targetRef.current.clientHeight
            //     }
            // }])
        }

        return () => {
            if (targetRef.current) resizeObserver.unobserve(targetRef.current)
        };
    }, []);
  
    return targetRef
};

export default useResizeObserver