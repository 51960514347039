import React, { useImperativeHandle, forwardRef, useRef, useState, useEffect, useContext, useMemo } from 'react';
import { Button, Empty, Upload, message } from 'antd';
import styles from './index.module.scss'
import PSAIModal from '@PSAIComponents/PSAIModal';
import API from '@api/api'
import { AppContext } from '@utils/AppContext';
import { HOME_PAGE_TYPE, IMAGE_TYPE } from '@utils/CONST';
import dayjs from 'dayjs';
import InfiniteScroll from 'react-awesome-infinite-scroll';
import Utils from '@utils/utils';
import useResizeObserver from '@utils/useResizeObserver';
import GoBack from '../goBack/goBack';

const ProjectItem = (props) => {
    // props.favorite
    return (
        <div className={styles.ProjectItem}>
            <div className={`${styles.ProjectItemCover} ${props.favorite && styles.favoriteBox}`}>
                {
                    props.favorite ?
                        props.inputImage ?
                        <img src={Utils.getImgUrlWithWebp(props.inputImage.split(',')[0], true)} /> :
                        <div className={styles.empty_img}></div> :
                    <img src={Utils.getImgUrlWithWebp(props.inputImage.split(',')[0], true)} />
                }
                {
                    props.inputImage ? 
                    <div className={styles.mask} onClick={props.onClick}>
                        <div className={styles.check_view}>查看合集</div>
                    </div> : ""
                }                
            </div>
            {
                props.favorite ? 
                <>
                <p className={styles.ProjectName}>
                    <i className={styles.favor_icon}></i>
                    <span>{props.projectName}</span>
                </p>
                <p className={styles.UpdatedTime}></p>
                </> :
                <>
                <p className={styles.ProjectName}>{props.projectName}</p>
                <p className={styles.UpdatedTime}>修改时间 {dayjs(props.updatedTime).format('YYYY/MM/DD HH:mm')}</p>
                </>
            }
        </div>
    )
}

const TaskItem = (props) => {
    return (
        <div
            className={`${styles.TaskItem} ${props.selectTaskId == props.taskId && styles.actived}`}
            onDoubleClick={props.onDoubleClick}
            onClick={props.onClick}
        >
            <img src={Utils.getImgUrlWithWebp(props.results[0].image, true)} />
        </div>
    )
}

const TaskList = forwardRef((props, ref) => {
    const [taskList, setTaskList] = useState([])
    const [selected, setSelected] = useState()
    const {globalHomePageType} = useContext(AppContext)
    
    const fetchType = useMemo(() => {
        if (globalHomePageType == HOME_PAGE_TYPE.MERCHANDISE) {
            return IMAGE_TYPE.COMMODITY
        } else if (globalHomePageType == HOME_PAGE_TYPE.CLOTHING) {
            return IMAGE_TYPE.MODEL
        }
    }, [globalHomePageType])

    useImperativeHandle(ref, () => ({
        onSubmit
    }))

    const onSubmit = async () => {
        try {
            if (!selected) return false
            let res = await API.commonDownload({taskId: selected})
            if (res?.code != 0) throw new Error(res?.message)
            const imgPath = res.data
            return imgPath
        } catch (err) {
            message.warning(err.message)
        }
    }

    const handleDoubleClick = async (item) => {
        const taskId = item.taskId
        try {
            let res = await API.commonDownload({taskId: taskId})
            if (res?.code != 0) throw new Error(res?.message)
            const imgPath = res.data
            props.onDoubleClick(imgPath)
        } catch (err) {
            message.warning(err.message)
        }
    }

    useEffect(() => {
        if (props.projectId == 'favorite') {
            API.getAllFavoriteList({
                type: fetchType, // model or commodity
                outputType: 'image'
            }).then(res => {
                if (res.code != 0) throw new Error(res.message)
                console.log('setFavoriteList', res.data.filter(item => item.results.length > 0))
                setTaskList(res.data.filter(item => item.results.length > 0))
            })
        } else {
            API.getProjectDetail({
                projectId: props.projectId,
            }).then(res => {
                console.log(res)
                if (res.code != 0) throw new Error(res.message)
                const taskList = res.data.taskList.filter(item => item.status == "succeeded" && item.results.length > 0)
                setTaskList(taskList.reverse())
            })
        }
    }, [])

    const onPickTask = (item) => {
        setSelected(item.taskId)
    }

    return (
        <div className={`${styles.ListContent2} _beauty_scrollbar`}>
            {
                taskList.map(item => (
                    <TaskItem
                        selectTaskId={selected}
                        key={item.taskId}
                        {...item}
                        onDoubleClick={() => handleDoubleClick(item)}
                        onClick={() => onPickTask(item)}
                    />
                ))
            }
        </div>
    )
})

const PickFromProject = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        onSubmit
    }));

    const onSubmit = () => {
        if (!projectId) return Promise.resolve(false)
        return taskListRef.current.onSubmit()
    }

    const taskListRef = useRef()
    const [projectId, setProjectId] = useState()
    const [projectName, setProjectName] = useState()
    const [projectList, setProjectList] = useState([])
    const pageIndexRef = useRef(1)
    const pageSizeRef = useRef(18)
    const [total, setTotal] = useState(0)
    const [hasMore, setHasMore] = useState()
    const {globalHomePageType} = useContext(AppContext)
    
    const fetchType = useMemo(() => {
        if (globalHomePageType == HOME_PAGE_TYPE.MERCHANDISE) {
            return IMAGE_TYPE.COMMODITY
        } else if (globalHomePageType == HOME_PAGE_TYPE.CLOTHING) {
            return IMAGE_TYPE.MODEL
        }
    }, [globalHomePageType])

    const fetchMoreData = async () => {
        console.log('==fetchMoreData==')
        // await Utils.delayFunc(500)
        pageIndexRef.current = pageIndexRef.current+1
        API.getAllProjectList({
            type: props.useImageType ? fetchType : "", // model or commodity
            pageIndex: pageIndexRef.current,
            pageSize: pageSizeRef.current,
            outputType: 'image'
        }).then(res => {
            if (res.code != 0) throw new Error(res.message)
            const next = projectList?.concat(res.data.items)
            setProjectList(next)
            setTotal(res.data.total)
            setHasMore(next.length < res.data.total)
            console.log('==fetchMoreData done==')
        }).catch(err => {
            message.warning(err.message)
        })
    }
    useEffect(() => {
        API.getAllProjectList({
            type: props.useImageType ? fetchType : "", // model or commodity
            pageIndex: pageIndexRef.current,
            pageSize: pageSizeRef.current,
            outputType: 'image'
        }).then(res => {
            console.log('res', res)
            if (res.code != 0) throw new Error(res.message)
            setProjectList(res.data.items)
            setTotal(res.data.total)
            setHasMore(res.data.items.length < res.data.total)
        }).catch(err => {
            message.warning(err.message)
        })
    }, [fetchType])

    const onPickProject = (project) => {
        setProjectId(project.id)
        setProjectName(project.projectName)
    }

    const onCancelProject = () => {
        setProjectId()
        setProjectName()
    }

    const [favoriteList, setFavoriteList] = useState([])
    useEffect(() => {
        API.getAllFavoriteList({
            type: fetchType,
            outputType: 'image'
        }).then(res => {
            if (res.code != 0) throw new Error(res.message)
            console.log('setFavoriteList', res.data.filter(item => item.results.length > 0))
            setFavoriteList(res.data.filter(item => item.results.length > 0))
        })
    }, [fetchType])
    const firstFavorite = useMemo(() => {
        console.log('favoriteList[0]', favoriteList[0])
        return favoriteList[0]
    }, [favoriteList])

    return (
        <div className={styles.ListContainer}>
            {
                projectName ?
                <div style={{display: 'flex'}}><GoBack goBack={onCancelProject} /><p className={styles.Title} style={{marginLeft: '14px'}}><span>{projectName}</span></p></div> :
                <p className={styles.Title}><span>我的项目</span></p>
            }            
            <div className={`${styles.ListContent} ${projectId && styles.Hidden} _beauty_scrollbar`}>
                <InfiniteScroll
                    length={projectList?.length}
                    next={fetchMoreData}
                    hasMore={hasMore}
                    scrollableParent={document.querySelector(styles.ListContent)}
                    // pullDownToRefresh
                    // isRefreshing={isRefreshing}
                    // refreshFunction={reload}
                    pullDownComponent={<div style={{ height: "50px", background: "green" }}>下拉</div>}
                    releaseComponent={<div style={{ height: "50px", background: "red" }}>释放</div>}
                    refreshingComponent={<div style={{ height: "50px", background: "green" }}>加载中</div>}
                    refreshEndComponent={<div style={{ height: "50px", background: "red" }}>加载完成</div>}
                    loadingComponent={<div style={{ height: "50px", lineHeight: "50px", textAlign: 'center' }}><h4>加载中...</h4></div>}
                    endComponent={
                        (projectList?.length) ?
                            <div style={{ height: "30px", lineHeight: "30px", textAlign: 'center', fontWeight: 'normal', color: '#999' }}>
                                <span>没有更多内容了</span>
                            </div> : <div className={styles.EmptyContent}><Empty /></div>
                    }
                >
                    {
                        projectList?.length ?
                        <ProjectItem
                            key={"favorite"}
                            favorite={true}
                            inputImage={firstFavorite ? firstFavorite.results[0].image : ""}
                            projectName="我的收藏"
                            onClick={() => onPickProject({id: 'favorite', projectName: '我的收藏'})}
                        /> : <></>
                    }
                    {
                        projectList.map(item => (
                            <ProjectItem key={item.id} {...item} onClick={() => onPickProject(item)} />
                        ))
                    }
                </InfiniteScroll> 
            </div>
            {
                !projectId ? "" : 
                <TaskList
                    ref={taskListRef}
                    projectId={projectId}
                    onDoubleClick={props.onDoubleClick}
                />
            }
        </div>
    )
})

export default PickFromProject