
import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle, useContext } from 'react';
import './modelScale.scss';
import { fabric } from 'fabric';
import { useNavigate }from "react-router-dom";
import Utils from '@utils/utils'
import ModelScaleMirror from './modelScale_mirror'
import { ExclamationCircleOutlined, CloseOutlined, LoadingOutlined } from '@ant-design/icons';
import { colorPrimary } from '@utils/AppContext';
import { Button, Spin } from 'antd';
import { GenerateContext } from '@utils/GenerateContext';

const ModelScale = forwardRef((props, ref) => {
    // const { setGlobalLoading } = useContext(AppContext);
    const navigate = useNavigate();
    const canvasRef = useRef(null);
    const canvas = useRef(null);
    const modelScaleMirrorRef = useRef(null);
    const [width, setWidth] = useState(512);
    const [height, setHeight] = useState(512);
    const [fixedWidth, setFixedWidth] = useState(400);
    const [rectangle, setRectangle] = useState(null);
    const [imageWidth, setImageWidth] = useState(0);
    const [imageHeight, setImageHeight] = useState(0);
    const scaleFactorValueRef = useRef()
    const [scaleFactorValue, setScaleFactorValue] = useState();
    const [isTip, setIsTip] = useState(true);
    const [resizeScreen, setResizeScreen] = useState(1);
    const isLoadingRef = useRef(false)
    const [isLoading, setIsLoading] = useState(false);
    const [pattern, setAattern] = useState();
    const initRectRef = useRef({ // 构图优化推荐区域
        initialLeft: 0,
        initialTop: 0,
        initialWidth: 0,
        initialHeight: 0,
    })
    const initImgRef = useRef({ // 原图尺寸区域
        initialLeft: 0,
        initialTop: 0,
        initialWidth: 0,
        initialHeight: 0,
    })
    const initRectBottomRef = useRef()

    const [resultrect, setResultrect] = useState({
        left: 0,
        top: 0,
        width: 0,
        height: 0,
    });
    const [rectChangeSide, setRectChangeSide] = useState({
        width: 0,
        height: 0,
    });

    const [canvasIsReady, setCanvasIsReady] = useState(false)
    const initializedRef = useRef(false) // 初始化完成

    const {
        originCropParamsRef,
        modelScaleRef,
        setModelScale,
    } = useContext(GenerateContext)

    const offsetValue = 0 // 矩形框外扩偏移，为了矩形框和图片不完全重合造成不美观，在镜像画布获取矩形框内容时要剪掉

    useImperativeHandle(ref, () => ({
        // 可以在这里定义其他自定义引用方法
        handleReset,
        handleClick,
    }));

    useEffect(() => {
        setWidth(props.size)
        setHeight(props.size)
        setFixedWidth(props.size * 0.95)
    }, [props.size])

    useEffect(() => {
        const handleResize = () => {
            setResizeScreen(window.devicePixelRatio)
        };
        window.addEventListener('resize', handleResize);
    
        // 在组件卸载时移除事件监听器
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
         // 创建矩形框背景图
        const patternImageBase64 = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABHNCSVQICAgIfAhkiAAAADBJREFUOE9jfPP69X8GPEBYRASfNAPjqAHDIgz+AwG+iH7z5s1ZfPKMowYwDIMwAACuR1xZvSaHFwAAAABJRU5ErkJggg==';
        const pattern = new fabric.Pattern({
            source: patternImageBase64,
            repeat: 'repeat',
        });
        setAattern(pattern)
    }, [])

    const [bgUrl, setBgUrl] = useState()

    useEffect(() => {
        if (!canvasIsReady) return
        if (!fixedWidth) return
        if (!initializedRef.current) {
            initializedRef.current = true
            fabric.Image.fromURL(props.modelScaleOriBase64, (img) => {
                const scaleFactor = fixedWidth / Math.max(img.width, img.height);
                img.scale(scaleFactor);
                
                scaleFactorValueRef.current = scaleFactor
                setScaleFactorValue(scaleFactor)
                
                // 马赛克
                const bgRect = new fabric.Rect({
                    fill: pattern,
                    left: 0,
                    top: 0,
                    width: canvas.current.width,
                    height: canvas.current.height,
                    selectable: false,
                })

                // 计算图像对象的位置
                const imgWidth = img.getScaledWidth();
                const imgHeight = img.getScaledHeight();
                const imgLeft = (canvas.current.width - imgWidth) / 2; // 图像左侧位置
                const imgTop = canvas.current.height - imgHeight; // 图像顶部位置


                // 设置img
                img.set({
                    scale: scaleFactor,
                    selectable: false,
                    is_img: true,
                    left: imgLeft,
                    top: imgTop
                });

                // canvas.current.centerObject(img);
                const group = new fabric.Group([bgRect, img], {selectable: false, is_img_group: true,})

                const dataURL = group.toDataURL({
                    format: 'png',
                    multiplier: 2 // 导出图像的倍数（可选）
                })

                setBgUrl(dataURL)
                // 灰色mask
                const maskRect = new fabric.Rect({
                    left: 0,
                    top: 0,
                    width: canvas.current.width,
                    height: canvas.current.height,
                    selectable: false,
                    hoverCursor: 'default',
                    fill: 'rgba(0,0,0,.45)'
                })
    
                // 根据构图算法坐标得到推荐区域相对于原图的宽高比例，便宜量
                // 取推荐构图坐标【w,h,x1,y1,x2,y2】
                const originCropParams = originCropParamsRef.current || [1303, 835, 0, 0, 1303, 835]
                const scaleCropWidth = (originCropParams[4] - originCropParams[2]) / originCropParams[0]
                const scaleCropHeight = (originCropParams[5] - originCropParams[3]) / originCropParams[1]
                const scaleCropLeft = originCropParams[2] * scaleFactor
                const scaleCropTop = originCropParams[3] * scaleFactor

                const rectWidth = img.width * scaleFactor * scaleCropWidth;
                const rectHeight = img.height * scaleFactor * scaleCropHeight;
                const rectLeft = ((canvas.current.width - imgWidth) / 2) + scaleCropLeft;
                const rectTop = canvas.current.height - imgHeight + scaleCropTop;
    
                initRectRef.current = { // 记录一下操作框的初始位置、大小
                    initialLeft: rectLeft,
                    initialTop: rectTop,
                    initialWidth: rectWidth,
                    initialHeight: rectHeight,
                }

                initImgRef.current = { // 记录原图尺寸区域的位置和大小
                    initialLeft: ((canvas.current.width - imgWidth) / 2),
                    initialTop: canvas.current.height - imgHeight,
                    initialWidth: img.width * scaleFactor,
                    initialHeight: img.height * scaleFactor,
                }
                
                const rect = new fabric.Rect({
                    // fill: pattern,
                    borderColor: colorPrimary,
                    borderScaleFactor: 3,
                    strokeWidth: 0,
                    strokeUniform: true, // 限制边框宽度缩放
                    cornerColor: '#ffffff',
                    cornerFill: '#ffffff', // 设置控制点的填充色为白色
                    cornerStrokeColor: colorPrimary, // 设置控制点的边框颜色为#00a0ff
                    transparentCorners: false, // 控制点是否为实心
                    cornerSize: 14,
                    resizable: true,
                    lockRotation: true,
                    lockSkewingX: true,
                    lockSkewingY: true,
                    cornerStyle: 'circle',
                    hoverCursor: 'move',
                    globalCompositeOperation: 'destination-out',
                    selectable: true,
                    is_rect: true,
                });      
                
                rect.setControlsVisibility({
                    mtr: false, // 隐藏旋转点
                });
    
                // 返回构图优化时还原选框区域
                if (modelScaleRef.current) {
                    rect.set(modelScaleRef.current)
                } else {
                    rect.set({
                        left: initRectRef.current.initialLeft, // 初始左侧位置
                        top: initRectRef.current.initialTop,   // 初始顶部位置
                        width: initRectRef.current.initialWidth, // 初始宽度
                        height: initRectRef.current.initialHeight, // 初始高度
                        scaleX: 1,
                        scaleY: 1,
                    });
                }
            
                canvas.current.setActiveObject(rect);
                canvas.current.on('mouse:down', () => { // 这为了保持点击画布区域，矩形框依然保持选中状态
                    canvas.current.setActiveObject(rect);
                });
    
                let lastLeft, lastTop, lastScaleX, lastScaleY
                canvas.current.on('object:scaling', (e) => { // 限制缩放超出画布
                    hideControlBtn();
    
                    const activeObject = e.target;

                    activeObject.setCoords()
                    const boundingRect = activeObject.getBoundingRect()
            
                    // 计算边界值
                    const leftBound = 0;
                    const rightBound = canvas.current.width;
                    const topBound = 0;
                    const bottomBound = initRectRef.current.initialTop + initRectRef.current.initialHeight
                    
                    if (!initRectBottomRef.current) {
                        initRectBottomRef.current = boundingRect.top + boundingRect.height
                    }
                    if (
                        boundingRect.left + boundingRect.width > rightBound ||
                        boundingRect.left + boundingRect.width <= initRectRef.current.initialLeft ||
                        boundingRect.left < leftBound ||
                        boundingRect.left >= initRectRef.current.initialLeft + initRectRef.current.initialWidth || 
                        boundingRect.top < topBound ||
                        boundingRect.top >= bottomBound || 
                        boundingRect.top + boundingRect.height > canvas.current.height
                    ) {
                        activeObject.set({
                            left: lastLeft,
                            top: lastTop,
                            scaleX: lastScaleX,
                            scaleY: lastScaleY
                        })
                    } else {
                        lastLeft = activeObject.left
                        lastTop = activeObject.top
                        lastScaleX = activeObject.scaleX
                        lastScaleY = activeObject.scaleY
                    }
                    canvas.current.renderAll();
                });
            
                canvas.current.on('after:render', () => { // 初始化时候就显示分辨率文字
                    const objects = canvas.current.getObjects() 
                    let rectObj = objects.find(obj => obj.get('is_rect'))
                    const imgGroup = objects.find(obj => obj.get('is_img_group'))
                    const imgObj = imgGroup.getObjects().find(obj => obj.get('is_img'))

                    if (rectObj) {
                        const changeScale = (imgObj.getScaledWidth() * imgGroup.scaleX) / img.width
                       
                        // 实时保存拖拽框大小
                        setRectChangeSide({
                            height: Math.round(rectObj.getScaledHeight() / changeScale),
                            width: Math.round(rectObj.getScaledWidth() / changeScale),
                        })
                        showControlBtn(rectObj)


                        //  // 限制拖出画布
                        if(rectObj?.top < 0) {
                            rectObj.set({
                                top: 0,
                            })
                        }
                        if(rectObj?.left < 0) {
                            rectObj.set({
                                left: 0,
                            })
                        }
                        if(rectObj?.left + rectObj?.getScaledWidth() > canvas.current.width) {
                            rectObj.set({
                                left: canvas.current.width - rectObj?.getScaledWidth(),
                            })
                        }
                        if(rectObj?.top + rectObj?.getScaledHeight() > canvas.current.height) {
                            rectObj.set({
                                top: canvas.current.height - rectObj?.getScaledHeight()
                            })
                        }
                        
                        setResultrect({
                            top: rectObj.top / changeScale,
                            left: rectObj.left / changeScale,
                            width: Math.round(rectObj.getScaledWidth() / changeScale),
                            height: Math.round(rectObj.getScaledHeight() / changeScale),
                        })
                    }
                    
                });
                
    
                setRectangle(rect);
                setImageWidth(rectWidth);
                setImageHeight(rectHeight);
                rect.setCoords();
                canvas.current.add(group, maskRect, rect);
                canvas.current.renderAll();
    
            }, { left: 100, top: 100 });
        }        
    }, [props.modelScaleOriBase64, fixedWidth, canvasIsReady])

    useEffect(() => {
        if (!canvasIsReady) return
        if (width && height) {
            const oldWidth = canvas.current.height
            const oldHeight = canvas.current.height

            canvas.current.setDimensions({ width: width, height: height })

            if (initializedRef.current) {
                // 修改canvas中对象的位置和大小
                canvas.current.forEachObject((obj) => {
                    const scaleFactorX = width / oldWidth
                    const scaleFactorY = height / oldHeight

                    obj.left *= scaleFactorX
                    obj.top *= scaleFactorY
                    obj.scaleX *= scaleFactorX
                    obj.scaleY *= scaleFactorY

                    // 更新对象的位置和大小
                    obj.setCoords()

                    // 解决resize之后点击重置，rect位置偏移的问题
                    if (obj.get('is_rect')) {
                        initRectRef.current = {
                            initialLeft: initRectRef.current.initialLeft * scaleFactorX,
                            initialTop: initRectRef.current.initialTop * scaleFactorY,
                            initialWidth: initRectRef.current.initialWidth * scaleFactorX,
                            initialHeight: initRectRef.current.initialHeight * scaleFactorY,
                        }
                        initImgRef.current = {
                            initialLeft: initImgRef.current.initialLeft * scaleFactorX,
                            initialTop: initImgRef.current.initialTop * scaleFactorY,
                            initialWidth: initImgRef.current.initialWidth * scaleFactorX,
                            initialHeight: initImgRef.current.initialHeight * scaleFactorY,
                        }
                    }
                })
                canvas.current.renderAll()
            }
        }
    }, [width, height, fixedWidth, canvasIsReady])

    useEffect(() => {
        const canvasInstance = new fabric.Canvas(canvasRef.current, {
            preserveObjectStacking: true,
        });

        canvas.current = canvasInstance;
        canvas.current.setDimensions({ width: width, height: height });
        canvas.current.selection = false // 禁用组选择器

        setCanvasIsReady(true)
        
        return () => {
            canvasInstance.dispose();
        };
    }, []);

    // useEffect(() => { // 添加矩形框
    //     if (rectangle) {
    //         console.log('rectamge set1')
    //         rectangle.set({
    //             width: imageWidth,
    //             height: imageHeight,
    //             selectable: true,
    //         });
    //         rectangle.setCoords();
    //         canvas.current.renderAll();
    //     }
    // }, [rectangle, imageWidth, imageHeight]);

    const handleClick = () => {
        return new Promise((resolve) => {
            if (isLoadingRef.current) return resolve(false)
            isLoadingRef.current = true
            setIsLoading(true)

            // 缓存构图优化区域位置，方便还原
            const objects = canvas.current.getObjects() 
            const rectObj = objects.find(obj => obj.get('is_rect'))
            modelScaleRef.current = {
                left: rectObj.left,
                top: rectObj.top,
                width: rectObj.width,
                height: rectObj.height,
                scaleX: rectObj.scaleX,
                scaleY: rectObj.scaleY,
            }
            setModelScale(modelScaleRef.current)

            setTimeout(() => {
                modelScaleMirrorRef.current.handleClick().then((modelScaleMirror) => { // modelScaleMirror is webp
                    isLoadingRef.current = false
                    setIsLoading(false)
                    resolve({
                        base64: modelScaleMirror,
                        file: Utils.convertBase64ToFile(modelScaleMirror, 'image.png')
                    })
                })
            }, 500)
        })
        
    };

    const handleReset = () => { // 恢复矩形框大小、位置
        if (isLoadingRef.current) return
        const activeObject = canvas.current.getActiveObject();
        if (activeObject && activeObject.type === 'rect') {
            const rect = activeObject;

            initRectBottomRef.current = 0
            
            // 将矩形框恢复到初始位置和大小
            // rect.set({
            //     left: initRectRef.current.initialLeft,   // 初始左侧位置
            //     top: initRectRef.current.initialTop,     // 初始顶部位置
            //     width: initRectRef.current.initialWidth, // 初始宽度
            //     height: initRectRef.current.initialHeight, // 初始高度
            //     scaleX: 1,
            //     scaleY: 1,
            // });
            // rect.setCoords();
            // canvas.current.renderAll(); // 重新渲染画布

            // 将矩形框恢复到初始位置和大小
            rect.set({
                left: initImgRef.current.initialLeft,   // 初始左侧位置
                top: initImgRef.current.initialTop,     // 初始顶部位置
                width: initImgRef.current.initialWidth, // 初始宽度
                height: initImgRef.current.initialHeight, // 初始高度
                scaleX: 1,
                scaleY: 1,
            });
            rect.setCoords();
            canvas.current.renderAll(); // 重新渲染画布
        }
    };

    const showControlBtn = (obj) => {
        // -28相当于这个文字的高，否则字会在照片左上
        document.getElementsByClassName('dragBtnBox')[0].style.top = obj.top + offsetValue - 28 + 'px'
        document.getElementsByClassName('dragBtnBox')[0].style.left = obj.left + 'px'
        document.getElementsByClassName('dragBtnBox')[0].style.display = 'block'
    }
    const hideControlBtn = () => {
        document.getElementsByClassName('dragBtnBox')[0].style.display = 'none'
    }

    return (
        <div>
            <Spin spinning={isLoading}>
                <div className='modelScale_f'>                
                    <div className='modelScale' style={{margin: '0 auto', width, height, background: `url(${bgUrl}) center / 100% 100%`}}>
                        <canvas ref={canvasRef}></canvas>
                        <div className='dragBtnBox'>                        
                            {`${rectChangeSide.width} X ${rectChangeSide.height}`}
                        </div>
                    </div>
                </div>
            </Spin>
            {/* 为了保证裁剪分辨率不变，需要创建一个隐藏的、显示原图的画布 */}
            <ModelScaleMirror
                size={props.size}
                ref={modelScaleMirrorRef}
                offsetValue={offsetValue}
                resultrect={resultrect}
                scaleFactorValue={scaleFactorValue}
                resizeScreen={resizeScreen}
                modelScaleOriBase64={props.modelScaleOriBase64}
            />
        </div>
        
    );
});

export default ModelScale;