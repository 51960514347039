import { useCallback, useContext, useEffect, useRef } from "react"
import Utils from '@utils/utils'
import { message } from "antd"
import API from '@api/api'
import axios from 'axios';
import eventBus from '@utils/eventBus';

const useInteractive = (props) => {
    const { instance, topInstance } = props
    const modeRef = useRef(props.interactiveMode)
    const isMounted = useRef(true)
    const lastLogitsPathRef = useRef('')
    const whiteMaskUrlRef = useRef(props.whiteMaskUrl)

    useEffect(() => {
        return () => {
            isMounted.current = false
        }
    }, [])

    useEffect(() => {
        whiteMaskUrlRef.current = props.whiteMaskUrl
    }, [props.whiteMaskUrl])

    const getLogitsPath = () => lastLogitsPathRef.current
    const setLogitsPath = (path) => lastLogitsPathRef.current = path
    const getWhiteMaskUrl = () => whiteMaskUrlRef.current
    const setWhiteMaskUrl = (url) => whiteMaskUrlRef.current = url

    useEffect(() => {
        modeRef.current = props.interactiveMode
    }, [props.interactiveMode])

    const drawCircle = (x, y, color, label) => {
        const circle = new fabric.Circle({
            left: x,
            top: y,
            originX: 'center',
            originY: 'center',
            radius: 4,
            fill: color,
            selectable: false,
        })
        circle.set('_key', `interactive_${x}_${y}_${label}`)
        circle.set('is_interactive_point', true)
        circle.set('_label', label)
        topInstance.add(circle)
        topInstance.renderAll()

        return circle
    }

    const aiInteractiveNpyRef = useRef(props.aiInteractiveNpy)
    useEffect(() => {
        aiInteractiveNpyRef.current = props.aiInteractiveNpy
    }, [props.aiInteractiveNpy])

    const inputImageRef = useRef()
    useEffect(() => {
        inputImageRef.current = props.inputImageUrl
    }, [props.inputImageUrl])
    const displayScaleRef = useRef(props.displayScale)
    useEffect(() => {
        displayScaleRef.current = props.displayScale
    }, [props.displayScale])
    const aiinteractivePrediction = async () => {
        try {
            const objs = topInstance.getObjects()
            const points = objs.filter(item => item.get('is_interactive_point'))
            if (!points || points.length == 0) return false
            const pointJson = JSON.stringify(points.map(point => {
                const x = point.left
                const y = point.top
                const label = point.get('_label') == 'add' ? 1 : 0
                return {
                    x: Math.max(Math.round(x / displayScaleRef.current), 0),
                    y: Math.max(Math.round(y / displayScaleRef.current), 0),
                    label: label
                }
            }))
            const res = await API.aiinteractivePrediction({
                img_path: inputImageRef.current,
                mask_path: whiteMaskUrlRef.current, //第一次点击就传显著性分割的结果mask
                points_json: pointJson,
                img_embedding_path: aiInteractiveNpyRef.current,
                logits_path: lastLogitsPathRef.current
            })
            if(res.code != 0) throw new Error(res.message)
            const result = await aiinteractivePredictionResult(res.data)
            return result
        } catch (err) {
            const error = Utils.parseErrorCode(`[${err.message}]`)
            eventBus.emit('notification', '分割失败', error.message, 'error', error.code)
            return false
        }
    }

    const aiinteractivePredictionResult = (id)  => {
        return new Promise((resolve, reject) => {
            function loopCheck () {
                axios.post(API.aiinteractivePredictionUrl + id,  {}, {
                    headers: {
                        'Content-type' : 'application/json',
                        'Ps-Auth-Token': localStorage.getItem('token')
                    }
                }).then(res => {
                    if((res.data.code == 0 && res.data.data.status == 'succeeded')) {
                        // console.log('whiteMask', res.data.data.output[0])
                        Utils.urlToBase64(res.data.data.output[0]).then((whiteRes) => {
                            Utils.convertWhiteImageToBlue(whiteRes).then(blueRes => {
                                resolve({
                                    output: res.data.data.output,
                                    whiteMaskBase64: whiteRes,
                                    blueMaskBase64: blueRes,
                                    whiteMaskUrl: res.data.data.output[0],
                                })
                            })                            
                        })
                    } else if(res.data.code == 0 && res.data.data.status == 'failed') {
                        reject(new Error(res.data.data.errcode))
                    } else {
                        if (isMounted.current) {
                            setTimeout(() => {
                                loopCheck()
                            }, 400)
                        }                        
                    }
                }).catch(err => {
                    reject(err)
                })
            }
            
            loopCheck()
        })        
    }

    const isLoading = useRef(false)
    useEffect(() => {
        if (!instance) return
        instance.on('mouse:down', (ev) => {
            if (!modeRef.current) return
            // 不支持移动端touch事件
            if (Utils.isMobileDevice()) return message.warning('请在PC设备上操作')

            if (isLoading.current) return
            isLoading.current = true

            props.setLoading(true)

            // 备份用于撤销
            props.addUndo('interactive')
            
            const { button, offsetX, offsetY } = ev.e

            const canvasOffsetX = instance.viewportTransform[4]
            const canvasOffsetY = instance.viewportTransform[5]
            const zoom = instance.getZoom()
            const x = (offsetX - canvasOffsetX) / zoom
            const y = (offsetY - canvasOffsetY) / zoom

            let circle = null
            if (button === 0) {
                if (modeRef.current == 'add') {
                    circle = drawCircle(x, y, '#0FFF50', 'add');
                } else if (modeRef.current == 'remove') {
                    circle = drawCircle(x, y, '#FF4433', 'remove');
                }
            // } else if (button === 2) {
            //     drawCircle(x, y, '#FF4433', 'remove');
            //     props.setInteractiveMode('remove')
            }

            // 埋点
            Utils.useroplog(props.imageType, 'algorithm', 'aiInteractiveMask', {interactive: modeRef.current})
            aiinteractivePrediction().then(res => {
                if (!res) return
                const { output, whiteMaskBase64, blueMaskBase64, whiteMaskUrl } = res
                lastLogitsPathRef.current = output[1]
                whiteMaskUrlRef.current = whiteMaskUrl
                // props.setWhiteMaskBase64(whiteMaskBase64)
                props.setBlueMaskBase64(blueMaskBase64)                
                props.setUpdated(true) // 已更新
            }).finally(() => {
                circle.set({ visible: false })
                topInstance.renderAll()
                props.setLoading(false)
                isLoading.current = false
            })
        })
        instance.on('contextmenu', function (e) {
            e.e.preventDefault(); // 阻止默认右键菜单事件
            // 自定义右键菜单逻辑
        })
    }, [instance])

    return {
        getLogitsPath,
        setLogitsPath,
        getWhiteMaskUrl,
        setWhiteMaskUrl
    }
}

export default useInteractive