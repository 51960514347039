import { useRef, useEffect } from "react";

const useIntersectionObserver = (callback) => {
    const targetRef = useRef()
  
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    callback(true)
                } else {
                    callback(false)
                }
            })
        });

        if (targetRef.current) {
            observer.observe(targetRef.current)
        }

        return () => {
            if (targetRef.current) observer.unobserve(targetRef.current)
        };
    }, []);
  
    return targetRef
};

export default useIntersectionObserver