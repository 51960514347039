import { Button, Form, Input, message } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { useState, useEffect } from "react";
import Utils from '@utils/utils'
import eventBus from "@utils/eventBus";
import API from '@api/api'
import styles from './index.module.scss'
import PublicKey from '@utils/publicKey'
import VerityCode from "../VerifyCode";
import { ExclamationCircleOutlined} from '@ant-design/icons';

export default function EmailRegisterForm (props) {
    const [step, setStep] = useState(1)
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const email = Form.useWatch(['email'], form)

    const initialValues = {
        email: "",
        verifyCode: "",
        password: "",
        repeat: "",
        invitationCode: String(sessionStorage.getItem('inviteCode') || '').toUpperCase()
    }

    const handleValidateMobile = () => {
        return form.validateFields(['email'])
    }

    const onSubmit = () => {
        form.validateFields().then(values => {
            if (step == 1) return setStep(2)
            else {
                setLoading(true)
                API.emailRegister({
                    verifyCode: values.verifyCode,
                    email: values.email,
                    password: PublicKey.JSEncryptChange(values.password),
                    repeat: PublicKey.JSEncryptChange(values.repeat),
                    invitationCode: String(values.invitationCode).trim().toUpperCase(),
                    mode: 1
                }).then((res) => {
                    if(res.code != 0) throw new Error(res.message)
                    message.success('注册成功，请使用账号密码登录')
                    props.onBack("2")
                }).catch(err => {
                    message.error(err.message)
                    // setStep(1)
                }).finally(() => {
                    setLoading(false)
                })
            }
        })
        
    }

    return (
        <div>
            <div className={styles.GoBack} onClick={() => props.onBack("1")}>邮箱注册</div>
            <Form
                autoComplete="off"
                form={form}
                initialValues={initialValues}
                onFinish={onSubmit}
            >
            {
                step == 1 ?
                <>
                <Form.Item
                    name="email"
                    validateFirst
                    validateTrigger="onBlur"
                    rules={[
                        { required: true, message: '请输入邮箱', },
                        () => ({
                            validator(_, value) {
                                if (!Utils.validateContact2(value)) return Promise.reject(new Error('您输入的邮箱错误'))
                                return Promise.resolve()
                            }
                        })
                    ]}
                >
                    <Input
                        tabIndex="1"
                        size="large"
                        placeholder="请输入邮箱"
                        allowClear
                        prefix={<img style={{width: 16}} src={require('@assets/images/icon_email.png')} alt="" />}
                    />
                </Form.Item>
                <Form.Item
                    name="verifyCode"
                    rules={[ { required: true, message: '请输入验证码', }, ]}
                >
                    <VerityCode mobile={email} onValidate={handleValidateMobile} />
                </Form.Item>
                <Form.Item
                    name="invitationCode"
                    rules={[ { required: false }, ]}
                >
                    <Input
                        tabIndex="4"
                        size="large"
                        placeholder="请输入邀请码（选填）"
                        allowClear
                        prefix={<img style={{width: 16}} src={require('@assets/images/icon_Number.png')} alt="" />}
                    />
                </Form.Item>
                </> :
                <>
                <Form.Item hidden name={['email']}></Form.Item>
                <Form.Item hidden name={['verifyCode']}></Form.Item>
                <Form.Item hidden name={['invitationCode']}></Form.Item>
                <Form.Item
                    name="password"
                    validateFirst
                    validateTrigger="onBlur"
                    rules={[
                        { required: true, message: '请输入密码', },
                        () => ({
                            validator(_, value) {
                                if (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,20}$/.test(value)) return Promise.resolve();
                                return Promise.reject(new Error('请输入符合规范的密码'));
                            }
                        })
                    ]}
                >
                    <Input.Password
                        tabIndex="4"
                        size="large"
                        placeholder="请输入您的密码"
                        autoComplete="new-password"
                        allowClear
                        prefix={<img style={{width: 16}} src={require('@assets/images/icon_Password.png')} alt="" />}
                        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    />
                </Form.Item>
                <Form.Item
                    name="repeat"
                    validateFirst
                    validateTrigger="onBlur"
                    rules={[
                        { required: true, message: '请再次输入您的密码', },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (getFieldValue('password') === value) return Promise.resolve();
                              return Promise.reject(new Error('两次输入的密码不一致'));
                            },
                        }),
                    ]}
                >
                    <Input.Password
                        tabIndex="5"
                        size="large"
                        placeholder="请再次输入您的密码"
                        autoComplete="new-password"
                        allowClear
                        prefix={<img style={{width: 16}} src={require('@assets/images/icon_Password.png')} alt="" />}
                        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    />
                </Form.Item>
                </>
            }
                {step != 1 && <p style={{margin: '30px 0 10px', fontSize: '12px', color: '#999999'}}><ExclamationCircleOutlined style={{marginRight: 4}} />密码长度6-20位，应包含大小写字母和数字，不支持特殊字符</p>}
                <Form.Item>
                    <Button
                        tabIndex="5"
                        size="large"
                        block
                        type="primary"
                        loading={loading}
                        htmlType="submit"
                    >
                    {step == 1 ? '下一步' : '完成'}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}