import React, {useState, useEffect, forwardRef, useRef, useContext} from 'react';
import { fabric } from 'fabric';
import { AIEditContext } from '../../AIEditContext';

const BlurBgContCanvas = forwardRef((props, ref) => {
    const {addWatermark} = useContext(AIEditContext)

    const cansRef = useRef(null);
    const fabricCanvasRef = useRef()
    
    useEffect(() => {
        if (!fabricCanvasRef.current) return
        const bgImage = fabricCanvasRef.current.get('backgroundImage')
        bgImage.setSrc(props.url, () => fabricCanvasRef.current.renderAll(), {crossOrigin: 'anonymous'})
    }, [props.url])

    useEffect(() => {
        if(!props.url || !props.width || !props.height) {return}
        if (!fabricCanvasRef.current) {
            // 马赛克
            const patternImageBase64 = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABHNCSVQICAgIfAhkiAAAADBJREFUOE9jfPP69X8GPEBYRASfNAPjqAHDIgz+AwG+iH7z5s1ZfPKMowYwDIMwAACuR1xZvSaHFwAAAABJRU5ErkJggg==';
            const pattern = new fabric.Pattern({
                source: patternImageBase64,
                repeat: 'repeat',
            })

            const canvas = cansRef.current;
            const image = new Image();
            image.src = props.url;
            image.onload = () => {
                const fabricImage = new fabric.Image(image, { crossOrigin: 'anonymous' });
                // 缩放图像以适应指定的画布尺寸
                fabricImage.scale(props.displayScale);

                const newFabricCanvas = new fabric.Canvas(canvas, {
                    width: props.width,
                    height: props.height,
                    backgroundImage: fabricImage,
                    backgroundColor: pattern,
                    stopContextMenu: true,
                });
                addWatermark(newFabricCanvas)

                // 禁止对象的拖动和缩放
                fabricImage.set({
                    lockMovementX: true,
                    lockMovementY: true,
                    lockRotation: true,
                    lockScalingX: true,
                    lockScalingY: true,
                    hasControls: false,
                    hasBorders: false,
                })
                newFabricCanvas.renderAll();
                fabricCanvasRef.current = newFabricCanvas
                props.getCanvasRefsFun(newFabricCanvas)
                // setFabricCanvas(newFabricCanvas);
            };
        } else {
            const canvasScaleX = props.width / fabricCanvasRef.current.width
            const canvasScaleY = props.height / fabricCanvasRef.current.height
            const bgImage = fabricCanvasRef.current.backgroundImage
            if (bgImage) bgImage.scale(props.displayScale)

            const objs = fabricCanvasRef.current.getObjects()
            objs.forEach(item => {
                item.set({
                    scaleX: item.scaleX * canvasScaleX,
                    scaleY: item.scaleY * canvasScaleY
                })
                item.setCoords()               
            })
            
            fabricCanvasRef.current.viewportTransform[4] = fabricCanvasRef.current.viewportTransform[4] * canvasScaleX
            fabricCanvasRef.current.viewportTransform[5] = fabricCanvasRef.current.viewportTransform[5] * canvasScaleY
            fabricCanvasRef.current.setDimensions({ width: props.width, height: props.height})
            fabricCanvasRef.current.renderAll()
        }
    }, [props.width, props.height, props.displayScale]);


    return (
        <div className='blurBgContCanvas'>
            <canvas ref={cansRef} />
        </div>
    )
    
})

export default BlurBgContCanvas