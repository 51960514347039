import React, { useState, useRef, useEffect, useMemo } from 'react';
import ImgComparer from '../imgComparer'
import './blurBgCont.scss'
import { LoadingOutlined } from '@ant-design/icons';
import API from '@api/api.js';
import axios from 'axios'
import { message, Radio, Divider, Button, Spin } from 'antd';
import { useLocation } from "react-router-dom";
import { DownOutlined } from '@ant-design/icons';
import { fabric } from 'fabric';
import Utils from '@utils/utils';
import BlurBgContCanvas from './blurBgContCanvas';
import DragAndScale from '../dragAndScale/dragAndScale'
import ToolBox from '../toolBox/toolBox';
import {forwardRef, useImperativeHandle} from 'react'
import { useContext } from 'react'
import { AIEDIT_SOURCE } from '@utils/CONST';
import { AIEditContext } from '../../AIEditContext';

const BlurBgCont = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        onChange: () => {
            return props.url
        },
        createClick
    }))

    const {needPay, setNeedPay,
        isLoading1Blur, setIsLoading1Blur,
        isLoading2Blur, setIsLoading2Blur
    } = useContext(AIEditContext)

    const isMounted = useRef(true) // 判断组件是否已销毁
    const [url, setUrl] = useState(props.url)
    const [urlResult, setUrlResult] = useState()
    const [resultAlgoId, setResultAlgoId] = useState()
    const [width, setWidth] = useState() // display width
    const [height, setHeight] = useState() // display height
    const location = useLocation();
    const [downloading, setDownloading] = useState(false)
    const [windowSize, setWindowSize] = useState()
    const [fabricCanvas, setFabricCanvas] = useState(null);
    const wrapRef = useRef()
    const dragScaleRef = useRef()
    const imgSizeRef = useRef({width: 0, height: 0}) // 图片原始尺寸
    const [imgSize, setImgSize] = useState({width: 0, height: 0}) // 图片原始尺寸
    const [displayScale, setDisplayScale] = useState(1)
    const [inited, setInited] = useState(false)
    const imgMaxLimit = useRef(2048)

    const initImageSize = async () => {
        const {width, height} = await Utils.getImageDimensions(url)
        imgSizeRef.current = {width, height}
        setImgSize(imgSizeRef.current)
        if (Math.max(width, height) > imgMaxLimit.current) {
            props.errorCallback()
        }
    }

    const calcCanvasSize = () => {
        const containerWidth = wrapRef.current?.clientWidth - 120 - 150
        const containerHeight = wrapRef.current?.clientHeight
        const size = Utils.getImgMaxSizeInContainer(
            imgSizeRef.current.width,
            imgSizeRef.current.height,
            containerWidth,
            containerHeight
        )
        setDisplayScale(size.width / imgSizeRef.current.width)
        setWidth(size.width)
        setHeight(size.height)
    }

    useEffect(() => {
        setUrl(props.url)
    }, [])

    useEffect(() => {
        if (!url) return
        initImageSize().then(() => {
            calcCanvasSize()
            setInited(true)
        })
    }, [url])

    useEffect(() => {
        const onWindowResize = (e) => {
            calcCanvasSize()
        }
        window.addEventListener('resize', onWindowResize)
        return () => {
            isMounted.current = false
            window.removeEventListener('resize', onWindowResize)
        }
    }, [])

    // useEffect(() => {
    //     return () => {
    //         if (isMounted.current) return
    //         props.onChange(url || props.url)
    //     }
    // }, [url])
        
    const createClick = (zoom) => {
        // aisuperresolutionPrediction(zoom * 2)
        if(!url) {
            message.warning('请先上传图片！')
            return false
        }
        if(isLoading1Blur || isLoading2Blur) {
            message.warning('处理中，请等待！')
            return false
        }
        dragScaleRef.current.resetCanvas()
        //超分埋点,这里都是AI编辑的超分，因此imageType直接取sessionStorage.getItem('oplog_imageType')
        Utils.useroplog(sessionStorage.getItem('oplog_imageType'), 'algorithm', 'aiQualityUpgrade', {'AI_edit_pic': url})
        Utils.hdDownloadFunc({
            imgPath: url,
            scale: zoom
        }, zoom == 2?setIsLoading1Blur: setIsLoading2Blur).then(res => {
            if (!res) return
            const {algoId, output} = res
            setUrlResult(output)
            setResultAlgoId(algoId)
            downloadUrl.current = null
        })
    }

    const downloadUrl = useRef()
    const commonDownloadFunc = async () => {
        if (downloadUrl.current) {
            Utils.downUrl(downloadUrl.current, sessionStorage.getItem('oplog_imageType'), false)
            message.success('下载成功')
        } else {
            // aiEditDownloadWithLoadingFunc
            try {
                let res = await API.aiEditDownload({
                    algoId: resultAlgoId,
                    sourceAlgo: AIEDIT_SOURCE.AI_QUALITY,
                    needPay
                })
                if (res?.code != 0) throw new Error(res?.message)
                Utils.downUrl(res.data, sessionStorage.getItem('oplog_imageType'), false)
                downloadUrl.current = res.data
                if (downloadUrl.current) setNeedPay(false)
                message.success('下载成功')
            } catch (err) {
                message.warning(err.message)
            }
        }        
    }

    const onDragAndScaleChange = ({zoom, moveable, zoomable}) => {
        //
    }

    const getCanvasRefsFun = (canvasRef) => {
        setFabricCanvas(canvasRef)
    }

    const rightContent = (
        <div className='edit_right_c' style={{position: 'relative'}} ref={wrapRef}>
        {
            inited ?
            <div className='wrap_box wrap_box_'>
                <div className='dragAndScale_box'>
                    {
                        fabricCanvas? <DragAndScale
                        ref={dragScaleRef}
                        min={1}
                        max={8}
                        onChange={onDragAndScaleChange}
                        canvasList={[fabricCanvas]}
                        disabled={urlResult? true: false}
                        options={[
                            { label: '适应屏幕', value: 1 },
                            // { label: '实际大小', value: Number((1 / displayScale).toFixed(2)) },
                            { label: '100%', value: 1 },
                            { label: '200%', value: 2 },
                            { label: '300%', value: 3 },
                            { label: '400%', value: 4 },
                        ]}
                        />: null
                    }
                    {
                        urlResult?<p>对比时可直接使用滚轮缩放、按住鼠标移动</p>: null
                    }
                </div>
                {
                    urlResult?
                    <div style={{height: height, width: width}}>
                        <ImgComparer
                            leftImage={url}
                            rightImage={urlResult}
                        />
                    </div>:
                        width * height ?
                        // <img id='imageId' src={url}  style={{height: height, width: width, objectFit: 'contain', maxWidth: 'none'}} alt="" /> :
                        <BlurBgContCanvas 
                        url={url}
                        height={height}
                        width={width}
                        displayScale={displayScale}
                        getCanvasRefsFun={getCanvasRefsFun}
                        /> :
                        null
                }
                <ToolBox
                    download={{disabled: !urlResult, trigger: commonDownloadFunc}}
                    uploadCb={props.uploadCb}
                />
            </div> : 
            <div style={{display:"flex", justifyContent:"center", alignItems: "center", height: "100%"}}>
                <Spin size="large" />
            </div>
        }
        </div>
    )

    return (
        <div className='edit_cont blurBgCont'>
            <div className='edit_right'>
            {
                !props.url ?
                props.defaultPage:
                rightContent
            } 
            </div>
        </div>
    )
})

export default BlurBgCont;

