import { AppContext } from '@utils/AppContext';
import React, { useState, useContext, useEffect, useRef, useMemo } from 'react';
import TaskList from '../taskList/taskList'
import ProcessImg from '../processImg/processImg'
import API from '@api/api.js';
import { CloseOutlined } from '@ant-design/icons';
import { Badge, Modal } from 'antd';
import Utils from '@utils/utils'
import './sidebarTasks.scss'
import { useNavigate } from 'react-router-dom';
import eventBus from '@utils/eventBus';
import PayModal from '../payModal/payModal';
import { channel } from '@utils/AppContext';
import { HOME_PAGE_TYPE, IMAGE_TYPE } from "@utils/CONST"
import PSAIModal from '@PSAIComponents/PSAIModal';

const SidebarTasks = () => {
    const [initialized, setInitialized] = useState(false)
    const REDIRECT_URL = '/home/homemain/photoProjectDetail';
    const navigate = useNavigate()
    const { globalHomePageType, update, forceUpdate } = useContext(AppContext);
    const [topThreeTask, setTopThreeTask] = useState([])
    const [count, setCount] = useState(0)
    const [unfinishedCount, setUnfinishedCount] = useState(0)
    const [isCheckStatus, setIsCheckStatus] = useState(false)
    const [unReadCount, setUnReadCount] = useState(0)
    const payChildRef = useRef(null);


    const todetail = (id) => {
        navigate(REDIRECT_URL, {state: {id: id}})
    }

    const fetchType = useMemo(() => {
        if (globalHomePageType == HOME_PAGE_TYPE.MERCHANDISE) {
            return IMAGE_TYPE.COMMODITY
        } else if (globalHomePageType == HOME_PAGE_TYPE.CLOTHING) {
            return IMAGE_TYPE.MODEL
        }
    }, [globalHomePageType])

    const fetchTask = () => {
        API.getTaskListByUser({
            type: fetchType,
            pageIndex: 1,
            pageSize: 3
        }).then(res => {
            if (!res || res?.code !== 0 || !res?.data?.items) {
                console.log('get taskList error', res?.code, res?.message);
                return
            }
            setTopThreeTask(res?.data?.items)
            setCount(res?.data?.total || 0)
            setUnfinishedCount(res?.data?.other?.unfinished || 0)
            setUnReadCount(res?.data?.other?.unread || 0)
        }).catch((error) => {
            console.error(error)
        }).finally(() => {
            setInitialized(true)
        })
    }

    const loopFetchData = () => {
        return API.getTaskListByUser({
            type: fetchType,
            pageIndex: 1,
            pageSize: 3
        }).then(res => {
            if (!res || res?.code !== 0 || !res?.data?.items) {
                console.log('get getTaskList error');
                throw new Error('get getTaskList error')
            }
            const arr = res?.data?.items
            setCount(res?.data?.total || 0)
            setUnfinishedCount(res?.data?.other?.unfinished || 0)
            setUnReadCount(res?.data?.other?.unread || 0)
            setTopThreeTask(pre => {
                const arr2 = [...pre]
                for (let i = 0; i < arr.length; i++) {
                    for (let j = 0; j < arr2.length; j++) {
                        if (arr2[j].id == arr[i].id && arr2[j].taskId == arr[i].taskId) {
                            arr2[j].completedAt = arr[i].completedAt
                            arr2[j].status = arr[i].status
                        }
                    }
                }
                return arr2
            })            
        }).catch(err => console.error(err))
    }

    // const getLastUnsucceededIndex = () => {
    //     let lastUnsucceededIndex = -1
    //     for (let i = topThreeTask.length - 1; i >= 0; i--) {
    //         if (topThreeTask[i].status != 'succeeded' && topThreeTask[i].status != 'failed') {
    //             lastUnsucceededIndex = i
    //             break
    //         }
    //     }
    //     return lastUnsucceededIndex
    // }

    // const checkStatus = () => {
    //     if (!isCheckStatus) {
    //         const index = getLastUnsucceededIndex()
    //         if (index < 0) return
    //         setIsCheckStatus(true)
    //         return Utils.delayFunc(1000).then(() => {
    //             return loopFetchData()
    //         }).finally(() => {
    //             setIsCheckStatus(false)
    //         })            
    //     }
    // }

    // useEffect(() => {
    //     if (!isCheckStatus) fetchTask()
    // }, [update, isCheckStatus])

    // useEffect(() => {
    //     if (initialized) forceUpdate({})
    // }, [unfinishedCount])

    // useEffect(() => {
    //     checkStatus()
    // }, [topThreeTask])

    const [open, setOpen] = useState(false)
    const onShowDialog = () => {
        setOpen(true)
    }

    const openrechargeClick = () => {
        payChildRef.current.openrechargeClick()
    }

    const taskListAvailble = useMemo(() => {
        return globalHomePageType != HOME_PAGE_TYPE.IMAGE_SERVICE
    }, [globalHomePageType])

    return (
        <>
            <div className='Sidebar_tasks'>
                {
                    localStorage.getItem('channel') == 'taobao'? null:
                    <div className='money_box' onClick={() => openrechargeClick()}>
                        <div className='money_box_ energy_point'>
                            <i></i>
                            <p>充值</p>
                        </div>
                    </div>
                }
                {
                    taskListAvailble &&
                    <div className='money_box' onClick={() => onShowDialog()}>
                        <div className={'money_box_ icon_tasklist'}>
                            <i></i>
                            <p>任务列表</p>
                        </div>
                    </div>
                }
            </div>
            <PSAIModal
                title="任务列表"
                open={open}
                footer={null}
                maxWidth={1130}
                onCancel={() => setOpen(false)}
            >
                <TaskList closeHandler={() => setOpen(false)}/>
            </PSAIModal>
            <PayModal ref={payChildRef}></PayModal>
        </>
    )
}

export default SidebarTasks