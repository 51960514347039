import {Outlet, useLocation} from 'react-router-dom'
import React, { useState, useContext, useRef, useEffect } from 'react';
import { AppContext } from '@utils/AppContext';

export default function MobileLayout () {
    useEffect(() => {
        console.log('===set base-widht 768')
        const html = document.querySelector('html')
        html.style.setProperty('--min-font-size', '0px');
        html.style.setProperty('--max-font-size', '100px');
        html.style.setProperty('--base-width', '768');
    }, [])

    return (
        <Outlet />
    )
}