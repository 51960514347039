import { Divider , Select, Spin, message } from "antd"
import { useEffect, useMemo, useRef, useState } from "react"
import { LoadingOutlined } from '@ant-design/icons';
import styles from './index.module.scss'
import API from '@api/api'
import Utils from '@utils/utils'
import useResizeObserver from "@utils/useResizeObserver";

const Customized = (props) => {
   const [createNum, setCreateNum] = useState(0)
   const [customPlanList, setCustomPlanList] = useState()

   const onCreateNumChange = (val) => {
        if(val == 'add') {
            if(createNum < 99) setCreateNum(pre => pre + 1)
        } else if(val == 'reduce') {
            if(createNum > 0) setCreateNum(pre => pre - 1)
        }
    }

    useEffect(() => {
        orderCustomPlan()
    }, [])

    const orderCustomPlan = () => {
        API.orderCustomPlan({}).then((res) => {
            if(res.code == 0) {
                setCustomPlanList(res.data)
            } else {
                message.error(res.message)
            }
        })
    }

    const payClick = () => {
        props.payFunc({
            id: customPlanList.id,
            name: customPlanList.description,
            level: 1,
            supportDevices: 10, 
            diyPower: customPlanList.powerAmount + createNum*50000,
            list: [{
                id: customPlanList.id,
                price: Number(customPlanList?.price?.split('.')[0]) + createNum*600,
                powerAmount: customPlanList.powerAmount + createNum*50000,
                description: `价值${customPlanList.powerAmount + createNum*50000}AI虹豆，365天后清空`,
                tips: `价值${customPlanList.powerAmount + createNum*50000}AI虹豆，365天后清空`,
                unit: 'Year', // Month包月 / Quarter包季 / Year包年
            }]
        })
    }

    const [wrapWidth, setWrapWidth] = useState()
    const wrapRef = useResizeObserver((entries) => {
        for (let entry of entries) {
            // 处理尺寸变化的逻辑
            setWrapWidth(entry.contentRect.width)
        }
    })

    return (
        <div className={styles.customized} ref={wrapRef}>
            <Divider className={styles.customized_title}>定制服务</Divider>
            <div className={`${styles.main_box} ${wrapWidth < 960 && styles.column}`}>
                <div className={styles.div1}>
                    <div className={styles.div1_left}>
                        <span className={styles.title}>随心配</span>
                        <ul className={styles.PlanItemContent_ul}>
                            <li><img src={require(`@assets/images/planicon2.png`)}/>{Utils.addCommasToNumber(customPlanList?.powerAmount + createNum*50000)}虹豆<span>（1虹豆≈¥0.013）</span></li>
                            <li><img src={require(`@assets/images/planicon2.png`)}/>高清下载</li>
                            <li><img src={require(`@assets/images/planicon2.png`)}/>同时在线设备限{customPlanList?.supportDevices}台</li>
                            <li><img src={require(`@assets/images/planicon2.png`)}/>专属1V1客服支持</li> 
                        </ul>
                    </div>
                    <div className={styles.div1_right}>
                        <p className={styles.div1_right_title}> ￥<span>{Utils.addCommasToNumber(customPlanList?.price)}元</span>/年起</p>
                        <p className={styles.tip}>按5W点增加，每增加5W点价格增加600元</p>
                        <div className={styles.btn_box}>
                            <div className={styles.btn_box_left}>
                                <div className={styles.create_styl}>
                                    <span className={styles.num_btn} style={{backgroundColor: createNum == 0?'#d7d7d7': null, marginRight: '8px'}} onClick={(e) => onCreateNumChange('reduce')}>-</span>
                                    <span style={{marginRight: '4px'}}>{createNum}</span>
                                    <span className={styles.num_btn} style={{backgroundColor: createNum == 99?'#d7d7d7': null, marginRight: '10px'}} onClick={(e) => onCreateNumChange('add')} >+</span>
                                    <span className={styles.use_point}><img src={require('@assets/images/arcbean2.png')}/><span>{Utils.addCommasToNumber((customPlanList?.powerAmount || 0) + createNum*50000)}</span></span>
                                </div>

                            </div>
                            <div onClick={payClick} className={styles.btn_box_right}>¥{Utils.addCommasToNumber(Number(customPlanList?.price) + createNum*600)}&nbsp;&nbsp;&nbsp;购买</div>
                        </div>
                    </div>
                </div>
                <div className={styles.div2}>
                    <div className={styles.div2_left}>
                        <span className={styles.title}>商务定制</span>
                        <ul className={styles.PlanItemContent_ul}>
                            <li><img src={require(`@assets/images/planicon5.png`)}/>支持虹豆定制、账号管理、资源定制、API对接、前端定制等</li>
                            <li><img src={require(`@assets/images/planicon5.png`)}/>合作形式根据需求灵活选择</li>
                            <li><img src={require(`@assets/images/planicon5.png`)}/>可添加PSAI为您提供最佳的策略和方案 </li>
                            <li><img src={require(`@assets/images/planicon5.png`)}/>专属1V1客服支持</li> 
                        </ul>
                    </div>
                    <div className={styles.div2_right}>
                        <img src={Utils.qrcodeSales} alt="扫码联系我们" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Customized