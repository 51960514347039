import React, {useState, useEffect, useRef, forwardRef, useImperativeHandle, useCallback, useMemo, useContext} from 'react';
import { fabric } from 'fabric';
import { message } from 'antd';
import styles from './Drag.module.scss'
import { colorPrimary, colorCenterLine } from '@utils/AppContext';
import Utils from '@utils/utils'
import API from '@api/api'
import { GenerateContext } from '@utils/GenerateContext';
import { HOME_PAGE_TYPE, IMAGE_TYPE, CLOTHES_SUBTYPE } from "@utils/CONST"

// 自定义corner，参考fabric/src/mixins/default_controls.js
const rotateIcon = "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgdmlld0JveD0iMCAwIDMyIDMyIj48ZGVmcz48c3R5bGU+LmF7ZmlsbDojZmZmO3N0cm9rZTojMzkzZWZkO3N0cm9rZS13aWR0aDoycHg7fS5iLC5le2ZpbGw6bm9uZTt9LmJ7c3Ryb2tlOiMxZDFiMWI7c3Ryb2tlLWxpbmVjYXA6cm91bmQ7c3Ryb2tlLW1pdGVybGltaXQ6MTA7fS5je2ZpbGw6IzFkMWIxYjt9LmR7c3Ryb2tlOm5vbmU7fTwvc3R5bGU+PC9kZWZzPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMjQ3IC03MDkpIj48ZyBjbGFzcz0iYSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTI0NyA3MDkpIj48Y2lyY2xlIGNsYXNzPSJkIiBjeD0iMTYiIGN5PSIxNiIgcj0iMTYiLz48Y2lyY2xlIGNsYXNzPSJlIiBjeD0iMTYiIGN5PSIxNiIgcj0iMTUiLz48L2c+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTI1NS44NDEgNzE2LjQxNSkiPjxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0yIDEpIj48cGF0aCBjbGFzcz0iYiIgZD0iTTE1LjU3MywxMTcuNTJhNy4yODUsNy4yODUsMCwwLDEtMTMuMjY0LDAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAuNDQ0IC0xMDcuMjIzKSIvPjxwYXRoIGNsYXNzPSJiIiBkPSJNMiw2LjMzMWE3LjI4NSw3LjI4NSwwLDAsMSwxMy4zMTksMCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMC43MjYgLTIpIi8+PHBhdGggY2xhc3M9ImMiIGQ9Ik0yLjMsMGwyLjMsMi4zSDBaIiB0cmFuc2Zvcm09Im1hdHJpeCgwLjg5OSwgMC40MzgsIC0wLjQzOCwgMC44OTksIDE0LjgwOCwgNy4zNzcpIi8+PHBhdGggY2xhc3M9ImMiIGQ9Ik0yLjMsMGwyLjMsMi4zSDBaIiB0cmFuc2Zvcm09Im1hdHJpeCgtMC45NTEsIC0wLjMwOSwgMC4zMDksIC0wLjk1MSwgNC4zODMsIDYuNjIyKSIvPjwvZz48L2c+PC9nPjwvc3ZnPg==";
const img = document.createElement('img');
img.src = rotateIcon;
const objectControls = fabric.Object.prototype.controls;
function renderIcon(ctx, left, top, styleOverride, fabricObject) {
    const size = 32;
    ctx.save();
    ctx.translate(left, top);
    ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
    ctx.drawImage(img, -size/2, -size/2, size, size);
    ctx.restore();
}
objectControls.mtr.sizeX = 32
objectControls.mtr.sizeY = 32
objectControls.mtr.render = renderIcon

const Drag = forwardRef((props, ref) => {
    const {bgImage, refImgLocalInfo, imageType, isCustomScene} = useContext(GenerateContext)
    const { deskSize, imgRatio, imgWidth, imgHeight } = props
    const canvasRef = useRef(null);
    const canvas = useRef(null)
    const [activeObj, setActiveObj] = useState({});
    const horizontalLine = useRef()
    const verticalLine = useRef()

    const initializedRef = useRef(0) // 初始化完成 0-未初始化 1-初始化中 2-初始化完成

    useImperativeHandle(ref, () => ({
        handleClick,
        loadNewImageFunc,
    }));

    const imgWidthRef = useRef(imgWidth)
    const imgHeightRef = useRef(imgHeight)
    useEffect(() => {
        imgWidthRef.current = imgWidth
        imgHeightRef.current = imgHeight
    }, [imgWidth, imgHeight])

    /** 撤销/恢复操作 begin **/
    const saveLenRef = useRef(0) // 保存每一步的saveOprateList数据的长度
    const deleLenRef = useRef(0) // 需要删除每一步的deleteOperateList数据的长度
    const operIndex = useRef(-1) // 操作的index的值
    const saveOprateList = useRef([]) // 保存的数据，存的值为每一步的json数据
    const deleteOperateList = useRef([]) // 需要删除的index列表
    // 清除操作历史
    function clearOperateData () {
        saveOprateList.current = []
        deleteOperateList.current = []
        operIndex.current = -1
        saveLenRef.current = saveOprateList.current.length
        deleLenRef.current = deleteOperateList.current.length
    }
    // 保存操作的数据
    function saveOpreteDate () {
        if (!canvas.current) return
        const json = canvas.current.toJSON([
            'borderColor',
            'cornerColor',
            'cornerSize',
            'transparentCorners',
            'cornerStyle',
            'lockRotation',
            'lockScalingFlip',
            'is_mask',
            'is_softedge',
            'is_horizontal_line',
            'is_vertical_line',
            'flipX',
            'flipY',
        ])
        // console.log('json', json)
        if (deleLenRef.current > 0) {
            deleteOperateList.current.forEach(item => {
                saveOprateList.current[item].del = true
            })
            saveOprateList.current = saveOprateList.current.filter(item => !item.del)
            deleteOperateList.current = []
            saveOprateList.current.push(json)
            operIndex.current = saveOprateList.current.length - 1
        } else {
            saveOprateList.current.push(json)
            operIndex.current++
        }
        // console.log('保存操作的数据')
        saveLenRef.current = saveOprateList.current.length
        deleLenRef.current = deleteOperateList.current.length
    }
    // 上一步操作
    function preStepOperate () {
        return new Promise(resolve => {
            if (operIndex.current > 0) {
                const json = saveOprateList.current[operIndex.current - 1]
                canvas.current.loadFromJSON(json, () => {
                    canvas.current.renderAll()
                    resolve()
                })
                if (deleteOperateList.current.includes(operIndex.current - 1)) {}
                else {
                    deleteOperateList.current.push(operIndex.current)
                    operIndex.current--
                }
                // console.log('上一步操作')
                saveLenRef.current = saveOprateList.current.length
                deleLenRef.current = deleteOperateList.current.length
            } else {
                saveLenRef.current = saveOprateList.current.length
                deleLenRef.current = deleteOperateList.current.length
                resolve()
            }            
        })
    }
    // 下一步操作
    function nextStepOperate () {
        if (operIndex.current + 1 >= saveOprateList.current.length) return Promise.resolve()
        return new Promise(resolve => {
            const json = saveOprateList.current[operIndex.current + 1]
            canvas.current.loadFromJSON(json, () => {
                canvas.current.renderAll()
                resolve()
            })
            if (deleteOperateList.current.includes(operIndex.current + 1)) {
                const index = deleteOperateList.current.indexOf(operIndex.current + 1)
                deleteOperateList.current.splice(index, 1)
            }
            operIndex.current++
            saveLenRef.current = saveOprateList.current.length
            deleLenRef.current = deleteOperateList.current.length
        })        
    }
    /** 撤销/恢复操作 end **/

    const canvasSize = useMemo(() => {
        return Utils.getImgMaxSizeInContainer(
            imgWidth,
            imgHeight,
            deskSize.width,
            deskSize.height
        )
    }, [deskSize, imgWidth, imgHeight])

    const canvasWidth = useMemo(() => {
        return canvasSize.width
    }, [canvasSize])

    const canvasHeight = useMemo(() => {
        return canvasSize.height
    }, [canvasSize])
    
    const initCanvasInstance = () => {
        return new Promise(resolve => {
            const canvasInstance = new fabric.Canvas(canvasRef.current, {
                preserveObjectStacking: true,
            });
    
            canvas.current = canvasInstance;
            canvas.current.setDimensions({ width: canvasWidth, height: canvasHeight })
            canvas.current.selection = false // 禁用组选择器
                
            // 缩放监听事件
            canvas.current.on('object:scaling', (e) => {
                hideControlBtn()
                const activeObject = e.target;
    
                // 限制最小边缩放和最长边缩放
                const minShortEdge = 50;
                const maxLongEdge = canvas.current.width;
    
                // 计算对象的宽度和高度
                const width = activeObject.width * activeObject.scaleX;
                const height = activeObject.height * activeObject.scaleY;
                
                // 计算短边/长边的长度
                const shortEdge = Math.min(width, height);
                const longEdge = Math.max(width, height)
                
                if (shortEdge < minShortEdge) {
                    // 如果短边的长度小于最小值，就将缩放比例设置为,最小值对应的比例，并重新渲染画布。
                    const scale = minShortEdge / shortEdge;
                    activeObject.scaleX *= scale;
                    activeObject.scaleY *= scale;
                    canvas.current.renderAll();
                }
    
                // if (longEdge > maxLongEdge) {
                //     const scale = maxLongEdge / longEdge;
                //     activeObject.scaleX *= scale;
                //     activeObject.scaleY *= scale;
                //     canvas.current.renderAll();
                // }                       
            });
    
            canvas.current.on('selection:created', (e) => {
                // console.log(canvas.current.getActiveObject().getSrc())
                const selectedObject = e.selected[0];
                // console.log(selectedObject)
                setActiveObj(selectedObject)
                showControlBtn(selectedObject)
            });
            
            canvas.current.on('selection:updated', (e) => {
                const selectedObject = e.selected[0];
                setActiveObj(selectedObject)
                showControlBtn(selectedObject)
            });
    
            canvas.current.on('object:moving', (e) => {
                const obj = e.target;
                showControlBtn(obj)
                showcenterLine(obj)
            });
    
            canvas.current.on('object:modified', (e) => {
                saveOpreteDate() // 保存操作的数据
            })
    
            canvas.current.on('object:rotating', (e) => {
                const obj = e.target;
                showControlBtn(obj)
            });
    
            canvas.current.on('mouse:up', ev => {
                // 禁止移出画布
                let canvasBoundaries = canvas.current.calcViewportBoundaries()
                let obj = ev.target
                if(!obj) return false
    
                let objBoundingRect = obj ? obj.getBoundingRect() : null
    
                if (objBoundingRect.left > canvasBoundaries.br.x) {
                    ev.target.left = canvasBoundaries.br.x - objBoundingRect.width
                    hideControlBtn()
                }
                if (objBoundingRect.left + objBoundingRect.width < canvasBoundaries.tl.x) {
                    ev.target.left = canvasBoundaries.tl.x
                    hideControlBtn()
                }
                if (objBoundingRect.top > canvasBoundaries.br.y) {
                    ev.target.top = canvasBoundaries.br.y - objBoundingRect.height
                    hideControlBtn()
                }
                if (objBoundingRect.top + objBoundingRect.height < canvasBoundaries.tl.y) {
                    ev.target.top = canvasBoundaries.tl.y
                    hideControlBtn()
                }
    
                hidecenterLine()
                canvas.current.renderAll()          
            })
    
            canvas.current.on('selection:cleared', (e) => {
                hideControlBtn()
                hidecenterLine()
            });

            canvas.current.on('after:render', () => {
                // 通知drag变化情况
                props.onDragChange(handleClick())
            })

            if (bgImage) {
                fabric.Image.fromURL(bgImage, function(img, isError) {
                    const scale = canvas.current.width / img.width
                    img.scale(scale)
                    canvas.current.setBackgroundImage(img, canvas.current.renderAll.bind(canvas.current))
                    resolve()
                })
            } else {
                resolve()
            }
        })
    }
        
    const showcenterLine = (obj) => {
        // 显示中线--------------
        let center = obj.getCenterPoint();
        // 判断对象中心点是否与画布中心点重合
        let showHorizontalLine = Math.abs(center.y - canvas.current.height / 2) <= 1;
        let showVerticalLine = Math.abs(center.x - canvas.current.width / 2) <= 1;

        // 移除之前的中线对象
        // horizontalLine.current.set({ visible: false })
        // verticalLine.current.set({ visible: false })

        // 当对象中心点与画布中心点重合时，创建新的中线对象
        if (showHorizontalLine && !horizontalLine.current) {
            if (!horizontalLine.current) {
                horizontalLine.current = new fabric.Line([0, canvas.current.height / 2, canvas.current.width, canvas.current.height / 2], { stroke: colorCenterLine, selectable: false });
                horizontalLine.current.set('is_horizontal_line', true)
                canvas.current.add(horizontalLine.current);
            }
        } else {
            if (horizontalLine.current) {
                canvas.current.remove(horizontalLine.current)
                horizontalLine.current = null
            }
        }

        if (showVerticalLine) {
            if (!verticalLine.current) {
                verticalLine.current = new fabric.Line([canvas.current.width / 2, 0, canvas.current.width / 2, canvas.current.height], { stroke: colorCenterLine, selectable: false });
                verticalLine.current.set('is_vertical_line', true)
                canvas.current.add(verticalLine.current);
            }            
        } else {
            if (verticalLine.current) {
                canvas.current.remove(verticalLine.current)
                verticalLine.current = null
            }
        }
    }

    const hidecenterLine = () => {
        // 移除中线--------------
        if (horizontalLine.current) {
            canvas.current.remove(horizontalLine.current)
            horizontalLine.current = null
        }
        if (verticalLine.current) {
            canvas.current.remove(verticalLine.current)
            verticalLine.current = null
        }
    }

    // 撤销操作
    function handleUndo(event) {
        if (event.ctrlKey) {
            if (event.key == 'z') { // CTRL + Z
                preStepOperate().then(() => {
                    const objects = canvas.current.getObjects()
                    horizontalLine.current = objects.find(item => item.get('is_horizontal_line'))
                    verticalLine.current = objects.find(item => item.get('is_vertical_line'))
                })
            }
        }
    };

    // 方向键移动监听事件
    function handleObjectMoving(event) {
        if (event.key != 'ArrowUp' && event.key != 'ArrowDown' && event.key != 'ArrowLeft' && event.key != 'ArrowRight') return
        let moveDistance = 1
        if (event.shiftKey) moveDistance = 10 // 按住 Shift 键时的移动距离
        else moveDistance = 1 // 松开 Shift 键后恢复默认移动距离
        let obj = canvas.current.getActiveObject();
        if (obj) {
            switch (event.key) {
                case 'ArrowUp':
                    obj.set({ top: obj.top - moveDistance });
                    break;
                case 'ArrowDown':
                    obj.set({ top: obj.top + moveDistance });
                    break;
                case 'ArrowLeft':
                    obj.set({ left: obj.left - moveDistance });
                    break;
                case 'ArrowRight':
                    obj.set({ left: obj.left + moveDistance });
                    break;
            }

            showcenterLine(obj)
            canvas.current.renderAll();

            showControlBtn(obj)

            saveOpreteDate() // 保存操作的数据
        }
    }

    // 删除选中对象
    const handleDelete = (e) => {
        if (e.keyCode === 8 || e.keyCode === 46) {
            const activeObject = canvas.current.getActiveObject();
            if(!activeObject || activeObject.get('is_mask')) return false // 禁止删除分割出来的商品
            if (activeObject) {
                canvas.current.remove(activeObject);
                saveOpreteDate() // 保存操作的数据
            }
        }
    };

    const isMounted = useRef(true) // 判断组件是否已销毁
    useEffect(() => {
        document.addEventListener('keydown', handleDelete);
        document.addEventListener('keydown', handleUndo);
        document.addEventListener('keydown', handleObjectMoving);

        return () => {
            document.removeEventListener('keydown', handleDelete);
            document.removeEventListener('keydown', handleUndo);
            document.removeEventListener('keydown', handleObjectMoving);
            if (canvas.current) {
                canvas.current.dispose(); // 销毁
                canvas.current = null
            }                
            isMounted.current = false
        }
    }, [])

    const initMaskAndSoftedge = async (dragScale, maskUrl) => {
        if (!isMounted.current) return
        if (!dragScale || dragScale.length == 0) {
            const img = await loadImageUrl(maskUrl)
            loadMaskUrl(img)
            return
        }
        const oriDragScale = dragScale.find(item => item.name == 'ori')
        const softedges = dragScale.filter(item => item.name != "ori")
        // 获取softedge素材资源
        let softEdgeList = []
        if (softedges.length > 0) {
            try {
                const res = await API.commodityShowcaseList()
                if(res.code == 0) softEdgeList = res.data
            } catch (err) {
                console.error(err)
            }
        }
        
        const oriImg = await loadImageUrl(maskUrl)
        if (oriDragScale) {
            loadMaskUrl(oriImg, {
                x: oriDragScale.lt_x,
                y: oriDragScale.lt_y,
                angle: 360 - oriDragScale.angle,
                scale: oriDragScale.scale_rate,
                zIndex: oriDragScale.z_index,
                flipX: oriDragScale.horizontal,
                flipY: oriDragScale.vertical,
            })
        } else {
            loadMaskUrl(oriImg)
        }

        const softEdgeImgList = await Promise.all(softedges.map(item => {
            const softEdge = softEdgeList.find(softedge => softedge.imageUrl.split('?')[0].split('/').pop() == item.softedge_path)
            if (!softEdge) return Promise.resolve()
            return loadImageUrl(softEdge.imageUrl)
        }))
        for (let index in softedges) {
            const obj = softedges[index]
            const img = softEdgeImgList[index]
            // 恢复softedge
            if (!img) continue
            loadNewImage(img, {
                x: obj.lt_x,
                y: obj.lt_y,
                angle: 360 - obj.angle,
                scale: obj.scale_rate,
                zIndex: obj.z_index,
                flipX: obj.horizontal,
                flipY: obj.vertical,
            })
        }
    }

    useEffect(() => {
        if (!canvasWidth || !canvasHeight) return
        if (!initializedRef.current) {
            initializedRef.current = 1
            initCanvasInstance().then(() => {
                return initMaskAndSoftedge(props.dragScale, props.maskUrl)
            }).then(() => {
                clearOperateData() // 清除操作历史
                saveOpreteDate() // 保存操作的数据
            }).finally(() => {
                initializedRef.current = 2
            })
        }
    }, [props.dragScale, props.maskUrl, canvasWidth, canvasHeight])

    useEffect(() => {
        if (!canvasWidth || !canvasHeight) return
        if (!canvas.current) return

        const oldWidth = canvas.current.width
        const oldHeight = canvas.current.height

        canvas.current.setDimensions({ width: canvasWidth, height: canvasHeight })

        // 修改canvas中对象的位置和大小
        if (oldWidth && oldHeight) {

            const bgImg = canvas.current.backgroundImage
            if (bgImg) {
                const scaleFactorX = canvasWidth / oldWidth
                bgImg.scaleX *= scaleFactorX
                bgImg.scaleY *= scaleFactorX
                canvas.current.setBackgroundImage(bgImg, canvas.current.renderAll.bind(canvas.current))
            }

            canvas.current.forEachObject((obj) => {
                const scaleFactorX = canvasWidth / oldWidth
                const scaleFactorY = canvasHeight / oldHeight
    
                obj.left *= scaleFactorX
                obj.top *= scaleFactorY
                obj.scaleX *= scaleFactorX
                obj.scaleY *= scaleFactorX
    
                // 更新对象的位置和大小
                obj.setCoords()                    
            })
        }        
        const activeObj = canvas.current.getActiveObject()
        activeObj && showControlBtn(activeObj)

        canvas.current.renderAll()
    }, [canvasWidth, canvasHeight])

    const getObjScale = (objWidth, objHeight, imgWidth, imgHeight, canvasWidth, canvasHeight) => {
        // 商品图过小会导致多生的问题：用户上传商品后，工作台上显示的默认商品尺寸为，商品长边所在的画布边边*0.7
        const scaleFactorX = canvasWidth * .7 / objWidth
        const scaleFactorY = canvasHeight * .7 / objHeight
        const scaleFactor = Math.min(scaleFactorX, scaleFactorY)
        return scaleFactor
    }

    useEffect(() => {
        if (!imgWidth || !imgHeight) return
        if (!canvas.current) return        
        clearOperateData() // 清除操作历史

        const canvasWidth = canvas.current.width
        const canvasHeight = canvas.current.height

        // 修改canvas中对象的位置和大小
        canvas.current.forEachObject((obj) => {
        
            const scale = getObjScale(obj.width, obj.height, imgWidth, imgHeight, canvasWidth, canvasHeight)
            obj.scale(scale)
            canvas.current.centerObject(obj)

            if(refImgLocalInfo) {
                const canvaScale = canvasWidth / imgWidth

                // 原物体在当前画布上位置
                const height = refImgLocalInfo.height * canvaScale
                const width = refImgLocalInfo.width * canvaScale
                const left = refImgLocalInfo.left * canvaScale
                const top = refImgLocalInfo.top * canvaScale
                const objScaledHeight = obj.getScaledHeight()
                

                obj.scale(obj.scaleX * height / objScaledHeight)

                // 替换物体相对画布位置
                const objScaledWidth = obj.getScaledWidth()
                const objTop = top
                const objLeft = left + width / 2 - objScaledWidth / 2
                obj.left = objLeft
                obj.top = objTop
            }

            // 更新对象的位置和大小
            obj.setCoords()
        })       
        const activeObj = canvas.current.getActiveObject()
        activeObj && showControlBtn(activeObj)

        canvas.current.renderAll()

        saveOpreteDate() // 保存操作的数据
    }, [imgWidth, imgHeight, refImgLocalInfo])

    // 切换自定义场景 移除softedge
    useEffect(() => {
        if(imageType == IMAGE_TYPE.COMMODITY && isCustomScene) {
            if (!canvas.current) return
            const allObjects = canvas.current.getObjects();
            allObjects.map((item) => {
                if(item.is_softedge) {
                    canvas.current.remove(item)
                }
            })
            canvas.current.renderAll()
            saveOpreteDate() // 保存操作的数据
        }
    }, [isCustomScene])

    const loadImageUrl = (url) => {
        return new Promise(resolve => {
            fabric.Image.fromURL(url, (img) => {
                resolve(img)
            })
        })
    }

    // 添加mask
    const loadMaskUrl = useCallback((img, opts) => {
        if (!isMounted.current) return
        const scale = getObjScale(img.width, img.height, imgWidth, imgHeight, canvasWidth, canvasHeight)
        img.scale(scale);

        // 修改缩放框角的样式
        img.set({
            cornerColor: '#ffffff',
            cornerFill: '#ffffff', // 设置控制点的填充色为白色
            cornerStrokeColor: colorPrimary, // 设置控制点的边框颜色为#00a0ff
            borderColor: colorPrimary,     // 边框颜色
            cornerSize: 8,            // 角落大小
            transparentCorners: false, // 角落是否透明
            cornerStyle: 'circle',     // 角落样式，可选值有 'rect' 和 'circle'
            lockRotation: false,        // 禁用旋转功能
            lockScalingFlip: true // 禁用镜像翻转
        });

        // 将控制点设置为不可见
        img.setControlsVisibility({
            mt: false, // 隐藏上边中心点
            mb: false, // 隐藏下边中心点
            ml: false, // 隐藏左边中心点
            mr: false, // 隐藏右边中心点
            // mtr: false // 隐藏旋转点
        });

        img.set('is_mask', true)
        canvas.current.add(img);
        if (opts) {
            const relativeScale = (imgWidth / canvasWidth)
            img.set({
                left: opts.x / relativeScale,
                top: opts.y / relativeScale,
                scaleX: opts.scale / relativeScale,
                scaleY: opts.scale / relativeScale,
                flipX: !!opts.flipX,
                flipY: !!opts.flipY,
            })
            img.rotate(opts.angle)
        } else {
            img.center();
        }

        if(refImgLocalInfo) {
            const canvaScale = canvasWidth / imgWidth
            // 原物体在当前画布上位置
            const height = refImgLocalInfo.height * canvaScale
            const width = refImgLocalInfo.width * canvaScale
            const left = refImgLocalInfo.left * canvaScale
            const top = refImgLocalInfo.top * canvaScale
            const objScaledHeight = img.getScaledHeight()
            
            img.scale(img.scaleX * height / objScaledHeight)

            // 替换物体相对画布位置
            const objScaledWidth = img.getScaledWidth()
            const objTop = top
            const objLeft = left + width / 2 - objScaledWidth / 2
            img.left = objLeft
            img.top = objTop
        }

        // 需要setCoords，否则找不到物体位置
        img.setCoords()
        canvas.current.renderAll();
    }, [canvasWidth, canvasHeight, imgWidth, imgHeight])
    
    // 添加softedge
    const loadNewImage = useCallback((img, opts) => {
        const scale = getObjScale(img.width, img.height, imgWidth, imgHeight, canvasWidth, canvasHeight)
        img.scale(scale);

        // 修改缩放框角的样式
        img.set({
            cornerColor: '#ffffff',
            cornerFill: '#ffffff', // 设置控制点的填充色为白色
            cornerStrokeColor: colorPrimary, // 设置控制点的边框颜色为#00a0ff
            borderColor: colorPrimary,
            cornerSize: 8,
            transparentCorners: false,
            cornerStyle: 'circle',
            lockRotation: false,
            lockScalingFlip: true
        });
        
        img.setControlsVisibility({
            mt: false, // 隐藏上边中心点
            mb: false, // 隐藏下边中心点
            ml: false, // 隐藏左边中心点
            mr: false, // 隐藏右边中心点
            // mtr: false // 隐藏旋转点
        });

        img.set("is_softedge", true)
        
        canvas.current.add(img);

        if (opts) {
            const relativeScale = (imgWidth / canvasWidth)
            img.set({
                left: opts.x / relativeScale,
                top: opts.y / relativeScale,
                scaleX: opts.scale / relativeScale,
                scaleY: opts.scale / relativeScale,
                flipX: !!opts.flipX,
                flipY: !!opts.flipY,
            })
            img.rotate(opts.angle)
            img.setCoords()
        } else {
            const randomLeft = Math.floor(Math.random() * (canvasWidth - img.getScaledWidth()));
            const randomTop = Math.floor(Math.random() * (canvasHeight - img.getScaledHeight()));
            img.set({ left: randomLeft, top: randomTop });
            img.setCoords()
        }

        canvas.current.renderAll();
    }, [canvasWidth, canvasHeight, imgWidth, imgHeight])

    const loadNewImageFunc = (url) => {
        const objects = canvas.current.getObjects()
        if(objects.length < 11) {
            loadImageUrl(url).then(img => {
                loadNewImage(img)
                saveOpreteDate() // 保存操作的数据
            })            
        } else {
            message.warning('超出添加数量限制！')
        }
    }

    // 切换或取消参考图时触发
    useEffect(() => {
        if (!canvas.current) return
        if (bgImage) {
            clearOperateData() // 清除操作历史
            fabric.Image.fromURL(bgImage, function(img, isError) {
                const scale = canvas.current.width / img.width
                img.scale(scale)
                canvas.current.setBackgroundImage(img, () => {
                    canvas.current.renderAll()
                    saveOpreteDate() // 保存操作的数据
                })
            })
        } else {
            clearOperateData() // 清除操作历史
            canvas.current.setBackgroundImage(null, () => {
                canvas.current.renderAll()
                saveOpreteDate() // 保存操作的数据
            })
        }
    }, [bgImage])
    
    const dragBtnBox = useRef()
    const showControlBtn = (obj) => {
        if (!dragBtnBox.current) return
        const clientWidth = dragBtnBox.current.clientWidth
        const clientHeight = dragBtnBox.current.clientHeight
        const canvasWidth = canvas.current.width
        const canvasHeight = canvas.current.height

        // 旋转到一定角度时浮框位置调整，避免遮挡
        if (obj.angle > 145 && obj.angle < 215) {
            dragBtnBox.current.style.top = Math.min(Math.max(obj.getCenterPoint().y - (obj.getBoundingRect().height) /2 - 5 - clientHeight, 0), canvasHeight - clientHeight) +  'px' // getBoundingRect 取得是蓝色框实时变化
        } else {
            dragBtnBox.current.style.top = Math.min(Math.max(obj.getCenterPoint().y + (obj.getBoundingRect().height) /2 + 5, 0), canvasHeight - clientHeight) +  'px' // getBoundingRect 取得是蓝色框实时变化
        }
        dragBtnBox.current.style.left = Math.min(Math.max(obj.getCenterPoint().x - (obj.width * obj.scaleX) /2, 0), canvasWidth - clientWidth) + 'px' 
        dragBtnBox.current.style.visibility = 'visible'
    }

    const hideControlBtn = () => {
        if (!dragBtnBox.current) return
        dragBtnBox.current.style.visibility = 'hidden'
    }    

    const getAxisObj = (object, name, zIndex) => {
        let objItem ={}
        const obj = object
        const scaleX = obj.scaleX;

        const canvasWidth = canvas.current.width
        const canvasHeight = canvas.current.height

        // const relativeScale = 512 / width
        const relativeScaleX = imgWidthRef.current / canvasWidth
        const relativeScaleY = imgHeightRef.current / canvasHeight
        // console.log('cw', cw, Math.floor(obj.left * relativeScaleX))
        // console.log('ch', ch, Math.floor(obj.top * relativeScaleY))
        // console.log('scaleX', scaleX, scaleX * relativeScaleX)

        // 获取中心点坐标，取摆正后左上角坐标
        const centerPointer = obj.getCenterPoint()
        const objScaleWidth = object.width * object.scaleX
        const objScaleHight = object.height * object.scaleX
        const objScaleX = centerPointer.x - (objScaleWidth / 2)
        const objScaleY = centerPointer.y - (objScaleHight / 2)

        // objItem.lt_x = Math.floor(obj.left * relativeScaleX)
        // objItem.lt_y = Math.floor(obj.top * relativeScaleY)
        objItem.lt_x = Math.floor(objScaleX * relativeScaleX)
        objItem.lt_y = Math.floor(objScaleY * relativeScaleY)
        objItem.angle = 360 - Math.floor(object.angle)
        objItem.scale_rate = scaleX * relativeScaleX
        objItem.vertical = object.get('flipY')?1:0
        objItem.horizontal = object.get('flipX')?1:0
        objItem.mask_path = name == 'ori'? '': object.getSrc().split('?')[0].split('/').pop().replace("_softedge", "_mask")
        objItem.img_path = name == 'ori'? '': object.getSrc().split('?')[0].split('/').pop().replace("_softedge", "")
        objItem.softedge_path = name == 'ori'? '': object.getSrc().split('?')[0].split('/').pop()
        objItem.name = name == 'booth'?object.getSrc().split('softedge/')[1].split('.png')[0]: name
        objItem.z_index = zIndex
        // console.log(object.angle)
        
        return objItem
    }

    const dragClick = (flag) => {
        if(!activeObj) return false;
        switch(flag) {
            case 'bottom':
                activeObj.sendToBack()
                saveOpreteDate() // 保存操作的数据
                break;
            case 'down':
                activeObj.sendBackwards()
                saveOpreteDate() // 保存操作的数据
                break;
            case 'up':
                activeObj.bringForward()
                saveOpreteDate() // 保存操作的数据
                break;
            case 'top':
                activeObj.bringToFront()
                saveOpreteDate() // 保存操作的数据
                break;
            default:
                break;
        }
    }

    const overturnClick = (val) => {
        if(!activeObj) return false;
        if(val == 'horizontal') {
            activeObj.flipX = !activeObj.flipX;
        } else if(val == 'vertical') {
            activeObj.flipY = !activeObj.flipY;
        }
        canvas.current.renderAll();
        saveOpreteDate() // 保存操作的数据
    }

    const handleClick = () => { // 获取所有对象层级
        const objects = canvas.current.getObjects();
        let arr = []
        for (let i = 0; i < objects.length; i++) {
            const object = objects[i];
            if (object.type == 'line') continue
            // 获取对象的层级
            const index = canvas.current.getObjects().indexOf(object);
            if(object.get('is_mask')) {
              arr.push(getAxisObj(object, 'ori', index))
            } else {
              arr.push(getAxisObj(object, 'booth', index))
            }
        }
        return arr
    }

    const deleteClick = () => {
        if (activeObj) {
            canvas.current.remove(activeObj);
            saveOpreteDate() // 保存操作的数据
        }
    }

    const copyClick = () => {
        loadNewImageFunc(activeObj.getSrc())
    }

    return (
        <div className={styles.Drag}>
            <canvas ref={canvasRef}></canvas>
            <div className={styles.dragBtnBox} ref={dragBtnBox}>
              {/* <div onClick={() => dragClick('bottom')}>置底</div> */}
              {/* <div onClick={() => dragClick('top')}>置顶</div> */}
              
              <div className={`${styles.menuItem} ${styles.menuItem1}`} onClick={() => dragClick('up')}>
                  <div className={styles.imgContent}></div>
                  <span>上一层</span>
              </div>
              <div className={`${styles.menuItem} ${styles.menuItem2}`} onClick={() => dragClick('down')}>
                  <div className={styles.imgContent}></div>
                  <span>下一层</span>
              </div>
              <div className={`${styles.menuItem} ${styles.menuItem5}`} onClick={() => overturnClick('horizontal')}>
                  <div className={styles.imgContent}></div>
                  <span>水平</span>
              </div>
              <div className={`${styles.menuItem} ${styles.menuItem6}`} onClick={() => overturnClick('vertical')}>
                  <div className={styles.imgContent}></div>
                  <span>垂直</span>
              </div>
              {
                !activeObj?.is_mask?<div className={`${styles.menuItem} ${styles.menuItem4}`} onClick={copyClick}>
                    <div className={styles.imgContent}></div>
                    <span>复制</span>
                </div>: null
              }
              {
                !activeObj?.is_mask?<div className={`${styles.menuItem} ${styles.menuItem3}`} onClick={deleteClick}>
                    <div className={styles.imgContent}></div>
                    <span>删除</span>
                </div>: null
              }
             
            </div>
        </div>
    )
})

export default Drag