import {FACE_OPTIONS, HAIR_OPTIONS} from '@utils/CONST'
import { ALGO_TYPE, IMAGE_TYPE } from './CONST'

export const formartGenerateParams = (imageType, {
    bgStyle, // 背景选择
    isCustomScene, // 自定义场景
    isSceneFracturing, // 场景裂变
    isSameStyle, // 拍同款
    isSimilarStyle, // 相似风格
    refVersion, // 自定义场景的版本，由于商品
    faceTagText,
    hairTagText,
}) => {
    // 真人图特征
    const faceTag = FACE_OPTIONS.find(item => item.text == faceTagText)?.value || 'default'
    const hairTag = HAIR_OPTIONS.find(item => item.text == hairTagText)?.value || 'default'
    const extra_property = [hairTag, faceTag].filter(item => item !== "default").join(',')
    
    // 算法类型
    const algo_type = 
        imageType == IMAGE_TYPE.MODEL ? ALGO_TYPE.MODEL :
        imageType == IMAGE_TYPE.DRESSFORM ? ALGO_TYPE.DRESSFORM :
        imageType == IMAGE_TYPE.COMMODITY ? (
            refVersion == 3 ?
                ALGO_TYPE.COMMODITY_REMOVE :
                ALGO_TYPE.COMMODITY
        ) :
        imageType == IMAGE_TYPE.CLOTHES ? ALGO_TYPE.CLOTHES : ""

    // keep_bkg参数
    let keep_bkg
    if (imageType == IMAGE_TYPE.MODEL || imageType == IMAGE_TYPE.DRESSFORM) {
        if (isCustomScene) {
            if (isSceneFracturing) keep_bkg = 3 // 场景裂变
            else if (isSimilarStyle) keep_bkg = 0 // 相似风格
            else if (isSameStyle) keep_bkg = 0 // 相似风格
        } else {
            if (bgStyle == '00_keep') { // 保持原图场景
                keep_bkg = 2
            } else {
                keep_bkg = 0
            }
        } 
    }

    return {
        extra_property,
        algo_type,
        keep_bkg,
    }
}