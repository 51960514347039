import { useEffect, useRef, useState } from "react"

const useCanvasMove = (props) => {
    const { instance, bgInstance, topInstance } = props
    // 是否可以移动
    const moveableRef = useRef(props.moveable)
    // 定义变量来保存初始状态
    const isDraggingRef = useRef(false)
    const lastPosXRef = useRef(0)
    const lastPosYRef = useRef(0)
    const lastScaleRef = useRef(1)
    const [lastScale, setLastScale] = useState(1)


    const [zoomToPoint, setZoomToPoint] = useState({x: 0, y: 0})
    const zoomToPointRef = useRef({x: 0, y: 0})

    // 移动控制
    const [viewportOffset, setViewportOffset] = useState([1,0,0,1,0,0])

    useEffect(() => {
        moveableRef.current = props.moveable
    }, [props.moveable])

    const resetCanvas = () => {
        if (!instance) return
        instance.viewportTransform = [1,0,0,1,0,0] // 重置canvas位置
        bgInstance.viewportTransform = [1,0,0,1,0,0] // 重置canvas位置
        topInstance.viewportTransform = [1,0,0,1,0,0] // 重置canvas位置
        lastScaleRef.current = 1
        setLastScale(1)
        instance.renderAll()
        bgInstance.renderAll()
        topInstance.renderAll()

        setViewportOffset([...instance.viewportTransform])
    }

    const resizeCanvas = (viewportTransform) => {
        instance.viewportTransform = viewportTransform // 重置canvas位置
        bgInstance.viewportTransform = viewportTransform // 重置canvas位置
        topInstance.viewportTransform = viewportTransform // 重置canvas位置
        instance.renderAll()
        bgInstance.renderAll()
        topInstance.renderAll()

        setViewportOffset([...instance.viewportTransform])
    }

    // ctrl + 鼠标滚轮: 缩放画笔大小
    const ctrlKeyPressedRef = useRef(false)
    useEffect(() => {
        const keydown = ev => {
            if ((ev.key == 'Control' || ev.code == 'ControlLeft' || ev.code == 'ControlRight' || ev.keyCode == 17)) {
                ctrlKeyPressedRef.current = true
            }
        }
        const keyup = ev => {
            if (ev.key == 'Control' || ev.code == 'ControlLeft' || ev.code == 'ControlRight' || ev.keyCode == 17) {
                ctrlKeyPressedRef.current = false
            }
        }
        // 监听键盘按下
        document.addEventListener('keydown', keydown)
        document.addEventListener('keyup', keyup)
        return () => {
            document.removeEventListener('keydown', keydown)
            document.removeEventListener('keyup', keyup)
        }
    }, [])

    useEffect(() => {
        if (!instance) return
        const objects = instance.getObjects()
        if (props.moveable) {
            objects.forEach(item => item.set({hoverCursor: 'move'}))
        } else {
            objects.forEach(item => item.set({hoverCursor: 'pointer'}))
        }
    }, [instance, props.moveable])

    useEffect(() => {
        if (!instance) return
        zoomToPointRef.current = { x: instance.width / 2, y: instance.height / 2 }
        setZoomToPoint(zoomToPointRef.current)
        // 启用交互式操作
        instance.isDrawingMode = false // 禁用绘图模式
        instance.selection = false // 禁用选中对象
        // 监听鼠标按下事件
        instance.on('mouse:down', (ev) => {
            if (!moveableRef.current) return
            // 仅在按下鼠标左键时触发
            if (ev.e.button === 0) {
                isDraggingRef.current = true;
                lastPosXRef.current = ev.e.clientX;
                lastPosYRef.current = ev.e.clientY;
                lastScaleRef.current = instance.getZoom();
                setLastScale(lastScaleRef.current)
            }
        })
        // 监听鼠标移动事件
        instance.on('mouse:move', function(event) {
            if (!moveableRef.current) return
            if (isDraggingRef.current) {
                const currentPosX = event.e.clientX;
                const currentPosY = event.e.clientY;
            
                // 计算移动的距离
                const deltaX = currentPosX - lastPosXRef.current;
                const deltaY = currentPosY - lastPosYRef.current;
            
                // 移动画布
                instance.viewportTransform[4] += deltaX;
                instance.viewportTransform[5] += deltaY;

                bgInstance.viewportTransform[4] += deltaX;
                bgInstance.viewportTransform[5] += deltaY;

                topInstance.viewportTransform[4] += deltaX;
                topInstance.viewportTransform[5] += deltaY;

                // offsetXRef.current = instance.viewportTransform[4]
                // offsetYRef.current = instance.viewportTransform[5]
            
                // 更新画布显示
                instance.requestRenderAll();
                bgInstance.requestRenderAll()
                topInstance.requestRenderAll()
            
                // 更新上一次位置
                lastPosXRef.current = currentPosX;
                lastPosYRef.current = currentPosY;

                setViewportOffset([...instance.viewportTransform])
            }
        })
        // 监听鼠标释放事件
        instance.on('mouse:up', function(event) {
            if (!moveableRef.current) return
            // 仅在释放鼠标左键时触发
            if (event.e.button === 0) {
                isDraggingRef.current = false;
            }
        })
        // 监听鼠标滚轮事件
        instance.on('mouse:wheel', function(event) {
            if (ctrlKeyPressedRef.current) return
            // if (!moveableRef.current) return
            const delta = event.e.deltaY;
            let zoom = instance.getZoom();
        
            // 根据滚轮滚动方向调整缩放比例
            if (delta > 0) {
                zoom *= 0.95; // 缩小画布
            } else {
                zoom *= 1.05; // 放大画布
            }

            zoom = Math.max(Math.min(zoom, props.maxZoom), props.minZoom)
        
            // 设置缩放origin
            zoomToPointRef.current = { x: event.e.offsetX, y: event.e.offsetY }
            setZoomToPoint(zoomToPointRef.current)
            lastScaleRef.current = zoom
            setLastScale(zoom)
            
            event.e.preventDefault();
            event.e.stopPropagation();
        })
    }, [instance])


    useEffect(() => {
        if (!instance) return
        instance.zoomToPoint(zoomToPointRef.current, props.zoom);
        bgInstance.zoomToPoint(zoomToPointRef.current, props.zoom);
        topInstance.zoomToPoint(zoomToPointRef.current, props.zoom);
        instance.renderAll()
        bgInstance.renderAll()
        topInstance.renderAll()
        lastScaleRef.current = props.zoom
        setLastScale(props.zoom)

        setViewportOffset([...instance.viewportTransform])
    }, [props.zoom, instance])

    return {
        zoom: lastScale,
        zoomToPoint: zoomToPoint,
        viewportOffset: viewportOffset,
        resetCanvas,
        resizeCanvas,
    }
}

export default useCanvasMove