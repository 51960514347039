import { useEffect, forwardRef, useImperativeHandle, useRef, useState, useContext } from "react";
import { fabric } from 'fabric';
import { AIEditContext } from "../../AIEditContext";

const formartNumber = (number, digits = 2) => {
    return Number(number.toFixed(digits))
}

const MagicEraseCanvas = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        clear,
        getDataURL,
        getSaveData,
        undo,
        loadSaveData,
    }));

    const { addWatermark, eraseType } = useContext(AIEditContext)

    const eraseTypeRef = useRef()

    const bgCanvasRef = useRef()
    const bgInstanceRef = useRef()

    const canvasRef = useRef()
    const fabricInstanceRef = useRef()
    const [fabricInstance, setFabricInstance] = useState()

    const pencilBrushRef = useRef()
    const circleMouseRef = useRef()

    const moveableRef = useRef(props.moveable)
    const ctrlPressedRef = useRef(false)
    const displayScaleRef = useRef(props.displayScale)

    // 删除icon
    let deleteIcon = "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='595.275px' height='595.275px' viewBox='200 215 230 470' xml:space='preserve'%3E%3Ccircle style='fill:%23F44336;' cx='299.76' cy='439.067' r='218.516'/%3E%3Cg%3E%3Crect x='267.162' y='307.978' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)' style='fill:white;' width='65.545' height='262.18'/%3E%3Crect x='266.988' y='308.153' transform='matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)' style='fill:white;' width='65.544' height='262.179'/%3E%3C/g%3E%3C/svg%3E";
    let deleteImg = document.createElement('img');
    deleteImg.src = deleteIcon;

    function renderIcon(icon) {
        return function renderIcon(ctx, left, top, styleOverride, fabricObject) {
            var size = this.cornerSize;
            ctx.save();
            ctx.translate(left, top);
            ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
            ctx.drawImage(icon, -size / 2, -size / 2, size, size);
            ctx.restore();
        }
    }

    useEffect(() => {
        ctrlPressedRef.current = props.ctrlKeyPressed
    }, [props.ctrlKeyPressed])

    useEffect(() => {
        displayScaleRef.current = props.displayScale
    }, [props.displayScale])

    useEffect(() => {
        moveableRef.current = props.moveable
    }, [props.moveable])

    const isUndoableType = (item) => {
        return ['path', 'rect', 'circle'].includes(item.type);
    };

    const getDataURL = () => {
        // console.log('getDataURL')
        const pathList = fabricInstanceRef.current.getObjects().filter(item => isUndoableType(item))
        const tempCanvas = new fabric.StaticCanvas(null, {
            width: fabricInstanceRef.current.width,
            height: fabricInstanceRef.current.height,
            backgroundColor: '#000'
        })
        tempCanvas.add(...pathList)
        const exportPath = tempCanvas.toDataURL({ format: 'png', multiplier: 1 / displayScaleRef.current })
        return exportPath
    }
    const getSaveData = () => {
        console.log('getSaveData')
        const bgSaveData = bgInstanceRef.current.toJSON()
        const saveData = fabricInstanceRef.current.toJSON()
        return {
            bgjson: bgSaveData,
            json: saveData,
            lines: saveData.objects.filter(item => isUndoableType(item))
        }
    }
    const undo = () => {
        const pathList = fabricInstanceRef.current.getObjects().filter(item => isUndoableType(item))
        if (pathList.length > 0) {
            fabricInstanceRef.current.remove(pathList.pop())
        }
        props.getIsDraw(pathList)
    }
    const loadSaveData = ({ bgjson, json, lines }) => {
        // 只加载前景画布的绘制内容，避免重复加载背景
        fabricInstanceRef.current.loadFromJSON(json, () => {
            fabricInstanceRef.current.setDimensions({ width: props.displayWidth, height: props.displayHeight });

            const objs = fabricInstanceRef.current.getObjects();
            let allObj = objs.filter(item => item.type == 'rect' || item.type == 'path')
            let allObj2 = objs.filter(item => item.strokeLineCap == 'round') // 这是涂抹路径区别与圈选路径的属性
            if (allObj.length > 0) {
                allObj.map((item) => {
                    item.set({
                        hasControls: true,
                        selectable: true,
                        evented: true,
                        cornerSize: 8,
                    });
                    item.setControlsVisibility({
                        mtr: false,
                    });
                })
            }
            if(allObj2.length > 0) {
                allObj2.map((item) => {
                    item.set({
                        hasControls: false,
                        selectable: false,
                        evented: false,
                    });
                })
            }
            fabricInstanceRef.current.renderAll();
        });
        props.getIsDraw(lines);
    };

    const clear = () => {
        // console.log('clear')
        pencilBrushRef.current._reset() // 清除path
        const pathList = fabricInstanceRef.current.getObjects().filter(item => isUndoableType(item))
        fabricInstanceRef.current.remove(...pathList)
    }

    useEffect(() => {
        // 马赛克
        const patternImageBase64 = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABHNCSVQICAgIfAhkiAAAADBJREFUOE9jfPP69X8GPEBYRASfNAPjqAHDIgz+AwG+iH7z5s1ZfPKMowYwDIMwAACuR1xZvSaHFwAAAABJRU5ErkJggg==';
        const pattern = new fabric.Pattern({
            source: patternImageBase64,
            repeat: 'repeat',
        })
        // 初始化canvas对象
        fabric.Image.fromURL(props.url, async (img) => {
            img.scale(props.displayScale) // 背景展示完全
            // 背景canvas
            const bgInstance = new fabric.Canvas(bgCanvasRef.current, {
                width: props.displayWidth,
                height: props.displayHeight,
                backgroundColor: pattern, // 加载马赛克背景
                backgroundImage: img, // 加载默认背景
                fireRightClick: false, // 启用右键
                stopContextMenu: true, // 禁用右键菜单
                isDrawingMode: false, // 禁用绘图模式
                // selection: false, // 禁用选中对象
            })
            await addWatermark(bgInstance) // 添加水印
            bgInstanceRef.current = bgInstance

            // 画笔canvas
            const instance = new fabric.Canvas(canvasRef.current, {
                width: props.displayWidth,
                height: props.displayHeight,
                fireRightClick: false, // 启用右键
                stopContextMenu: true, // 禁用右键菜单
                isDrawingMode: false, // 禁用绘图模式
                // selection: false, // 禁用选中对象
            })
            instance.includeDefaultValues = false

            // 初始化画布
            const brush = new fabric.PencilBrush(instance)
            pencilBrushRef.current = brush
            brush.color = 'rgb(255, 255, 255)'
            brush.width = props.size //props.size * props.displayScale
            brush.limitedToCanvasSize = true
            instance.isDrawingMode = true
            instance.freeDrawingBrush = pencilBrushRef.current
            instance.freeDrawingCursor = 'pointer'

            // 画笔位置
            const circle = new fabric.Circle({
                left: 0,
                top: 0,
                radius: props.size / 2, // props.size * props.displayScale / 2
                fill: 'rgba(255, 255, 255, .3)',
                strokeWidth: 2,
                stroke: 'white',
                originX: 'center',
                originY: 'center',
                hasControls: false,
                hasBorders: false,
                selectable: false,
            })
            circleMouseRef.current = circle
            instance.bringToFront(circle)

            fabricInstanceRef.current = instance
            setFabricInstance(instance)
            props.setFabricInstance(bgInstance, instance)
        }, { crossOrigin: 'anonymous' })
    }, [])

    useEffect(() => {
        if (!bgInstanceRef.current) return
        const bgImage = bgInstanceRef.current.get('backgroundImage')
        bgImage.setSrc(props.url, () => bgInstanceRef.current.renderAll(), { crossOrigin: 'anonymous' })
    }, [props.url])

    useEffect(() => {
        if (!pencilBrushRef.current) return
        if (!fabricInstanceRef.current) return
        pencilBrushRef.current.width = props.size // props.size * props.displayScale
        circleMouseRef.current.set({ radius: props.size / 2 }) // props.size * props.displayScale / 2
        fabricInstanceRef.current.renderAll()
    }, [props.size, props.displayScale])

    useEffect(() => { // 控制是否启用绘图模式
        if (!fabricInstanceRef.current) return
        if (props.moveable || eraseTypeRef.current != 'draw') {
            fabricInstanceRef.current.isDrawingMode = false
        } else {
            fabricInstanceRef.current.isDrawingMode = true
        }
    }, [props.moveable, eraseTypeRef.current])

    const drawingRef = useRef()
    useEffect(() => {
        if (!fabricInstance) return

        fabricInstance.on('mouse:down', (event) => {
            if (moveableRef.current) return
            if (eraseTypeRef.current === 'draw') {
                drawingRef.current = true;
            } else if (eraseTypeRef.current === 'rect') {
                drawingRef.current = true;
                startRect(event);
            } else if (eraseTypeRef.current === 'circle') {
                const { x, y } = fabricInstanceRef.current.getPointer(event.e);
                points.push([x, y]);  // 记录起始点
                fabricInstanceRef.current.selection = false; // 禁止出现默认的矩形框
                drawingRef.current = true;
            }
        })
        fabricInstance.on('mouse:move', (event) => {
            if (eraseTypeRef.current === 'rect' && drawingRef.current) {
                moveRect(event);
            }
            if (eraseTypeRef.current === 'draw') {
                updatePathPosition(event);
            }
            if (eraseTypeRef.current === 'circle') {
                updateCirclePosition(event);
            }
        })
        fabricInstance.on('mouse:up', () => {
            if (eraseTypeRef.current === 'draw') {
                drawingRef.current = false;
            } else if (eraseTypeRef.current === 'rect' && drawingRef.current) {
                endRect();
                drawingRef.current = false;
            } else if (eraseTypeRef.current === 'circle' && drawingRef.current) {
                endCircle();
                drawingRef.current = false;
            }
        })
        fabricInstance.on('path:created', ({ path }) => {
            path.set({ selectable: false })
            const pathList = fabricInstanceRef.current.getObjects().filter(item => item.type == 'path')
            props.getIsDraw(pathList)
        })
        fabricInstance.on('mouse:wheel', function (event) {
            if (!circleMouseRef.current) return
            if (!ctrlPressedRef.current) return

            event.e.preventDefault();
            event.e.stopPropagation();

            const delta = event.e.deltaY;
            // 根据滚轮滚动方向调整缩放比例
            if (delta > 0) {
                props.changeBrushSize(-1)
            } else {
                props.changeBrushSize(1)
            }
        })

        // 监听鼠标移入事件，显示圆形画笔----------------处理涂抹移出画布情况
        fabricInstance.on('mouse:over', () => {
            if (!circleMouseRef.current) return;

            let circleRef = null;
            if (Array.isArray(circleMouseRef.current)) {
                circleRef = circleMouseRef.current.find((item) => item.type == 'circle');
            } else {
                circleRef = circleMouseRef.current;
            }

            if (circleRef && !fabricInstance.contains(circleRef) && eraseTypeRef.current === 'draw') {
                fabricInstance.add(circleRef);
                fabricInstance.bringToFront(circleRef);
            }
        });

        // 监听鼠标移出事件，隐藏圆形画笔
        fabricInstance.on('mouse:out', () => {
            // fabricInstanceRef.current.discardActiveObject(); // 鼠标移出矩形或者圈选对象，取消选中
            // fabricInstanceRef.current.renderAll();

            if (!circleMouseRef.current) return;
            let circleRef = null;
            if (Array.isArray(circleMouseRef.current)) {
                circleRef = circleMouseRef.current.find((item) => item.type == 'circle');
            } else {
                circleRef = circleMouseRef.current;
            }
            if (fabricInstance.contains(circleRef)) {
                fabricInstance.remove(circleRef);
            }
        });

    }, [fabricInstance])

    // 组件卸载
    useEffect(() => {
        return () => {
            if (fabricInstanceRef.current) {
                fabricInstanceRef.current.off('mouse:down');
                fabricInstanceRef.current.off('mouse:move');
                fabricInstanceRef.current.off('mouse:up');
            }
        };
    }, []);

    // 更新涂抹圆形的画笔位置
    const updatePathPosition = (event) => {
        if (!fabricInstanceRef.current || !circleMouseRef.current) return;

        const { offsetX, offsetY } = event.e;

        const canvasOffsetX = fabricInstanceRef.current.viewportTransform[4];
        const canvasOffsetY = fabricInstanceRef.current.viewportTransform[5];
        const zoom = fabricInstanceRef.current.getZoom();

        const x = (offsetX - canvasOffsetX) / zoom;
        const y = (offsetY - canvasOffsetY) / zoom;
        if (Array.isArray(circleMouseRef.current) && circleMouseRef.current) {
            console.log(circleMouseRef.current)
            console.log(circleMouseRef.current.find(item => item.type == 'circle'))
            circleMouseRef.current.find(item => item.type == 'circle').set({
                left: x,
                top: y,
            });
        } else {
            circleMouseRef.current.set({
                left: x,
                top: y,
            });
        }
        fabricInstanceRef.current.renderAll();
    };

    // 调整指针样式--------------------
    useEffect(() => {
        if (!fabricInstanceRef.current) return
        eraseTypeRef.current = eraseType
        if (fabricInstanceRef.current) {
            if (eraseTypeRef.current === 'draw') {
                fabricInstanceRef.current.isDrawingMode = true
            } else if (eraseTypeRef.current === 'rect') {
                fabricInstanceRef.current.defaultCursor = 'crosshair'; // 十字光标
                fabricInstanceRef.current.isDrawingMode = false // 禁用画笔
            } else if (eraseTypeRef.current === 'circle') {
                fabricInstanceRef.current.isDrawingMode = false;
            }
        }

    }, [eraseType, fabricInstanceRef.current]);

    // 删除按钮方法--------------------
    const deleteIconFun = (obj) => {
        obj.controls.deleteControl = new fabric.Control({
            x: 0.5,
            y: -0.5,
            offsetY: -12,
            offsetX: 12,
            cursorStyle: 'pointer',
            render: renderIcon(deleteImg),
            cornerSize: 20,
            mouseUpHandler: function(eventData, transform) {
                // 删除
                const target = transform.target;
                if (target) {
                    props.getIsDraw(target);
                    fabricInstanceRef.current.remove(target); 
                    fabricInstanceRef.current.renderAll();
                }
            }
        })
    }


    // 绘制矩形方法--------------------
    const rectRef = useRef(); // 存储当前绘制的矩形框

    const startRect = (event) => {
        const activeObject = fabricInstanceRef.current.getActiveObject();
        if (activeObject) { // 选中对象后禁止绘制新的矩形
            return;
        }

        const { offsetX, offsetY } = event.e;
        const canvasOffsetX = fabricInstanceRef.current.viewportTransform[4];
        const canvasOffsetY = fabricInstanceRef.current.viewportTransform[5];
        const zoom = fabricInstanceRef.current.getZoom();
        const x = (offsetX - canvasOffsetX) / zoom;
        const y = (offsetY - canvasOffsetY) / zoom;

        const rect = new fabric.Rect({
            left: x,
            top: y,
            width: 0,
            height: 0,
            fill: 'rgba(57, 62, 253, 0.2)',
            stroke: '#fff',
            strokeDashArray: [5, 5],
            strokeWidth: 2,
            selectable: true,
            evented: true,
            lockRotation: true,
            cornerSize: 8,
        });
        rect.setControlsVisibility({
            mtr: false, // 隐藏旋转点
        });
        deleteIconFun(rect)
       

        // 保存初始鼠标点击位置
        rect.startX = x;
        rect.startY = y;

        fabricInstanceRef.current.add(rect);
        rectRef.current = rect;
    };


    const moveRect = (event) => {
        if (!rectRef.current  || !drawingRef.current) return;

        const { offsetX, offsetY } = event.e;
        const canvasOffsetX = fabricInstanceRef.current.viewportTransform[4];
        const canvasOffsetY = fabricInstanceRef.current.viewportTransform[5];
        const zoom = fabricInstanceRef.current.getZoom();
        const x = (offsetX - canvasOffsetX) / zoom;
        const y = (offsetY - canvasOffsetY) / zoom;

        const rect = rectRef.current;

        // 计算矩形宽高，确保其为正值
        const width = Math.abs(x - rect.startX);
        const height = Math.abs(y - rect.startY);

        // 如果鼠标向左移动，将 left 设置为鼠标的当前位置
        rect.set({
            left: x < rect.startX ? x : rect.startX,
            top: y < rect.startY ? y : rect.startY,
            width: width,
            height: height,
        });

        fabricInstanceRef.current.renderAll();
    };

    const endRect = () => {
        if (rectRef.current) {
            rectRef.current.setCoords(); // 完成时设置坐标
            rectRef.current = null;

            const rectList = fabricInstanceRef.current.getObjects().filter(item => item.type == 'rect');
            props.getIsDraw(rectList);
        }
    };
    // 绘制矩形方法--------------------


    // 圈选功能》》》》》》》》》》》》》》

    const points = []; // 记录圈选移动路径

    const updateCirclePosition = (event) => {
        const activeObject = fabricInstanceRef.current.getActiveObject();
        if (activeObject || !drawingRef.current) {
            return;
        }

        if (eraseTypeRef.current === 'circle' && points.length > 0) {
            const { offsetX, offsetY } = event.e;
            const canvasOffsetX = fabricInstanceRef.current.viewportTransform[4];
            const canvasOffsetY = fabricInstanceRef.current.viewportTransform[5];
            const zoom = fabricInstanceRef.current.getZoom();
            const x = (offsetX - canvasOffsetX) / zoom;
            const y = (offsetY - canvasOffsetY) / zoom;


            // 检查鼠标是否在画布内--------------处理圈选移出画布情况
            const canvasWidth = fabricInstanceRef.current.width;
            const canvasHeight = fabricInstanceRef.current.height;
            if (x < 0 || x > canvasWidth || y < 0 || y > canvasHeight) {
                endCircle()
                return false
            }
            points.push([x, y]);

            // 清除临时路径
            fabricInstanceRef.current.getObjects().forEach((obj) => {
                if (obj._isTemporaryPath) {
                    fabricInstanceRef.current.remove(obj);
                }
            });

            // 绘制新的路径
            const pathData = `M ${points[0][0]} ${points[0][1]} ` +
                points.slice(1).map(p => `L ${p[0]} ${p[1]}`).join(' ');

            // 创建新的临时路径
            const path = new fabric.Path(pathData, {
                fill: '',
                stroke: '#fff',
                strokeWidth: 2,
                strokeDashArray: [5, 5],
                selectable: false,
                evented: false,
                _isTemporaryPath: true // 用于标记临时路径
            });

            fabricInstanceRef.current.add(path);
            fabricInstanceRef.current.renderAll();
        }
    };

    const endCircle = () => {
        const activeObject = fabricInstanceRef.current.getActiveObject();
        if (activeObject) {
            return;
        }
        if(points.length < 5) { //记录路径是否超过5，避免点击时触发圈选
            clearTemporaryPaths();
            points.length = 0; 
            return;
        }
        if (eraseTypeRef.current === 'circle' && points.length > 0) {
            // 闭合路径
            points.push(points[0]);  // 回到起点，形成闭合路径

            const pathData = `M ${points[0][0]} ${points[0][1]} ` +
                points.slice(1).map(p => `L ${p[0]} ${p[1]}`).join(' ') + ' Z';  // Z 表示闭合

            const path = new fabric.Path(pathData, {
                fill: 'rgba(57, 62, 253, 0.2)',
                stroke: '#fff',
                strokeWidth: 2, 
                strokeDashArray: [5, 5],
                selectable: true,
                evented: true,
                cornerSize: 8,
                // lockScalingX: true,
                // lockScalingY: true,
            });
            path.setControlsVisibility({
                mtr: false,
            });
            deleteIconFun(path)

            fabricInstanceRef.current.add(path);
            points.length = 0;  // 清空路径点数组

            // 清除临时路径
            clearTemporaryPaths()

            const circleList = fabricInstanceRef.current.getObjects().filter(item => item.type === 'path');
            props.getIsDraw(circleList);
        }
    };

    const clearTemporaryPaths = () => {
        fabricInstanceRef.current.getObjects().forEach((obj) => {
            if (obj._isTemporaryPath) fabricInstanceRef.current.remove(obj);
        });
        fabricInstanceRef.current.renderAll();
    };
    // 圈选功能》》》》》》》》》》》》》》

    const handleMouseOut = () => {
        if (drawingRef.current) {
            cancelRectDrawing();  // 取消未完成的矩形--------------处理矩形移出画布情况
        }
    };

    const cancelRectDrawing = () => {
        const rect = rectRef.current;
        if (rect) {
            fabricInstanceRef.current.remove(rect);
        }
        drawingRef.current = false;
        rectRef.current = null;
    };


    // 绑定事件到window
    useEffect(() => {
        window.addEventListener('mouseout', handleMouseOut);

        // 清理事件绑定
        return () => {
            window.removeEventListener('mouseout', handleMouseOut);
        };
    }, []);



    useEffect(() => {
        if (!fabricInstanceRef.current) return

        const canvasScaleX = props.displayWidth / fabricInstanceRef.current.width
        const canvasScaleY = props.displayHeight / fabricInstanceRef.current.height

        const bgImage = bgInstanceRef.current.get('backgroundImage')
        if (bgImage) bgImage.scale(props.displayScale)

        bgInstanceRef.current.viewportTransform[4] = bgInstanceRef.current.viewportTransform[4] * canvasScaleX
        bgInstanceRef.current.viewportTransform[5] = bgInstanceRef.current.viewportTransform[5] * canvasScaleY
        bgInstanceRef.current.setDimensions({ width: props.displayWidth, height: props.displayHeight })

        const objs1 = bgInstanceRef.current.getObjects()
        objs1.forEach(item => {
            item.set({
                scaleX: item.scaleX * canvasScaleX,
                scaleY: item.scaleY * canvasScaleY
            })
            item.setCoords()
        })

        fabricInstanceRef.current.viewportTransform[4] = fabricInstanceRef.current.viewportTransform[4] * canvasScaleX
        fabricInstanceRef.current.viewportTransform[5] = fabricInstanceRef.current.viewportTransform[5] * canvasScaleY
        fabricInstanceRef.current.setDimensions({ width: props.displayWidth, height: props.displayHeight })

        const objs = fabricInstanceRef.current.getObjects()
        objs.forEach(item => {
            if (item.type == 'path') {
                item.scale(item.scaleX * canvasScaleX)
                item.set({
                    left: formartNumber(item.left * canvasScaleX),
                    top: formartNumber(item.top * canvasScaleY),
                })
                item.setCoords()
            } else {
                item.set({
                    width: formartNumber(item.width * canvasScaleX),
                    height: formartNumber(item.height * canvasScaleX),
                    left: formartNumber(item.left * canvasScaleX),
                    top: formartNumber(item.top * canvasScaleY),
                })
                item.setCoords()
            }
        })

        bgInstanceRef.current.renderAll()
        fabricInstanceRef.current.renderAll()
    }, [props.displayWidth, props.displayHeight, props.displayScale])

    return (
        <div className="magicErase_canvasBox">
            <div className="magicErase_bgcanvas"><canvas ref={bgCanvasRef} /></div>
            <div className="magicErase_canvas"><canvas ref={canvasRef} /></div>
        </div>
    )
})

export default MagicEraseCanvas