import styles from './ActionGroup.module.scss'
import ActionItem from './ActionItem'
import React, { useEffect, useState } from 'react';

const ActionGroup = (props) => {  

    return (
        <div className={styles.radio_group} style={{...props.style}}>
        {
            props.items.map(item => (
                <ActionItem
                    key={item.value}
                    {...item}
                    onChange={props.onChange}
                    selected={props.value}
                />
            ))
        }
        </div>
    )
}

export default ActionGroup