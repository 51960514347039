export const MODEL_PARAMS = [
    { key: 'img_path', type: '' }, // required
    { key: 'mask_path', type: '' }, // required
    { key: 'mask_path_cloth', type: '' }, // required
    { key: 'lt_x', type: '', default: 1 },
    { key: 'lt_y', type: '', default: 1 },
    { key: 'scale_rate', type: '', default: 1 },
    { key: 'prompt', type: '' }, // required
    { key: 'negative_prompt', type: '' }, // required
    { key: 'seed', type: '' }, // required
    { key: 'bkg_url', type: '' }, // required
    { key: 'ip_scale', type: '', default: 0.5 },
    { key: 'steps', type: '', default: 30 },
    { key: 'cfg', type: '', default: 7 },
    { key: 'defined_flag', type: '' }, // required
    { key: 'mote_style', type: '' }, // required
    { key: 'mote_bg_style', type: '' }, // required
    { key: 'version', type: '' }, // required
    { key: 'upscale_enable', type: '', default: 0 },
    { key: 'faceRestore_enable', type: '', default: 1 },
    { key: 'style_info', type: '', default: 1 },
    { key: 'faceshape_style', type: '', default: '' },
    { key: 'modelKeyList', type: '', default: [] },
    { key: 'bgKeyList', type: '', default: [] },
    { key: 'gender', type: '', default: null },
    { key: 'cloth_type', type: '', default: 1 },
    { key: 'skin_maskpath', type: '' }, // required
    { key: 'hair_maskpath', type: '', default: '' }, // required
    { key: 'tryon_flag', default: 0 },
    { key: 'keep_bkg', default: 0 },
    { key: 'dp_scale', default: 0.5 },
]

export const DRESSFORM_PARAMS = [
    { key: 'img_path', type: '' },
    { key: 'mask_path_cloth', type: '' },
    { key: 'lt_x', type: '', default: 1 },
    { key: 'lt_y', type: '', default: 1 },
    { key: 'scale_rate', type: '', default: 1.3 },
    { key: 'prompt', type: '' },
    { key: 'negative_prompt', type: '' },
    { key: 'seed', type: '' },
    { key: 'bkg_url', type: '' },
    { key: 'steps', type: '', default: 20 },
    { key: 'cfg', type: '', default: 7 },
    { key: 'defined_flag', type: '' },
    { key: 'mote_style', type: '' },
    { key: 'mote_bg_style', type: '' },
    { key: 'version', type: '' },
    { key: 'upscale_enable', type: '', default: 0 },
    { key: 'faceRestore_enable', type: '', default: 1 },
    { key: 'body_mask_path', type: '', },
    { key: 'faceshape_style', type: '', default: '' },
    { key: 'modelKeyList', type: '', default: [] },
    { key: 'bgKeyList', type: '', default: [] },
    { key: 'gender', type: '', default: null },
    { key: 'cloth_type', type: '', default: 1 },
]

export const COMMODITY_PARAMS = [
    { key: 'img_path', type: '' },
    { key: 'mask_path', type: '' },
    { key: 'imgs_dict', type: '' },
    { key: 'p_prompt', type: '', default: "" },
    { key: 'n_prompt', type: '', default: "" },
    { key: 'sample_mtd', type: '', default: "Eular a" },
    { key: 'seed', type: '', default: -1 },
    { key: 'steps', type: '', default: 20 },
    { key: 'cfg', type: '', default: 7 },
    { key: 'defined_flag', type: '' },
    { key: 'goods_bg_style', type: '', default: '' },
    { key: 'version', type: '', default: '' },
    { key: 'upscale_enable', type: '', default: 1 },
    { key: 'bgKeyList', type: '', default: [] },
    { key: 'refine_version', type: '', default: 1 },
    { key: 'ref_strength', type: '', default: 0.3 },
    { key: 'ref_prompt_strength', type: '', default: 0.7 },
]

export const TRYON_PARAMS = [
    { key: 'cloth_path', type: '' },
    { key: 'mote_style', type: '' }, // 模特
    { key: 'person_style', type: '' }, // 姿势
    { key: 'cloth_mask', type: '' },
    { key: 't_model', type: '' }, // 取值1,2,3 全身区域分割v0.2已更新，t_model 传3
    { key: 'version', type: '' },
    { key: 'bkg_style', type: '' },
    { key: 'modelKeyList', type: '', default: [] },
    { key: 'bgKeyList', type: '', default: [] },
    { key: 'gender', type: '' },
    { key: 'cloth_type', type: '' },
    { key: 'seed', type: '' },
    { key: 'self_defined', type: '', default: 0 }, // 自定义flag
    { key: 'mote_img', type: '', default: '' }, // 用户上传得模特图，用于构建模特
]