import React, { useState, useRef, useEffect, useMemo } from 'react';
import '../../components/editContent/blurBgCont.scss'
import { Button } from 'antd';
import {forwardRef, useImperativeHandle} from 'react'
import { useContext } from 'react'
import { AIEditContext } from '../../AIEditContext';

const BlurBgCont = forwardRef((props, ref) => {

    const {isLoading1Blur, isLoading2Blur} = useContext(AIEditContext)

    return (
        <div className='edit_cont blurBgCont'>
            <div className={`edit_center ${!props.url && 'disabled'}`}>
                <Button disabled={!props.url} className='operation_btn' type="primary" loading={isLoading1Blur} onClick={() => props.createClickBlur(2)}>高清</Button>
                <Button disabled={!props.url} className='operation_btn' type="primary" style={{marginLeft: '4%'}} loading={isLoading2Blur} onClick={() => props.createClickBlur(4)}>超清</Button>
            </div>
        </div>
    )
})

export default BlurBgCont;

