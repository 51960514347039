import { useContext, useEffect, useState } from "react";
import LeftBox from "./components/LeftBox";
import styles from './index.module.scss'
import { Tabs } from "antd";
import MobileAndVerifyForm from "./components/MobileAndVerifyForm";
import AccountAndPwdForm from "./components/AccountAndPwdForm";
import WechatScanForm from "./components/WechatScanForm";
import eventBus from "@utils/eventBus";
import { AppContext } from "@utils/AppContext";
import ForgotPwdForm from "./components/ForgotPwdForm";
import EmailRegisterForm from "./components/EmailRegisterForm";

const Login = (props) => {
    const [activeKey, setActiveKey] = useState(props.activeKey || "1")
    const [mobile, setMobile] = useState()
    const items = [{
        key: "1", label: '手机验证码',
    }, {
        key: "2", label: '账号密码',
    }, {
        key: "3", label: '微信扫码',
    }]
    return (
        <>
        <Tabs
            defaultActiveKey={activeKey}
            className={styles.LoginTabs}
            items={items}
            onChange={key => setActiveKey(key)}
        />
        {
            activeKey == "1" ? <MobileAndVerifyForm mobile={mobile} onMobileChange={val => setMobile(val)} loginCallback={props.loginCallback} onEmailRegiste={props.onEmailRegiste} /> :
            activeKey == "2" ? <AccountAndPwdForm mobile={mobile} onMobileChange={val => setMobile(val)} loginCallback={props.loginCallback} onForgotPwd={props.onForgotPwd}/> :
            activeKey == "3" ? <WechatScanForm loginCallback={props.loginCallback} /> : null
        }
        </>
    )
}

export default function LoginModal (props) {
    const {setHasToken} = useContext(AppContext)
    const [mode, setMode] = useState(0) // 0-normal, 1-忘记密码，2-邮箱注册，

    const handleLogin = (data) => {
        if (!data) return props.closeFunc(false)
        eventBus.emit('tokenValid') // 通知token有效，详见注册tokenValid的组件逻辑
        localStorage.setItem('noPassword', data.noPassword)
        localStorage.setItem('token', data.token)
        localStorage.setItem('channel', data.channel)
        setHasToken(true)
        props.closeFunc(true, data.register)
    }

    const [activeKey, setActiveKey] = useState("1")
    const handleBack = (value="1") => {
        setActiveKey(value)
        setMode(0)
    }

    return (
        <div className={styles.login_box}>
            <LeftBox />
            <div className={styles.RightBox}>
                <div className={styles.RightContent}>
                    <p className={styles.r_title}>即刻体验PhotoStudio<span className="registered_trademark">®</span> AI</p>
                    <div className={styles.r_content}>
                    {
                        mode == 0 ?
                        <Login
                            activeKey={activeKey}
                            loginCallback={handleLogin}
                            onForgotPwd={() => setMode(1)}
                            onEmailRegiste={() => setMode(2)}
                            closeFunc={props.closeFunc}
                        /> :
                        mode == 1 ?
                        <ForgotPwdForm
                            onBack={handleBack}
                        /> :
                        <EmailRegisterForm
                            loginCallback={handleLogin}
                            onBack={handleBack}
                        />
                    }
                        
                    </div>
                    <div className={styles.r_footer}>
                        <p className={styles.r_p}>
                            <span className={styles.span_normal}>登录即表示您已阅读并同意本产品的</span>
                            <a className={styles.span_click} href='/服务协议.htm' target="_blank">服务协议</a> 和&nbsp;
                            <a className={styles.span_click} href='/隐私政策.htm' target="_blank">隐私政策</a>
                        </p>
                        <p className={styles.notice_text}>* 本产品不面向未满 18 周岁的未成年人及不具有完全民事行为能力人</p>
                        <p className={styles.notice_text}>* 如果您未满 18 周岁或不具有完全民事行为能力，您不得注册或使用本产品</p>
                    </div>
                </div>
            </div>
        </div>
    )
}