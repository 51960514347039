import React, {useState, useContext, Suspense, lazy, useEffect, useRef, useMemo} from 'react';
import {Outlet,Route,Routes,useLocation,Navigate} from 'react-router-dom';
import PcLayout from '../layout/pc';
import { AppContext } from '@utils/AppContext';
import '../view/comom.scss'
import eventBus from '@utils/eventBus';

import SelfModelFake from '@view/page/selfModelFake2/index'
import AiEdit from "@view/page/aiEdit/aiEdit";
import MyProject from "@view/page/myProject/myProject";
import FavorDetail from '@view/page/PhotoProjectDetail/favoriteIndex'
import PhotoProjectDetail from "@view/page/PhotoProjectDetail";
import PersonalCenter from "@view/PersonalCenter";
import {IMAGE_TYPE} from '@utils/CONST'

import Sidebar from '@view/compoents/sidebar/sidebar'

// 引入组件
const Taobao = lazy(() => import('@view/page/tripleLogin/taobao.jsx'))
const WebsiteMaintenance = lazy(() => import('@view/page/websiteMaintenance/websiteMaintenance.jsx'))
const Login = lazy(() => import('@view/Login'))

const Pay = lazy(() => import('@view/Pay'))
const ImageExtraction = lazy(() => import('@view/page/imageExtraction'))

function HomeLayout () {
    const location = useLocation();
    const [isPersonalCenter, setPersonalCenter] = useState(false)
    useEffect(() => {
        if( location.pathname == '/home/homemain/personalCenter') {
            setPersonalCenter(true)
        } else {
            setPersonalCenter(false)
        }
    }, [location.pathname]);

    return (
        <div className='Home_main'>
            { !isPersonalCenter && <Sidebar></Sidebar>}
            <div className='main_right'>
                <Outlet />
            </div>
        </div>
    )
}

export default function PcRoute () {
    const location = useLocation();
    const { globalUser } = useContext(AppContext)

    useEffect(() => {
        const target = document.getElementById('topView')
        if (target) target.scrollIntoView()
    }, [location.pathname])

    const cmCount = useMemo(() => { // 违规次数;4次及以上需要封号
        return globalUser?.cmCount || 0
    }, [globalUser])
    const cmReadFlag = useMemo(() => { // 已读标记，0-未读，需要弹窗；1-已读
        return globalUser?.cmReadFlag == 0 ? globalUser?.cmReadFlag : (globalUser?.cmReadFlag || 1)
    }, [globalUser])
    const appealBeforeTime = useMemo(() => { // 申诉期限
        return globalUser?.appealBeforeTime || '--'
    }, [globalUser])

    useEffect(() => {
        if (cmCount == 0 || cmReadFlag == 1) return
        if (cmCount < 4) eventBus.emit('violationWarn', cmCount, appealBeforeTime) // 违规警告
        else eventBus.emit('suspenNotice', cmCount, 1, appealBeforeTime) // 封号提醒
    }, [cmCount, cmReadFlag, appealBeforeTime])

    return (
        <Routes>
            <Route path="/" element={<PcLayout />}>
                <Route path="" element={<Login />} />
                <Route path="taobao" element={<Taobao />} />
                {/* <Route path="home/*" element={<Home /> } /> */}
                <Route path="home/" element={<div style={{height: 'calc(100% - 60px)', overflow: 'hidden', position: 'relative'}}><Outlet /></div>}>
                    <Route path="homemain/*" element={<HomeLayout/>}>
                        <Route path="model" element={<SelfModelFake imageType={IMAGE_TYPE.MODEL} key={IMAGE_TYPE.MODEL} />}></Route> {/*真人图*/}
                        <Route path="dressform" element={<SelfModelFake imageType={IMAGE_TYPE.DRESSFORM} key={IMAGE_TYPE.DRESSFORM} />}></Route> {/*人台图*/}
                        <Route path="clothes" element={<SelfModelFake imageType={IMAGE_TYPE.CLOTHES} key={IMAGE_TYPE.CLOTHES} />}></Route> {/*AI试衣*/}
                        <Route path="commodity" element={<SelfModelFake imageType={IMAGE_TYPE.COMMODITY} key={IMAGE_TYPE.COMMODITY} />}></Route> {/*商品图*/}
                        <Route path="commodity_video" element={<SelfModelFake imageType={IMAGE_TYPE.COMMODITY_VIDEO} key={IMAGE_TYPE.COMMODITY_VIDEO} />}></Route> {/*商品视频*/}
                        <Route path="model_video" element={<SelfModelFake imageType={IMAGE_TYPE.MODEL_VIDEO} key={IMAGE_TYPE.MODEL_VIDEO} />}></Route> {/*模特视频*/}
                        <Route path="personalCenter" element={<PersonalCenter key={'personal'}/>}></Route>
                        <Route path="personalCenter/record" element={<PersonalCenter key={'personalRecord'} record/>}></Route>
                        <Route path="aiedit" element={<AiEdit />}></Route>
                        <Route path="myProject" element={<MyProject />}></Route>
                        <Route path="favor_detail" element={<FavorDetail />}></Route>
                        <Route path="PhotoProjectDetail" element={<PhotoProjectDetail key="project" />}></Route>
                        <Route path="*" element={<Navigate replace to="/" />} />
                    </Route>
                    <Route path="image_extraction" element={<ImageExtraction/>}></Route>
                    <Route path="*" element={<Navigate replace to="/" />} />
                </Route>
                <Route path="pay" element={<Pay /> } />
            </Route>
        </Routes>
    )
}