import { Input, Select } from "antd"
import styles from './ResolutionSelection.module.scss'
import { useEffect, useRef, useState } from "react"
import {forwardRef, useImperativeHandle} from 'react'

const ResolutionSelection = forwardRef((props, ref) => {
    const { initImgRatio, initImgWidth, initImgHeight, disabled } = props
    const lockRef = useRef(initImgRatio != 'custom')
    const [lock, setLock] = useState(initImgRatio != 'custom')
    const ratioRef = useRef(initImgRatio||'1:1')
    const [ratio, setRatio] = useState(initImgRatio||'1:1')
    const widthRef = useRef(initImgWidth||1024)
    const [width, setWidth] = useState(initImgWidth||1024)
    const [fixedWidth, setFixedWidth] = useState(initImgWidth||1024)
    const heightRef = useRef(initImgHeight||1024)
    const [height, setHeight] = useState(initImgHeight||1024)
    const [fixedHeight, setFixedHeight] = useState(initImgHeight||1024)
    const baseResolution = useRef(props.baseResolution || 800)
    const maxWLimit = useRef(props.maxWidth || 2048)
    const minWLimit = useRef(props.minWidth || 500)
    const maxHLimit = useRef(props.maxHeight || 2048)
    const minHLimit = useRef(props.minHeight || 500)
    const initCustomWidth = useRef(props.initCustomWidth || 1024)
    const initCustomHeight = useRef(props.initCustomHeight || 1024)

    useEffect(() => {
        maxWLimit.current = props.maxWidth || 2048
        minWLimit.current = props.minWidth || 500
        maxHLimit.current = props.maxHeight || 2048
        minHLimit.current = props.minHeight || 500
        initCustomWidth.current = props.initCustomWidth || 1024
        initCustomHeight.current = props.initCustomHeight || 1024
    }, [
        props.maxWidth,
        props.minWidth,
        props.maxHeight,
        props.minHeight,
        props.initCustomWidth,
        props.initCustomHeight,
    ])

    useImperativeHandle(ref, () => ({
        customSize: (width, height ) => {
            ratioRef.current = 'custom'
            setRatio('custom')
            lockRef.current = false
            setLock(false)
            widthRef.current = Math.round(width)
            setWidth(Math.round(width))
            setFixedWidth(Math.round(width))
            heightRef.current = Math.round(height)
            setHeight(Math.round(height))
            setFixedHeight(Math.round(height))
        },
        getSize: () => {
            return {
                width: fixedWidth,
                height: fixedHeight,
            }
        }
    }))

    useEffect(() => {
        props.onChange && props.onChange(fixedWidth, fixedHeight, ratio)
    }, [fixedWidth, fixedHeight, ratio])

    const options = [
        { label: '自定义', value: 'custom', },
        { label: '1 : 1', value: '1:1', },
        { label: '3 : 4', value: '3:4', },
        { label: '4 : 3', value: '4:3', },
        { label: '9 : 16', value: '9:16', },
        { label: '16 : 9', value: '16:9', },
    ]

    const handleChange = value => {
        widthRef.current = 0
        heightRef.current = 0
        ratioRef.current = value
        setRatio(value)
        if (value == 'custom') {
            lockRef.current = false
            setLock(false)
            initCustomRatio()
        } else {
            lockRef.current = true
            setLock(true)
            initUncustomRatio()
        }
    }

    const onLockChange = () => {
        if (disabled) return
        lockRef.current = !lockRef.current
        setLock(lockRef.current)
        if (lockRef.current) {
            ratioRef.current = '1:1'
            setRatio('1:1')
            initUncustomRatio()
        } else {
            ratioRef.current = 'custom'
            setRatio('custom')
            initCustomRatio()
        }
    }

    const initCustomRatio = () => {
        const w = initCustomWidth.current, h = initCustomHeight.current
        widthRef.current = w
        setWidth(w)
        setFixedWidth(w)
        heightRef.current = h
        setHeight(h)
        setFixedHeight(h)
    }
    const initUncustomRatio = () => {
        calcWidthAndHeight()
    }

    // 判断是否是偶数
    function getNearestEvenNumber (num) {
        // if (num % 2 == 0) return num
        // else return num+1
        return num
    }
    const calcWidthAndHeight = (w, h) => {
        if (ratioRef.current == 'custom') {
            if (w) {
                w = getNearestEvenNumber(Math.min(Math.max(w, minWLimit.current), maxWLimit.current))
                widthRef.current = w
                setWidth(w)
                setFixedWidth(w)
            }
            if (h) {
                h = getNearestEvenNumber(Math.min(Math.max(h, minHLimit.current), maxHLimit.current))
                heightRef.current = h
                setHeight(h)
                setFixedHeight(h)
            }            
        } else {
            const ratios = ratioRef.current.split(':') // [w : h]
            if (w) {
                w = Math.min(Math.max(w, minHLimit.current), maxWLimit.current)
                h = Math.round(w / ratios[0] * ratios[1])
                if (h != Math.min(Math.max(h, minHLimit.current), maxHLimit.current)) {
                    h = Math.min(Math.max(h, minHLimit.current), maxHLimit.current)
                    w = Math.round(h / ratios[1] * ratios[0])
                }
                w = getNearestEvenNumber(w)
                h = getNearestEvenNumber(h)
                widthRef.current = w
                setWidth(w)
                setFixedWidth(w)
                heightRef.current = h
                setHeight(h)
                setFixedHeight(h)
            } else if (h) {
                h = Math.min(Math.max(h, minHLimit.current), maxHLimit.current)
                w = Math.round(h / ratios[1] * ratios[0])
                if (w != Math.min(Math.max(w, minWLimit.current), maxWLimit.current)) {
                    w = Math.min(Math.max(w, minWLimit.current), maxWLimit.current)
                    h = Math.round(w / ratios[0] * ratios[1])
                }
                w = getNearestEvenNumber(w)
                h = getNearestEvenNumber(h)
                widthRef.current = w
                setWidth(w)
                setFixedWidth(w)
                heightRef.current = h
                setHeight(h)
                setFixedHeight(h)
            } else {
                if (ratios[0] / ratios[1] > 1) {
                    calcWidthAndHeight(null, baseResolution.current)
                } else {
                    calcWidthAndHeight(baseResolution.current)
                }
            }
        }
    }
    const onWidthChange = (e) => {
        setWidth(Math.round(e.target.value))
    }
    const onWidthBlur = (e) => {
        if (isNaN(e.target.value)) {
            setWidth(widthRef.current)
        } else {
            calcWidthAndHeight(Math.round(e.target.value))
        }
    }
    const onHeightChange = (e) => {
        setHeight(Math.round(e.target.value))
    }
    const onHeightBlur = (e) => {
        if (isNaN(e.target.value)) {
            setHeight(heightRef.current)
        } else {
            calcWidthAndHeight(null, Math.round(e.target.value))
        }
    }
    
    return (
        <div className={styles.Container}>
        {
            props.ratioOnly ? 
            <div className={styles.RatioOnly}>
                <div className={`${ratio == '16:9' && styles.actived}`} onClick={() => handleChange('16:9')}>16:9</div>
                <div className={`${ratio == '9:16' && styles.actived}`} onClick={() => handleChange('9:16')}>9:16</div>
                <div className={`${ratio == '4:3' && styles.actived}`} onClick={() => handleChange('4:3')}>4:3</div>
                <div className={`${ratio == '3:4' && styles.actived}`} onClick={() => handleChange('3:4')}>3:4</div>
                <div className={`${ratio == '1:1' && styles.actived}`} onClick={() => handleChange('1:1')}>1:1</div>
            </div> :
            <>
            <Select
                disabled={disabled}
                className={styles.Select}
                popupClassName={styles.SelectPopup}
                size="small"
                defaultValue={ratio}
                value={ratio}
                options={options}
                onChange={handleChange}
            />
            <div className={styles.InputContainer}>
                <div className={styles.InputContent}>
                    <Input disabled={disabled} value={width} className={styles.Input} size="small" variant="borderless" onChange={onWidthChange} onBlur={onWidthBlur} />
                    <span className={styles.InputSuffix}>Px</span>
                </div>
                <div className={`${styles.LockBtn} ${!lock && styles.unlock} ${disabled && styles.disabled}`} onClick={onLockChange}></div>
                <div className={styles.InputContent}>
                    <Input disabled={disabled} value={height} className={styles.Input} size="small" variant="borderless" onChange={onHeightChange} onBlur={onHeightBlur} />
                    <span className={styles.InputSuffix}>Px</span>
                </div>
            </div>
            </>
        }
        </div>
    )
})

export default ResolutionSelection