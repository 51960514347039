import React, { useState, useContext, useEffect, useMemo, useRef  } from 'react';
import './aiEdit.scss'
import { Button, Menu, Modal, Spin, Upload, message } from 'antd';
import AIBeauty from './components/aiBeauty/aiBeauty'
import BlurBgCont from './components/editContent/blurBgCont'
import MagicErase from './components/magicErase/magicErase'
import { useLocation, useNavigate } from "react-router-dom";
import { AppContext } from '@utils/AppContext';
import ShapeShift from './components/shapeShift2/shapeShift';
import FillLight from './components/fillLight/fillLight';
import EditLeftBar from './editLeftBar';
import Utils from '@utils/utils';
import API from '@api/api';
import styles from './aiEdit.module.scss'
import { InfoCircleOutlined } from '@ant-design/icons';
import { AIEditContext, AIEditContextProvider } from './AIEditContext';
import PSAIModal from '@PSAIComponents/PSAIModal';
import WaitUploadPage from '@view/compoents/WaitUploadPage2';
import Example from '@assets/json/example.json'
import { HOME_PAGE_TYPE, IMAGE_TYPE, UPLOAD_SOURCE } from '@utils/CONST';

const AiEdit = () => {
    const { updateConfig, globalHomePageType } = useContext(AppContext) // 触发更新flag
    const { aiEditUrl, setAIEditUrl, setNeedPay } = useContext(AIEditContext)

    const [exampleList] = useState(Example)

    const modelExamples = useMemo(() => {
        const imageType = globalHomePageType == HOME_PAGE_TYPE.MERCHANDISE ? 
            IMAGE_TYPE.COMMODITY :
            IMAGE_TYPE.MODEL
        return exampleList
            .filter(item => item.type == imageType)
            .filter(item => !item.disabled)
    }, [exampleList, globalHomePageType])

    const location = useLocation();
    const menuKeyRef = useRef('matting')
    const [menuKey, setMenuKey] = useState('matting');
    const [loading, setLoading] = useState(false)
    const imageUrlRef = useRef(location?.state?.picUrl)
    const projectIdRef = useRef(location?.state?.projectId)
    const taskIdRef = useRef(location?.state?.taskId)
    const imgMaxLimit = useRef(4096)
    const imgMinLimit = useRef(500)

    useEffect(() => {
        updateConfig({sidebarKey: 'aiEdit'})
    }, [])
    
    useEffect(()=>{
        if(location.state && location.state.menuKey){
            setMenuKey(location.state.menuKey);
            menuKeyRef.current = location.state.menuKey;
        }
    },[location.state])
    
    const [MENU_ITEMS] = useState({
        MATTING: {
            label: '智能抠图',
            key: 'matting',
        },
        FILL_LIGHT: {
            label: '智能补光',
            key: 'filllight',
            icon: <i className={`${styles.LeftMenuIcon} ${styles.Icon_light}`}></i>
        },
        BEAUTIFY: {
            label: '智能美化',
            key: 'beautify',
            icon: <i className={`${styles.LeftMenuIcon} ${styles.Icon_beautify}`}></i>
        },
        MAGIC_ERASE: {
            label: '魔法擦除',
            key: 'magicErase',
            icon: <i className={`${styles.LeftMenuIcon} ${styles.Icon_magicErase}`}></i>
        },
        // BLUR_BG: { 
        //     label: '背景虚化',
        //     key: 'blurBg',
        //     icon: <img src={require('@assets/images/Menu bar_Keying_normat.png')} alt="" />
        // },
        IMAGE_UPGRADE: {
            label: '画质升级',
            key: 'imageUpgrade',
            icon: <i className={`${styles.LeftMenuIcon} ${styles.Icon_imageUpgrade}`}></i>
        },
        SHAPE_SHIFT: {
            label: '随心变形',
            key: 'shapeShift',
            icon: <i className={`${styles.LeftMenuIcon} ${styles.Icon_shapeShift}`}></i>
        },
        FILTER: {
            label: '滤镜',
            key: 'filter', 
        }
    })

    // const [MENU_KEYS] = useState(
    //     /psai.cn|canary.ps.arcsoftbiz.com|photostudio.arcsoft.com.cn/.test(window.location.href) ?
    //     ['MATTING', 'FILL_LIGHT', 'BEAUTIFY', 'MAGIC_ERASE', 'IMAGE_UPGRADE', 'FILTER'] :
    //     ['MATTING', 'FILL_LIGHT', 'BEAUTIFY', 'MAGIC_ERASE', 'IMAGE_UPGRADE', 'SHAPE_SHIFT', 'FILTER']
    // )

    // const menuItems = useMemo(() => {
    //     return MENU_KEYS.map(key => MENU_ITEMS[key])
    // }, [MENU_KEYS, MENU_ITEMS])

    const filllightRef = useRef()
    const beautifyRef = useRef()
    const magicEraseRef = useRef()
    const imageUpgradeRef = useRef()
    const shapeShiftRef = useRef()
    function isBase64 (src) {return /^data:image\/[a-z]+;base64,/.test(src)}
    const onClick = async (e, url) => {
        // filllight/ beautify / magicErase / imageUpgrade/ shapeShift
        const menuComp = menuKeyRef.current == 'filllight' ? filllightRef.current :
                         menuKeyRef.current == 'beautify' ? beautifyRef.current :
                         menuKeyRef.current == 'magicErase' ? magicEraseRef.current :
                         menuKeyRef.current == 'imageUpgrade' ? imageUpgradeRef.current :
                         menuKeyRef.current == 'shapeShift' ? shapeShiftRef.current :
                         menuKeyRef.current == 'filter' ? filllightRef.current :
                         menuKeyRef.current == 'matting' ? filllightRef.current : null
        if (menuComp) {
            let imgValue = url || menuComp.onChange()
            if (!imgValue) {
                menuKeyRef.current = e.key
                setMenuKey(e.key)
            } else {
                setLoading(true)
                if (e.key == 'imageUpgrade') { // 图片尺寸不能超过imgMaxLimit
                    const {width, height} = await Utils.getImageDimensions(imgValue)
                    if (width > imgMaxLimit.current || height > imgMaxLimit.current) {
                        await uploadConfirm('confirm', {
                            title: '图片过大',
                            content: `图片尺寸不可超过${imgMaxLimit.current}*${imgMaxLimit.current}`,
                            okText: '使用其他功能',
                            cancelText: '重新上传',
                            onOk: goToMagicErase,
                            onCancel: async () => { // 上传取消不会关闭弹窗
                                try {
                                    await reupload()
                                    onClick({key: e.key}, imageUrlRef.current)
                                } catch (err) {
                                    setLoading(false)
                                    throw err
                                }
                            }
                        })
                        return 
                    }
                } else if (e.key == 'shapeShift') { // 图片过大，是否调整大小后使用
                    const {width, height} = await Utils.getImageDimensions(imgValue)
                    if (width > imgMaxLimit.current || height > imgMaxLimit.current) {
                        const res = await uploadConfirm('confirm', {
                            title: '图片过大',
                            content: `为您调整尺寸后可打开, 分辨率 ≤ ${imgMaxLimit.current}*${imgMaxLimit.current}`,
                            okText: '调整并打开',
                            cancelText: '重新上传',
                            onOk: async () => {
                                let file
                                if (isBase64(imgValue)) file = Utils.convertBase64ToFile(imgValue, 'image.png')
                                else file = Utils.urlToFile(imgValue)
                                return await Utils.resizeImageFileMaxByAliyunBy(file, imgMaxLimit.current)
                            },
                            onCancel: reupload
                        })
                        if (!res || !res.success) {
                            setLoading(false)
                            return
                        } else if (res.base64) {
                            imgValue = res.base64
                        } else {
                            onClick({key: e.key}, imageUrlRef.current)
                            return 
                        }                        
                    }
                }
                if (imgValue) {
                    if (isBase64(imgValue)) {
                        const url = await Utils.uploadBase64Image(imgValue)
                        if (!url) return setLoading(false)
                        imageUrlRef.current = url
                        setAIEditUrl(imageUrlRef.current)
                    } else {
                        imageUrlRef.current = imgValue
                        setAIEditUrl(imageUrlRef.current)
                    }
                }
                // console.log('menuKeyRef.current', e.key)
                menuKeyRef.current = e.key
                setMenuKey(e.key)
                setLoading(false)
            }
        }
    }
    
    const uploadConfirm = (type, {title, content, okText, cancelText, maskClosable, onOk=()=>{}, onCancel=()=>{}} ) => {
        return new Promise((resolve) => {
            PSAIModal.confirm({
                title: title,
                content: content,
                closable: false,
                icon: <InfoCircleOutlined style={{color: "#FF864C"}} />,
                centered: true,
                maskClosable: maskClosable,
                okText: okText,
                cancelText: cancelText,
                async onOk () {
                    try {
                        const res = await onOk()
                        resolve(res)
                    } catch (err) {
                        console.log('onOk reject', err)
                        resolve(false)
                        throw err
                    }
                },
                async onCancel () {
                    try {
                        const res = await onCancel()
                        console.log('onCancel', res)
                        resolve(res)
                    } catch (err) {
                        console.log('onCancel reject', err)
                        resolve(false)
                        throw err
                    }
                },
                async onClose () {
                    resolve(false)
                }
            })
        })
    }
    const errorCallback = () => {
        uploadConfirm('confirm', {
            title: '仅支持人像图片',
            content: '补光及美化功能仅对人像生效',
            okText: '使用其他功能',
            cancelText: '重新上传',
            onOk: goToMagicErase,
            onCancel: reupload
        })
    }
    const errorCallback2 = () => {
        uploadConfirm('confirm', {
            title: '画质升级',
            content: '【画质升级】支持的最大尺寸为2048^2048',
            okText: '使用其他功能',
            cancelText: '重新上传',
            onOk: goToMagicErase,
            onCancel: reupload
        })
    }
    const reupload = () => {
        return new Promise((resolve, reject) => {
            const input = document.createElement('input')
            input.type = 'file'
            input.accept = 'image/jpeg,image/png,image/webp,image/avif'
            let flag = 0
            const onForcus = () => {
                setTimeout(() => {
                    if (flag == 0) {
                        reject(new Error('cancel'))
                    }
                    window.removeEventListener('focus', onForcus)
                }, 200)
            }
            window.addEventListener('focus', onForcus)
            input.addEventListener('change', () => {
                flag = 1
                const file = input.files[0];
                if (file) {
                    beforeUpload(file).then(() => resolve(true))
                    input.remove()
                } else {
                    reject(new Error('cancel'))
                }
            })
            input.click()
        })
    }
    const goToMagicErase = () => {
        menuKeyRef.current = 'magicErase'
        setMenuKey('magicErase')
        return false
    }
    const beforeUpload = async (file) => {
        console.log('beforeUpload in aiedit')
        try {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/webp'|| file.type === 'image/avif';
            if (!isJpgOrPng) throw new Error('您只能上传JPG/PNG/WEBP/AVIF格式的文件')
            const {width, height} = await Utils.getImageDimensions(file)
            if ((width > imgMaxLimit.current || height > imgMaxLimit.current)) {
                await uploadConfirm('confirm', {
                    title: '图片过大',
                    content: `为您调整尺寸后可打开, 分辨率 ≤ ${imgMaxLimit.current}*${imgMaxLimit.current}`,
                    okText: '调整并打开',
                    cancelText: '重新上传',
                    onOk: async () => {
                        const res = await Utils.resizeImageFileMaxByAliyunBy(file, imgMaxLimit.current)
                        if (res && res.success) {
                            beforeUpload(res.file)
                        }
                    },
                    onCancel: async () => { // 上传取消不会关闭弹窗
                        await reupload()
                    }
                })
            } else {
                if (file) {
                    const imageType = globalHomePageType == HOME_PAGE_TYPE.MERCHANDISE ? 
                        IMAGE_TYPE.COMMODITY :
                        IMAGE_TYPE.MODEL
                    const source = imageType == IMAGE_TYPE.MODEL ? UPLOAD_SOURCE.AIEDIT_MODEL : UPLOAD_SOURCE.AIEDIT_COMMODITY
                    const res = await Utils.checkImageIslegal(file, 0, source)
                    if (!res) return false
                    if (!res.isLegal) {
                        PSAIModal.confirm({
                            content: '经检测，您上传的内容涉嫌违规，已进行屏蔽处理，人工审核会对图片进行复核。',
                            okText: '继续上传',
                            cancelText: '取消',
                            onOk() {reupload()},
                            onCancel() {},
                        })
                        return false
                    }
                    imageUrlRef.current = res.data
                    setAIEditUrl(imageUrlRef.current)
                    setNeedPay(true)
                }
            }
        } catch (err) {
            message.warning(err.message)
        } finally {
            return false
        }
    }

    const handleImageChange = (url) => {
        imageUrlRef.current = url
        setAIEditUrl(imageUrlRef.current)
        setNeedPay(true)
    }

    const defaultPage = (
        <WaitUploadPage
            btnText={globalHomePageType == HOME_PAGE_TYPE.MERCHANDISE ? "上传商品图" : "上传模特图"}
            uploadTips={`建议图片尺寸不小于500*500，不超过${imgMaxLimit.current}*${imgMaxLimit.current}`}
            uploadExamples={modelExamples}
            pickFromProject={true}
            onUpload={beforeUpload}
            onChange={handleImageChange}
            useImageType
        />
    )

    // 调用各模块内部方法

    const selectPreset = (index, style) => {
        filllightRef.current.selectPreset(index, style)
    }
    const lightLoadModule = (type, value, flag) => {
        filllightRef.current.lightLoadModule(type, value, flag)
    }
    const lightSettingChange = (val, text) => {
        filllightRef.current.lightSettingChange(val, text)
    }
    const oneClickBeauty = () => {
        beautifyRef.current.oneClickBeauty()
    }
    const saveUndoList = () => {
        beautifyRef.current.saveUndoList()
    }
    const [faceArray ,setfaceArray] = useState()
    const [bodyArray ,setbodyArray] = useState()
    const getfaceArray = (val) => {
        setfaceArray(val)
    }
    const getbodyArray = (val) => {
        setbodyArray(val)
    }

    const handleDraw = (key) => {
        magicEraseRef.current.handleDraw(key)
    }

    const repairSizeChange = (value, text) => {
        magicEraseRef.current.repairSizeChange(value, text)
    }

    const createClick = (value) => {
        magicEraseRef.current.createClick(value)
    }

    const createClickBlur = (zoom) => {
        imageUpgradeRef.current.createClick(zoom)
    }

    const EDIT_CONT_MAP = {
        [MENU_ITEMS?.BEAUTIFY.key]: <AIBeauty getfaceArray={getfaceArray} getbodyArray={getbodyArray} ref={beautifyRef} key={imageUrlRef.current} projectId={projectIdRef.current} taskId={taskIdRef.current} url={imageUrlRef.current} defaultPage={defaultPage} uploadCb={beforeUpload} errorCallback={errorCallback} />,
        [MENU_ITEMS?.SHAPE_SHIFT.key]: <ShapeShift ref={shapeShiftRef} key={imageUrlRef.current} projectId={projectIdRef.current} taskId={taskIdRef.current}  url={imageUrlRef.current} defaultPage={defaultPage} uploadCb={beforeUpload} />,
        [MENU_ITEMS?.IMAGE_UPGRADE.key]: <BlurBgCont ref={imageUpgradeRef} key={imageUrlRef.current} projectId={projectIdRef.current} taskId={taskIdRef.current}  url={imageUrlRef.current} defaultPage={defaultPage} uploadCb={beforeUpload} errorCallback={errorCallback2} />,
        [MENU_ITEMS?.MAGIC_ERASE.key]: <MagicErase ref={magicEraseRef} key={imageUrlRef.current} projectId={projectIdRef.current} taskId={taskIdRef.current}  url={imageUrlRef.current} defaultPage={defaultPage} uploadCb={beforeUpload} />,
        [MENU_ITEMS?.FILL_LIGHT.key]: <FillLight ref={filllightRef} key={imageUrlRef.current} projectId={projectIdRef.current} taskId={taskIdRef.current}  url={imageUrlRef.current} defaultPage={defaultPage} uploadCb={beforeUpload} errorCallback={errorCallback} />,
        [MENU_ITEMS?.MATTING.key]: <FillLight ref={filllightRef} key={imageUrlRef.current} projectId={projectIdRef.current} taskId={taskIdRef.current}  url={imageUrlRef.current} defaultPage={defaultPage} uploadCb={beforeUpload} errorCallback={errorCallback} />,
        [MENU_ITEMS?.FILTER.key]: <FillLight ref={filllightRef} key={imageUrlRef.current} projectId={projectIdRef.current} taskId={taskIdRef.current}  url={imageUrlRef.current} defaultPage={defaultPage} uploadCb={beforeUpload} errorCallback={errorCallback} />,
    }

    return (
        <Spin spinning={loading} delay={500} wrapperClassName={styles.Spin}>
            <div className='AiEdit main_style'>
            {
                <>
                {/* <Menu
                    className={styles.LeftMenu}
                    onClick={onClick}
                    defaultSelectedKeys={[menuKey]}
                    selectedKeys={[menuKey]}
                    mode="inline"
                    items={menuItems}
                /> */}
                <EditLeftBar
                    // activeKey={tabKey}
                    activeKey={menuKey}
                    selectPreset={selectPreset}
                    lightLoadModule={lightLoadModule}
                    lightSettingChange={lightSettingChange}
                    url={imageUrlRef.current}
                    oneClickBeauty={oneClickBeauty}
                    saveUndoList={saveUndoList}
                    faceArray={faceArray}
                    bodyArray={bodyArray}
                    handleDraw={handleDraw}
                    repairSizeChange={repairSizeChange}
                    createClick={createClick}
                    createClickBlur={createClickBlur}
                    imageUrlRef={imageUrlRef.current}
                    onClick={onClick}
                />
                    { EDIT_CONT_MAP[menuKey] }
                </>
            }                
            </div>
        </Spin>
    )
}

export default () => (
    <AIEditContextProvider>
        <AiEdit />
    </AIEditContextProvider>
)