import React, { useEffect, useMemo, useState } from 'react';
import '../aiEdit.scss'
// import Slider from 'rc-slider';
// import 'rc-slider/assets/index.css';
import { Slider } from 'antd';
import { colorPrimary } from '@utils/AppContext';

const Rcslider = (props) => {
    const [value, setValue] = useState(props.value || 0);
   
    useEffect(() => {
        setValue(props.value)
    }, [props.value])

    const setChange = (value) => {
        // props.getRcsliderValue && props.getRcsliderValue(value)
        setValue(value)
    }

    const onAfterChange = (value) => {
        props.getRcsliderValue && props.getRcsliderValue(value)
    }

    const trackStyle = useMemo(() => {
        if (value >= 0) {
            return {
                left: '50%',
                width: Math.abs(value / 2) + '%',
                background: colorPrimary
            }
        } else {
            return {
                left: 'auto',
                right: '50%',
                width: Math.abs(value / 2) + '%',
                background: colorPrimary
            }
        }
    }, [value])
  
    const railStyle = {
        background: '#d6d6d6'
    }

    return (
        <div className='rcslider'>
            <p><span>{props.title}</span><span>{value}</span></p>
            <Slider
                disabled={props.disabled}
                min={props.min || 0}
                max={props.max || 100}
                value={value}
                tooltip={{ formatter: null }}
                trackStyle={trackStyle}
                railStyle={railStyle}
                onChange={setChange}
                onAfterChange={onAfterChange}
            />
        </div>
    )
}

export default Rcslider