// 首页选择的服装版/商品版
/**
   import { HOME_PAGE_TYPE } from "@utils/CONST"
   HOME_PAGE_TYPE.CLOTHING
   HOME_PAGE_TYPE.MERCHANDISE
 */
export const HOME_PAGE_TYPE = {
    CLOTHING: "clothing", // 服装版
    MERCHANDISE: "merchandise", // 商品版
    IMAGE_SERVICE: "image_service", // AI商拍服务 
}

/**
 import { IMAGE_TYPE } from "@utils/CONST"
 IMAGE_TYPE.MODEL
 IMAGE_TYPE.DRESSFORM
 IMAGE_TYPE.COMMODITY
 IMAGE_TYPE.CLOTHES
 */
export const IMAGE_TYPE = {
    MODEL: "model", // 真人图
    DRESSFORM: "dressform", // 人台图
    COMMODITY: "commodity", // 商品图
    COMMODITY_VIDEO: "commodityVideo", // 商品视频
    CLOTHES: "clothes", // AI试衣
    MODEL_VIDEO: "tryonVideo", // 衣服视频
}

export const ALGO_TYPE = {
    MODEL: "modelInpaint", // 真人图
    DRESSFORM: "dressformInpaint", // 人台图
    COMMODITY: "commodityInpaint", // 商品图
    COMMODITY_REMOVE: "removeInpaint", // 商品拍同款
    COMMODITY_VIDEO: "commodityVideo", // 商品视频
    CLOTHES: "tryonInpaint", // AI试衣
    MODEL_VIDEO: "tryonVideo", // 衣服视频
}

// 衣服图下的分类
/**
 import { CLOTHES_SUBTYPE } from "@utils/CONST"
 CLOTHES_SUBTYPE.SHORT
 CLOTHES_SUBTYPE.LONG_SLEEVE
 CLOTHES_SUBTYPE.SKIRT
 */
export const CLOTHES_SUBTYPE = {
    SHORT: "clothes_short", // 短袖
    LONG_SLEEVE: "clothes_longSleeve", // 长袖
    SKIRT: "clothes_skirt", // 连衣裙
}

// 衣服图生成对应的cloth_type字段
export const CLOTH_TYPE = {
    SHIRT: 0, // 上衣
    PANTS: 1, // 下装
    SKIRT: 2, // 裙子
    SUIT: 3, // 套装
}

// AI编辑枚举值
export const AIEDIT_SOURCE = {
    AI_LIGHT: 'aibeauty', // 补光
    AI_BEAUTY: 'aiSmartBeauty', // 美颜
    AI_QUALITY: 'superResolution', // 超分
    AI_REMOVE: 'removeInpaint', // 擦除
    AI_SHAPE_SHIFT: 'aidraggan', // 变形
    AI_IMAGE_EXTRACTION: 'aiImageExtraction', // 智能抠图
}

// 大客户专属功能枚举值
export const PRIVATE_ABILITIES = {
    TRYON_DIY_POSE: "TRYON_DIY_POSE",
    MODEL_KEEP_POSE: "MODEL_KEEP_POSE",
    TRYON_KEEP_POSE: "TRYON_KEEP_POSE",
    TRYON_KEEP_BKG: "TRYON_KEEP_BKG",
    VIDEO_TRYON_DIY_KEY: "VIDEO_TRYON_DIY_KEY",
    VIDEO_TRYON_SUPPORT_BACK: "VIDEO_TRYON_SUPPORT_BACK", // 视频背身图是否支持
    MODEL_SAME_STYLE: "MODEL_SAME_STYLE", // 真人拍同款
}

// 特征：表情
export const FACE_OPTIONS = [
    {text: '默认', value: 'default'},
    {text: '微笑', value: '(light smile:1.3)'},
]

// 特征：表情
export const HAIR_OPTIONS = [
    {text: '默认', value: 'default'},
    // {text: '短发', value: '(short hair:1.3)'},
    {text: '短发', value: '(Bob haircut)'},
    {text: '长发', value: '(long hair:1.3)'},
    {text: '直发', value: '(straigth hair:1.3)'},
    {text: '卷发', value: '(curly hair:1.3)'},
    // {text: '大波浪', value: '(wavy hair:1.3)'},
    // {text: '单马尾', value: '(ponytail:1.3)'},
    {text: '马尾', value: '(twintails:1.3)'},
    // {text: '丸子头（单）', value: '(hair bun:1.3)'},
    {text: '丸子头', value: '(double bun:1.3)'},
    {text: '齐刘海', value: '(blunt bangs:1.3)'}
]

// 图片上传来源
export const UPLOAD_SOURCE = {
    MODEL: "model", // 真人图
    DRESSFORM: "dressform", // 人台图
    COMMODITY: "commodity", // 商品图
    CLOTH_TOP: "cloth_top", // 衣服上装
    CLOTH_BOTTOM: "cloth_bottom", // 衣服下装
    CLOTH_DRESS: "cloth_dress", // 衣服连体装
    TRYON_VIDEO: "tryon_video", // 模特视频输入
    AIEDIT_MODEL: "ai_edit_model", // AI编辑-服装图
    AIEDIT_COMMODITY: "ai_edit_commodity", // AI编辑-商品图
    EXTRACTION: "extraction" // 抠图输入
}