import './head.scss'
import { useLocation, useNavigate }from "react-router-dom"; 
import { useEffect, useState, useContext, useMemo, useRef } from 'react';
import { AppContext, channel } from '@utils/AppContext';
import { Menu, Divider, message, Popover, Button, Modal  } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import LoginModal from '../loginModal';
import LoggedHead from '../loggedHead/loggedHead';
import eventBus from '@utils/eventBus';
import Utils from '@utils/utils'
import styles from './head.module.scss'
import { HOME_PAGE_TYPE } from "@utils/CONST"
import PSAIModal from '@PSAIComponents/PSAIModal';
import dayjs from 'dayjs';

const Head = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    
    const { globalHomePageType, globalActivity, config, updateConfig, globalUser,setGlobalUser } = useContext(AppContext);
    const [loginOpen, setLoginOpen] = useState(false)
    const [mobile, setMobile] = useState()
    const [iconRotate , setIconRotate] = useState(0)
    const loginCallback = useRef()

    useEffect(() => {
        const handleLoginMessage = ({mobile, callback}={}) => {
            if (localStorage.getItem('token')) {
                message.info('您已登录')
            } else {
                setMobile(mobile)
                setLoginOpen(true)
            }
            if (callback) loginCallback.current = callback
        }

        updateConfig({loginHeadIndex: '1'})
        eventBus.on('login', handleLoginMessage);

        return () => {
            eventBus.off('login', handleLoginMessage);
        }
    }, [])

    const type = useMemo(() => {
        if (globalHomePageType == HOME_PAGE_TYPE.MERCHANDISE) return 'AI商品图'
        else if (globalHomePageType == HOME_PAGE_TYPE.CLOTHING) return '服装模特图'
        else if (globalHomePageType == HOME_PAGE_TYPE.IMAGE_SERVICE) return "AI商拍服务"
    }, [globalHomePageType])

    const logoShowType = useMemo(() => { // 左上角logo显示品类
        return !/\/home\/homemain\/personalCenter/.test(location.pathname) && location.pathname != '/'
    }, [location.pathname])

    useEffect(() => {
        if (!loginOpen) setMobile('')
    }, [loginOpen])

    const selectedKeys = useMemo(() => {
        return [config.loginHeadIndex]
    }, [config.loginHeadIndex])

    // useEffect(() => {
    //     // location.hash = '#'
    //     window.scrollTo({
    //         top: 0,
    //         left: 0,
    //         behavior: 'smooth'
    //     })
    // }, [config.loginHeadIndex])

    const menuChange = (e) => {
        if (e.key == HOME_PAGE_TYPE.CLOTHING) {
            if(!localStorage.getItem('token')) {
                message.warning('请先登录账号！')
                eventBus.emit('login', {
                    callback: logged => {
                        if (logged) menuChange(e)
                        else console.log('取消了登录')
                    }
                })
                return
            } else {
                Utils.useroplog(HOME_PAGE_TYPE.CLOTHING, 'function', 'navigation', {from: 'homeMenu'})
                navigate("/home/homemain/model")
            }
            sessionStorage.removeItem('taskCache_model')
            sessionStorage.removeItem('taskCache_dressform')
            sessionStorage.removeItem('taskCache_clothes')
            sessionStorage.removeItem('taskCache_tryonVideo')
        } else if (e.key == HOME_PAGE_TYPE.MERCHANDISE) {
            if(!localStorage.getItem('token')) {
                message.warning('请先登录账号！')
                eventBus.emit('login', {
                    callback: logged => {
                        if (logged) menuChange(e)
                        else console.log('取消了登录')
                    }
                })
                return
            } else {
                Utils.useroplog(HOME_PAGE_TYPE.MERCHANDISE, 'function', 'navigation', {from: 'homeMenu'})
                navigate("/home/homemain/commodity")
            }
            sessionStorage.removeItem('taskCache_commodity')
            sessionStorage.removeItem('taskCache_commodityVideo')
        } else if(e.key == 5) {
            if(!localStorage.getItem('token')) {
                message.warning('请先登录账号！')
                eventBus.emit('login', {
                    callback: logged => {
                        if (logged) menuChange(e)
                        else console.log('取消了登录')
                    }
                })
                return
            } else {
                navigate("/home/homemain/image_service")
            }
        } else if (e.key == 'accessories') {
            message.warning('暂未开放！')
            return false
        }  else if (e.key == '4') {
            window.open('/article', '_blank');
        } else {
            updateConfig({loginHeadIndex: e.key})
        }
    }

    const loginClick = () => {
        setLoginOpen(true)
    }

    const goDashboard = () => {
        updateConfig({loginHeadIndex: '1'})
    }

    const items = [{
        key: '1',
        onTitleClick: menuChange,
        popupClassName: 'menu-item-overflow-visible',
        label: (
            <span className="submenu-title-wrapper">
                产品
                <DownOutlined style={{
                    transition: "all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)",
                    transform: `scaleY(.8) rotate(${iconRotate}deg)`
                }}/>
            </span>
        ),
        children: [{
            key: HOME_PAGE_TYPE.CLOTHING,
            label: <a href="/home/homemain/model" onClick={e => e.preventDefault()}>服装版</a>
        }, {
            key: HOME_PAGE_TYPE.MERCHANDISE,
            label: <a href="/home/homemain/commodity" onClick={e => e.preventDefault()}>商品版</a>
        }, {
            key: 'accessories',
            label: (<span className='not-ready-item'>更多 ...</span>),
            disabled: true,
        }]
    },{
        key: '2',
        label: <a href="/" onClick={e => e.preventDefault()}>会员方案</a>,
    }, {
        key: '4',
        label: <a target='_blank' href="/article" onClick={e => e.preventDefault()}>产品资讯</a>,
    }, {
        key: '3',
        label: <a href="/" onClick={e => e.preventDefault()}>联系我们</a>,
    }]


    const onOpenChange = (openKeys) => {
        if (openKeys.length > 0) {
            setIconRotate(180)
        } else {
            setIconRotate(0)
        }
    }

    const goBack = () => {
        updateConfig({loginHeadIndex: '1'})
        navigate('/')
    }

    // 活动状态 0-未开始 1-进行中 2-已结束
    const ACTIVITY_240711_STATUS = useMemo(() => {
        if (!globalActivity) return 0
        const activity = globalActivity.ACTIVITY_240711
        // const now = dayjs('2024-07-14')
        const now = dayjs()
        const start = dayjs(activity.beginAt)
        const end = dayjs(activity.endAt)
        return now.isBefore(start) ? 0 : now.isAfter(end) ? 2 : 1 
    })

    const loginCloseFunc = (logged, isRegister) => {
        setLoginOpen(false)
        if (logged) {
            sessionStorage.removeItem('taskCache_model')
            sessionStorage.removeItem('taskCache_dressform')
            sessionStorage.removeItem('taskCache_clothes')
            sessionStorage.removeItem('taskCache_commodity')
            sessionStorage.removeItem('taskCache_tryonVideo')
            sessionStorage.removeItem('taskCache_commodityVideo')
            if (isRegister) { // 首次登录
                if (ACTIVITY_240711_STATUS == 1) eventBus.emit('cashbackActivity') // 返现活动
                else eventBus.emit('inviteUser')
                eventBus.emit('rewardMessage', '首次登录赠送豆数', 500)
            } else {
                message.success('登录成功', 5)
            }
        }
        if (loginCallback.current) {
            loginCallback.current(!!logged)
            loginCallback.current = null
        } else {
            const urlParams = new URLSearchParams(location.search)
            const redirect = urlParams.get('redirect')
            if (redirect) navigate(redirect)
        }
    }

    const isImageExtraction = useMemo(() => {
        return location.pathname == '/home/image_extraction'
    }, [location])


    return (
        <>
            <div className='Head LoginHead' style={{ backgroundColor: `${props.noBackground ? 'transparent' : '#FFFFFF'}` }}>
                <div className={`head_left`}>
                {
                    isImageExtraction ?
                    <span style={{display: 'flex', alignItems: 'center', fontSize: '16px', fontWeight: 700, whiteSpace: 'nowrap'}}>
                        <i className='logo2' onClick={goBack}></i>
                        智能抠图
                    </span> :
                    <>
                    {
                        logoShowType ?
                        <>
                        {type == '服装模特图' && <i className='logo2 cloth_logo' onClick={goBack}></i>}
                        {type == 'AI商品图' && <i className='logo2 mc_logo' onClick={goBack}></i>}
                        {type == 'AI商拍服务' && <i className='logo2' onClick={goBack}></i>}
                        </> :
                        <i className='logo2' onClick={goBack}></i>
                    }
                    </>
                }
                </div>
                {
                    location.pathname == '/' &&
                    <div className='head_center'>
                        <Menu selectedKeys={selectedKeys} onClick={menuChange} onOpenChange={onOpenChange} mode="horizontal" items={localStorage.getItem('channel') == 'taobao'?items.slice(0, 1).concat(items.slice(2)): items} />     
                    </div>
                }
                <div className='head_right'>
                {
                    localStorage.getItem('token') ? 
                        <LoggedHead /> :
                        // <div className='btn_r' onClick={loginClick}><span>登录</span><Divider type="vertical" /><span>注册</span></div>
                        <Button className='btn_r' type="primary" onClick={loginClick}><span>登录</span><Divider style={{backgroundColor: '#FFF'}} type="vertical" /><span>注册</span></Button>
                }
                </div>
                {
                    localStorage.getItem('channel') == 'taobao'?<p className='tips'>注:通过淘宝服务市场购买的虹豆豆仅能在此使用</p>: null
                }
            </div>
            <PSAIModal
                maxWidth={956}
                open={loginOpen}
                footer={null}
                closable={false}
                onCancel={() => loginCloseFunc(false)}
            >
                <LoginModal open={loginOpen} mobile={mobile} closeFunc={loginCloseFunc} />
            </PSAIModal>
        </>
    );
};

export default Head;