import styles from './index.module.scss'
import React, {useState} from 'react';
import { Button, Input, Upload, message  } from 'antd';
import API from '@api/api.js';
import PSAIModal from '@PSAIComponents/PSAIModal';
import { PlusOutlined } from '@ant-design/icons';
import Utils from '@utils/utils'

const AppealModal = (props) => {
    const [comments, setComments] = useState('');
    const [fileList, setFileList] = useState([]);
    const [contact, setContact] = useState('');
    const [loading, setLoading] = useState()

    const uploadButton = (
        <div>
          <PlusOutlined style={{fontSize: '22px', color: '#979797'}} />
        </div>
    );

    function feedback (fileUrls) {
        if (!contact || !comments) return message.warning('请填写被封禁的账号和申诉理由')
        if (!Utils.validateContact(contact)) return message.warning('请输入正确的手机号')
        return API.userAppeal({
            token: localStorage.getItem('token') || props.token,
            appealContent: comments,
            appealContact: contact,
            appealAttachments: fileUrls,
        }).then(res => {
            if (res.code == 0) {
                message.success('申诉提交成功，我们将会处理结果发送到您的手机！', 10)
            } else {
                message.error(res.message)
            }
        })
    }

    const onSubmit = async () => {
        try {
            setLoading(true)
            if (!comments) {
                message.warning('请输入问题描述')
                return setLoading(false)
            }
            let fileUrls = []
            if (fileList.length > 0) {
                // AAA
                const token = localStorage.getItem('token') || props.token
                const list = fileList.map(item => Utils.checkImageIslegalWithoutToken(item.originFileObj, token, 0))
                fileUrls = await Promise.all(list) // 这不需要存审核ID
                if (fileUrls.findIndex(item => !item) >= 0) return setLoading(false)
                const isLegal = fileUrls.some(item => !item.isLegal);
                if(isLegal) {
                    PSAIModal.confirm({
                        content: '经检测，您上传的内容涉嫌违规，已进行屏蔽处理，人工审核会对图片进行复核。',
                        okText: '检查图片',
                        cancelText: '取消',
                        onOk() {},
                        onCancel() {},
                    })
                    return false
                }
            }
            await feedback(fileUrls.map(item => item.data))
            
            return props.onCancel()
        } catch (err) {
            message.error(err.message)
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className={styles.FeedbackContainer}>
            <div className={styles.FormContent}>
                <div className={styles.FormItem}>
                    <label htmlFor="feedback_contact">手机号<span>*</span></label>
                    <div className={styles.FormItemContent}>
                        <Input
                            id="feedback_contact"
                            value={contact}
                            maxLength={11}
                            onChange={e => setContact(e.target.value)}
                            placeholder="手机号"
                        />
                    </div>
                </div>
                <div className={styles.FormItem}>
                    <label htmlFor="feedback_comments">申诉理由<span>*</span></label>
                    <div className={styles.FormItemContent}>
                        <Input.TextArea
                            id="feedback_comments"
                            showCount
                            maxLength={300}
                            style={{height: 150}}
                            onChange={e => setComments(e.target.value)}
                            value={comments}
                            placeholder="请填写您的申诉理由，我们会认真进行复核"
                        />
                    </div>
                </div>
                <div className={styles.FormItem}>
                    <label htmlFor="feedback_files">问题图片 {`${fileList.length}/3张`}</label>
                    <div className={styles.FormItemContent}>
                        <Upload
                            id="feedback_files"
                            accept='image/jpeg,image/png,image/webp,image/avif'
                            listType="picture-card"
                            fileList={fileList}
                            beforeUpload={() => false}
                            onPreview={() => {return false}}
                            onChange={(fileList) => setFileList(fileList.fileList)}
                            maxCount={3}
                            showUploadList={{showPreviewIcon: false}}
                        >
                            {fileList.length >= 3 ? null : uploadButton}
                        </Upload>
                    </div>
                </div>
            </div>
            <div className={styles.BtnContent}>
                <Button onClick={props.onCancel}>取消</Button>
                <Button type="primary" onClick={onSubmit} loading={loading}>提交</Button>
            </div>
        </div>
    )
}

export default AppealModal