import React, {useState, useEffect, forwardRef, useRef, useMemo, useImperativeHandle} from 'react';
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css';
import ResolutionSelection from '@view/compoents/ResolutionSelection/ResolutionSelection';
import Utils from '@utils/utils'
import styles from './crop.module.scss'

const Crop = forwardRef((props, ref) => {
    const originWidth = useRef(0)
    const originHeight = useRef(0)
    const scaleRef = useRef(1)
    const [imgStyle, setImgStyle] = useState({})

    const resolutionRef = useRef()
    const initRef = useRef(false)

    const [cropX, setCropX] = useState(0)
    const [cropY, setCropY] = useState(0)
    const [cropWidth, setCropWidth] = useState(0)
    const [cropHeight, setCropHeight] = useState(0)

    useImperativeHandle(ref, () => ({
        getCropResult,
        getCropImgBase64,
    }))

    const getCropResult = () => {
        const scale = scaleRef.current
        return {
            x: cropX / scale,
            y: cropY / scale,
            width: Math.round(cropWidth / scale),
            height: Math.round(cropHeight / scale),
            scale: scaleRef.current
        }
    }

    const getCropImgBase64 = async () => {
        return 
    }

    useEffect(() => {
        if(props.cropParams?.src) {
            let {width, height} = props.cropParams
            originWidth.current = width
            originHeight.current = height
            let scale
            if (width / height > 1) {
                scale = 500 / width
                height = height / width * 500
                width = 500
            } else {
                scale = 500 / height
                width = width / height * 500
                height = 500
            }
            setImgStyle({
                width, height,
                objectFit: 'contain'
            })
            setCropWidth(Number(Number(width).toFixed(1)))
            setCropHeight(Number(Number(height).toFixed(1)))
            scaleRef.current = scale
            initRef.current = true
        }
    },[props.cropParams])
  
    const crop = useMemo(() => {
        return { 
            unit: 'px', // Can be 'px' or '%'
            x: cropX,
            y: cropY,
            width: cropWidth,
            height: cropHeight,
        }
    }, [cropX, cropY, cropWidth, cropHeight])

    const cropChange = (crop) => {
        if (!initRef.current) return
        setCropX(crop.x)
        setCropY(crop.y)
        setCropWidth(Number(Number(crop.width).toFixed(1)))
        setCropHeight(Number(Number(crop.height).toFixed(1)))
    }
    const cropComplete = (crop) => {
        let width = Math.round(Number(Number(crop.width).toFixed(1)) / scaleRef.current)
        let height = Math.round(Number(Number(crop.height).toFixed(1)) / scaleRef.current)
        if (resolutionRef.current) {
            const size = resolutionRef.current.getSize()
            if (size.width != width || size.height != height) {
                resolutionRef.current.customSize(width, height)
            }
        }
    }
    const onChange = (w, h, ratio) => {
        if (!initRef.current) return
        const width = Number(Number(w * scaleRef.current).toFixed(1))
        const height = Number(Number(h * scaleRef.current).toFixed(1))
        setCropWidth(width)
        setCropHeight(height)
    }

    const [direction, setDirection] = useState('horizontal') // horizontal横屏；vertical竖屏
    const [ratio, setRatio] = useState('9:16') // 9:16, 4:3, 1:1

    return (
        <div className={styles.crop}>
            <ReactCrop
                className={styles.crop_ReactCrop}
                crop={crop}
                keepSelection={true}
                // locked={true}
                ruleOfThirds
                onChange={cropChange}
                onComplete={cropComplete}
            >
                <img style={imgStyle} src={props.cropParams.src} />
            </ReactCrop>
            <div style={{width: '65%'}}>
                <ResolutionSelection
                    ratioOnly={props.ratioOnly}
                    ref={resolutionRef}
                    initImgRatio={"custom"}
                    initImgWidth={props.cropParams.width}
                    initImgHeight={props.cropParams.height}
                    initCustomWidth={Math.round(Number(Number(cropWidth).toFixed(1)) / scaleRef.current)}
                    initCustomHeight={Math.round(Number(Number(cropHeight).toFixed(1)) / scaleRef.current)}
                    baseResolution={Math.min(props.cropParams.width, props.cropParams.height)}
                    maxWidth={Math.floor(props.cropParams.width - cropX / scaleRef.current)}
                    minWidth={10}
                    maxHeight={Math.floor(props.cropParams.height - cropY / scaleRef.current)}
                    minHeight={10}
                    onChange={onChange}
                />
            </div>
        </div>
    )
})
export default Crop