import React, { useState, useContext, useEffect, useMemo } from 'react';
import { LoadingOutlined, DownOutlined } from '@ant-design/icons';
import { Dropdown, Input, Space, Pagination, Button, message, Modal, Empty, Spin } from 'antd';
import { useNavigate, useLocation } from "react-router-dom";
import useAllProject from './hooks/useAllProjects';
import API from '@api/api.js';
import utils from '@utils/utils.js'
import { AppContext } from '@utils/AppContext';
import ProjectPicView from '@view/compoents/projectPicView/projectPicView'
import styles from './myProject.module.scss'
import { HOME_PAGE_TYPE } from "@utils/CONST"
import PSAIModal from '@PSAIComponents/PSAIModal';

const FAVOR_REDIRECT_URL = '/home/homemain/favor_detail'
const REDIRECT_URL = '/home/homemain/photoProjectDetail';

const FIELD_MAP = {
    'updatedTime': '修改时间',
    'createdTime': '创建时间',
    'projectName' : '项目名称'
}

const FIELD_MAP2 = {
    'all': '全部',
    'image': '模特图',
    'video' : '模特视频'
}
const FIELD_MAP3 = {
    'all': '全部',
    'image': '商品图',
    'video' : '商品视频'
}

const PAGE_SIZE_NUM = 11;

const MyProject = () => {
    const {globalHomePageType} = useContext(AppContext)
    const [order, setOrder] = useState(localStorage.getItem('order') || 'desc')
    const [sortField, setSortField] = useState(localStorage.getItem('sortField') || 'updatedTime')
    const [projectName, setProjectName] = useState(localStorage.getItem('searchName') || '')
    const [searchName, setSearchName] = useState(localStorage.getItem('searchName') || '')
    const [pageIndex, setPageIndex] = useState(localStorage.getItem('pageIndex') ? Number(localStorage.getItem('pageIndex')) : 1)
    const [pageSize] = useState(PAGE_SIZE_NUM)
    const [outputType, setOutputType] = useState('all') // video / image

    const TYPE_FIELD_MAP = useMemo(() => {
        if (globalHomePageType == HOME_PAGE_TYPE.MERCHANDISE) {
            return FIELD_MAP3
        } else if (globalHomePageType == HOME_PAGE_TYPE.CLOTHING) {
            return FIELD_MAP2
        }
    }, [globalHomePageType])

    const searchDetail = useMemo(() => {
        return {
            // 搜索名
            projectName: projectName,
            // 页数索引
            pageIndex: pageIndex,
            // 页数
            pageSize: pageSize,
            // 搜索的字段
            sortField: sortField,
            // 排序的顺序
            order: order,
            // type: commodityVideo
            outputType: outputType
        }
    }, [projectName, pageIndex, pageSize, sortField, order, outputType])

    // const [update ,forceUpdate] = useState({}) // 触发更新flag
    const { update, forceUpdate, updateConfig } = useContext(AppContext) // 触发更新flag
    // 全部的项目信息，以及总量
    const { allProject, favoriteList, total, loading } = useAllProject(searchDetail, update);
    // 选中的项目
    const [selectedPics, setSelectedPics] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const [dropdownOpen2, setDropdownOpen2] = useState(false)
    // 是否点击选择
    const [isSelected, setIsSelected] = useState(false);
    const [downLoading, setDownLoading] = useState(false)
    const navigate = useNavigate();

    useEffect(() => {
        return () => {
            localStorage.setItem('sortField', sortField)
            localStorage.setItem('order', order)
            localStorage.setItem('searchName', searchName)
        }
    }, [sortField, order, searchName, pageIndex])

    useEffect(() => {
        const debounceTimer = setTimeout(() => {
            // 在延迟时间内执行最后一次事件
            setProjectName(searchName)
        }, 500) // 设置延迟时间为500毫秒

        // 每次 searchName 发生变化时，清除之前的定时器
        return () => clearTimeout(debounceTimer);
    }, [searchName])

    // 解决最后一页数据清空时，无法跳转到前一页的问题
    useEffect(() => {
        if (total > 0) {
            const totalPages = Math.ceil(total / pageSize)
            setPageIndex(pre => Math.min(pre, totalPages))
            localStorage.setItem('pageIndex', pageIndex)
        }
    }, [total, pageIndex])

    const onDelete = () => {
        PSAIModal.confirm({
            title: "删除项目",
            content: "请确认是否删除这些项目",
            onOk: deleteProject
        })
    }

    const deleteProject = () => {
        return API.projectBatchMove({
            ids: selectedPics
        }).then(res => {
            if (res?.code === 0 && res?.message === 'succeed') {
                message.success('删除成功');
                setSelectedPics([])
                forceUpdate({})
            } else {
                message.error('删除失败，请联系管理员')
            }
        })
    }

    const downloadPatchProject = () => {
        setDownLoading(true)
        API.projectPatchDownload({
            ids: selectedPics
        }).then(res => {
            if (res?.code === 0 && res?.message === 'succeed') {
                return utils.downloadFileWithToken(res?.data).then(() => {
                    message.success('下载成功');
                }).catch(() => {
                    message.warning('下载失败，请稍后重试');
                })
            } else {
                message.warning(res?.message || '下载失败，请联系管理员')
            }
        }).finally(() => {
            setDownLoading(false)
        })
    }

    // 全选/取消全选
    const selectAll = () => {
        if (isSelectedAll) {
            setSelectedPics([])
        } else {
            setSelectedPics(allProject.map(item => item.id))
        }
    }

    // 判断是否已全选
    const isSelectedAll = useMemo(() => {
        if (allProject.length == 0 || allProject.length != selectedPics.length) return false

        const sortedArr1 = allProject.map(item => item.id).sort()
        const sortedArr2 = selectedPics.slice().sort()

        for (let i = 0; i < sortedArr1.length; i++) {
            if (sortedArr1[i] !== sortedArr2[i]) {
                return false;
            }
        }

        return true
    }, [allProject, selectedPics])

    const outputTypeItems = useMemo(() => {
        if (globalHomePageType == HOME_PAGE_TYPE.MERCHANDISE) {
            return [{
                key: 'all',
                label: <p>全部</p>,
                onClick: () => {setOutputType('all')}
            }, {
                key: 'image',
                label: <p>商品图</p>,
                onClick: () => {setOutputType('image')}
            }, {
                key: 'video',
                label: <p>商品视频</p>,
                onClick: () => {setOutputType('video')}
            }]
        } else if (globalHomePageType == HOME_PAGE_TYPE.CLOTHING) {
            return [{
                key: 'all',
                label: <p>全部</p>,
                onClick: () => {setOutputType('all')}
            }, {
                key: 'image',
                label: <p>模特图</p>,
                onClick: () => {setOutputType('image')}
            }, {
                key: 'video',
                label: <p>模特视频</p>,
                onClick: () => {setOutputType('video')}
            }]
        }
    }, [globalHomePageType])
    
    const items = [
        {
            key: 'updatedTime',
            label: <p>修改时间</p>,
            onClick: () => {setSortField('updatedTime')}
        }, {
            key: 'projectName',
            label: <p>项目名称</p>,
            onClick: () => {setSortField('projectName')}
        }
        , {
            type: 'divider',
        }
        , {
            key: 'asc',
            label: <p>升序</p>,
            onClick: () => {setOrder('asc')}
        },  {
            key: 'desc',
            label: <p>降序</p>,
            onClick: () => {setOrder('desc')}
        },
    ];

    useEffect(() => {
        updateConfig({sidebarKey: 0})
    }, [])
  
    return (
       <>
            <div className={`main_style ${styles.myProject}`}>
                <div className={styles.m_title}>
                    <div className={styles.m_title_c}>
                        <div className={styles.m_menu}>
                            <Dropdown menu={{items}} trigger={['click']} open={dropdownOpen} onOpenChange={flag => setDropdownOpen(flag)}>
                                <Space>
                                    {FIELD_MAP[searchDetail.sortField]}
                                    <DownOutlined className={styles.dropdown_action} />
                                </Space>
                            </Dropdown>
                        </div>
                        <div className={styles.m_menu}>
                            <Dropdown menu={{items: outputTypeItems}} trigger={['click']} open={dropdownOpen2} onOpenChange={flag => setDropdownOpen2(flag)}>
                                <Space>
                                    {TYPE_FIELD_MAP[searchDetail.outputType]}
                                    <DownOutlined className={styles.dropdown_action} />
                                </Space>
                            </Dropdown>
                        </div>
                        <Input
                            allowClear
                            value={searchName}
                            className={styles.focused}
                            placeholder="请输入要搜索的项目名称"
                            prefix={<img style={{width: 14}} src={require('@assets/images/Find.png')} alt="" />}
                            onChange={(e) => setSearchName(e.target.value)}
                        />
                    </div>
                    <div className={styles.options_opts}>
                        {!isSelected && <Button className={styles.options_select} onClick={() => setIsSelected(true)}>选择</Button>}
                        {isSelected && 
                        <>
                            <Button onClick={() => selectAll()}>{isSelectedAll ? '取消全选' : '全选'}</Button>
                            <Button disabled={selectedPics.length==0} loading={downLoading} onClick={() => selectedPics.length > 0 ? downloadPatchProject() : message.warning('请选择一条记录')}>下载</Button>
                            <Button disabled={selectedPics.length==0} onClick={() => selectedPics.length > 0 ? onDelete() : message.warning('请选择一条记录')}>删除</Button>
                            <Button
                                style={{background: '#edf0ff'}}
                                onClick={() => {
                                    setIsSelected(false);
                                    setSelectedPics([])
                                }}
                            >取消</Button>
                        </>
                        }
                    </div>
                </div>
                <div className={styles.m_box} style={{minHeight: 600}}>
                    {/* <div className='m_box_item_'>
                        <div className='m_box_create' onClick={createClick}>
                            <div className='m_box_create_'>
                                <div className='m_box_create_box'>
                                    <img src={require('@assets/images/icon_New Project.png')} alt="" />
                                    <p>新建项目</p>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {
                        (allProject && allProject.length > 0 && !loading) &&
                        <div className={styles.m_box_item_} key={"bookmark"}>
                            <ProjectPicView
                                favorite={true}
                                favoriteList={favoriteList}
                                projectInfo={{projectName: '我的收藏'}}
                                showDetail={{sortField: searchDetail.sortField}}
                                forceUpdate={forceUpdate}
                                clickFn={() => navigate(FAVOR_REDIRECT_URL)}
                            />
                        </div>
                    }
                    {allProject.map((project) => (
                        <div className={styles.m_box_item_} key={project?.id}>
                            <ProjectPicView
                                isSelected={isSelected}
                                checked={selectedPics.includes(project?.id)}
                                setSelectedPics={setSelectedPics}
                                projectInfo={project}
                                showDetail={{sortField: searchDetail.sortField}}
                                forceUpdate={forceUpdate}
                                clickFn={() => navigate(REDIRECT_URL, {state: {id: project?.id}})}
                            />
                        </div>
                    ))}
                    {   
                        (!allProject || allProject.length == 0) &&
                        !loading &&
                        <div style={{ width: '100%', height: '100%', display: "flex", justifyContent: "center", alignItems: "flex-start", paddingTop: 200 }}>
                            <Empty description={false} />
                        </div>
                    }
                    {
                        loading &&
                        <div style={{ width: '100%', height: '100%', display: "flex", justifyContent: 'center', alignItems: "flex-start", paddingTop: 200 }}>
                            <Spin
                                spinning={loading}
                                size="large"
                            ></Spin>
                        </div>
                    }
                </div>
                <Pagination
                    className={styles.pagination}
                    hideOnSinglePage
                    current={pageIndex}
                    defaultCurrent={1}
                    total={total}
                    pageSize={PAGE_SIZE_NUM}
                    showSizeChanger={false} 
                    onChange={(pageIndex) => setPageIndex(pageIndex)}
                />
                
                
            </div>
       </>
    )
}

export default MyProject