import { useContext, useEffect, useMemo } from "react"
import { AppContext } from "@utils/AppContext"
import { HOME_PAGE_TYPE } from "@utils/CONST"
import { useNavigate, useLocation }from "react-router-dom"; 
import styles from './index.module.scss'

const SwitchHomePageType = (props) => {
    const { globalHomePageType, updateGlobalHomePageType, globalUser } = useContext(AppContext)
    const navigate = useNavigate();
    const location = useLocation();

    const activedType = useMemo(() => {
        console.log('globalHomePageType', globalHomePageType)
        if (globalHomePageType == HOME_PAGE_TYPE.CLOTHING) return "CLOTH"
        else if (globalHomePageType == HOME_PAGE_TYPE.MERCHANDISE) return "MERCHANDISE"
        else if (globalHomePageType == HOME_PAGE_TYPE.IMAGE_SERVICE) return "SERVICE"
    }, [globalHomePageType])

    const onSwitch = (type) => {
        if (type == HOME_PAGE_TYPE.CLOTHING) navigate("/home/homemain/model")
        else if (type == HOME_PAGE_TYPE.MERCHANDISE) navigate("/home/homemain/commodity")
        else if (type == HOME_PAGE_TYPE.IMAGE_SERVICE) navigate("/home/homemain/image_service")
    }

    const sliderPosition = useMemo(() => {
        if (activedType == "CLOTH") {
            return '0%'
        } else if (activedType == "MERCHANDISE") {
            return '50%'
        }
    }, [activedType])



    return (
        <div className={styles.Container}>
            <div className={styles.SliderBar} style={{ left: sliderPosition }}></div>
            <div className={`${styles.SelectItem} ${activedType == 'CLOTH' && styles.actived}`} onClick={() => onSwitch(HOME_PAGE_TYPE.CLOTHING)}>服装版</div>
            <div className={`${styles.SelectItem} ${activedType == 'MERCHANDISE' && styles.actived}`} onClick={() => onSwitch(HOME_PAGE_TYPE.MERCHANDISE)}>商品版</div>
            {/* {
                globalUser?.userType === 1? <div className={`${styles.SelectItem} ${activedType == 'SERVICE' && styles.actived}`} onClick={() => onSwitch(HOME_PAGE_TYPE.IMAGE_SERVICE)}>AI 摄影</div>: null
            } */}
        </div>
    )
}

export default SwitchHomePageType