import React, {useState, useEffect, useRef, useContext, useMemo, useCallback} from 'react';
import { Radio, Upload, Checkbox, Popover, Empty, Modal, message, Image, Spin } from 'antd';
import Utils from '@utils/utils'
import API from '@api/api'
import { AppContext } from '@utils/AppContext';
import styles from './index.module.scss'
import { GenerateContext } from "@utils/GenerateContext"
import SelectionItem from '../SelectionItem';
import { IMAGE_TYPE } from '@utils/CONST';
import PSAIModal from '@PSAIComponents/PSAIModal';
import { UploadOutlined, LoadingOutlined } from '@ant-design/icons';
import { PRIVATE_ABILITIES } from "@utils/CONST"
import exampleJSON from '@assets/custom_model_example/example.json'

// 模特
export default function PoseSelection (props) {
    const {
        isProEnv,
        hasPrivateAbility,
    } = useContext(AppContext);
    const {
        modelFilter,
        poseDate, // 全局姿势列表
        setPoselList, // 修改全局姿势列表
        customPoseDate, // 全局自定义姿势列表
        setCustomPoseList, // 修改全局自定义姿势列表
    } = useContext(GenerateContext);
    const [loading, setLoading] = useState(true)
    const [update, forceUpdate] = useState()

    // 筛选条件 皮肤/性别/年龄
    const [sex, setSex] = useState([...modelFilter?.sex || []]);
    const [skin, setSkin] = useState([...modelFilter?.skin || []]);
    const [age, setAge] = useState([...modelFilter?.age || []]);
    useEffect(() => {
        setSex([...modelFilter?.sex || []])
        setSkin([...modelFilter?.skin || []])
        setAge([...modelFilter?.age || []])
    }, [modelFilter])
    
    const fetchData = () => {
        setLoading(true)
        return API.modelTryOnList({
            source: props.isCustomPose ? 1 : 0, // 0-预设素材;1-自定义素材；
            age: age.join(),
            sex: sex.join(),
            skin: skin.join(),
        }).then(res => {
            if (res.code != 0) throw new Error(res.message)
            // 模特随机的key是00_default_style，需要过滤掉
            const list = res.data.filter(item => item.name != '00_default_style')
            if (props.isCustomPose) {
                setCustomPoseList([...list])
            } else {
                setPoselList([...list])
            }
            return list
        }).catch(err => {
            console.error(err)
            message.warning(err.message)
        }).finally(() => {
            setLoading(false)
        })
    }

    const noticeOnceRef = useRef(true) // 未找到素材的提示仅出现一次
    const checkIsExist = (list, poseName) => {
        let found = false
        if (!found) {
            // 检查是否包含 props.poseName 对应的项，找不到的话需要弹窗提示
            for (let i = 0; i < list.length; i++) {
                if (list[i].name === poseName) {
                    found = true;
                    break;
                }
                let subList = list[i].subList
                if (subList) {
                    for (let j = 0; j < subList.length; j++) {
                        if (subList[j].name === poseName) {
                            found = true;
                            break
                        }
                    }
                }
            }
        }
        if (noticeOnceRef.current) {
            noticeOnceRef.current = false
            if (found) props.foundPoseFunc()
            else props.notFoundPoseFunc()
        }
        return found
    }
    // 将sublist内部的选择项移到外面
    const switchSubToMain = (list, poseName) => {
        let found = false
        for (let i = 0; i < list.length; i++) {
            if (list[i].name === poseName) break
            let subList = list[i].subList
            if (subList) {
                for (let j = 0; j < subList.length; j++) {
                    if (subList[j].name === poseName) {
                        list[i].name = poseName
                        list[i].imageUrl = subList[j].imageUrl
                        found = true
                        break
                    }
                }
            }
            if (found) break
        }
        if (found) return list
        else return false
    }
    // 初始化场景
    const initedRef = useRef(false)
    useEffect(() => {
        fetchData().then(list => {
            if (!list) return
            if (initedRef.current) return
            initedRef.current = true
            // if (props.poseNameList.length == 0) {
            //     let selectedName = list[0]?.name
            //     let isSelfDefined = list[0]?.attr.skin == 'custom'
            //     let selfDefinedImg = list[0]?.attr.tryOnImage
            //     props.onPoseChange(selectedName, isSelfDefined, selfDefinedImg)
            // }
        })
    }, [age, sex, skin, update, props.refreshRecent])

    useEffect(() => {
        const resourceList = props.isCustomPose ? customPoseDate : poseDate
        if (!resourceList) return
        if (props.poseNameList.length > 0) {
            for (let name of props.poseNameList) {
                const isExist = checkIsExist(resourceList, name)
                if (!isExist) props.onPoseChange(name)
            }
        }
        if (props.poseNameList.length > 0) {
            const list = switchSubToMain(resourceList, props.poseNameList[0])
            if (list) {
                if (props.isCustomPose) {
                    setCustomPoseList([...list])
                } else {
                    setPoselList([...list])
                }
            }
        }
    }, [poseDate, customPoseDate, props.poseNameList])

    // subList选中时替换主体的name和url
    const onMoreSceneChange = (item) => {
        let isSelfDefined = item.attr.skin == 'custom'
        let selfDefinedImg = item.attr.tryOnImage
        props.onPoseChange(item.name, isSelfDefined, selfDefinedImg)
    }

    // 选择某个主体
    const selectImgClick = (item) => {
        let isSelfDefined = item.attr.skin == 'custom'
        let selfDefinedImg = item.attr.tryOnImage
        props.onPoseChange(item.name, isSelfDefined, selfDefinedImg)
    }

    // 显示自定义姿势
    // const showCustomPose = useMemo(() => {
    //     // return !isProEnv
    //     return !isProEnv || hasPrivateAbility(PRIVATE_ABILITIES.TRYON_DIY_POSE)
    //     // return true
    // }, [hasPrivateAbility])

    const showList = useMemo(() => {
        const resourceList = props.isCustomPose ? customPoseDate : poseDate
        if (!resourceList) return []
        const poseDataFiltered = resourceList
        const showCount = props.isCustomPose ? props.visibleCount - 1 : props.visibleCount
        
        // const poseIndexList = props.poseNameList.map(name => {
        //     return poseDataFiltered.findIndex(item => {
        //         if (item.name == name) return true
        //         if (item.subList?.find(subitem => subitem.name == name)) return true
        //     })
        // }).filter(index => index >= 0).sort()
        // console.log('poseIndexList', poseIndexList)

        return poseDataFiltered.slice(0,showCount)
    }, [poseDate, customPoseDate, props.poseNameList, props.isCustomPose, props.visibleCount])

    // 图片要求
    const [open, setOpen] = useState()
    const onImgRequire = (e) => {
        e.stopPropagation()
        setOpen(true)
    }
    const uploadFile = (file) => {
        return Utils.checkImageIslegal(file, 1).then(res => {
            if (!res) return false
            if (!res.isLegal) {
                PSAIModal.confirm({
                    content: '经检测，您上传的内容涉嫌违规，已进行屏蔽处理，人工审核会对图片进行复核。',
                    okText: '重新上传',
                    cancelText: '取消',
                    onOk() {
                        Utils.openFile().then((res) => {
                            if(res) beforeUpload(res)
                        })
                    },
                    onCancel() {},
                })
                return false
            } else {
                const {imageAntispamId, data} = res
                return {
                    url: data,
                    imageAntispamId
                }
            }
        }).catch((err) => {
            message.error(err.message)
        })
    }

    const [uploadLoading, setUploadLoading] = useState(false)
    const doUpload = async (file) => {
        try {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/webp'|| file.type === 'image/avif';
            if (!isJpgOrPng) throw new Error('您只能上传JPG/PNG/WEBP/AVIF格式的文件')
            const resizeRes = await Utils.resizeImageFileMaxByAliyunBy(file, 2048)
            if (!resizeRes || !resizeRes.success) throw new Error(resizeRes?.message)
            file = resizeRes.file
            const res = await uploadFile(file)
            if (!res) return false
            const {url, imageAntispamId} = res
            const res2 = await API.saveCustomTryOnModel({
                tryOnImage: url,
                thumbnail: url,
                imageAntispamId,
            })
            if (res2?.code != 0) throw new Error(res2?.message)
            // 自动选中刚刚上传的图
            // fetchData().then(list => {
            //     for (let i = 0; i < list.length; i++) {
            //         const item = list[i]
            //         if (item.attr.tryOnImage == url) {
            //             let selfDefinedImg = item.attr.tryOnImage
            //             props.onPoseChange(item.name, true, selfDefinedImg)
            //             break
            //         }
            //     }
            // })
            return url
        } catch (err) {
            // message.warning(err.message)
            return false
        }
    }
    const [totalCount, setTotalCount] = useState(0)
    const totalCountRef = useRef(0)
    const [successCount, setSuccessCount] = useState(0)
    const successCountRef = useRef(0)
    const uploadDebounce = useCallback(_.debounce(async (file, fileList) => {
        setUploadLoading(true)
        console.log('===uploadDebounce===')
        if (fileList.length > 20) message.warning('单次批量上传最多20张')
        fileList = fileList.slice(0, 20)
        totalCountRef.current = fileList.length
        setTotalCount(fileList.length)
        setSuccessCount(0)
        successCountRef.current = 0
        for (let i = 0; i < fileList.length; i++) {
            const file = fileList[i]
            const res = await doUpload(file)
            if (res) {
                successCountRef.current++
                setSuccessCount(pre => pre+1)
            }
        }
        setOpen(false)
        forceUpdate({})
        if (successCountRef.current == 0) {
            message.warning('上传失败，请重新上传符合要求的模特图')
        } else if (successCountRef.current != totalCountRef.current) {
            const failCount = totalCountRef.current - successCountRef.current
            message.info(`${failCount}张上传失败，请重新上传符合要求的模特图`)
        } else {
            message.success('上传成功')
        }
        setUploadLoading(false)
    }, 100), [])
    const beforeUpload = async (file, fileList) => {
        if (uploadLoading) return
        uploadDebounce(file, fileList)
        return false
    }
    const handleDrop = async (e) => {
        if (uploadLoading) return
        if ("getFilesAndDirectories" in e.dataTransfer) {
            const filesAndDirs = await e.dataTransfer.getFilesAndDirectories()
            const fileList = await Utils.getFilesFromDirs(filesAndDirs, "/")
            console.log('===handleDrop===')
            uploadDebounce(fileList[0], fileList)
            return false
        }
    }

    const onRemove = (item) => {
        PSAIModal.confirm({
            title: '确定删除这个自定义模特吗？',
            onOk() {
                API.removeCustomTryOnModel({
                    id: item.id
                }).then(res => {
                    if (res?.code != 0) return message.warning(res?.message)
                    message.success('删除成功')
                    forceUpdate({})
                })
            },
        })
    }

    return (
        <div className={styles.Container}>
            <div className={styles.Content}>
            {
                loading ? <div className={styles.empty_wrap}><Spin /></div> :
                (!props.isCustomPose && showList?.length == 0) ? <div className={styles.empty_wrap}><Empty /></div> : 
                <div className={styles.SelectionItemBox}>
                {
                    props.isCustomPose &&
                    <Upload.Dragger
                        disabled={uploadLoading}
                        key="upload"
                        className={`${styles.sceneItem} ${styles.uploadWrap}`}
                        accept='image/jpeg,image/png,image/webp,image/avif'
                        showUploadList={false}
                        multiple
                        beforeUpload={beforeUpload}
                        onDrop={handleDrop}
                    >
                        <div
                            className={[
                                styles.addTrigger,
                                props.tryOn && styles.tryOn,
                            ].join(' ')}
                        >
                            <div className={styles.addTriggerContent}>
                                { uploadLoading ? <LoadingOutlined /> : <UploadOutlined /> }
                                <p className={styles.Title}>上传模特图</p>
                                { uploadLoading && <p className={styles.UploadProgress}>{successCount} / {totalCount}</p> }
                                <p className={styles.Requirement} onClick={onImgRequire}>图片要求</p>
                                {/* <div className={styles.cornerMark}><span>限时免费</span></div> */}
                            </div>
                        </div>
                    </Upload.Dragger>
                }
                {
                    showList.map(item => (
                        <SelectionItem
                            key={item.id}
                            isPose
                            showTitle
                            multiple
                            selected={props.poseNameList}
                            onClick={selectImgClick}
                            onMoreClick={onMoreSceneChange}
                            onRemove={onRemove}
                            record={item}
                        />                        
                    ))
                }
                </div>
            }
            </div>
            <PSAIModal
                title={"图片要求"}
                open={open}
                maxWidth={850}
                footer={null}
                onCancel={() => setOpen(false)}
            >
                <div className={styles.UploadExample}>
                    <div>
                        <p className={styles.UploadExampleTitle}>正确示例</p>
                        <div className={styles.UploadExampleList}>
                        {exampleJSON.correct_list.map(item => (
                            <div key={item.value} className={styles.UploadExampleItem}>
                                <div>
                                    <img src={require(`@assets/custom_model_example/${item.value}`)} />
                                    <img src={require('@assets/images/accurate.png')} alt="" />
                                </div>
                                <p>{item.label.split('\r\n').map(item => <span key={item}>{item}<br/></span>)}</p>
                            </div>
                        ))}
                        </div>
                    </div>
                    <div>
                        <p className={styles.UploadExampleTitle}>错误示例</p>
                        <div className={styles.UploadExampleList}>
                        {exampleJSON.incorrect_list.map(item => (
                            <div key={item.value} className={styles.UploadExampleItem}>
                                <div>
                                    <img src={require(`@assets/custom_model_example/${item.value}`)} />
                                    <img src={require('@assets/images/incorrect.png')} alt="" />
                                </div>
                                <p>{item.label.split('\r\n').map(item => <span key={item}>{item}<br/></span>)}</p>
                            </div>
                        ))}
                        </div>
                    </div>
                </div>
            </PSAIModal>
        </div>
    )
}