import { Modal, Spin } from "antd"
import { useEffect, useState, useRef } from "react"
import QRCode from 'qrcode';
import styles from './shareModal.module.scss'
import { LoadingOutlined } from '@ant-design/icons';
import PSAIModal from "@PSAIComponents/PSAIModal";

const ShareModal = (props) => {
    const [open, setOpen] = useState()
    const [img, setImg] = useState()
    const [loading, setLoading] = useState()
    const qrCodeRef = useRef(null);
    
    useEffect(() => {
        setLoading(true)
        setOpen(props.open)
        setImg(props.shareImg)
    }, [props.open, props.shareImg])

    const afterOpenChange = (open) => {
        const generateQRCode = async () => {
            try {
                const qrCodeDataUrl = await QRCode.toDataURL(img);
                qrCodeRef.current.src = qrCodeDataUrl
                setLoading(false)
            } catch (error) {
                console.error('Failed to generate QR code:', error)
            }
        }
        if (open) generateQRCode()
    }

    return (
        <PSAIModal
            title="分享"
            open={open}
            footer={null}
            maxWidth={320}
            getContainer={document.body}
            afterOpenChange={afterOpenChange}
            onCancel={() => props.closeFunc()}
        >
            <div className={styles.ShareBody}>
                <div className={styles.ShareBox}>
                    <Spin
                        spinning={loading}
                        size="large"
                    >
                        <img ref={qrCodeRef} src="" />
                    </Spin>
                </div>
                <p className={styles.ShareTips}>分享至微信</p>
            </div> 
        </PSAIModal>
    )
}

export default ShareModal