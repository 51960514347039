import { Button, Upload } from "antd"
import styles from './ToolBox.module.scss'
import { useContext } from "react";
import { AppContext } from "@utils/AppContext";

const ToolBox = (props) => {
    // const {setGlobalLoading} = useContext(AppContext);

    const onBeforeUpload = async (file) => {
        await props.reUpload(file)
        return false
    }

    return (
        <div className={styles.ToolBox}>
            {
                !props.mattingDisabled &&
                <Button className={`${props.border && styles.border}`} onClick={props.mattingOpt}>
                    抠图优化
                </Button>
            }
            {
                !props.composeDisabled &&
                <Button className={`${props.border && styles.border}`} onClick={props.composeOpt}>
                    构图优化
                </Button>
            }
            <Upload
                accept={'image/jpeg,image/png,image/webp,image/avif'}
                listType="picture"
                showUploadList={false}
                className={styles.Upload}
                beforeUpload={onBeforeUpload}
                maxCount={1}
            >
                <Button className={`${props.border && styles.border}`}>
                    重新上传
                </Button>
            </Upload>
        </div>
    )
}
export default ToolBox