import React, {useState, useEffect, useContext, useMemo, useRef} from 'react';
import {useNavigate} from 'react-router-dom'
import { Table, Spin, Divider , Space, message, Popconfirm, Tabs, Tag, Button, Skeleton, Tour, Upload } from 'antd';
import API from '@api/api.js';
import UpdatePasswordModal from '@view/compoents/personalCenterModal/updatePasswordModal'
import BindWechatModal from '@view/compoents/personalCenterModal/bindWechatModal';
import PayModal from '@view/compoents/payModal/payModal'
import Customized from "@view/compoents/customized";
import OneTimePay from "@view/compoents/oneTimePay/oneTimePay";
import { AppContext, colorPrimary } from '@utils/AppContext';
import { RightOutlined } from '@ant-design/icons';
import styles from './PersonalCenter.module.scss'
import MemberPackage from './compoents/memberPackage/memberPackage';
import GoBack from './compoents/goBack/goBack';
import eventBus from '@utils/eventBus';
import PSAIModal from '@PSAIComponents/PSAIModal';
import dayjs from 'dayjs';
import Utils from '@utils/utils';

const PersonalCenter = (props) => {   
    const { globalUser, globalActivity, updateConfig, setGlobalLoading } = useContext(AppContext);
    const [info, setInfo] = useState();
    const [plansList, setPlansList] = useState([]);
    const [memberList, setMemberList] = useState([]);
    const [orderList, setOrderList] = useState([]);
    const [orderLoading, setOrderLoading] = useState(false)
    const [logList, setLogList] = useState([])
    const [logLoading, setLogLoading] = useState(false)
    const [wechatModal, setWechatModal] = useState(false)
    const [pwdOpen, setPwdOpen] = useState(false)
    const [powerList, setPowerList] = useState([])
    const [alipayOpen, setAlipayOpen] = useState(false)
    const [alipayItems, setAlipayItems] = useState()
    const [isPowerPackage, setIsPowerPackage] = useState(false)
    const [loading, setLoading] = useState(false)
    const [imageUrl, setImageUrl] = useState();
    const [isRecord] = useState(props.record);
    const navigate = useNavigate()
    const payChildRef = useRef(null);

    const handleCancelOrder = (record) => {
        API.removeOrder({tradeId: record.tradeId}).then(res => {
            message.success('订单已取消')
            getOrderList()
        })
    }

    const handlePay = (record) => {
        eventBus.emit('pc_pay', record.tradeId, () => getOrderList())
    }

    const logColumns = [{
        title: '扣点项目',
        key: 'title',
        render: (record) => record.title,
    }, {
        title: '扣除虹豆数',
        dataIndex: 'amount',
        key: 'amount',
    }, {
        title: '操作时间',
        dataIndex: 'createdTime',
        key: 'createdTime'
    }]
    const orderColumns = [{
        title: '订单编号',
        key: 'tradeId',
        dataIndex: 'tradeId',
    }, {
        title: '服务',
        key: 'subject',
        dataIndex: 'subject',
    }, {
        title: '订单金额',
        key: 'dealAmount',
        dataIndex: 'dealAmount',
    }, {
        title: '订单状态',
        key: 'status',
        dataIndex: 'status',
        render: (text, record) => {
            if (text == 'created') return <Tag color="red" style={{cursor: "pointer", width: 65}} onClick={() => handlePay(record)}>待支付-></Tag>
            else if (text == 'closed') return <Tag style={{width: 65, textAlign: 'center'}}>已关闭</Tag>
            else if (text == 'success') return <Tag color="geekblue" style={{width: 65, textAlign: 'center'}}>已完成</Tag>
            else if (text == 'failed') return <Tag color="red" style={{width: 65, textAlign: 'center'}}>失败</Tag>
            else if (text == 'admin') return <Tag style={{width: 65, textAlign: 'center'}}>已分配</Tag>
        }
    }, {
        title: '下单时间',
        key: 'orderTime',
        dataIndex: 'orderTime',
    }, {
        title: '操作',
        key: 'action',
        render: (_,record) => {
            return record.status == 'created' ? <Button size="small" type="link" onClick={() => handleCancelOrder(record)}>取消订单</Button> : null
        }
    }]
    const columns = [
        {
            title: '订单编号',
            key: 'tradeId',
            render: (record) => record.tradeId,
        },
        {
            title: '服务',
            key: 'subject',
            render: (record) => record.subject,
        },
        {
            title: '虹豆总量',
            dataIndex: 'powerAmount',
            key: 'powerAmount',
        },
        {
            title: '已用虹豆',
            render: (record) => record.powerAmount - record.powerBalance,
        },
        {
            title: '余额 ',
            dataIndex: 'powerBalance',
            key: 'powerBalance',
        },
        {
            title: '可用状态',
            render: (record) => record.status == 0 ?
                                <Tag color="geekblue">生效中</Tag> :
                                record.status == 1 ?
                                <Tag>已失效</Tag> :
                                <Tag>已升级</Tag>,
        },
        {
            title: '生效时间',
            dataIndex: 'effectiveTime',
            key: 'effectiveTime',
        },
        {
            title: '失效时间',
            dataIndex: 'expiredTime',
            key: 'expiredTime',
          },
        // {
        //     title: '支付状态',
        //     key: 'status',
        //     render: (record) => (
        //         record.status == '未支付'?
        //             <Popover
        //                 destroyTooltipOnHide
        //                 trigger={'click'}
        //                 content={<PayContent tradeId={record.tradeId} refresh={refreshState}/>}
        //             >
        //                 <span style={{color: colorPrimary, cursor: 'pointer'}}>{record.status}</span>
        //             </Popover> :
        //             record.status
        //     )
        // },
        // {
        //   title: '操作',
        //   key: 'action',
        //   width: 150,
        //   render: (_, record) => (
        //     <Space size="middle">
        //         {/* <span>申请发票</span> */}
        //       <Popconfirm
        //         title="删除确认"
        //         description="确认要删除该订单吗？"
        //         onConfirm={() => deleteClick(record.tradeId)}
        //     >
        //         <span>删除</span>
        //     </Popconfirm>
        //     </Space>
        //   ),
        // },
      ];

    useEffect(() => {
        updateConfig({sidebarKey: -1})
    }, [])

    const refreshState = () => {
        eventBus.emit('updateUserInfo')
        eventBus.emit('updateActivityInfo')
        powerPackages()
    }

    useEffect(() => {
        if (!isRecord) return
        const updateUserInfo = () => {
            getMembershipList()
            getOrderList()
            getUsageBillList()
        }
        eventBus.on('updateUserInfo', updateUserInfo) // 更新用户的消费记录和虹豆消费记录
        return () => {
            if (!isRecord) return
            eventBus.off('updateUserInfo', updateUserInfo)
        }
    }, [])

    useEffect(() => {
        refreshState()
    }, [])

    const onTabsChange = (key) => {
        if (key == '0') {
            getMembershipList()
        } else if (key == '1') {
            getOrderList()
        } else {
            getUsageBillList()
        }
    }

    function powerPackages()  {
        API.powerPackages({}).then((res) => {
            if(res.code == 0) {
                setPowerList(res.data)
            } else {
                message.error(res.message)
            }
        })
    }

    function getUsageBillList (id) {
        setLogLoading(true)
        API.getUsageBillList({
            // userId: id
        }).then((res) => {
            if(res.code == 0) {
                setLogList(res.data.map((item, key) => ({...item, key})))
            } else {
                message.error(res.message)
            }
        }).catch(err => {
            console.error(err)
        }).finally(() => {
            setLogLoading(false)
        })
    }

    function getMembershipList () {
        setOrderLoading(true)
        API.getMembershipList({}).then(res => {
            if(res.code == 0) {
                setMemberList(res.data)
            } else {
                message.error(res.message)
            }
        }).finally(() => {
            setOrderLoading(false)
        })
    }

    function getOrderList(id) {
        setOrderLoading(true)
        API.getOrderList({}).then((res) => {
            if(res.code == 0) {
                setOrderList(res.data)
            } else {
                message.error(res.message)
            }
        }).catch(err => {
            console.error(err)
        }).finally(() => {
            setOrderLoading(false)
        })
    }

    const openModalToPay = (item) => {
        eventBus.emit('pc_prepay', item, false, 0, () => refreshState())
    }
    
    const openModalToPayPowerPackage = () => {
        const items = {
            name: '加油包',
            list: powerList.map(item => ({
                ...item,
                numberText: item.powerAmount + '虹豆',
                tips: '30天内有效',
            }))
        }
        eventBus.emit('pc_prepay', items, true, 0, () => refreshState())
    }

    const items = [{
        key: '1',
        label: '订单记录',
        children: <Table loading={orderLoading} rowKey="tradeId" pagination={{hideOnSinglePage: true}} columns={orderColumns} dataSource={orderList} />,
    }, {
        key: '0',
        label: '消费记录',
        children: <Table loading={orderLoading} rowKey="tradeId" pagination={{hideOnSinglePage: true}} columns={columns} dataSource={memberList} />,
    }, {
        key: '2',
        label: '虹豆使用记录',
        children: <Table loading={logLoading} rowKey="key" pagination={{hideOnSinglePage: true}} columns={logColumns} dataSource={logList} />,
    }]

    const copyFun = (text) => {
        if(!text) return
        const textarea = document.createElement('textarea');
        textarea.value = text

        document.body.appendChild(textarea);

        textarea.select();
        document.execCommand('copy');

        document.body.removeChild(textarea);

        message.success('已复制邀请码！')
    }

    const openPayClick = () => {
        if(localStorage.getItem('channel') == 'taobao') return
        payChildRef.current.openrechargeClick()
    }

    const unregisterClick = () => {
        PSAIModal.confirm({
            content: <div>
                <p>请确认是否注销账号？确认后不可找回！</p>
                <p style={{float: 'left', height: '32px', display: 'flex',marginTop: '10px', alignItems: 'flex-end'}}>
                    <span style={{fontSize: 12, color: '#979797', }}>确定即表示您已阅读并同意本产品的</span>
                    <a style={{color: 'var(--color-primary)', cursor: 'pointer', fontWeight: 'bold', fontSize: 14, transform: 'translate(2px, 1px)'}} target='_blank' href='/注销须知.htm'>注销须知</a>    
                </p>
            </div>,
            okText: '注销',
            cancelText: '取消',
            onOk: unregisterUser,
            onCancel() {},
        })
    }

    const unregisterUser = () => {
        setGlobalLoading('注销中')
        API.unregisterUser({
            // userId: id
        }).then((res) => {
            if(res.code == 0) {
                navigate('/')
                localStorage.removeItem('token')
                sessionStorage.removeItem('taskCache_model')
                sessionStorage.removeItem('taskCache_dressform')
                sessionStorage.removeItem('taskCache_clothes')
                sessionStorage.removeItem('taskCache_commodity')
                sessionStorage.removeItem('taskCache_tryonVideo')
                sessionStorage.removeItem('taskCache_commodityVideo')
                localStorage.removeItem('channel')
                message.success('账号已注销')
            } else {
                message.error(res.message)
            }
        }).catch(err => {
            console.error(err)
        }).finally(() => {
            setGlobalLoading(false)
        })
    }

    // 活动状态 0-未开始 1-进行中 2-已结束
    const ACTIVITY_240711_STATUS = useMemo(() => {
        if (!globalActivity) return 0
        const activity = globalActivity.ACTIVITY_240711
        // const now = dayjs('2024-07-14')
        const now = dayjs()
        const start = dayjs(activity.beginAt)
        const end = dayjs(activity.endAt)
        return now.isBefore(start) ? 0 : now.isAfter(end) ? 2 : 1 
    })

    return (
        <>
            <div className={styles.PersonalCenter}>
                <div className={styles.PersonalCenter_}>
                    {/* <GoBack style={{position: 'absolute',left: 20,top: 10,}} /> */}
                    <div className={styles.pe_left}>
                        <img style={{marginBottom: '20px'}}  src={require('@assets/images/icon_Avatar.png')} alt="" />
                        {/* <p style={{marginBottom: '20px'}} className={styles.plan_p}>{globalUser?.membership?.plan}</p> */}

                        <div className={styles.messag_box}>
                            <p className={styles.messag_box_p1}>生效套餐信息</p>
                            <p className={styles.messag_box_p2}><span style={{maxWidth: 'none', width: 'auto'}}>{globalUser?.membership?.plan}</span></p>
                        </div>
                        <div className={styles.messag_box}>
                            <p className={styles.messag_box_p1}>可用虹豆</p>
                            <p className={styles.messag_box_p2}><span>{globalUser?.membership?.balance} <img src={require('@assets/images/arcbean2.png')}/></span><span onClick={() => openModalToPayPowerPackage()}>加油包</span></p>
                        </div>
                        {
                            ACTIVITY_240711_STATUS != 1 &&
                            <div className={styles.messag_box}>
                                <p className={styles.messag_box_p1}>邀请码</p>
                                <p className={styles.messag_box_p2}><span>{globalUser?.invitationCode}</span><span onClick={() => copyFun(globalUser?.invitationCode)}>复制</span></p>
                            </div>
                        }
                        <Divider />
                        <div className={styles.messag_box}>
                            <p className={styles.messag_box_p1}>{globalUser?.mobile?'更换': '绑定'}手机号</p>
                            <p className={styles.messag_box_p2}><span>{globalUser?.mobile?.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')}</span><span onClick={() => eventBus.emit('bindOrChangeMobile')}>{globalUser?.mobile ? '修改' : '绑定'}</span></p>
                        </div>
                        {
                            Utils.getCurrentDomainAndCheckTiktok() &&
                            <div className={styles.messag_box}>
                                <p className={styles.messag_box_p1}>{globalUser?.email?'更换': '绑定'}邮箱</p>
                                <p className={styles.messag_box_p2}><span className={styles.ellipsis}>{globalUser?.email}</span><span onClick={() => eventBus.emit('bindOrChangeEmail')}>{globalUser?.email ? '修改' : '绑定'}</span></p>
                            </div>
                        }
                        <div className={styles.messag_box}>
                            <p className={styles.messag_box_p1}>账号密码</p>
                            <p className={styles.messag_box_p2}><span>******</span><span onClick={() => setPwdOpen(true)}>{localStorage.getItem('noPassword') == 'true' ? '设置' : '修改 '}</span></p>
                        </div>
                        <div className={styles.messag_box}>
                            <p className={styles.messag_box_p1}>第三方登录</p>
                            <p className={styles.messag_box_p2}><span><img style={{width: '20px', height: '20px',cursor: 'pointer'}} src={require(`@assets/images/${globalUser?.thirdPartyLoginChannel.length > 0?'wechat1.png': 'wechat2.png'}`)} alt="" /></span><span onClick={() => setWechatModal(true)}>绑定</span></p>
                        </div>

                        <p className={styles.change_record}  >
                            <span onClick={() => navigate("/home/homemain/personalCenter/record")}>{`${'购买及使用记录 >'}`}</span>
                            <Button size='small' onClick={unregisterClick}>注销账号</Button>
                        </p>
                    </div>
                    <div className={styles.pe_right}>
                        <div className={styles.pe_right_}>
                            {
                                isRecord?<div>
                                    <span onClick={() => navigate("/home/homemain/personalCenter")} className={styles.back_record}>{`${'返回'}`}</span>
                                    <div className={styles.order_top}>
                                        <span>可用虹豆</span>
                                        <img src={require('@assets/images/energy point (2).png')} alt="" />
                                        <span>{globalUser?.membership?.balance}</span>
                                        <span>冻结中虹豆</span>
                                        <img  src={require('@assets/images/energy point (2).png')} alt="" />
                                        <span>{globalUser?.membership?.freeze}</span>
                                        <span onClick={openPayClick}>充值</span>
                                    </div>
                                    <Tabs defaultActiveKey="1" items={items} onChange={onTabsChange} />
                                </div>:<div className={styles.MemberPackageWrap}>
                                    <MemberPackage payFunc={openModalToPay} />
                                    <Customized payFunc={openModalToPay}/>
                                    <OneTimePay  />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <PayModal ref={payChildRef}></PayModal>
            <UpdatePasswordModal open={pwdOpen} closeFunc={() => setPwdOpen(false)}/>
            <BindWechatModal 
                open={wechatModal}
                closeFunc={() => {refreshState();setWechatModal(false)}}
                thirdPartyLoginChannel={globalUser?.thirdPartyLoginChannel?.length > 0?true: false}
            />
        </>
    )
}
export default PersonalCenter