import { useContext, useEffect, useRef, useState } from 'react'
import { Button, Modal, Input, message } from 'antd';
import { useNavigate }from "react-router-dom"; 
import Utils from '@utils/utils'
import API from '@api/api.js';
import { ExclamationCircleOutlined } from '@ant-design/icons';
// import './bindMobileModal.scss'
import styles from './bindMobileModal.module.scss'
import PSAIModal from '@PSAIComponents/PSAIModal';
import { AppContext } from '@utils/AppContext';

const BindMobileModal = (props) => {
    const { globalUser } = useContext(AppContext);
    const [open, setOpen] = useState(false)
    const [mobile, setMobile] = useState()
    const [intervalId, setIntervalId] = useState()
    const [waitCode, setWaitCode] = useState(false)
    const [countdown, setCountdown] = useState(60)
    const countdownRef = useRef(60)
    const [verifyCode, setVerifyCode] = useState() // 验证码
    const [step, setStep] = useState(1) // 步骤。1-验证身份 2-更换手机号
    const [okDisabled, setOkDisabled] = useState(true)
    const [okLoading, setOkLoading] = useState(false)
    const [isMobileError, setIsMobileError] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        return () => {
            intervalId && clearInterval(intervalId)
        }
    }, [])

    useEffect(() => {
        if (step == 1) {
            setOkDisabled(!verifyCode)
        } else {
            setOkDisabled(!verifyCode || !mobile || isMobileError)
        }
    }, [step, verifyCode])

    useEffect(() => {
        reset()
        setMobile(globalUser?.mobile || '')
        if(globalUser?.mobile || '') {
            setStep(1)
        } else {
            setStep(2)
        }
        setOpen(props.open)
    }, [props.open, globalUser])

    const reset = () => {
        setWaitCode(false)
        setCountdown(60)
        countdownRef.current = 60
        setVerifyCode('')
        setOkLoading(false)
        setIsMobileError(false)
        intervalId && clearInterval(intervalId)
    }

    const mobileBlur = () => {
        setIsMobileError(!Utils.validateContact(mobile))
    }

    const codeClick = () => {
        if (!waitCode) {
            setWaitCode(true)
            API.userSendCode({
                mobile: mobile
            }).then((res) => {
                if(res.code == 0) {
                    message.success('发送成功！')
                    let id = setInterval(() => {
                        countdownRef.current -= 1
                        setCountdown(countdownRef.current)
                        if (countdownRef.current === 0) {
                            clearInterval(id)
                            setWaitCode(false)
                            countdownRef.current = 60
                        }
                    }, 1000)
                    setIntervalId(id)
                } else {
                    setWaitCode(false)
                    message.error(res.message)
                }
            })
        }               
    }

    const onOk = () => {
        if (step == 1) {
            setOkLoading(true)
            API.checkVerifyCode({
                mobile: mobile,
                verifyCode: verifyCode
            }).then(res => {
                if(res.code == 0) {
                    message.success('已确认身份，请更换手机号')
                    reset()
                    setStep(2)
                    setMobile('')
                } else {
                    setVerifyCode('')
                    message.error(res.message)
                }                
            }).finally(() => {
                setOkLoading(false)
            })
        } else {
            setOkLoading(true)
            API.changeMobile({
                mobile: mobile,
                verifyCode: verifyCode
            }).then(res => {
                if(res.code == 0) {
                    message.success('手机号已更新，请重新登录')
                    props.closeFunc()
                    API.logout({}).finally(() => {
                        navigate('/')
                        localStorage.removeItem('token')
                        sessionStorage.removeItem('taskCache_model')
                        sessionStorage.removeItem('taskCache_dressform')
                        sessionStorage.removeItem('taskCache_clothes')
                        sessionStorage.removeItem('taskCache_commodity')
                        sessionStorage.removeItem('taskCache_tryonVideo')
                        sessionStorage.removeItem('taskCache_commodityVideo')
                    })
                } else {
                    setVerifyCode('')
                    message.error(res.message)
                }                
            }).finally(() => {
                setOkLoading(false)
            })
        }
    }

    return (
        <PSAIModal
            title={globalUser?.mobile?'更换手机号': '绑定手机号'}
            maxWidth={520}
            okText={ step == 1 ? '下一步' : '确认' }
            open={open}
            okButtonProps={{ disabled: okDisabled, loading: okLoading }}
            onCancel={() => props.closeFunc()}
            onOk={onOk}
        >
            <div className={styles.mobileModal_body}>
                {globalUser?.mobile && <p className={styles.mobileModal_subtitle}>更换手机号后将解除当前手机号与本账号的关联</p>}
                <div className={styles.mobileModal_form}>
                    
                    {
                        step == 1 ? 
                            <p>当前手机号为{mobile?.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')}，请先输入验证码以确认身份</p> :
                            <div className={styles.mobileModal_mobile}>
                                <label htmlFor="mobile-new" className={styles.mobileModal_new_label}>绑定手机号</label>
                                <Input
                                    id='mobile-new'
                                    className={styles.mobileModal_new_input}
                                    size='large'
                                    value={mobile}
                                    placeholder="请输入需要绑定的手机号"
                                    onBlur={mobileBlur}
                                    onChange={(e) => setMobile(e.target.value)}
                                    prefix={<img style={{width: 16}} src={require('@assets/images/icon_phone.png')} alt="" />}
                                />
                            </div>
                    }
                    
                    <div className={styles.mobileModal_code}>
                        <label htmlFor="mobile-code" className={styles.mobileModal_code_label}>手机验证码</label>
                        <Input
                            id='mobile-code'
                            size='large'
                            maxLength={6}
                            value={verifyCode}
                            placeholder="请输入验证码"
                            onChange={(e) => setVerifyCode(e.target.value)}
                            prefix={<img style={{width: 16}} src={require('@assets/images/icon_Validation.png')} alt="" />}
                        />
                        <Button size='large' onClick={codeClick}>{waitCode ? `${countdown}秒` : '验证码'}</Button>
                    </div>
                    <p className={styles.err_text} style={{opacity: isMobileError? 1: 0}}><ExclamationCircleOutlined /> 您输入的手机号错误</p>
                </div>
            </div>
        </PSAIModal>
    )
}

export default BindMobileModal