import React, {useState, useEffect, useRef, useContext, useMemo} from 'react';
import { Radio, Upload, Checkbox, Popover, Empty, Modal, message, Image, Spin } from 'antd';
import Utils from '@utils/utils'
import API from '@api/api'
import { AppContext } from '@utils/AppContext';
import styles from './index.module.scss'
import { GenerateContext } from "@utils/GenerateContext"
import SelectionItem from '../SelectionItem';
import { IMAGE_TYPE, PRIVATE_ABILITIES } from '@utils/CONST';
import PSAIModal from '@PSAIComponents/PSAIModal';
import CustomModel from '../CustomModel';

// 模特
export default function ModelSelection (props) {
    const {
        isProEnv,
        filteredModelListRef,
        hasPrivateAbility,
    } = useContext(AppContext);
    const {
        imageType,
        modelFilter,
        keepPose, setKeepPose,
        modelDate, // 全局模特列表
        setModelList, // 修改全局模特列表
        setHairTagText, // 特征属性：发型
    } = useContext(GenerateContext);
    const [loading, setLoading] = useState(true)
    const [update, forceUpdate] = useState()

    // 筛选条件 皮肤/性别/年龄
    const [sex, setSex] = useState([...modelFilter?.sex || []]);
    const [skin, setSkin] = useState([...modelFilter?.skin || []]);
    const [age, setAge] = useState([...modelFilter?.age || []]);
    const [type, setType] = useState([]);
    useEffect(() => {
        setSex([...modelFilter?.sex || []])
        setSkin([...modelFilter?.skin || []])
        setAge([...modelFilter?.age || []])
    }, [modelFilter])

    const [keepPoseItem] = useState({
        id: 'keep_pose',
        name: '__keep_pose',
        imageUrl: 'https://photostudio-ai-oss.arcsoft.com.cn/photostudio-web/admin/icon_keep_bkg.png',
        attr: {title: imageType == IMAGE_TYPE.CLOTHES ? '不更换容貌' : '不更换模特'},
        isKeepPose: true
    })

    const fetchData = () => {
        setLoading(true)
        return API.modelPersonList({
            age: age.join(),
            sex: sex.join(),
            skin: skin.join(),
            type: type.join(),
            imageType: imageType
        }).then(res => {
            if (res.code != 0) throw new Error(res.message)
            // 模特随机的key是00_default_style，需要过滤掉
            const list = res.data
                .filter(item => item.name != '00_default_style')
                .filter(item => { // 非真人图需要排除constant_id资源
                    if (imageType != IMAGE_TYPE.MODEL) {
                        return item.attr.algoModelType != "constant_id"
                    } else {
                        return true
                    }
                })
            setModelList([...list])
            return list
        }).catch(err => {
            console.error(err)
            message.warning(err.message)
        }).finally(() => {
            setLoading(false)
        })
    }

    const noticeOnceRef = useRef(true) // 未找到素材的提示仅出现一次
    const checkIsExist = (list, modelName) => {
        let found = modelName == '00_default_style'
        if (!found) {
            // 检查是否包含 props.modelName 对应的项，找不到的话需要弹窗提示
            for (let i = 0; i < list.length; i++) {
                if (list[i].name === modelName) {
                    found = true;
                    break;
                }
                let subList = list[i].subList
                if (subList) {
                    for (let j = 0; j < subList.length; j++) {
                        if (subList[j].name === modelName) {
                            found = true;
                            break
                        }
                    }
                }
            }
        }
        if (noticeOnceRef.current) {
            noticeOnceRef.current = false
            if (found) props.foundModelFunc()
            else props.notFoundModelFunc()
        }
        return found
    }
    // 将sublist内部的选择项移到外面
    const switchSubToMain = (list, modelName) => {
        if (modelName == '00_default_style') return false
        let found = false
        for (let i = 0; i < list.length; i++) {
            if (list[i].name === modelName) break
            let subList = list[i].subList
            if (subList) {
                for (let j = 0; j < subList.length; j++) {
                    if (subList[j].name === modelName) {
                        list[i].name = modelName
                        list[i].imageUrl = subList[j].imageUrl
                        found = true
                        break
                    }
                }
            }
            if (found) break
        }
        if (found) return list
        else return false
    }
    // 初始化场景
    const initedRef = useRef(false)
    useEffect(() => {
        fetchData().then(list => {
            if (!list) return
            if (initedRef.current) return
            initedRef.current = true
            if (!keepPose && !props.modelName) {
                let selectedName = list[0]?.name
                props.onModelChange(selectedName)
            }
        })
    }, [skin, sex, age, type, update, props.refreshRecent])

    useEffect(() => {
        if (!modelDate) return
        if (props.modelName) {
            const isExist = checkIsExist(modelDate, props.modelName)
            if (!isExist) {
                let selectedName = modelDate[0]?.name
                props.onModelChange(selectedName)
            }
        }
        if (props.modelName) {
            const list = switchSubToMain(modelDate, props.modelName)
            if (list) setModelList([...list])
        }
    }, [modelDate, props.modelName])

    useEffect(() => {
        // 为rongrong特殊定制需求，选择rongrong的模特素材，自动改为短发
        if (/_rongrong(id)?/.test(props.modelName)) {
            const text = '短发'
            setHairTagText(text)
        }
    }, [props.modelName])

    useEffect(() => {
        if (!modelDate) return
        if (!age || age.length == 0) { // 不特意删选时，随机模特过滤掉儿童
            filteredModelListRef.current = modelDate
                .filter(item => item?.attr?.age != 'children')
                .map(item => [item.name, ...item.subList.map(i => i.name)])
                .flat()
        } else {
            filteredModelListRef.current = modelDate
                .map(item => [item.name, ...item.subList.map(i => i.name)])
                .flat()
        }
    }, [modelDate, age])

    // subList选中时替换主体的name和url
    const onMoreSceneChange = (item) => {
        setKeepPose(false)
        props.onModelChange(item.name)
    }

    // 选择某个主体
    const selectImgClick = (item) => {
        if (item.isKeepPose) {
            setKeepPose(true)
            props.onModelChange()
        } else {
            setKeepPose(false)
            props.onModelChange(item.name)
        }        
    }

    // 显示自定义姿势
    const showCustomModel = useMemo(() => {
        // return !isProEnv
        // return hasPrivateAbility(PRIVATE_ABILITIES.TRYON_DIY_POSE)
        // return imageType == IMAGE_TYPE.MODEL
        return false
    }, [imageType])

    const showKeepPose = useMemo(() => {
        if (imageType == IMAGE_TYPE.CLOTHES) return !isProEnv || hasPrivateAbility(PRIVATE_ABILITIES.TRYON_KEEP_POSE)
        else if (imageType == IMAGE_TYPE.MODEL) return true
        else return false
    }, [imageType, isProEnv, hasPrivateAbility])

    const showList = useMemo(() => {
        if (!modelDate) return []
        const showCount = showCustomModel ? props.visibleCount - 1 : props.visibleCount // 显示的item数量
        const showCountWithoutResident = showKeepPose ? showCount - 1 : showCount // 显示的非常驻的资源数量
        const findIndex = modelDate.findIndex(item => {
            if (item.name == props.modelName) return true
            if (item.subList?.find(subitem => subitem.name == props.modelName)) return true
        })
        if (findIndex >= 0) {
            if (findIndex < showCountWithoutResident) {
                if (showKeepPose) {
                    return [keepPoseItem, ...modelDate.slice(0, showCountWithoutResident)]
                } else {
                    return [...modelDate.slice(0, showCountWithoutResident)]
                }
            } else {
                const findItem = modelDate[findIndex]
                if (showKeepPose) {
                    return [keepPoseItem, findItem, ...modelDate.slice(1, showCountWithoutResident)]
                } else {
                    return [findItem, ...modelDate.slice(1, showCountWithoutResident)]
                }
            }
        } else {
            if (showKeepPose) {
                return [keepPoseItem, ...modelDate.slice(0, showCountWithoutResident)]
            } else {
                return [...modelDate.slice(0, showCountWithoutResident)]
            }
        }
    }, [modelDate, keepPoseItem, props.modelName, props.visibleCount, showCustomModel, showKeepPose])

    const onRemove = (item) => {
        PSAIModal.confirm({
            title: '确定删除这个定制模特吗？',
            onOk() {
                API.removeCustomMaterial({
                    id: item.id
                }).then(res => {
                    if (res?.code != 0) return message.warning(res?.message)
                    message.success('删除成功')
                    forceUpdate({})
                })
            },
        })
    }

    return (
        <div className={styles.Container}>
            <div className={styles.Content}>
            {
                loading ? <div className={styles.empty_wrap}><Spin /></div> :
                showList?.length == 0 ? <div className={styles.empty_wrap}><Empty /></div> : 
                <div className={styles.SelectionItemBox}>
                {
                    showCustomModel &&
                    <CustomModel onChange={() => forceUpdate({})} />
                }
                {
                    showList.map(item => (
                        <SelectionItem
                            key={item.id}
                            showTitle
                            selected={props.modelName}
                            onClick={selectImgClick}
                            onMoreClick={onMoreSceneChange}
                            onRemove={onRemove}
                            record={item}
                        />
                    ))
                }
                </div>
            }
            </div>
        </div>
    )
}