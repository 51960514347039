import { Slider, Tooltip } from "antd"
import { useEffect, useState } from "react"
import styles from './StrengthStepSlider.module.scss'

// props.reverse: 
//   true-与相似度负相关，value=0在最右边；
//   false-与相似度正相关，value=1在最右边；
const StrengthSlider = (props) => {
    const [value, setValue] = useState(Number((1-props.value).toFixed(1)))
    useEffect(() => {
        if (!props.reverse) {
            setValue(props.value)
        } else {
            const reverseValue = Number((1-props.value).toFixed(1))
            setValue(reverseValue)
        }
    }, [props.value, props.reverse])
    const onChange = (value) => {
        if (!props.reverse) {
            props.onChange(value)
        } else {
            const reverseValue = Number((1-value).toFixed(1))
            props.onChange(reverseValue)
        }
    }
    const formatter = (value) => {
        if (props.reverse) {
            value = Number((1-value).toFixed(1))
        }
        if (props.formatter) return props.formatter(value)
        else return value
    }
    return (
        <div className={styles.StrengthSlider}>
            {
                props.title &&
                <p>
                    <span>{props.title}</span>
                </p>
            }
            <Slider
                marks={props.marks}
                disabled={props.disabled}
                defaultValue={value}
                value={value}
                min={props.min || 0}
                max={props.max || 1}
                step={props.step}
                tooltip={{formatter: props.showTooltip ? formatter : null}}
                onChange={onChange}
            />
            <p style={{ justifyContent: 'space-between', marginTop: 10 }}>
                <span>{props.leftTips || '低'}</span>
                <span>{props.rightTips || '高'}</span>
            </p>
        </div>
    )
}

export default StrengthSlider
