import { useNavigate } from 'react-router'
import { Dropdown, Modal, Popover, Spin, Tooltip, message } from 'antd'
import utils from '@utils/utils'
import API from '@api/api'
import { useRef, useState, useEffect, useContext, useMemo } from 'react'
import axios from 'axios'
import { AppContext } from '@utils/AppContext';
import styles from './taskTools.module.scss'
import ShareModal from '../shareModal/shareModal'
import { IMAGE_TYPE } from "@utils/CONST"
import useResizeObserver from '@utils/useResizeObserver'
import PSAIModal from '@PSAIComponents/PSAIModal'
import { PlayCircleFilled, PauseCircleFilled } from '@ant-design/icons'
import PSAISpinDot from '@PSAIComponents/PSAISpinDot';

const TaskTools = (props) => {
    const {
        forceUpdate,
        setGlobalLoading,
        hdDownloadWithLoadingFunc
    } = useContext(AppContext) // 触发更新flag
    const {mainImage, taskId, imageId, bookmarked} = props
    const navigate = useNavigate()
    const isMounted = useRef(true) // 判断组件是否已销毁
    const [downloadOpen, setDownloadOpen] = useState()
    const [downloadToolTipOpen, setDownloadToolTipOpen] = useState()

    useEffect(() => {
        return () => {
            isMounted.current = false
        }
    }, [])
    
    // 去AI编辑
    const toAiEditor = async () => {
        try {
            let res = await API.commonDownload({taskId: props.taskId})
            if (res?.code != 0) throw new Error(res?.message)
            const imgPath = res.data
            // 埋点，记录AI 编辑的是什么类型的图
            sessionStorage.setItem('oplog_imageType', props.imageType)
            navigate(
                '/home/homemain/aiEdit',
                {
                    state: {
                        picUrl: imgPath,
                    }
                },
            )
        } catch (err) {
            message.warning(err.message)
        }
    }

    // 普通下载
    const commonDownloadFunc = async () => {
        try {
            setDownloadOpen(false)
            let res = await API.commonDownload({taskId})
            if (res?.code != 0) throw new Error(res?.message)
            // console.log('commonDownload', res)
            const image = res.data
            utils.downUrl(image, props.imageType)
            message.success('下载成功');
        } catch (err) {
            message.error(err.message)
        }
    }

    // 高清下载
    const hdDownloadFunc = async () => {
        try {
            setDownloadOpen(false)
            const needPay = true
            const hdAvailable = !props.showHDPreview
            if (!props.hdPayed) {
                PSAIModal.confirm({
                    title: `下载${props.imageType == IMAGE_TYPE.MODEL_VIDEO ? "高清视频" : "高清图"}将扣除您${hdCost}个虹豆`,
                    content: `该${props.imageType == IMAGE_TYPE.MODEL_VIDEO ? "视频" : "图片"}生成后进行下载操作无需再次扣点，您确认要下载吗？`,
                    onOk: async () => {
                        if (props.imageType == IMAGE_TYPE.MODEL_VIDEO) {
                            hdDownloadWithLoadingFunc(taskId, imageId, props.imageType).then(() => forceUpdate({}))
                        } else {
                            if (hdAvailable) {
                                const res = await API.hdDownload({taskId})
                                if (res?.code != 0) throw new Error(res?.message)
                                const image = res.data
                                utils.downUrl(image, props.imageType)
                                message.success('下载成功')
                            } else {
                                hdDownloadWithLoadingFunc(taskId, imageId, props.imageType).then(() => forceUpdate({}))
                            }
                        }
                    }
                })
            } else {
                const res = await API.hdDownload({taskId})
                if (res?.code != 0) throw new Error(res?.message)
                const image = res.data
                utils.downUrl(image, props.imageType)
                message.success('下载成功')
            }
        } catch (err) {
            message.error(err.message)
        }
    }

    // 高清预览
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        if (props.showHDPreview) setLoading(false)
    }, [props.showHDPreview])
    const toHDPreview = async (e) => {
        if (loading) return
        e.stopPropagation()
        setLoading(true)
        // setGlobalLoading('请稍后')
        // await utils.delayFunc(500)
        const res = await utils.toHDPreview(taskId, imageId, props.imageType)
        if (res) forceUpdate({})
        else setLoading(false)
        // setGlobalLoading()
    }

    const isLoading = useRef()
    const toBookmark = () => {
        if (isLoading.current) return
        isLoading.current = true
        API.bookmarkTask({
            taskId
        }).then(() => {
            if (bookmarked) message.success('已取消收藏')
            else message.success('已收藏')
            forceUpdate({})
        }).finally(() => {
            isLoading.current = false
        })
    }

    const hdCost = useMemo(() => {
        if (props.imageType == IMAGE_TYPE.COMMODITY_VIDEO ||  props.imageType == IMAGE_TYPE.MODEL_VIDEO) return 40
        return 5
    }, [props.imageType])

    const downloadMenuContent = (
        <div className={styles.DownloadMenu}>
            <div className={styles.MenuItem} onClick={hdDownloadFunc}>
                <span>高清下载</span>
                {
                    !props.hdPayed &&
                    <div style={{display: 'flex',alignItems: 'baseline'}}>
                    <span>（消耗</span>
                    <img style={{width: '10px', height: '10px'}} src={require('@assets/images/arcbean2.png')}/>
                    <span>{hdCost}）</span>
                    </div>
                }
            </div>
            <div className={styles.MenuItem} onClick={commonDownloadFunc}>
                <span>普通下载</span>
            </div>
        </div>
    )

    const onOpenChange = (value) => {
        setDownloadToolTipOpen(false)
        setDownloadOpen(value)
    }

    const onOpenToolTipChange = (value) => {
        if (downloadOpen) return setDownloadToolTipOpen(false)
        setDownloadToolTipOpen(value)
    }

    useEffect(() => {
        props.downloadOpenCb && props.downloadOpenCb(downloadOpen)
    }, [downloadOpen])

    const [shareOpen, setShareOpen] = useState()
    const [shareImg, setShareImg] = useState()
    const toShare = () => {
        setShareImg(mainImage)
        setShareOpen(true)
    }

    const [wrapWidth, setWrapWidth] = useState(200)
    const wrapRef = useResizeObserver(entries => {
        for (let entry of entries) {
            setWrapWidth(entry.contentRect.width)
        }
    })

    const toPlayVideo = () => {
        console.log('toPlayVideo')
        props.videoPlayer.onPlay(props.videoPlayer.isPlaying ? false : true)
    }
    
    return (
        <>
        <div className={`${styles.TaskTools} ${wrapWidth < 200 && styles.small}`} ref={wrapRef}>
            <div className={styles.TaskToolsRoundIcons} onClick={e => e.stopPropagation()}>
            {
                props.showShare &&
                <Tooltip title="分享" arrow={false} mouseEnterDelay={.5} color={ props.withoutMask ? 'rgba(27,27,27,.45)' : 'rgba(255, 255, 255, .2)' }>
                    <div
                        className={`${styles.RoundIcon} ${styles.share} ${props.withoutMask && styles.dark}`}
                        onClick={toShare}
                    ></div>
                </Tooltip>
            }
            { 
                props.showBookmark &&
                <Tooltip title="收藏" arrow={false} mouseEnterDelay={.5} color={ props.withoutMask ? 'rgba(27,27,27,.45)' : 'rgba(255, 255, 255, .2)' }>
                    <div
                        className={`${styles.RoundIcon} ${styles.bookmark} ${bookmarked && styles.checked} ${props.withoutMask && styles.dark}`}
                        onClick={toBookmark}
                    ></div>
                </Tooltip>
            }
            {
                !utils.isMobileDevice() &&
                props.showAIEditor &&
                <Tooltip title="AI编辑" arrow={false} mouseEnterDelay={.5} color={ props.withoutMask ? 'rgba(27,27,27,.45)' : 'rgba(255, 255, 255, .2)' }>
                    <div
                        className={`${styles.RoundIcon} ${styles.aieditor} ${props.withoutMask && styles.dark}`}
                        onClick={toAiEditor}
                    ></div>
                </Tooltip>
            }
            {
                props.showDownload &&
                <Popover
                    placement="bottomLeft"
                    content={downloadMenuContent}
                    arrow={false}
                    open={downloadOpen}
                    overlayInnerStyle={{ borderRadius: '12px', padding: 0, background: 'transparent', boxShadow: 'none' }}
                    trigger={"click"}
                    onOpenChange={onOpenChange}
                >
                    <Tooltip open={downloadToolTipOpen} onOpenChange={onOpenToolTipChange} title="下载至本地" arrow={false} mouseEnterDelay={.5} color={ props.withoutMask ? 'rgba(27,27,27,.45)' : 'rgba(255, 255, 255, .2)' }>
                        <div
                            className={`${styles.RoundIcon} ${styles.download} ${downloadOpen && styles.hover} ${props.withoutMask && styles.dark}`}
                        ></div>
                    </Tooltip>                
                </Popover>
            }
            </div>
            {
                props.showHDPreview &&
                <div
                    className={styles.hdPreview}
                    onClick={toHDPreview}
                >
                    {loading ? <Spin size="small" indicator={<PSAISpinDot color={"white"} />} /> : '高清预览'}
                </div>
            }
            {
                props.videoPlayer &&
                <div className={styles.videoPlayer} onClick={e => e.stopPropagation()}>
                    {
                        !props.videoPlayer.showVideoBtn ? "" :
                        props.videoPlayer.isPlaying ?
                        <PauseCircleFilled className={styles.playBtn} onClick={toPlayVideo}/>:
                        <PlayCircleFilled className={styles.playBtn} onClick={toPlayVideo}/>
                    }
                    {
                        !props.videoPlayer.showProgressBar ? "" :
                        props.videoPlayer.duration == 0 ? "" :
                        <div className={styles.progress}>
                            <div style={{width: `${props.videoPlayer.currentTime / props.videoPlayer.duration * 100}%`}}></div>
                        </div>
                    }
                </div>
            }
        </div>
        <ShareModal open={shareOpen} shareImg={shareImg} closeFunc={() => setShareOpen(false)} />
        </>
    )
}

export default TaskTools