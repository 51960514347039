import { useEffect, useState, useRef, useContext, useMemo } from "react";

import API from '@api/api.js';
import { message } from "antd";
import { HOME_PAGE_TYPE, IMAGE_TYPE } from "@utils/CONST"
import { AppContext } from "@utils/AppContext";

const PAGE_SIZE_NUM = 11;

const DEFAULT_PROPS = {
    NAME: '',
    PAGE_INDEX: 1,
    PAGE_SIZE: PAGE_SIZE_NUM,
    SORT_FIELD: '',
    ORDER: 'desc'
}

function useAllProject(searchDetail, update) {
    const {globalHomePageType} = useContext(AppContext)
    const {
        projectName = DEFAULT_PROPS.NAME,
        pageIndex = DEFAULT_PROPS.PAGE_INDEX,
        pageSize = DEFAULT_PROPS.PAGE_SIZE,
        sortField = DEFAULT_PROPS.SORT_FIELD,
        order = DEFAULT_PROPS.ORDER,
        outputType = ""
    } = searchDetail;

    const [allProject, setAllProject] = useState([]);
    const [favoriteList, setFavoriteList] = useState([])
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(true);
    
    const fetchType = useMemo(() => {
        if (globalHomePageType == HOME_PAGE_TYPE.MERCHANDISE) {
            return IMAGE_TYPE.COMMODITY
        } else if (globalHomePageType == HOME_PAGE_TYPE.CLOTHING) {
            return IMAGE_TYPE.MODEL
        }
    }, [globalHomePageType])

    useEffect(() => {
        Promise.all([
            API.getAllFavoriteList({
                type: fetchType, // model or commodity
                outputType: 'all'
            }),
            API.getAllProjectList({
                type: fetchType, // model or commodity
                projectName: projectName,
                pageIndex: pageIndex,
                pageSize: pageSize,
                sortField: sortField,
                order: order,
                outputType: outputType,
            })
        ]).then(([favorRes, res]) => {
            if (!res || res?.code !== 0 || !res?.data?.items) {
                console.log('get projectList error', res?.code, res?.message);
                if (res?.message) {
                    message.error(res.message)
                }
                setLoading(false);
                return
            }
            setLoading(false);
            setTotal(res?.data?.total)
            setAllProject(res?.data?.items);
            setFavoriteList(favorRes?.data.filter(item => item.results.length > 0))
        }).catch((error) => {
            setLoading(false);
            console.log(error)
        })
    }, [order, pageIndex, pageSize, projectName, sortField, outputType, update])

    return {allProject, total, loading, favoriteList};
}

export default useAllProject;
