import { useEffect, useMemo, useState } from 'react'
import styles from './index.module.scss'

export default function SceneSwitchTab (props) {
    const [activeKey, setActiveKey] = useState(props.activeKey)
    useEffect(() => {
        setActiveKey(props.activeKey)
    }, [props.activeKey])

    const handleClick = (item) => {
        if (item.disabled) return
        props.onChange(item.key)
    }

    const TabRender = useMemo(() => {
        return props.items.find(item => item.key == activeKey)?.children
    }, [activeKey, props.items])

    const activeKeyIndex = useMemo(() => {
        return props.items.findIndex(item => activeKey == item.key)
    }, [activeKey, props.items])

    return (
        <div className={`${styles.SelectScene}`} >
            <div className={`${styles.scene_change_tab}`}>
            {
                props.items.map(item => (
                    <div
                        key={item.key}
                        className={`${styles.scene_change_tab_item} ${activeKey == item.key && styles.actived} ${item.isNew && styles.newIcon} ${item.disabled && styles.disabled}`}
                        onClick={() => handleClick(item)}
                    >
                        <div className={`${item.isNew && styles.newIcon} ${item.disabled && styles.disabledIcon} ${item.limitedFree && styles.limitedFreeIcon}`}>
                        {item.label}
                        </div>
                    </div>
                ))
            }
            </div>
            <div className={`${styles.scene_change_tab_content} ${activeKeyIndex == 0 && styles.active_first} ${activeKeyIndex == props.items.length-1 && styles.active_last}`}>
            {
                TabRender
            }
            </div>
        </div>
        
    )
}