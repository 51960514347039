import { useEffect, useMemo, useRef, useState } from 'react'
import ShapeShiftCtrl from './shapeShiftCtrl'
import ShapeShiftCanvas from './shapeShiftCanvas';
import { Spin, message } from 'antd'
import DragAndScale from '../dragAndScale/dragAndScale'
import styles from './shapeShift.module.scss'
import Utils from '@utils/utils'
import API from '@api/api'
import axios from 'axios';
import ImgComparer from '../imgComparer'
import ToolBox from '../toolBox/toolBox';
import {forwardRef, useImperativeHandle} from 'react'
import { AppContext } from '@utils/AppContext';
import { useContext } from 'react'
import { AIEDIT_SOURCE } from '@utils/CONST';
import { AIEditContext } from '../../AIEditContext';

const ShapeShift = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        onChange: () => {
            return genImgRef.current
        }
    }))

    const {needPay, setNeedPay, getImgWithWatermark} = useContext(AIEditContext)
    const { aiEditDownloadWithLoadingFunc } = useContext(AppContext) // 触发更新flag

    const wrapRef = useRef()

    const [originImg, setOriginImg] = useState()
    // const [originImgBase64, setOriginImgBase64] = useState()
    const genImgRef = useRef()
    const [genImg, setGenImg] = useState()
    const [genImgWithWatermark, setGenImgWithWatermark] = useState()
    const shapeFrameRef = useRef()
    const [shapeFrame, setShapeFrame] = useState()
    const [showCompare, setShowCompare] = useState()
    const [downloading] = useState(false)
    const isMounted = useRef(true) // 判断组件是否已销毁
    const [inited, setInited] = useState(false)

    const imgSizeRef = useRef({width: 0, height: 0})
    const [imgSize, setImgSize] = useState({width: 0, height: 0})
    const [canvasSize, setCanvasSize] = useState({width: 0, height: 0})
    const [displayScale, setDisplayScale] = useState(1)

    const dragAndScaleRef = useRef()
    const shapeCanvasRef = useRef()

    const initImageSize = async () => {
        const {width, height} = await Utils.getImageDimensions(originImg)
        imgSizeRef.current = {width, height}
        setImgSize(imgSizeRef.current)
    }
    
    const calcCanvasSize = () => {
        const containerWidth = wrapRef.current?.clientWidth - 120 - 150
        const containerHeight = wrapRef.current?.clientHeight
        const size = Utils.getImgMaxSizeInContainer(
            imgSizeRef.current.width,
            imgSizeRef.current.height,
            containerWidth,
            containerHeight
        )
        setDisplayScale(size.width / imgSizeRef.current.width)
        setCanvasSize(size)
    }

    useEffect(() => {
        Utils.urlToBase64(props.url).then(base64 => {
            genImgRef.current = props.url
            setGenImg(props.url)
            setOriginImg(props.url)
            downloadUrl.current = null
            // setOriginImgBase64(base64)
        })
    }, [])

    useEffect(() => {
        // 某些浏览器，比如火狐，页面初始化后立刻获得的dom尺寸不准确
        // 所以需要加个settimeout延迟获取
        const onWindowResize = () => {
            calcCanvasSize()
        }
        window.addEventListener('resize', onWindowResize)
        return () => {
            window.removeEventListener('resize', onWindowResize)
        }
    }, [])

    useEffect(() => {
        const handleBeforeUnload = () => {
            if (areaRef.current) releaseAiDragGan(areaRef.current) // 释放dragGAN资源
        }
        // 在页面即将被卸载之前触发
        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            isMounted.current = false
            if (areaRef.current) releaseAiDragGan(areaRef.current) // 释放dragGAN资源
            window.removeEventListener('beforeunload', handleBeforeUnload);
        }
    }, [])

    useEffect(() => {
        if (!originImg) return
        initImageSize().then(res => {
            calcCanvasSize()
            setInited(true)
        })
    }, [originImg])

    // useEffect(() => {        
    //     return () => {
    //         if (isMounted.current) return
    //         props.onChange(genImgRef.current)
    //     }
    // }, [])

    // 重选区域
    const unlockArea = () => {
        shapeCanvasRef.current.clearPoints() 
        setAreaLocked(false)
        if (areaRef.current) releaseAiDragGan(areaRef.current) // 重选区域时释放资源
        areaRef.current = ''
    }

    // 应用变形
    const isLoopRef = useRef(false)
    const [isLoop, setIsLoop] = useState(false)
    const areaRef = useRef('')
    const shapeHandle = () => {
        const data = shapeCanvasRef.current?.getData()
        revokeListRef.current.push({ data, genImg: genImgRef.current, shapeFrame: shapeFrameRef.current })
        setRevokeListLength(revokeListRef.current.length)
        areaRef.current = data.area
        shapeCanvasRef.current.clearPoints() // 清除坐标点
        return aiDragGan(genImgRef.current, data.area, data.coord_s)
    }
    const releaseAiDragGan = (area) => {
        console.log('release', area)
        return API.aiDragGan({
            originalImage: props.url, // 与area生成md5
            image: genImgRef.current,
            flag_patch: 0, // 废弃，默认传0
            // 释放资源是0 点击应用变形是1
            return_crop: 0,
            // 中心点的 x y 以及 rect 的宽度 (第一步用)
            area: area,
            // 选中点的x y 化为整数后拼接的字符串 (第二步用) "178,213,161,182"
            coord_s: '',
            latent_url: ''
        }).catch(err => {
            console.error('releaseAiDragGan fail', err)
        })
    }
    const aiDragGan = (image, area, coord_s, flag=1) => {
        console.log('aiDragGan', area)
        console.time('dragGan time')
        isLoopRef.current = true
        setIsLoop(isLoopRef.current)
        return API.aiDragGan({
            originalImage: props.url, // 与area生成md5
            image: image,
            flag_patch: 0, // 废弃，默认传0
            // 释放资源是0 点击应用变形是1
            return_crop: flag,
            // 中心点的 x y 以及 rect 的宽度 (第一步用)
            area: area,
            // 选中点的x y 化为整数后拼接的字符串 (第二步用) "178,213,161,182"
            coord_s: coord_s,
            latent_url: ''
        }).then(res => {
            if (res.code == 0) {
                const predictionId = res.data
                const reqUrl = `${API.aiDragGanUrl}/${predictionId}`
                console.log('set loop true')
                return loopGetAiDragGanShapeRes(reqUrl).then(res => {
                    if (res.data?.output && res.data?.output.length > 0) {
                        genImgRef.current = res.data?.output[0]
                        setGenImg(res.data?.output[0])
                        downloadUrl.current = null
                        // console.log('loopGetAiDragGanShapeRes', res.data)
                    }
                })
            }
        }).catch(err => {
            message.error(err.message)
        }).finally(() => {
            console.timeEnd('dragGan time')
            isLoopRef.current = false
            setIsLoop(isLoopRef.current)
        })
    }
    const loopGetAiDragGanShapeRes = (url, time=100) => {
        let notice = false
        return new Promise((resolve, reject) => {
            const loopFunc = () => {
                axios.post(url, {}, {
                    headers: {
                        'Content-type' : 'application/json',
                        'Ps-Auth-Token': localStorage.getItem('token')
                    }
                }).then(res => {
                    if((res.data.code === 0 && res.data.data.status === 'succeeded')) {
                        resolve(res.data)
                    } else if(res.data.code === 0 && res.data.data.errcode != 0) {
                        const errCode = res.data.data.errcode
                        if (errCode == 71005) reject(new Error(`没有资源，请重新应用变形[${errCode}]`))
                        else reject(new Error(`变形失败，请稍后再试[${errCode}]`))
                    } else {
                        if (res.data?.data?.tmp_files) {
                            shapeFrameRef.current = res.data?.data?.tmp_files
                            setShapeFrame(res.data?.data?.tmp_files)
                            if (!notice) {
                                message.info('开始变形...')
                                notice = true
                            }                            
                        }
                        if (isMounted.current && isLoopRef.current) {
                            setTimeout(() => {
                                loopFunc()
                            }, time)
                        }
                    }
                })
            }
            loopFunc()
        })
    }

    // 右侧工具栏
    const revokeListRef = useRef([])
    const [revokeListLength, setRevokeListLength] = useState(0)
    const restoreListRef = useRef([])
    const [restoreListLength, setRestoreListLength] = useState(0)
    // 撤销
    const revoke = () => {
        console.log('撤销')
        if(revokeListRef.current.length <= 0) return
        // 保存待恢复的数据
        const data = shapeCanvasRef.current?.getData()
        restoreListRef.current.push({ data, genImg: genImgRef.current, shapeFrame: shapeFrameRef.current })
        setRestoreListLength(restoreListRef.current.length)
        areaRef.current = data.area
        // 清除坐标点
        shapeCanvasRef.current.clearPoints()
        // 取出撤销的数据并恢复
        const revokeData = revokeListRef.current.pop()
        setRevokeListLength(revokeListRef.current.length)

        if (areaRef.current != revokeData.data.area) {
            releaseAiDragGan(areaRef.current) // 撤销时释放资源
        }

        if (revokeData.shapeFrame) {
            genImgRef.current = revokeData.genImg
            setGenImg(revokeData.genImg)
            downloadUrl.current = null
            shapeFrameRef.current = revokeData.shapeFrame
            setShapeFrame(revokeData.shapeFrame)
            setAreaLocked(true)
            shapeCanvasRef.current.loadData(revokeData.data)
            areaRef.current = revokeData.data.area
        } else {
            genImgRef.current = revokeData.genImg
            setGenImg(revokeData.genImg)
            downloadUrl.current = null
            shapeFrameRef.current = undefined
            setShapeFrame()
            setAreaLocked(false)
            shapeCanvasRef.current.loadData(revokeData.data)
            areaRef.current = ''
        }
    }
    const revokeable = useMemo(() => {
        return !showCompare && !isLoop && (revokeListLength > 0)
    }, [isLoop, revokeListLength, showCompare])
    // 恢复
    const restore = () => {
        console.log('恢复')
        if(restoreListRef.current.length <= 0) return
        // 保存待撤销的数据
        const data = shapeCanvasRef.current?.getData()
        revokeListRef.current.push({ data, genImg: genImgRef.current, shapeFrame: shapeFrameRef.current })
        setRevokeListLength(revokeListRef.current.length)
        // 清除坐标点
        shapeCanvasRef.current.clearPoints()
        // 取出恢复的数据并恢复
        const resotreData = restoreListRef.current.pop()
        setRestoreListLength(restoreListRef.current.length)

        if (areaRef.current != resotreData.data.area) {
            releaseAiDragGan(areaRef.current) // 撤销时释放资源
        }

        if (resotreData.shapeFrame) {
            genImgRef.current = resotreData.genImg
            setGenImg(resotreData.genImg)
            downloadUrl.current = null
            shapeFrameRef.current = resotreData.shapeFrame
            setShapeFrame(resotreData.shapeFrame)
            setAreaLocked(true)
            shapeCanvasRef.current.loadData(resotreData.data)
            areaRef.current = resotreData.data.area
        } else {
            genImgRef.current = props.url
            setGenImg(props.url)
            downloadUrl.current = null
            shapeFrameRef.current = undefined
            setShapeFrame()
            setAreaLocked(false)
            shapeCanvasRef.current.loadData(resotreData.data)
            areaRef.current = ''
        }
    }
    const restoreable = useMemo(() => {
        return !showCompare && !isLoop && (restoreListLength > 0)
    }, [isLoop, restoreListLength, showCompare])

    const downloadUrl = useRef()
    const commonDownloadFunc = async () => {
        if (downloadUrl.current) {
            Utils.downUrl(downloadUrl.current, sessionStorage.getItem('oplog_imageType'), false)
            message.success('下载成功')
        } else {
            downloadUrl.current = await aiEditDownloadWithLoadingFunc(
                genImgRef.current,
                AIEDIT_SOURCE.AI_SHAPE_SHIFT,
                sessionStorage.getItem('oplog_imageType'),
                needPay
            )
            if (downloadUrl.current) setNeedPay(false)
        }
    }
    const resetable = useMemo(() => {
        return !showCompare && !isLoop && originImg != genImg
    }, [isLoop, originImg, genImg, showCompare])
    // 重置
    const reset = () => {
        setShowCompare(false)
        if (areaRef.current) releaseAiDragGan(areaRef.current) // 释放资源
        revokeListRef.current = []
        setRevokeListLength(0)
        restoreListRef.current = []
        setRestoreListLength(0)
        areaRef.current = ''
        genImgRef.current = props.url
        setGenImg(props.url)
        downloadUrl.current = null
        shapeFrameRef.current = undefined
        setShapeFrame()
        setOriginImg(props.url)
        setAreaLocked(false)
        shapeCanvasRef.current.reset()
        dragAndScaleRef.current.resetCanvas() // 重置
    }

    // 移动缩放控制
    const [canvasList, setCanvasList] = useState()
    const [moveable, setMoveable] = useState(false)
    const [zoom, setZoom] = useState(1)
    const onDragAndScaleChange = ({zoom, moveable}) => {
        setMoveable(moveable)
        setZoom(zoom)
    }
    const dragAndScale = (
        <div className={styles.DragAndScaleWrap}>
            <DragAndScale
                ref={dragAndScaleRef}
                disabled={showCompare? true: false}
                min={1}
                max={8}
                onChange={onDragAndScaleChange}
                canvasList={canvasList}
                options={[
                    { label: '适应屏幕', value: 1 },
                    { label: '实际大小', value: Number((1 / displayScale).toFixed(2)) },
                    { label: '100%', value: 1 },
                    { label: '200%', value: 2 },
                    { label: '300%', value: 3 },
                    { label: '400%', value: 4 },
                ]}
            />
            <div className={styles.ImgSize}>图像尺寸：{imgSize.width}x{imgSize.height}</div>
            {
                showCompare ?
                <p>对比时可直接使用滚轮缩放、按住鼠标移动</p> :
                <ul className={styles.Shortcut}>
                    <li>快捷键：</li>
                    <li><i className={`${styles.Icon} ${styles.MouseWheel}`}></i> 缩放图片</li>
                    <li><i className={`${styles.Icon} ${styles.MouseRight}`}></i> 重选&变形</li>
                    <li><i className={`${styles.Icon} ${styles.SpaceKeyAndLeft}`}></i> 拖动图片</li>
                    <li><i className={`${styles.Icon} ${styles.CtrlAndZ}`}></i> 撤销操作</li>
                    <li><i className={`${styles.Icon} ${styles.DeleteKey}`}></i> 删除操作</li>
                </ul>
            }
        </div>
    )

    // 前后对比
    const handleCompare = () => {
        if (!showCompare) {
            getImgWithWatermark(genImg).then(base64 => {
                setGenImgWithWatermark(base64)
                setShowCompare(true)
            })
        } else {
            setShowCompare(false)
        }
    }

    // 锁定区域
    const [areaLocked, setAreaLocked] = useState(false)
    const [shapeable, setShapeable] = useState(false)
    const onCanvasChange = ({shapeable}) => {
        setShapeable(shapeable)
    }
    const rightContent = (
        <>
        <div className='edit_right_c' style={{ position: 'relative', flex: 1 }} ref={wrapRef}>
        {
            inited ?
            <div className="wrap_box wrap_box_">
                <div className="dragAndScale_box">
                    {dragAndScale}
                </div>
                {
                    showCompare &&
                    <div style={{width: canvasSize.width, height: canvasSize.height}} >
                        <ImgComparer
                            leftImage={originImg}
                            rightImage={genImgWithWatermark}
                        />
                    </div>
                }
                <div
                    style={{
                        position: showCompare ? 'absolute' : 'relative',
                        top: showCompare ? '-9999px' : '0',
                        left: showCompare ? '-9999px' : '0',
                        width: canvasSize.width,
                        height: canvasSize.height,
                    }}
                >
                    <ShapeShiftCanvas
                        key={originImg}
                        ref={shapeCanvasRef}
                        inputImage={originImg}
                        // inputImageBase64={originImgBase64}
                        shapeFrame={shapeFrame}
                        displayScale={displayScale}
                        displayWidth={canvasSize.width}
                        displayHeight={canvasSize.height}
                        zoom={zoom}
                        isLoading={isLoop}
                        moveable={moveable}
                        areaLocked={areaLocked}
                        lockAreaFunc={() => setAreaLocked(true)}
                        initCanvasFunc={setCanvasList}
                        onChange={onCanvasChange}
                        unlockAreaFunc={unlockArea}
                        shapeFunc={shapeHandle}
                    />
                </div>
                <ToolBox
                    showCompare={showCompare}
                    compare={{disabled: originImg == genImg, trigger: handleCompare}}
                    revoke={{disabled: !revokeable, trigger: revoke}}
                    restore={{disabled: !restoreable, trigger: restore}}
                    reset={{disabled: !resetable, trigger:reset}}
                    download={{disabled: originImg == genImg, trigger: commonDownloadFunc}}
                    uploadCb={props.uploadCb}
                />
            </div> : 
            <div style={{display:"flex", justifyContent:"center", alignItems: "center", height: "100%"}}>
                <Spin size="large" />
            </div>
        }
        </div>
        </>
    )

    // 快捷键
    const altKeyPressedRef = useRef(false)
    const shiftKeyPressedRef = useRef(false)
    const ctrlKeyPressedRef = useRef(false)
    const keyZPressedRef = useRef(false)
    const deletePressedRef = useRef(false)
    useEffect(() => {
        const keydown = ev => {
            if ((ev.key == 'Control' || ev.code == 'ControlLeft' || ev.code == 'ControlRight' || ev.keyCode == 17) && !ctrlKeyPressedRef.current) { // ctrl+Z 可撤销操作
                ctrlKeyPressedRef.current = true
                if (keyZPressedRef.current) {
                    revoke()
                }
            }
            if ((ev.key == 'z' || ev.code == 'KeyZ' || ev.keyCode == 90) && !keyZPressedRef.current) { // ctrl+Z 可撤销操作
                keyZPressedRef.current = true
                if (ctrlKeyPressedRef.current) {
                    revoke()
                }
            }
            if ((ev.key == 'Delete' || ev.code == 'Delete' || ev.keyCode == 46) && !deletePressedRef.current) { // delete 可删除标记点
                deletePressedRef.current = true
                shapeCanvasRef.current?.deletePointGroup()
            }
        }
        const keyup = ev => {
            if (ev.key == 'Control' || ev.code == 'ControlLeft' || ev.code == 'ControlRight' || ev.keyCode == 17) {
                ctrlKeyPressedRef.current = false
            }
            if (ev.key == 'z' || ev.code == 'KeyZ' || ev.keyCode == 90) {
                keyZPressedRef.current = false
            }
            if (ev.key == 'Delete' || ev.code == 'Delete' || ev.keyCode == 46) {
                deletePressedRef.current = false
            }
        }
        // 监听键盘按下
        document.addEventListener('keydown', keydown)
        document.addEventListener('keyup', keyup)
        return () => {
            document.removeEventListener('keydown', keydown)
            document.removeEventListener('keyup', keyup)
        }
    }, [])

    return (
        <div className='edit_cont' style={{flex: '1'}}>
            <div className={`edit_center ${!props.url && 'disabled'}`}>
                <ShapeShiftCtrl
                    disabled={!props.url}
                    areaLocked={areaLocked}
                    shapeable={shapeable}
                    isLoading={isLoop}
                    unlockAreaFunc={unlockArea}
                    shapeFunc={shapeHandle}
                />
            </div>
            <div className='edit_right'>
            {
                !props.url ?
                props.defaultPage:
                rightContent      
            }
            </div>
        </div>
    )
})

export default ShapeShift