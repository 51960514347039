import axios from 'axios'
import eventBus from '@utils/eventBus';
import Utils from '@utils/utils';

const preproductionBaseUrl  = `http://canary.ps.arcsoftbiz.com` // 灰度版本 staging
const productionBaseUrl  = `https://photostudio.arcsoft.com.cn` // 上线版本 production

// const devBaseUrl = `https://applepay.arcsoft.com.cn` // 阿里云测试版 development
const devBaseUrl = `http://172.17.13.140:8083` // 阿里云测试版 development
// const devBaseUrl = `http://172.17.13.187:8084` // Dr.wang 版本 development
// const devBaseUrl = `http://172.17.123.68:8084` // qingyu本地 development

const url  = (process.env.NODE_ENV == 'development' || process.env.NODE_ENV == 'test') ?
                devBaseUrl:
                process.env.NODE_ENV == 'staging' ?
                    preproductionBaseUrl:
                    productionBaseUrl

const upload = url + "/aishops/common/upload"
const uploadImage = url +  "/aishops/common/uploadImage";
const uploadImageWithToken = url + "/aishops/common/uploadImageWithToken"
const maskPredictionAicloth = url +  "/aishops/aicloth/mask/prediction";
const maskPredictionAidressform = url +  "/aishops/aidressform/mask/prediction";
const maskPrediction = url +  "/aishops/aimodel/mask/prediction";
const maskPredictionGoods = url +  "/aishops/aigods/mask/prediction";
const inpaintPrediction = url +  "/aishops/aimodel/inpaint/prediction";
const inpaintPredictionGoods = url +  "/aishops/aigods/inpaint/prediction";
const aidressformPrediction = url +  "/aishops/aidressform/inpaint/prediction";
const virtualtryonPrediction = url + "/aishops/aivirtualtryon/handle/prediction" // virtualtryon功能测试
const handlePrediction = url +  "/aishops/aiautocrop/handle/prediction";
const aiembeddingPrediction = url +  "/aishops/aiembedding/mask/prediction"; //sam
const aiinteractivePrediction = url +  "/aishops/aiinteractive/mask/prediction"; //交互式分割
const airemovePrediction = url +  "/aishops/aidressform/airemove/prediction"; //擦除 修复
const aisuperresolutionPrediction = url +  "/aishops/aisuperresolution/handle/prediction"; // 图片sr
const aisuperresolution4VideoPrediction = url + "/ps/web/algo/videoSR/predict"; // 视频sr
const aibeautyPredictionUrl = url + "/aishops/aibeauty/handle/prediction" // 一键梅花算法
const getTaskListByUserUrl = url + '/ps/web/project/getTaskListByUser'; // 获取用户的任务队列，不需要参数
const getTaskInfoUrl = url + '/ps/web/project/getTaskInfo'; // “快速生成” 或 “重新生成”时，调用此接口获取当前任务相关的所有参数
const deleteTaskImageUrl = url + '/ps/web/project/task/remove'; // 删除单张结果图
const getAllProjectListUrl = url + '/ps/web/project/list';
const getAllFavoriteListUrl = url + '/ps/web/project/listBookmarkTask'
const getProjectDetailUrl = url + '/ps/web/project/details';
const deleteProjectUrl = url + '/ps/web/project/remove';
const renameProjectUrl = url + '/ps/web/project/rename';
const setProjectReadFlagUrl = url + '/ps/web/project/setProjectReadFlag'
const modelTryOnList = url + '/ps/web/material/modelTryOn';
const modelPersonList = url + '/ps/web/material/modelPerson';
const modelBackgroundList = url + '/ps/web/material/modelBackground';
const tryonBackgroundList = url + '/ps/web/material/modelTryOnBackground';
const commodityBackgroundList = url + '/ps/web/material/commodityBackground';
const commodityShowcaseList = url + '/ps/web/material/commodityShowcase';
const projectCreate = url + '/ps/web/project/save';
const aiDragGanUrl = url + '/aishops/aidraggan/handle/prediction';
const setProjectMainPicUrl = url + '/ps/web/project/setMainImage';
const picBatchMoveUrl = url + '/ps/web/project/task/batchRemove';
const projectBatchMoveUrl = url + '/ps/web/project/batchRemove';
const projectDownloadUrl = url + '/ps/web/project/download';
const projectBatchDownloadUrl = url + '/ps/web/project/batchDownload';
const favorBatchDownloadUrl = url + '/ps/web/project/downloadBookmarkImage'
const userSendCode = url + '/ps/web/user/sendCode';
const userLogin = url + '/ps/web/user/login';
const emailRegister = url + '/ps/web/user/emailRegister';
const orderPlans = url + '/ps/web/order/plans';
const orderPlansByType = url + '/ps/web/order/plansByType'
const orderPlanDetails = url + '/ps/web/order/planDetails'
const paymentOrderStatusUrl = url + '/ps/web/order/paymentOrderStatus'
const powerPackages = url + '/ps/web/order/powerPackages';
const subscribePlan = url + '/ps/web/order/subscribePlan';
const subscribeWithoutOrder = url + '/ps/web/order/subscribeWithoutOrder';
const userInfo = url + '/ps/web/user/userInfo';
const getMembershipList = url + '/ps/web/order/getMembershipList'
const getOrderList = url + '/ps/web/order/getOrderList';
const getUsageBillList = url + '/ps/web/order/getUsageBillList'
const subscribePowerPackage = url + '/ps/web/order/subscribePowerPackage';
const subscribePowerPackageWithoutOrder = url + '/ps/web/order/subscribePowerPackageWithoutOrder';
const payForOrder = url + '/ps/web/order/payForOrder';
const removeOrder = url + '/ps/web/order/removeOrder';
const checkVerifyCodeUrl = url + '/ps/web/user/checkVerifyCode'
const changeMobileUrl = url + '/ps/web/user/changeMobile'
const setPasswordUrl = url + '/ps/web/user/setPassword'
const logout = url + '/ps/web/user/logout';
const getTaskImages = url + '/ps/web/project/getTaskImages';
const getHDImage = url + '/ps/web/project/getHDImage';
const setHDImage = url + '/ps/web/project/setHDImage';
const getClothesCategoryListUrl = url + '/ps/web/material/modelClothesCategoryList' // 获取模特服饰分类树
const getVersionUrl = url + '/ps/web/version'
const bookmarkTaskUrl = url + '/ps/web/project/task/bookmark'
const setSaleCodeUrl = url + '/ps/web/user/setSalesCode'
const completeInfoUrl = url + '/ps/web/user/completeInfo'
const getPowerBalance = url + '/ps/web/user/powerBalance'
const uploadResource = url + '/ps/web/user/uploadResource'
const feedback = url + '/ps/web/user/feedback'
const useroplog = url + '/ps/web/user/oplog'
const forgetPassword = url + '/ps/web/user/forgetPassword'
const scoreImage = url + '/ps/web/project/scoreImage'
const saveCustomTryOnModelUrl = url + '/ps/web/material/saveCustomTryOn' // 自定义tryOn添加
const removeCustomTryOnModelUrl = url + '/ps/web/material/removeCustomTryOn' // 自定义tryOn删除
const showcaseList = url + '/ps/web/project/showcaseList'
const saveCustomMaterialUrl = url + '/ps/web/material/saveCustomMaterial'
const removeCustomMaterialUrl = url + '/ps/web/material/removeCustomMaterial'
const taobaoOauth = url + '/ps/web/user/third/taobao/oauth'
const setCmRead = url + '/ps/web/user/setCmRead'
const userAppeal = url + '/ps/web/user/appeal'
const orderCustomPlan = url + '/ps/web/order/customPlan'
const unregisterUser = url + '/ps/mobile/unregisterUser'
const usercaptcha = url + '/ps/web/user/captcha'
const bgCategoryList = url + '/ps/web/material/bgCategoryList' // 商品背景列表
const image2Text = url + '/ps/web/algo/image2Text' // 图生文字
const savePrompt = url + '/ps/web/material/savePrompt' // 保存参考图prompt
const listOrder = url + '/ps/web/merchant/listOrder' //大客户交付
const getOrderDetails = url + '/ps/web/merchant/getOrderDetails' //大客户需求页详细数据
const confirmOrder = url + '/ps/web/merchant/confirmOrder' //大客户-确认拒绝订单
const listWithSku = url + '/ps/web/merchant/listWithSku' //大客户交付页订单列表
const listArtifactsBySku = url + '/ps/web/merchant/listArtifactsBySku' //根据sku获取成片图
const dashboardInfo = url + '/ps/web/merchant/dashboardInfo' //大客户需求页统计数据
const merchantdownload = url + '/ps/web/merchant/download'
const merchantdownloadBatch = url + '/ps/web/merchant/downloadBatch'
const merchantrefine = url + '/ps/web/merchant/refine'

// 模特视频
const modelvideoList = url + '/ps/web/material/videoTryOn'
const videoTryonImageCheck = url + '/ps/web/videoTryonImageCheck'
const doTryonVideo = url + '/ps/web/algo/doTryonVideo'
const saveVideoMaterial = url + '/ps/web/material/saveVideoMaterial'

// 商品视频生成
const commodityVideoPredictUrl = url + '/ps/web/algo/doCommodityVideo' 

// 优惠券模块
const acquireCouponUrl = url + '/ps/web/coupon/acquireCoupon' // 优惠券领取
const changeCouponUrl = url + '/ps/web/coupon/changeCoupon' // 优惠券兑换 
const getOneCouponUrl = url + '/ps/web/coupon/getOne' // 获取单张券码信息
const getCouponListForPayUrl = url + '/ps/web/coupon/listPayCouponSelect' // 支付页优惠下拉列表
const getCouponListUrl = url + '/ps/web/coupon/listCouponPage' // 用户优惠券列表

// 微信登录
const wechatQrcode = url + '/ps/web/user/third/wechat/qrcode'
const loginStatus = url + '/ps/web/user/third/wechat/qr/loginStatus'
const bindStatus = url + '/ps/web/user/third/wechat/qr/bindStatus'
const unbind = url + '/ps/web/user/third/wechat/qr/unbind'

// 下载&高清下载
const commonDownloadUrl = url + '/ps/web/project/commonDownload'
const hdDownloadUrl = url + '/ps/web/project/srDownload'
const aiEditDownloadUrl = url + '/ps/web/project/aiEditDownload'

// 人脸检测
const personCheckUrl = url + '/ps/web/personCheck'
const multiSemanticPredictionUrl = url + '/ps/web/algo/multiSemantic'

// 活动
const activityInviteInfo = url + '/ps/web/activity/activityInviteCashbackInfo' // 活动邀请情况
const inviteRecords = url + '/ps/web/activity/listInviteRecords' // 返现记录
const withdrawalRecords = url + '/ps/web/activity/listWithdrawalRecords' // 提现记录
const handleWithdrawal = url + '/ps/web/activity/withdrawal' // 提现操作

const getCommodityCategoryUrl = url + '/ps/web/algo/getCategory' // 根据mask获取商品分类

const generateConstantIdUrl = url + '/ps/web/algo/generateConstantId' // 创建ConstantId


// 添加响应拦截器
axios.interceptors.response.use(function (response) {
    // console.log('axios.interceptors.response', response)
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    if (response.data?.code == -4001 || response.data?.code == -5023) {
        response.data.message = "" // 将message置空，避免多次弹出未授权消息框
        localStorage.removeItem('token')
        sessionStorage.removeItem('taskCache_model')
        sessionStorage.removeItem('taskCache_dressform')
        sessionStorage.removeItem('taskCache_clothes')
        sessionStorage.removeItem('taskCache_commodity')
        sessionStorage.removeItem('taskCache_tryonVideo')
        sessionStorage.removeItem('taskCache_commodityVideo')
        eventBus.emit('tokenExpired', {pathname: location.pathname})
        // message.error('token过期,请重新登录')
        // throw new Error('登录失效')
    }
    return response
}, function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    console.log('axios.interceptors.response error', error)
    return Promise.reject(error);
})

function getFun(url) {
    return function (params) {
        return new Promise(function (resolve, reject) {
            axios.get(url + '?ts=' + new Date().getTime(),{params, headers: {
                'Ps-Auth-Token': localStorage.getItem('token')
            }}).then(function (res) {
                if (res.status >= 200 && res.status < 300) {
                    resolve(res.data)
                }
            }).catch((error) => {
                    reject(error)
            })
        })
    }
}

function postFun(URL, params) {
    return function (data) {
        return new Promise(function (resolve, reject) {
            axios.post(URL+ '?ts=' + new Date().getTime(),data,{params, headers: {
                'Ps-Auth-Token': localStorage.getItem('token')
            }}).then(function (res) {
                if (res.status >= 200 && res.status < 300) {
                    resolve(res.data)
                }
            }).catch((error) => {
                reject(error)
            })
        })
    }
}

export default  {
    upload: postFun(upload),
    uploadImage: postFun(uploadImage),
    uploadImageWithToken: postFun(uploadImageWithToken),
    maskPrediction: postFun(maskPrediction),
    maskPredictionAicloth: postFun(maskPredictionAicloth),
    maskPredictionAidressform: postFun(maskPredictionAidressform),
    maskPredictionGoods: postFun(maskPredictionGoods),
    inpaintPrediction: (data, params) => postFun(inpaintPrediction, params)(data),
    getInpaintPredictionById: (id) => postFun(`${inpaintPrediction}/${id}`)(),
    aiinteractivePrediction: postFun(aiinteractivePrediction),
    inpaintPredictionGoods: postFun(inpaintPredictionGoods),
    aidressformPrediction: (data, params) => postFun(aidressformPrediction, params)(data),
    virtualtryonPrediction: postFun(virtualtryonPrediction),
    aiembeddingPrediction: postFun(aiembeddingPrediction),
    airemovePrediction: postFun(airemovePrediction),
    handlePrediction: postFun(handlePrediction),
    aisuperresolutionPrediction: postFun(aisuperresolutionPrediction),
    aisuperresolution4VideoPrediction: postFun(aisuperresolution4VideoPrediction),
    aibeautyPrediction: postFun(aibeautyPredictionUrl),
    getTaskListByUser: getFun(getTaskListByUserUrl),
    getTaskInfo: getFun(getTaskInfoUrl),
    deleteTaskImage: postFun(deleteTaskImageUrl),
    getAllProjectList: getFun(getAllProjectListUrl),
    getAllFavoriteList: getFun(getAllFavoriteListUrl),
    getProjectDetail: getFun(getProjectDetailUrl),
    deleteProject: postFun(deleteProjectUrl),
    renameProeject: postFun(renameProjectUrl),
    setProjectReadFlag: postFun(setProjectReadFlagUrl),
    modelTryOnList: getFun(modelTryOnList),
    modelPersonList: getFun(modelPersonList),
    modelBackgroundList: getFun(modelBackgroundList),
    tryonBackgroundList: getFun(tryonBackgroundList),
    commodityBackgroundList: getFun(commodityBackgroundList),
    commodityShowcaseList: getFun(commodityShowcaseList),
    projectCreate: postFun(projectCreate),
    aiDragGan: postFun(aiDragGanUrl),
    setProjectMainPic: postFun(setProjectMainPicUrl),
    picBatchMove: postFun(picBatchMoveUrl),
    projectBatchMove: postFun(projectBatchMoveUrl),
    projectDownload: postFun(projectDownloadUrl),
    projectPatchDownload: postFun(projectBatchDownloadUrl),
    favorBatchDownload: postFun(favorBatchDownloadUrl),
    userSendCode: data => postFun(userSendCode)({...data, platform: sessionStorage.getItem('platform')}),
    userLogin: data => postFun(userLogin)({...data, platform: sessionStorage.getItem('platform'), sourceId: sessionStorage.getItem('sourceId'), device: Utils.isMobileDevice()? 'web_mobile': 'web_pc', authCode: sessionStorage.getItem('code')}),
    emailRegister: data => postFun(emailRegister)({...data, platform: sessionStorage.getItem('platform'), sourceId: sessionStorage.getItem('sourceId'), device: Utils.isMobileDevice()? 'web_mobile': 'web_pc', authCode: sessionStorage.getItem('code')}),
    orderPlans: getFun(orderPlans),
    orderPlansByType: getFun(orderPlansByType),
    orderPlanDetails: getFun(orderPlanDetails),
    paymentOrderStatus: postFun(paymentOrderStatusUrl),
    powerPackages: getFun(powerPackages),
    subscribePlan: data => postFun(subscribePlan)({...data, sourceId: sessionStorage.getItem('sourceId')}),
    subscribeWithoutOrder: data => postFun(subscribeWithoutOrder)({...data, sourceId: sessionStorage.getItem('sourceId')}),
    userInfo: getFun(userInfo),
    getMembershipList: getFun(getMembershipList),
    getOrderList: getFun(getOrderList),
    getUsageBillList: getFun(getUsageBillList),
    subscribePowerPackage: data => postFun(subscribePowerPackage)({...data, sourceId: sessionStorage.getItem('sourceId')}),
    subscribePowerPackageWithoutOrder: data => postFun(subscribePowerPackageWithoutOrder)({...data, sourceId: sessionStorage.getItem('sourceId')}),
    payForOrder: postFun(payForOrder),
    removeOrder: postFun(removeOrder),
    checkVerifyCode: data => postFun(checkVerifyCodeUrl)({...data, platform: sessionStorage.getItem('platform')}),
    changeMobile: data => postFun(changeMobileUrl)({...data, platform: sessionStorage.getItem('platform')}),
    setPassword: postFun(setPasswordUrl),
    logout: postFun(logout),
    getTaskImages: getFun(getTaskImages),
    getHDImage: getFun(getHDImage),
    setHDImage: postFun(setHDImage),
    getClothesCategoryList: getFun(getClothesCategoryListUrl),
    getVersion: getFun(getVersionUrl),
    bookmarkTask: postFun(bookmarkTaskUrl),
    setSaleCode: postFun(setSaleCodeUrl),
    completeInfo: postFun(completeInfoUrl),
    getPowerBalance: getFun(getPowerBalance),
    uploadResource: postFun(uploadResource),
    feedback: postFun(feedback),
    useroplog: postFun(useroplog),
    forgetPassword: data => postFun(forgetPassword)({...data, platform: sessionStorage.getItem('platform')}),
    scoreImage: postFun(scoreImage),
    saveCustomTryOnModel: postFun(saveCustomTryOnModelUrl), // 自定义tryOn添加
    removeCustomTryOnModel: postFun(removeCustomTryOnModelUrl), // 自定义tryOn删除
    showcaseList: data => getFun(showcaseList)({...data, sourceId: sessionStorage.getItem('sourceId')}),
    saveCustomMaterial: postFun(saveCustomMaterialUrl),
    removeCustomMaterial: postFun(removeCustomMaterialUrl),
    setCmRead: postFun(setCmRead),
    userAppeal: postFun(userAppeal),
    orderCustomPlan: getFun(orderCustomPlan),
    unregisterUser: postFun(unregisterUser),
    usercaptcha: postFun(usercaptcha),
    bgCategoryList: getFun(bgCategoryList),
    image2Text: postFun(image2Text),
    savePrompt: postFun(savePrompt),

    // 模特视频
    modelvideoList: getFun(modelvideoList),
    videoTryonImageCheck: postFun(videoTryonImageCheck),
    doTryonVideo: postFun(doTryonVideo),
    saveVideoMaterial: postFun(saveVideoMaterial),
    
    // 商品视频生成
    commodityVideoPrediction: postFun(commodityVideoPredictUrl),

    // 优惠券模块
    acquireCoupon: postFun(acquireCouponUrl), // 优惠券领取
    changeCoupon: postFun(changeCouponUrl), // 优惠券兑换
    getOneCoupon: getFun(getOneCouponUrl),
    getCouponListForPay: getFun(getCouponListForPayUrl),
    getCouponList: getFun(getCouponListUrl),

    // 普通下载和高清下载
    commonDownload: postFun(commonDownloadUrl),
    hdDownload: postFun(hdDownloadUrl),
    aiEditDownload: postFun(aiEditDownloadUrl),

    wechatQrcode: postFun(wechatQrcode),
    loginStatus: data => postFun(loginStatus)({...data, platform: sessionStorage.getItem('platform'), device: Utils.isMobileDevice()? 'web_mobile': 'web_pc', authCode: sessionStorage.getItem('code')}),
    bindStatus: data => postFun(bindStatus)({...data, platform: sessionStorage.getItem('platform')}),
    unbind: postFun(unbind),
    taobaoOauth: postFun(taobaoOauth),

    // 人脸检测
    personCheck: postFun(personCheckUrl),
    // 多语义分割
    multiSemanticPrediction: postFun(multiSemanticPredictionUrl),

    // 活动
    activityInviteInfo: getFun(activityInviteInfo),
    inviteRecords: getFun(inviteRecords),
    withdrawalRecords: getFun(withdrawalRecords),
    handleWithdrawal: postFun(handleWithdrawal),

    // 根据mask获取商品分类
    getCommodityCategory: postFun(getCommodityCategoryUrl),

    generateConstantId: postFun(generateConstantIdUrl),

    //大客户交付
    listOrder: getFun(listOrder), //需求页订单列表
    getOrderDetails: getFun(getOrderDetails), //订单详情
    confirmOrder:postFun(confirmOrder),//确认、拒绝订单
    listWithSku:getFun(listWithSku), //交付页订单列表
    listArtifactsBySku:getFun(listArtifactsBySku),//根据sku获取成片图
    dashboardInfo:getFun(dashboardInfo),//需求页统计数据
    merchantdownload: postFun(merchantdownload),
    merchantdownloadBatch: postFun(merchantdownloadBatch),
    merchantrefine: postFun(merchantrefine),

    multiSemanticPredictionUrl: url + '/ps/web/algo/multiSemantic/',
    maskPredictionUrl: url + '/aishops/aimodel/mask/prediction/',
    maskPredictionAiclothUrl: url + '/aishops/aicloth/mask/prediction/',
    maskPredictionAidressformUrl: url + '/aishops/aidressform/mask/prediction/',
    maskPredictionUrlGoods: url + '/aishops/aigods/mask/prediction/',
    handlePredictionUrl: url + '/aishops/aiautocrop/handle/prediction/',
    airemovePredictionUrl: url + '/aishops/aidressform/airemove/prediction/',
    aiinteractivePredictionUrl: url + '/aishops/aiinteractive/mask/prediction/',
    aibeautyPredictionUrl,
    aiembeddingPredictionUrl: url + '/aishops/aiembedding/mask/prediction/',
    aisuperresolutionPredictionUrl: url + '/aishops/aisuperresolution/handle/prediction/',
    aisuperresolution4VideoPredictionUrl: url + '/ps/web/algo/videoSR/prediction/',
    aiDragGanUrl,
    url: url,
    devBaseUrl
}