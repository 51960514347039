/**
 * 使用参考
 * 参数：
 *  disabled, // 禁用所有功能
 *  moveable, // 是否可以移动（在允许移动的前提下）
 *  moveAllowed, // 是否允许移动，建议用disabled代替
 *  resetAllowed, // 是否允许重置位置，建议用disabled代替
 *  zoomAllowed, // 是否允许缩放，建议用disabled代替
 *  canvasList, // fabric canvas对象列表 required
 *  min, // default 0.1
 *  max, // default 10
 *  onChange, // ({zoom, moveable}) => {}
 *  options, // List
 *  showReset, // 是否显示reset按钮
 * 
 * 暴露的内部函数：
 *  resetCanvas // 重置
 *  zoomOut // 缩小
 *  zoomIn // 放大
 * 
    <DragAndScale
        ref={dragScaleRef}
        min={1}
        max={8}
        onChange={onDragAndScaleChange}
        canvasList={canvasList}
        options={[
            { label: '适应屏幕', value: 1 },
            { label: '实际大小', value: Number((1 / displayScale).toFixed(2)) },
            { label: '100%', value: 1 },
            { label: '200%', value: 2 },
            { label: '300%', value: 3 },
            { label: '400%', value: 4 },
        ]}
    />

    dragScaleRef.current.resetCanvas() // 重置
    dragScaleRef.current.zoomOut() // 缩小10%
    dragScaleRef.current.zoomIn() // 放大10%
 * 
**/
import { Button, Popover, Space } from 'antd'
import { DragOutlined, MinusOutlined, PlusOutlined, UndoOutlined, LoadingOutlined } from '@ant-design/icons';
import styles from './dragAndScale.module.scss'
import { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import useCanvasMove from './hooks/useCanvasMove';

const defaultOptions = [
    { label: '适应屏幕', value: 1 },
    { label: '10%', value: 0.10 },
    { label: '25%', value: 0.25 },
    { label: '50%', value: 0.50 },
    { label: '75%', value: 0.75 },
    { label: '100%', value: 1 },
    { label: '200%', value: 2 },
    { label: '300%', value: 3 },
    { label: '400%', value: 4 },
]

const DragAndScale = forwardRef((props, ref) => {
    useImperativeHandle(ref, () => ({
        resetCanvas, // 重置
        zoomOut, // 缩小
        zoomIn, // 放大
    }))

    // 是否显示reset按钮
    const [showReset, setShowReset] = useState(props.showReset || false)
    useEffect(() => {
        if (props.showReset == undefined) return
        setShowReset(props.showReset)
    }, [props.showReset])

    // 是否禁用所有功能
    useEffect(() => {
        if (props.disabled == undefined) return
        setMoveAllowed(!props.disabled)
        setResetAllowed(!props.disabled)
        setZoomAllowed(!props.disabled)
    }, [props.disabled])

    // 是否可以移动（在允许移动的前提下）
    const [moveable, setMoveable] = useState(props.moveable || false)
    useEffect(() => {
        if (props.moveable == undefined) return
        setMoveable(props.moveable)
    }, [props.moveable])

    // 是否允许移动
    const moveAllowedRef = useRef(props.moveAllowed || true)
    const [moveAllowed, setMoveAllowed] = useState(props.moveAllowed || true)
    useEffect(() => {
        if (props.moveAllowed == undefined) return
        moveAllowedRef.current = props.moveAllowed
        setMoveAllowed(props.moveAllowed)
    }, [props.moveAllowed])

    // 是否允许重置位置
    const resetAllowedRef = useRef(props.resetAllowed || true)
    const [resetAllowed, setResetAllowed] = useState(props.resetAllowed || true)
    useEffect(() => {
        if (props.resetAllowed == undefined) return
        resetAllowedRef.current = props.resetAllowed
        setResetAllowed(props.resetAllowed)
    }, [props.resetAllowed])

    // 是否允许缩放
    const zoomAllowedRef = useRef(props.zoomAllowed || true)
    const [zoomAllowed, setZoomAllowed] = useState(props.zoomAllowed || true)
    useEffect(() => {
        if (props.zoomAllowed == undefined) return
        zoomAllowedRef.current = props.zoomAllowed
        setZoomAllowed(props.zoomAllowed)
    }, [props.zoomAllowed])

    // 缩放的上下限
    const minZoomRef = useRef(props.min || .1)
    const maxZoomRef = useRef(props.max || 10)
    useEffect(() => {
        minZoomRef.current = props.min
        maxZoomRef.current = props.max
    }, [props.min, props.max])

    // 缩放和下拉框的控制
    const [zoom, setZoom] = useState(1)
    const [selectName, setSelectName] = useState()
    const [selectValue, setSelectValue] = useState()
    const [options, setOptions] = useState(props.options || defaultOptions)
    const [open, setOpen] = useState(false)
    useEffect(() => {
        if (!props.options) return
        setOptions(props.options)
    }, [props.options])
    useEffect(() => {
        if (selectValue == undefined) return
        if (!zoomAllowedRef.current) return
        setZoom(selectValue)
    }, [selectValue])
    const onSelect = (item) => {
        setSelectName(item.label)
        setSelectValue(item.value)
        if (item.label == '适应屏幕') { // 缩放图片后无法回到初始位置，点击适应屏幕返回初始位置
            resetCanvas()
        } 
    }
    const zoomOut = () => { // 缩小
        if (!zoomAllowedRef.current) return
        setZoom(pre => {
            pre -= .1
            return Math.max(Math.min(pre, maxZoomRef.current), minZoomRef.current)
        })
    }
    const zoomIn = () => { // 放大
        if (!zoomAllowedRef.current) return
        setZoom(pre => {
            pre += .1
            return Math.max(Math.min(pre, maxZoomRef.current), minZoomRef.current)
        })
    }

    const {resetCanvas} = useCanvasMove({
        instance: props.canvasList && props.canvasList[0],
        otherInstances: props.canvasList && props.canvasList.slice(1),
        moveable: moveable,
        moveAllowed: moveAllowed,
        zoomAllowed: zoomAllowed,
        zoom: zoom,
        zoomOutFunc: zoomOut,
        zoomInFunc: zoomIn,
        zoomResetFunc: () => setZoom(1)
    }) 
    
    useEffect(() => {
        props.onChange && props.onChange({zoom, moveable})
    }, [zoom, moveable])
    
    useEffect(() => {
        const keydown = (ev) => {
            if (ev.key == ' ' || ev.code == 'Space' || ev.keyCode == 32) { // 空格键+鼠标 可拖动图片
                if (!moveAllowedRef.current) return
                setMoveable(true)
            }
        }
        const keyup = ev => {
            if (ev.key == ' ' || ev.code == 'Space' || ev.keyCode == 32) {
                setMoveable(false)
            }
        }
        document.addEventListener('keydown', keydown)
        document.addEventListener('keyup', keyup)
        return () => {
            document.removeEventListener('keydown', keydown)
            document.removeEventListener('keyup', keyup)
        }
    }, [])

    const content = (
        <div className={styles.PopoverContent}>
        {
            options.map(item => (
                <span
                    key={item.label}
                    className={`
                        ${styles.optionItem}
                        ${selectName == item.label && styles.optionItem_selected}
                    `}
                    onClick={() => onSelect(item)}
                >
                    {item.label}
                </span>
            ))
        }
        </div>
    )

    return (
        <div className={styles.DragAndScale}>
            <Space.Compact>
                <Button disabled={!zoomAllowed || zoom <= minZoomRef.current} size="small" icon={<MinusOutlined />} onClick={zoomOut}></Button>
                <Popover
                    placement="bottom"
                    arrow={false}
                    content={content}
                    overlayClassName={styles.PopoverWrap}
                    open={zoomAllowed && open}
                    onOpenChange={setOpen}
                >
                    <div className={styles.scale_label}>{Math.floor(zoom * 100)}%</div>
                </Popover>
                <Button disabled={!zoomAllowed || zoom >= maxZoomRef.current} size="small" icon={<PlusOutlined />} onClick={zoomIn}></Button>
            </Space.Compact>
            <Button disabled={!moveAllowed} type={moveable ? 'primary' : 'default'} size="small" icon={<DragOutlined />} onClick={() => setMoveable(pre => !pre)} />
            {showReset && <Button disabled={!resetAllowed} type={'default'} size="small" icon={<UndoOutlined />} onClick={() => resetCanvas()} />}
        </div>
    )
})

export default DragAndScale