import API from '@api/api'
import { IMAGE_TYPE } from "@utils/CONST"
import Utils from '@utils/utils'
import eventBus from '@utils/eventBus';
import axios from 'axios';
import { message } from 'antd';
import Decimal from 'decimal.js'

// 分割
export const maskPrediction = (filename, imageType, stopCondition, isPerson=false) => {
    let APIobj = API.maskPrediction
    if (imageType == IMAGE_TYPE.MODEL) APIobj = API.maskPrediction
    if (imageType == IMAGE_TYPE.CLOTHES) APIobj = API.maskPredictionAicloth // Vision tryon
    if (imageType == IMAGE_TYPE.DRESSFORM) APIobj = API.maskPredictionAidressform
    if (imageType == IMAGE_TYPE.COMMODITY) APIobj = isPerson ? API.maskPrediction : API.maskPredictionGoods
    let mask_style = 4
    if (imageType == IMAGE_TYPE.CLOTHES) mask_style = 3
    return APIobj({
        image: filename,
        mask_style: mask_style,
    }).then(res => {
        if (res.code != 0) throw new Error(res.message)
        return getMaskResult(res.data, imageType, stopCondition, isPerson)
    }).catch((err) => {
        console.log(err)
    })
}
// 通过mask3获取同商品分割一样的mask1
async function getMask1ByMask3 (mask3) {
    const base64 = await Utils.convertWhiteImageToBlue(mask3)
    const url = await Utils.uploadBase64Image(base64, true)
    console.log('getMask1ByMask3', mask3, url)
    return url
}
async function getMask2ByMask3 (origin, mask3) {
    const base64 = await Utils.getFrontImage(origin, mask3)
    const res = await Utils.getTrimmedImageBase64(base64)
    const url = await Utils.uploadBase64Image(res.base64, true)
    return url
}
function getTrimmedImageOfWhiteBlack (url) {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = "anonymous"
        img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
            const data = imageData.data;
            let minX = canvas.width, minY = canvas.height, maxX = 0, maxY = 0;
            for (let y = 0; y < canvas.height; y++) {
                for (let x = 0; x < canvas.width; x++) {
                    const index = (y * canvas.width + x) * 4;
                    if (data[index] > 0) {
                        minX = Decimal.min(minX, x);
                        minY = Decimal.min(minY, y);
                        maxX = Decimal.max(maxX, x);
                        maxY = Decimal.max(maxY, y);
                    }
                }
            }
            const rectWidth = Decimal.sub(maxX, minX).add(1).toNumber(); 
            const rectHeight = Decimal.sub(maxY, minY).add(1).toNumber();
            const resultCanvas = document.createElement('canvas');
            resultCanvas.width = rectWidth;
            resultCanvas.height = rectHeight;
            const resultCtx = resultCanvas.getContext('2d');
            resultCtx.drawImage(canvas, minX, minY, rectWidth, rectHeight, 0, 0, rectWidth, rectHeight);
            const resultBase64 = resultCanvas.toDataURL();
            resolve(resultBase64)
        }
        img.onerror = reject
        img.src = url
    })
}
// 获取分割结果
export const getMaskResult = (id, imageType, stopCondition, isPerson) => {
    return new Promise((resolve, reject) => {
        const loopFunc = () => {
            let url = API.maskPredictionUrl + id
            if (imageType == IMAGE_TYPE.MODEL) url = API.maskPredictionUrl + id
            if (imageType == IMAGE_TYPE.CLOTHES) url = API.maskPredictionAiclothUrl + id // Vision tryon
            if (imageType == IMAGE_TYPE.DRESSFORM) url = API.maskPredictionAidressformUrl + id
            if (imageType == IMAGE_TYPE.COMMODITY && isPerson) url = API.maskPredictionUrl + id
            if (imageType == IMAGE_TYPE.COMMODITY && !isPerson) url = API.maskPredictionUrlGoods + id
            axios.post(url, {}, {
                headers: {
                    'Content-type' : 'application/json',
                    'Ps-Auth-Token': localStorage.getItem('token')
                }
            }).then(async (res) => {
                if((res.data.code == 0 && res.data.data.status == 'succeeded')) {
                    let output = res.data.data.output
                    let input = res.data.data.input
                    if (imageType == IMAGE_TYPE.COMMODITY && isPerson) {
                        const mask3 = output[3]
                        Promise.all([
                            getMask1ByMask3(mask3), // 通过mask3产出mask1
                            getMask2ByMask3(output[0], mask3), // 通过mask3产出mask2
                            getTrimmedImageOfWhiteBlack(mask3).then(base64 => Utils.uploadBase64Image(base64, false)), // 处理mask3
                        ]).then(([url1, url2, url3,]) => {
                            output[1] = url1
                            output[2] = url2
                            output[3] = url3
                            output[4] = mask3
                            resolve({ output, input, id })
                        })
                    } else {
                        resolve({ output, input, id })
                    }
                } else if(res.data.code == 0 && res.data.data.status == 'failed') {
                    const error = Utils.parseErrorCode(`[${res.data.data.errcode}]`)
                    eventBus.emit('notification', '分割失败', error.message, 'error', error.code)
                    reject()
                } else {
                    if (stopCondition && stopCondition()) return resolve()
                    setTimeout(() => {
                        loopFunc()
                    }, 400)
                }
            })
        }
        loopFunc()
    })
}

// 获取第一次的npy文件等，交互式分割第一步获取embedding
export const maskDispose = (output, stopCondition) => {
    return API.aiinteractivePrediction({
        img_path: output[0],
        mask_path: output[4],
        points_json: JSON.stringify([{x: 0, y: 0, label: 1}]),
        img_embedding_path: '',
        logits_path: ''
    }).then((res) => {
        if (res.code != 0) throw new Error(res.message)
        return getMaskDisposeResult(res.data, stopCondition)
    }).catch((err) => {
        if (!err) return
        console.log(err)
        message.error(err.message)
    })
}

// 获取embedding结果
export const getMaskDisposeResult = (id, stopCondition) => {
    return new Promise((resolve, reject) => {
        const loopFunc = () => {
            axios.post(API.aiinteractivePredictionUrl + id,  {}, {
                headers: {
                    'Content-type' : 'application/json',
                    'Ps-Auth-Token': localStorage.getItem('token')
                }
            }).then(res => {
                if((res.data.code == 0 && res.data.data.status == 'succeeded')) {
                    resolve({
                        id,
                        npy: res.data.data.output[0]
                    })
                } else if(res.data.code == 0 && res.data.data.status == 'failed') {
                    const error = Utils.parseErrorCode(`[${res.data.data.errcode}]`)
                    eventBus.emit('notification', '检测失败', error.message, 'error', error.code)
                    reject()
                } else {
                    if (stopCondition()) return resolve()
                    setTimeout(() => {
                        loopFunc()
                    }, 400)
                }
            })
        }
        loopFunc()
    })
}

// 多语义分割
export const multiSemantic = (url, stopCondition) => {
    return API.multiSemanticPrediction({
        image_path: url
    }).then(res => {
        if (res.code != 0) throw new Error(res.message)
        return getMultiSemanticResult(res.data, stopCondition)
    }).catch((err) => {
        if (!err) return
        console.log(err)
        message.error(err.message)
    })
}

export const getMultiSemanticResult = (id, stopCondition) => {
    return new Promise((resolve, reject) => {
        const loopFunc = () => {
            let url = API.multiSemanticPredictionUrl + id
            axios.post(url, {}, {
                headers: {
                    'Content-type' : 'application/json',
                    'Ps-Auth-Token': localStorage.getItem('token')
                }
            }).then(async (res) => {
                if((res.data.code == 0 && res.data.data.status == 'succeeded')) {
                    resolve({
                        id,
                        output: res.data.data.output,
                    })
                } else if(res.data.code == 0 && res.data.data.status == 'failed') {
                    const error = Utils.parseErrorCode(`[${res.data.data.errcode}]`)
                    eventBus.emit('notification', '分割失败', error.message, 'error', error.code)
                    reject()
                } else {
                    if (stopCondition && stopCondition()) return resolve()
                    setTimeout(() => {
                        loopFunc()
                    }, 400)
                }
            })
        }
        loopFunc()
    })
}

export default {
    maskDispose,
    maskPrediction,
    multiSemantic,
}