import { IMAGE_TYPE } from "@utils/CONST";
import { Image } from "antd";
import { useMemo, useState } from "react";

const ImagePreview = (props) => {
    const [visible, setVisible] = useState(false);

    return (
        <span style={{display: 'inline-flex'}}>
            <img
                src={props.src + '?x-oss-process=image/resize,m_lfit,h_200,w_200'}
                style={{
                    width: props.width || 'auto',
                    height: props.height || 'auto',
                    cursor: 'pointer',
                    borderRadius: '4px',
                    objectFit: 'cover',
                    marginLeft: '4px'
                }}
                onClick={() => setVisible(true)}
            />
            <Image
                style={{display: 'none'}}
                preview={{
                    visible,
                    src: props.src,
                    toolbarRender: () => null,
                    onVisibleChange: (value) => {
                        setVisible(value);
                    },
                }}
            />
        </span>
    )
}

export default function GroupTitleContent (props) {
    const {
        useReal,
        showImg=true,
        imageType,
        personTitle, personRealTitle,
        backgroundTitle, backgroundRealTitle,
        videoTryonTitle,
        refImg,
        definedFlag,
        keepPose,
        keepBkg,
    } = props
    // 模特场景组合1 xxx/xxx
    const groupTitle = useMemo(() => {
        const taskModel = useReal ?
            (personRealTitle && personRealTitle.split('-')) || (personTitle && personTitle.split('-')) :
            (personTitle && personTitle.split('-'))
        const taskBg = useReal ?
            (backgroundRealTitle || backgroundTitle) :
            backgroundTitle
        let modelListComp = []
        let bgComp = []
        if (imageType == IMAGE_TYPE.MODEL_VIDEO) {
            return videoTryonTitle.split('+').map((item, index) => (
                <span className="_feature" key={item+index}>
                    {item}
                </span>
            ))
        } else if (imageType == IMAGE_TYPE.COMMODITY) {
            const isCustomScene = definedFlag == 1
            const background = isCustomScene ? '自定义场景' : taskBg
            bgComp = (
                <span className="_feature" key={'bg'}>
                    {background}
                    {refImg && showImg && isCustomScene ? <ImagePreview width={24} height={24} key={'refImg'} src={refImg} /> : ''}
                </span>
            )
        } else if (imageType == IMAGE_TYPE.MODEL || imageType == IMAGE_TYPE.DRESSFORM) {
            const modelList = keepPose == 1 ? ['保持模特'] : taskModel
            const isCustomScene = definedFlag == 1
            const background = isCustomScene ? (refImg ? '自定义场景' : "") : taskBg
            const list = modelList.length == 4 ? // [肤色-年龄-性别-英文名=风格]
                [modelList[2], modelList[0]+'肤色', ...modelList[3].split('/')] :
                [...modelList]
            modelListComp = list.filter(item => !!item).map((item, index) => (
                <span className="_feature" key={item+index}>
                    {item}
                </span>
            ))
            bgComp = background ? (
                <span className="_feature" key={'bg'}>
                    {background}
                    {refImg && showImg && isCustomScene ? <ImagePreview width={24} height={24} key={'refImg'} src={refImg} /> : ''}
                </span>
            ) : null
        } else if (imageType == IMAGE_TYPE.CLOTHES) {
            const postList = refImg ? ['自定义模特'] : []
            const modelList = keepPose == 1 ? ['保持容貌'] : taskModel
            const background = keepBkg == 1 ? ['保持场景'] : taskBg
            const list = modelList.length == 4 ? // [肤色-年龄-性别-英文名/风格]
                [...postList, modelList[2], modelList[0]+'肤色', ...modelList[3].split('/')] :
                [...postList, ...modelList]
            modelListComp = list.filter(item => !!item).map((item, index) => (
                <span className="_feature" key={item+index}>
                    {item}
                    {item == '自定义模特' && showImg ? <ImagePreview width={24} height={24} key={'refImg'} src={refImg} /> : ''}
                </span>
            ))
            bgComp = (
                <span className="_feature" key={'bg'}>
                    {background}
                </span>
            )
        } else {
            const modelList = taskModel
            const background = taskBg
            const list = modelList.length == 4 ? // [肤色-年龄-性别-英文名=风格]
                [modelList[2], modelList[0]+'肤色', ...modelList[3].split('/')] :
                [...modelList]
            modelListComp = list.filter(item => !!item).map((item, index) => (
                <span className='_feature' key={item+index}>{item}</span>
            ))
            bgComp = (
                <span className='_feature' key={'bg'}>
                    {background}
                </span>
            )
        }
        return [...modelListComp, bgComp]
    }, [])

    return groupTitle
}