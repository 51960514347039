import { Button, Spin, Upload, message } from "antd"
import { useState, useContext, useMemo, } from "react"
import Utils from '@utils/utils'
import styles from './index.module.scss'
import { IMAGE_TYPE } from "@utils/CONST"
import Example from '@assets/json/example.json'
import { GenerateContext } from "@utils/GenerateContext"

const UploadContent = (props) => {
    const [exampleList] = useState(Example)

    const modelExamples = useMemo(() => {
        return exampleList
            .filter(item => item.type == props.imageType)
            .filter(item => !item.disabled)
    }, [exampleList, props.imageType])

    const onClick = (e, example) => {
        e.stopPropagation()
        const {category, name, source, type} = example
        Utils.urlToBase64(source).then(base64 => {
            const file = Utils.convertBase64ToFile(base64, name)
            props.onUpload(file, name)
        })
    }

    const BtnText = useMemo(() => {
        return props.imageType == IMAGE_TYPE.MODEL ?
                "上传真人模特图" : 
                props.imageType == IMAGE_TYPE.DRESSFORM ?
                "上传人台图" :
                "上传商品图"
    }, [props.imageType])

    return (
        <div className={styles.UploadContent}>
            <div className={styles.UploadHandle}>
                <Button className={styles.UploadBtn} type="primary">{BtnText}</Button>
                <p className={styles.UploadTip2}>建议图片尺寸不小于500*500，长宽比不超过2:1</p>
                <div className={styles.UploadSample}>
                    <ul>
                    {
                        modelExamples.map((item, index) => (
                            <li key={item.name}>
                                <img src={Utils.getImgUrlWithWebp(item.source)} onClick={e => onClick(e, item)} />
                            </li>
                        ))
                    }
                    </ul>
                </div>
            </div>
        </div>
    )
}

const UploadModel = (props) => {
    const [loading, setLoading] = useState(false)
    const {
        setProjectId,
        setProjectName,
        globaluploadLoading, setGlobaluploadLoading
    } = useContext(GenerateContext)

    const onBeforeUpload = async (file, sampleName) => {
        // loading---
        // setLoading(true)
        setGlobaluploadLoading(true)
        try {
            const res = await props.onBeforeUpload(file, sampleName)
            if (!res) return
            console.log('===setProjectId null in UploadModel===')
            setProjectId()
            setProjectName('未命名')
        } catch {
            setGlobaluploadLoading(false)
        } finally {
            // setLoading(false)
            return false
        }
    }

    return (
        <div className={styles.Container}>
            <div id="tour_target_upload" className={styles.UploadBox}>
                <Spin spinning={globaluploadLoading} size="large" tip="抠图中...">
                    <Upload
                        accept={'image/jpeg,image/png,image/webp,image/avif'}
                        name="avatar"
                        listType="picture-card"
                        showUploadList={false}
                        className={styles.Upload}
                        beforeUpload={onBeforeUpload}
                        maxCount={1}
                    >
                        <UploadContent
                            imageType={props.imageType}
                            onUpload={onBeforeUpload}
                        />
                    </Upload>
                </Spin>
            </div>
        </div>
    )
}

export default UploadModel