import { Popover } from 'antd';
import { useState } from 'react';
import styles from './ActionGroup.module.scss'

const ActionItem = (props) => {
    const [hover, setHover] = useState(true)
    const [popoverOpen, setPopoverOpen] = useState()


    const onClick = () => {
        props.onChange && props.onChange(props.value)
        props.onClick && props.onClick()
    }
    return (
        <Popover
            content={props.popover}
            arrow={false}
            placement="bottomLeft"
            open={!!props.popover && popoverOpen}
            onOpenChange={setPopoverOpen}
            overlayClassName={styles.radio_popover}
            overlayStyle={{paddingTop: '10px'}}
        >
            <div
                className={`${styles.radio_warp} ${props.selected == props.value && styles.radio_warp_checked} ${props.disabled && styles.radio_warp_disabled}`}
                onClick={() => onClick()}
                onMouseOver={() => setHover(props.value)}
                onMouseOut={() => setHover()}
            >
                <span
                    className={styles.radio_icon}
                    style={{
                        backgroundImage: `url(${(props.selected == props.value || hover == props.value) && !props.disabled ? props.activedIcon : props.icon})`,
                        ...props.iconStyle
                    }}
                ></span>
                <span className={styles.radio_label}>{props.label}</span>
            </div>
        </Popover>
    )
}

export default ActionItem