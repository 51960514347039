import { Segmented, message, Skeleton, Button } from "antd";
import { useEffect, useMemo, useState , useContext, useRef} from "react"
import API from '@api/api'
import Utils from '@utils/utils'
import styles from './memberPackage.module.scss'
import { useNavigate } from "react-router-dom";
import { AppContext } from '@utils/AppContext';


const PlanItem = (props) => {
    const unit = useMemo(() => {
        return props.type == 0 ? '月' : props.type == 1 ? '季' : '年'
    }, [props.type])

    // const showNewOffer = useMemo(() => {
    //     return props.type == 1 && props.level != 'free'
    // }, [props.type])
    // const isMonth = useMemo(() => {
    //     console.log(props.type)
    // }, [props.type])
    // const { globalUser } = useContext(AppContext);
    const iconImg = ['planicon1.png', 'planicon2.png', 'planicon3.png', 'planicon4.png']
    const price = [
        ['0.048', '0.038', '0.024', '0.022'],
        [null, '0.034', '0.022', '0.020'],
        [null, '0.026', '0.017', '0.016 ']
    ]
    return (
        <div className={styles.PlanItem}>
            <div className={styles.PlanItemContent}>
                <Skeleton active loading={props.placeholer} paragraph={{rows: 9}}>
                {
                    !props.placeholer &&
                    <>
                    <div className={`${styles.PlanItemContent_top} ${props.index == 0?styles.top0: props.index == 1? styles.top1: props.index == 2? styles.top2 : styles.top3}`}>
                        <div>
                            <p>{props.planName.slice(0, props.planName.lastIndexOf(' '))}</p>
                            <p>{props.description}</p>
                        </div>
                    </div>
                    <div className={styles.PlanItemContent_content}>
                        <ul className={styles.PlanItemContent_ul}>
                            <li><img src={require(`@assets/images/${iconImg[props.index]}`)}/>{Utils.addCommasToNumber(props.powerAmount)}虹豆<span>（1虹豆≈¥{price[props.type][props.index]}）</span></li>
                            <li><img src={require(`@assets/images/${iconImg[props.index]}`)}/>高清下载</li>
                            <li><img src={require(`@assets/images/${iconImg[props.index]}`)}/>同时在线设备限{props.supportDevices}台</li>
                            {
                                props.index == 2 || props.index == 3 ? <li><img src={require(`@assets/images/${iconImg[props.index]}`)}/>专属1V1客服支持</li> : 
                                <li style={{visibility: 'hidden'}}>null</li> 
                            }
                        </ul>
                        <div>
                            <p className={styles.PlanItemContent_p}> ￥<span>{Utils.addCommasToNumber(props.price)}元</span>/{unit}</p>
                            <div className={styles.PlanItemContent_span} >
                                <Button type="primary" onClick={props.payFunc}>立即购买</Button>
                            </div>
                        </div>
                    </div>
                    </>
                }
                </Skeleton>
            </div>
        </div>
    )
}

const MemberPackage = (props) => {
    const navigate = useNavigate()
    const [plansList, setPlansList] = useState([]);
    const [type, setType] = useState(0)

    useEffect(() => {
        API.orderPlansByType({type: type}).then((res) => {
            if(res?.code != 0) return message.error(res.message)
            setPlansList(res.data)
        })
    }, [type])

    const onSegmentedChange = (value) => {
        setType(value)
    }

    const options = [
        { label: '包月', value: 0 },
        { label: '包季', value: 1 },
        { label: '包年', value: 2 },
    ]

    const payClick = (item, type) => {
        // type: 0-包月；1-包季；2-包年
        // description "捕光逐影"
        // level "basic"
        // name "PSAI 捕光逐影"
        // powerAmount 1000
        // price "48.00"
        // supportDevices 1
        // unit "Month"
        const unit = item.unit
        const tips = `${unit == 'Month' ? '30天' : unit == 'Quarter' ? '90天' : '365天'}`

        props.payFunc({
            id: item.id, // id
            name: item.name, // name
            level: item.level, // 等级
            supportDevices: item.supportDevices, // 支持设备
            list: [{
                id: item.id,
                price: item.price, // 价格
                powerAmount: item.powerAmount, // 虹豆点
                description: `价值${item.powerAmount}AI虹豆，${tips}后清空`,
                tips: `价值${item.powerAmount}AI虹豆，${tips}后清空`,
                unit: item.unit, // Month包月 / Quarter包季 / Year包年
            }]
        })
    }

    const Plan1List = (
        <div className={`${styles.PlansList} ${plansList.length > 3? styles.PlanType1: styles.PlanType2}`}>
        {
            plansList.length > 0 ?
            plansList.map((item, index) => (
                <PlanItem
                    key={type+item.level}
                    type={type}
                    description={item.description}
                    benefits={item.benefitsDescription}
                    planId={item.id}
                    level={item.level}
                    planName={item.name}
                    powerAmount={item.powerAmount}
                    price={item.price}
                    discountPrice={item.discountPrice}
                    unit={item.unit}
                    isBorder={props.isBorder}
                    supportDevices={item.supportDevices}
                    payFunc={() => payClick(item, type)}
                    index={plansList.length > 3?index: index + 1}
                />
            )) :
            plansList.map((_, index) => (
                <PlanItem
                    key={index}
                    isBorder={props.isBorder}
                    placeholer
                />
            ))
        }
        </div>
    )

    return (
        <div className={styles.MemberPackage}>
            <Segmented className={styles.Segmented} options={options} onChange={onSegmentedChange}/>
            { Plan1List }
        </div>
    )
}

export default MemberPackage