import { Select, message } from "antd";
import { useContext, useEffect, useState } from "react";
import styles from './index.module.scss'
import { GenerateContext } from "@utils/GenerateContext";
import { IMAGE_TYPE } from "@utils/CONST"
import API from '@api/api'

export default function SceneTypeSelection (props) {
    const [sceneTypeCategory, setSceneTypeCategory] = useState([])
    const [commodityTypeCategory, setCommodityTypeCategory] = useState([])
    const {imageType, commodityFilter, setCommodityFilter} = useContext(GenerateContext)
    
    const [sceneType, setSceneType] = useState(commodityFilter?.sceneType)
    const [commodityType, setCommodityType] = useState(commodityFilter?.commodityType)
    useEffect(() => {
        setSceneType(commodityFilter.sceneType)
        setCommodityType(commodityFilter.commodityType)
    }, [commodityFilter])
    
    useEffect(() => {
        if(imageType != IMAGE_TYPE.COMMODITY) return
        Promise.all([
            API.bgCategoryList({type: IMAGE_TYPE.COMMODITY, category: 'sceneType'}),
            API.bgCategoryList({type: IMAGE_TYPE.COMMODITY, category: 'commodityType'}),
        ]).then(res => {
            const res1 = res[0]
            const res2 = res[1]
            if (res1.code != 0) throw new Error(res1.message)
            if (res2.code != 0) throw new Error(res2.message)
            setSceneTypeCategory([...res1.data.map(item => ({
                label: item.display,
                value: item.value
            }))])
            setCommodityTypeCategory([...res2.data.map(item => ({
                label: item.display,
                value: item.value
            }))])
        }).catch(err => {
            message.warning(err.message)
        })
    }, [])

    const handleSceneTypeChange = (value) => {
        setCommodityFilter(pre => ({ ...pre, sceneType: value }))
    }
    const handleSceneTypeClear = () => {
        setCommodityFilter(pre => ({ ...pre, sceneType: "" }))
    }
    const handleCommodityTypeChange = (value) => {
        setCommodityFilter(pre => ({ ...pre, commodityType: value }))
    }
    const handleCommodityTypeClear = () => {
        setCommodityFilter(pre => ({ ...pre, commodityType: "" }))
    }

    return (
        <div className={styles.Container}>
            <Select
                value={commodityType}
                placement="bottomRight"
                allowClear
                placeholder="商品类别"
                onChange={handleCommodityTypeChange}
                onClear={handleCommodityTypeClear}
                options={commodityTypeCategory}
            />
            <Select
                value={sceneType}
                placement="bottomRight"
                allowClear
                placeholder="场景类别"
                onChange={handleSceneTypeChange}
                onClear={handleSceneTypeClear}
                options={sceneTypeCategory}
            />
        </div>
    )
}