import { Popover } from 'antd'
import styles from './VersionTips.module.scss'
import { useMemo } from 'react'

const VersionTips = (props) => {
    const imgStyle = useMemo(() => {
        return {
            ...props.imgStyle,
            objectFit: 'contain'
        }
    }, [props.imgStyle])
    return (
        <Popover
            overlayClassName={styles.RefImgPopover}
            arrow={false}
            autoAdjustOverflow={false}
            placement={props.placement || "topLeft"}
            content={
                <img
                    style={imgStyle} 
                    src={props.tipImg}
                />
            }
        >
            <i className={styles.RefImg}></i>
        </Popover>
    )
}

export default VersionTips