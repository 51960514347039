import { useEffect, useMemo, useRef } from "react"
import Utils from '@utils/utils'

const PAINT_COLOR = 'rgba(30, 144, 255, 0.8)'
const ERASE_COLOR = 'rgb(255, 255, 255)'
const PAINT_CIRCLE_COLOR = 'rgba(34, 55, 244, 0.2)'
const PAINT_CIRCLE_STROKE = 'rgba(57, 62, 253, 1)'
const ERASE_CIRCLE_COLOR = 'rgba(254, 41, 94, 0.2)'
const ERASE_CIRCLE_STROKE = 'rgba(254, 41, 94, 1)'

fabric.PencilBrushCustom = fabric.util.createClass(fabric.PencilBrush, {
    createPath: function(pathData) {
        const path = new fabric.Path(pathData, {
            fill: null,
            stroke: this.color == 'rgba(0,0,0,0)' ? ERASE_COLOR : this.color,
            strokeWidth: this.width,
            strokeLineCap: this.strokeLineCap,
            strokeMiterLimit: this.strokeMiterLimit,
            strokeLineJoin: this.strokeLineJoin,
            strokeDashArray: this.strokeDashArray,
            globalCompositeOperation: this.color == 'rgba(0,0,0,0)' ? 'destination-out' : 'source-over',
            selectable: false,
            hoverCursor: 'pointer',
            evented: false,
        });
        if (this.shadow) {
          this.shadow.affectStroke = true;
          path.shadow = new fabric.Shadow(this.shadow);
        }
  
        return path;
    },
})

const usePaint = (props) => {
    const { instance, topInstance } = props
    const modeRef = useRef(props.paintMode)
    const circleMouseRef = useRef()
    const pencilBrushRef = useRef()
    const tempPathRef = useRef() // 画笔临时路径
    const paintSizeRef = useRef()
    const eraseSizeRef = useRef()
    const drawingRef = useRef()

    useEffect(() => {
        if (!instance) return
        modeRef.current = props.paintMode
        if (props.paintMode && pencilBrushRef.current) {
            instance.isDrawingMode = true
        } else {
            instance.isDrawingMode = false
        }
    }, [props.paintMode, instance])

    const pencilScaleRef = useRef(props.displayWidth / 500)
    useEffect(() => {
        pencilScaleRef.current = props.displayWidth / 500
    }, [props.displayWidth])

    useEffect(() => {
        if (!instance) return
        paintSizeRef.current = props.paintSize
        eraseSizeRef.current = props.eraseSize
        if (pencilBrushRef.current) {
            if (!props.paintMode) {
                destroyCircle()
            } else {
                // 创建跟随鼠标移动的色块
                createCircle()
                if (props.paintMode == 'paint') {
                    // circleMouseRef.current.set({fill: PAINT_COLOR})
                    circleMouseRef.current.set({fill: PAINT_CIRCLE_COLOR, stroke: PAINT_CIRCLE_STROKE})
                    circleMouseRef.current.set({radius: paintSizeRef.current / 2 * pencilScaleRef.current})
                    pencilBrushRef.current.color = PAINT_COLOR
                    pencilBrushRef.current.width = paintSizeRef.current * pencilScaleRef.current
                } else {
                    // circleMouseRef.current.set({fill: ERASE_COLOR})
                    circleMouseRef.current.set({fill: ERASE_CIRCLE_COLOR, stroke: ERASE_CIRCLE_STROKE})
                    circleMouseRef.current.set({radius: eraseSizeRef.current / 2 * pencilScaleRef.current})
                    pencilBrushRef.current.color = 'rgba(0,0,0,0)'
                    pencilBrushRef.current.width = eraseSizeRef.current * pencilScaleRef.current
                }
            }
            instance.renderAll()
            topInstance.renderAll()
        }
    }, [props.paintMode, props.paintSize, props.eraseSize, instance, props.displayWidth])

    const setCircleMouse = (circle) => {
        circleMouseRef.current = circle
    }

    const createCircle = () => {
        if (circleMouseRef.current) return
        const circle = new fabric.Circle({
            left: 0,
            top: 0,
            radius: (modeRef.current == 'paint' ? paintSizeRef.current / 2 : eraseSizeRef.current / 2) * pencilScaleRef.current,
            fill: modeRef.current == 'paint' ? PAINT_CIRCLE_COLOR : ERASE_CIRCLE_COLOR,
            stroke: modeRef.current == 'paint' ? PAINT_CIRCLE_STROKE : ERASE_CIRCLE_STROKE,
            originX: 'center',
            originY: 'center',
        })
        circle.set('is_circle_mouse', true)
        circleMouseRef.current = circle
        topInstance.bringToFront(circle)
    }

    const destroyCircle = () => {
        if (circleMouseRef.current) {
            topInstance.remove(circleMouseRef.current)
            circleMouseRef.current = null
        }
    }

    // ctrl + 鼠标滚轮: 缩放画笔大小
    const ctrlKeyPressedRef = useRef(false)
    useEffect(() => {
        const keydown = ev => {
            if ((ev.key == 'Control' || ev.code == 'ControlLeft' || ev.code == 'ControlRight' || ev.keyCode == 17)) {
            } {
                ctrlKeyPressedRef.current = true
            }
        }
        const keyup = ev => {
            if (ev.key == 'Control' || ev.code == 'ControlLeft' || ev.code == 'ControlRight' || ev.keyCode == 17) {
                ctrlKeyPressedRef.current = false
            }
        }
        // 监听键盘按下
        document.addEventListener('keydown', keydown)
        document.addEventListener('keyup', keyup)
        return () => {
            document.removeEventListener('keydown', keydown)
            document.removeEventListener('keyup', keyup)
        }
    }, [])

    useEffect(() => {
        if (!instance) return
        // 创建PencilBrush
        if (!pencilBrushRef.current) {
            const brush = new fabric.PencilBrushCustom(instance)
            pencilBrushRef.current = brush
            brush.color = 'rgba(30, 144, 255, 0.8)';
            brush.width = 20
            brush.limitedToCanvasSize = true
            instance.freeDrawingBrush = pencilBrushRef.current
            instance.freeDrawingCursor = 'pointer'
        }
        instance.on('mouse:down', () => {
            if (!modeRef.current) return
            drawingRef.current = true
            // 备份用于撤销
            props.addUndo('paint')
        })
        instance.on('mouse:move', (event) => {
            if (!modeRef.current) return

            if (circleMouseRef.current) {
                const { offsetX, offsetY } = event.e
                const canvasOffsetX = instance.viewportTransform[4]
                const canvasOffsetY = instance.viewportTransform[5]
                const zoom = instance.getZoom()
                const x = (offsetX - canvasOffsetX) / zoom
                const y = (offsetY - canvasOffsetY) / zoom
                circleMouseRef.current.set({left: x, top: y})
                topInstance.renderAll()
            }
            
            // 实时擦除
            if (modeRef.current == 'erase') {
                if (!drawingRef.current) return
                if (tempPathRef.current) instance.remove(tempPathRef.current)
                let _points = []
                if (pencilBrushRef.current.decimate) {
                    _points = pencilBrushRef.current.decimatePoints(pencilBrushRef.current._points, pencilBrushRef.current.decimate);
                }
                if (_points.length == 0) return
                const pathData = pencilBrushRef.current.convertPointsToSVGPath(_points);
                if (pencilBrushRef.current._isEmptySVGPath(pathData)) {
                    return
                }
                const tempPath = new fabric.Path(pathData, {
                    fill: null,
                    stroke: modeRef.current == 'erase' ? ERASE_COLOR : PAINT_COLOR,
                    strokeWidth: (modeRef.current == 'erase' ? eraseSizeRef.current : paintSizeRef.current) * pencilScaleRef.current,
                    strokeLineCap: 'round',
                    strokeMiterLimit: 10,
                    strokeLineJoin: 'round',
                    strokeDashArray: null,
                    globalCompositeOperation: modeRef.current == 'erase' ? 'destination-out' : 'source-over',
                    selectable: false,
                    hoverCursor: 'pointer',
                    evented: false,
                })
                tempPathRef.current = tempPath
                instance.add(tempPath)
                instance.requestRenderAll()
            }
        })
        instance.on('mouse:up', () => {
            if (!modeRef.current) return
            if (tempPathRef.current) {
                instance.remove(tempPathRef.current)
                instance.renderAll()
            }
            pencilBrushRef.current._reset() // 清除path
            drawingRef.current = false
        })
        instance.on('path:created', () => {
            if (!modeRef.current) return
            // 埋点
            Utils.useroplog(props.imageType, 'algorithm', 'aiInteractiveMask', {paint: modeRef.current})
            // blue mask 重新混合
            props.getBlendedBlueMaskBase64()
            props.setUpdated(true) // 已更新
        })
        // 监听鼠标滚轮事件
        instance.on('mouse:wheel', function(event) {
            if (!ctrlKeyPressedRef.current) return
            if (!modeRef.current) return
            
            if (!circleMouseRef.current) return

            const delta = event.e.deltaY;
            let size = Math.round((circleMouseRef.current.get('radius') / pencilScaleRef.current) * 2)
            // 根据滚轮滚动方向调整缩放比例
            if (delta > 0) {
                size-- // 缩小画布
            } else {
                size++ // 放大画布
            }
            size = Math.floor(Math.max(Math.min(size, 50), 1))

            modeRef.current == 'paint' ?
                props.setPaintSize(size) :
                props.setEraseSize(size)
            
            event.e.preventDefault();
            event.e.stopPropagation();
        })
    }, [instance])

    return {
        setCircleMouse
    }
}

export default usePaint