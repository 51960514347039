import { useEffect, useState } from 'react'
import styles from './index.module.scss'

const RatioSelection = (props) => {
    const [value, setValue] = useState(props.value)

    useEffect(() => {
        setValue(props.value)
    }, [props.value])

    const [options] = useState([{
        label: '自定义',
        value: 'custom'
    }, {
    //     label: '推荐',
    //     value: 'recommend'
    // }, {
    //     label: '原始比例',
    //     value: 'original'
    // }, {
        label: '1:1',
        value: '1:1'
    }, {
        label: '2:3',
        value: '2:3'
    }, {
        label: '3:4',
        value: '3:4'
    }, {
        label: '9:16',
        value: '9:16'
    }])

    const handleClick = (value) => {
        if (props.disabled) return
        if (value == 'custom') { // 点击【自定义】时，唤起构图优化
            props.onTriggerComposeOpt()
        }
        props.onChange(value)
    }

    // useEffect(() => {
    //     if (!props.bgImage) props.onChange('custom')
    // }, [props.bgImage])

    useEffect(() => {
        if (props.disabled) props.onChange('custom')
    }, [props.disabled])

    return (
        <div className={styles.SelectModel}>
            <div className={styles.select_title}>
                <span>图像比例</span> 
            </div>
            <div className={`${styles.select_group}  ${props.disabled && styles.disabled}`}>
            {
                options.map(item => (
                    <span
                        key={item.value}
                        className={`${styles.select_item} ${value == item.value && styles.selected}`}
                        onClick={() => handleClick(item.value)}
                    >
                        {item.label}
                    </span>
                ))
            }
            </div>
        </div>
    )
}

export default RatioSelection