import WaitUploadPage from '@view/compoents/WaitUploadPage2'
import styles from './index.module.scss'
import { useContext, useState, useMemo } from 'react'
import { message, Button, Spin, Upload } from 'antd'
import Utils from '@utils/utils'
import PSAIModal from '@PSAIComponents/PSAIModal'
import VideoImage from './VideoImage'
import Example from '@assets/json/example.json'
import SampleSegment from '@assets/json/sample-segment.json'
import { GenerateContext } from '@utils/GenerateContext'
import { UPLOAD_SOURCE } from "@utils/CONST"
import { AppContext } from '@utils/AppContext'
import eventBus from '@utils/eventBus'
import { PRIVATE_ABILITIES } from '@utils/CONST';

const MiddleModelVideo = () => {
    const {setGlobalLoading, hasPrivateAbility} = useContext(AppContext)
    const {
        modelVideoFrontPic, setModelVideoFrontPic,
        modelVideoBackPic, setModelVideoBackPic,
        setProjectId, setProjectName
    } = useContext(GenerateContext)
    const [loading, setLoading] = useState(false)

    const [exampleList] = useState(Example)
    
    const modelExamples = useMemo(() => {
        return exampleList
            .filter(item => item.type == 'model_video')
            .filter(item => !item.disabled)
    }, [exampleList])

    // 无背身功能用户-示例图
    const modelExamples2 = useMemo(() => {
        return exampleList
            .filter(item => item.type == 'model_video')
            .filter(item => item.name != '模特视频3')
    }, [exampleList])

    const illegalAndReuploadHandle = async (sampleName) => {
        return new Promise((resolve, reject) => {
            PSAIModal.confirm({
                content: '经检测，您上传的内容涉嫌违规，已进行屏蔽处理，人工审核会对图片进行复核。',
                okText: '重新上传',
                cancelText: '取消',
                onOk() {
                    Utils.openFile().then((res) => {
                        if(res) {
                            beforeUpload(res, sampleName).then(resolve)
                        } else {
                            resolve(false)
                        }
                    }).catch(err => {
                        resolve(false)
                    })
                },
                onCancel() {
                    eventBus.emit('TOUR_PLAY')
                },
            })
        })
    }
    const multipleAndReuploadHandle = async (url, isFront) => {
        return new Promise((resolve, reject) => {
            PSAIModal.confirm({
                content: '您上传的图片包含多人，可能会影响视频生成的效果，建议上传只有一人的图片。',
                okText: '重新上传',
                cancelText: '继续生成',
                onOk() {
                    Utils.openFile().then((res) => {
                        if(res) {
                            beforeUpload(res, null, isFront).then(resolve)
                        } else {
                            resolve(false)
                        }
                    }).catch(err => {
                        resolve(false)
                    })
                },
                onCancel() {
                    resolve(url)
                },
            })
        })
    }
    const uploadHandle = async (file, sampleName) => {
        eventBus.emit('TOUR_PAUSE')
        const res = await beforeUpload(file, sampleName)
        setGlobalLoading(false)
        if (!res) {
            eventBus.emit('TOUR_PLAY')
            return false
        } else {
            if (res instanceof Array) {
                setModelVideoFrontPic(res[0])
                setModelVideoBackPic(res[1])
            } else {
                setModelVideoFrontPic(res)
                // setModelVideoBackPic()
            }
            setProjectId()
            setProjectName('未命名')
            eventBus.emit('TOUR_NEXT')
        }
    }
    const beforeUpload = async (file, sampleName, isFront=true) => {
        try {
            if (sampleName) {
                if (file instanceof File) {
                    const type = 'model_video'
                    const prefix = SampleSegment.prefix
                    const sample = Example.find(item => item.name == sampleName)
                    const sampleSegment = SampleSegment[type]?.find(item => item.name == sampleName)
                    if (!sample || !sampleSegment) return false
                    const input = sample.source
                    return encodeURI(input)
                } else {
                    const type = 'model_video'
                    const prefix = SampleSegment.prefix
                    const sample = Example.find(item => item.name == sampleName)
                    const sampleSegment = SampleSegment[type]?.find(item => item.name == sampleName)
                    if (!sample || !sampleSegment) return false
                    const inputList = sample.sourceGroup
                    return inputList
                }                
            }
            setGlobalLoading('上传中')
            // 检查图像是否符合要求
            const validate = await Utils.validateImageFile(file)
            if (!validate) return false
            // 图片最大尺寸重置2048
            const resizeRes = await Utils.resizeImageFileMaxByAliyunBy(file, 2048)
            if (!resizeRes || !resizeRes.success) throw new Error(resizeRes?.message)
            file = resizeRes.file
            const legalRes = await Utils.checkImageIslegal(file, 1, UPLOAD_SOURCE.TRYON_VIDEO) //审核
            if(legalRes) {
                if(!legalRes.isLegal) {
                    setGlobalLoading(false)
                    const res = await illegalAndReuploadHandle(sampleName)
                    return res
                } else {
                    const res = await Utils.videoTryonImageCheck(legalRes.data, isFront)
                    if (res == 0) { // 合格
                        return legalRes.data
                    } else if (res == 3) { // 检测到多人
                        setGlobalLoading(false)
                        const res = await multipleAndReuploadHandle(legalRes.data, isFront)
                        return res
                    } else {
                        return false
                    }
                }
            } else {
                return false
            }
        } catch (err) {
            message.warning(err.message);
            console.error(err.message)
            return false
        } finally {
            setGlobalLoading(false)
        }
    }

    const handleImageChange = (url) => {
        eventBus.emit('TOUR_NEXT')
        setModelVideoFrontPic(url)
        setProjectId()
        setProjectName('未命名')
    }

    return (
        <div className={styles.Container}>
        {
            modelVideoFrontPic?
            <VideoImage
                key={modelVideoFrontPic}
                onUpload={uploadHandle}
                onBeforeUpload={beforeUpload}
            /> :
            <WaitUploadPage
                btnText={"上传模特图"}
                uploadTips={"建议图片尺寸不小于500*500，长宽比不超过2:1"}
                uploadExamples={hasPrivateAbility(PRIVATE_ABILITIES.VIDEO_TRYON_SUPPORT_BACK)?modelExamples: modelExamples2}
                onUpload={uploadHandle}
                onChange={handleImageChange}
                useImageType
            >
                <div className={styles.UploadBox_right}>
                    <div className={styles.UploadBox_right_}></div>
                </div>
            </WaitUploadPage>
        }
        </div>
    )
}

export default MiddleModelVideo