import React, { useEffect, useMemo, useState } from 'react';
import { Slider } from 'antd';
import styles from './RcSlider.module.scss'
import { colorPrimary } from '@utils/AppContext';

const Rcslider = (props) => {
    const [value, setValue] = useState(props.defaultValue || 0);
   
    useEffect(() => {
        setValue(props.value)
    }, [props.value])

    const onChange = (value) => {
        setValue(value)
        props.onChange && props.onChange(value)
    }

    const onAfterChange = (value) => {
        props.getRcsliderValue && props.getRcsliderValue(value)
    }

    // const trackStyle = useMemo(() => {
    //     let half = props.defaultValue
    //     if (value >= half) {
    //         return {
    //             left: '50%',
    //             width: Math.abs(value - half) + '%',
    //             background: '#326fff'
    //         }
    //     } else {
    //         return {
    //             left: 'auto',
    //             right: '50%',
    //             width: Math.abs(half - value) + '%',
    //             background: '#326fff'
    //         }
    //     }
    // }, [value, props.defaultValue])
  
    const railStyle = {
        background: '#d6d6d6'
    }

    return (
        <div className={styles.RcSlider}>
            <p><span>{props.title}</span><span>{value}</span></p>
            <Slider
                className={styles.Slider}
                disabled={props.disabled}
                min={props.min || 0}
                max={props.max || 100}
                value={value}
                tooltip={{ formatter: null }}
                // trackStyle={{background: props.color || colorPrimary}}
                // railStyle={railStyle}
                classNames={{tracks: styles.tracks, track: styles[`track_${props.type}`], handle: styles[`handle_${props.type}`]}}
                onChange={onChange}
                onAfterChange={onAfterChange}
            />
        </div>
    )
}

export default Rcslider