import { useEffect, useRef, useState } from "react"
import API from "@api/api"
import { Spin, message, Input } from "antd"
import styles from './index.module.scss'

export default function WechatScanForm (props) {
    const isMounted = useRef(true) // 判断组件是否已销毁
    const [loading, setLoading] = useState(false)
    const [wechatUrl, setWechatUrl] = useState()
    const sceneStrRef = useRef()
    const inviteCodeRef = useRef(String(sessionStorage.getItem('inviteCode') || ''))
    const [inviteCode, setinviteCode] = useState(String(sessionStorage.getItem('inviteCode') || ''))
    
    useEffect(() => {
        return () => {
            isMounted.current = false
        }
    }, [])

    const loopWechatStatus = () => {
        API.loginStatus({
            sceneStr: sceneStrRef.current,
            invitationCode: inviteCodeRef.current
        }).then(res => {
            if(res.code == 0) {
                setTimeout(() => props.loginCallback(res.data), 500)
            } else if(res.code == -5036) {
                if (!isMounted.current) return
                setTimeout(() => {
                    loopWechatStatus()
                }, 1000)
            } else if(res.code == -5035) {
                getWechatQrcode()
            } else {
                message.warning(res.message == '二维码已过期或不存在'?null: res.message)
                props.loginCallback()
            }
        })
    }
    const getWechatQrcode = () => {
        setLoading(true)
        API.wechatQrcode().then((res) => {
            if(res.code != 0) throw new Error(res.message)
            setWechatUrl(res.data.url)
            sceneStrRef.current = res.data.sceneStr
            loopWechatStatus()
        }).catch(err => {
            message.error(err.message)
        }).finally(() => {
            setLoading(false)
        })
    }
    useEffect(() => {
        getWechatQrcode()
    }, [])

    return (
        <div>
            <Spin spinning={loading}>
                <div className={styles.wechatCode}>
                { wechatUrl && <img src={wechatUrl} alt="" /> }
                </div>
            </Spin>
            <Input
                value={inviteCode}
                size="large"
                placeholder="邀请码（选填）"
                onChange={(e) => setinviteCode(e.target.value)}
                status=""
                prefix={<img style={{width: 16}} src={require('@assets/images/icon_Number.png')} alt="" />}
            />
            <p style={{marginTop: '20px'}}>微信扫码关注公众号完成登录/注册</p>
        </div>
    )
}