import { useEffect, useState } from 'react'
import styles from './index.module.scss'

const ClothTabs = (props) => {
    const [activeKey, setActiveKey] = useState(props.clothType)

    useEffect(() => {
        props.onChange(activeKey)
        if (props.clothType != activeKey) props.onProjectChange()
    }, [activeKey])

    return (
        <div className={styles.Container}>
            <div className={styles.TabHanlde}>
            {
                props.items.map(item => (
                    <div
                        key={item.key}
                        className={`${styles.Tab_bar} ${activeKey == item.key && styles.actived}`}
                        onClick={() => setActiveKey(item.key)}
                    >
                        { item.label }
                    </div>
                ))
            }
            </div>
            <div className={`
                ${styles.TabContent}
                ${activeKey != props.items[0].key && styles.activedNotFirst}
                ${props.hasRightComp && styles.hasRightComp}
            `}>
            {
                props.items.map(item => (
                    activeKey == item.key && 
                    <div
                        key={item.key}
                        className={styles.TabContentItem}
                    >
                        {item.children}
                    </div>
                ))
            }
            </div>
        </div>
    )
}

export default ClothTabs