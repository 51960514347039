
import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import './modelScale.scss';
import { fabric } from 'fabric';
import Utils from '@utils/utils'
import { colorPrimary } from '@utils/AppContext';

const devicePixel = window.devicePixelRatio > 1? window.devicePixelRatio: 1; // 获取屏幕的缩放比例，在不等于100%时，canvas取到的坐标和显出是不一样的，写到这里是为了滑轮缩放后这个值不变
const ModelScaleMirror = forwardRef((props, ref) => {
    const canvasRef = useRef(null);
    const canvas = useRef(null);
    const [width, setWidth] = useState(512);
    const [height, setHeight] = useState(512);
    const rectangleRef = useRef()
    const [activeObj, setaAtiveObj] = useState(null);

    const initializedRef = useRef(false)
    const [canvasIsReady, setCanvasIsReady] = useState(false)

    useEffect(() => {
        setWidth(props.size)
        setHeight(props.size)
    }, [props.size])

    useImperativeHandle(ref, () => ({
        handleClick
    }));

    useEffect(() => {
        const canvasInstance = new fabric.Canvas(canvasRef.current, {
            preserveObjectStacking: true,
        });

        canvas.current = canvasInstance;

        setCanvasIsReady(true)

        return () => {
            canvas.current.dispose();
        };
    }, [])

    useEffect(() => {
        if (!initializedRef.current) return
        
        rectangleRef.current.set(props.resultrect)
        rectangleRef.current.setCoords();
        canvas.current.renderAll();
    }, [props.resultrect])

    useEffect(() => {
        if (!canvasIsReady) return
        if (initializedRef.current) return
        if (!width || !height || !props.scaleFactorValue) return

        canvas.current.setDimensions({
            width: width / props.scaleFactorValue,
            height: height / props.scaleFactorValue
        });

        fabric.Image.fromURL(props.modelScaleOriBase64, (img) => {

            // 计算图像对象的位置
            const imgWidth = img.getScaledWidth();
            const imgHeight = img.getScaledHeight();
            const imgLeft = (canvas.current.width - imgWidth) / 2; // 图像左侧位置
            const imgTop = canvas.current.height - imgHeight; // 图像顶部位置

            // 修改缩放框角的样式
            img.set({
                // scale: scaleFactor,
                selectable: false,
                left: imgLeft,
                top: imgTop
            });

            const rectWidth = props.resultrect.width == 0?img.width: props.resultrect.width;
            const rectHeight = props.resultrect.height == 0?img.height: props.resultrect.height;

            const rect = new fabric.Rect({
                left: props.resultrect.left,
                top: props.resultrect.top,
                width: rectWidth,
                height: rectHeight,
                fill: 'transparent',
                borderColor: colorPrimary,
                // stroke: '#326fff',
                strokeWidth: 0,
                cornerColor: colorPrimary,
                transparentCorners: false,
                cornerSize: 6,
                resizable: true,
                lockRotation: true,
                lockSkewingX: true,
                lockSkewingY: true,
                cornerStyle: 'circle',
                hasControls: false,
            });

        
            canvas.current.setActiveObject(rect);
            setaAtiveObj(canvas.current.getActiveObject())
            rectangleRef.current = rect
            if(props.resultrect.height == 0) {
                rect.set({
                    left: imgLeft,
                    top: imgTop,
                });
            }
            canvas.current.add(img, rect);
            canvas.current.renderAll();

            initializedRef.current = true
        }, { left: 100, top: 100 });
    }, [props.scaleFactorValue, props.modelScaleOriBase64, width, height, props.resultrect, canvasIsReady]);

    const handleClick = () => {
        return new Promise((resolve, reject) => {
            // 使用getBoundingRect在图片像素为664x984时有精度问题
            // const boundingRect = rectangleRef.current.getBoundingRect();
    
            // const left = boundingRect.left;
            // const top = boundingRect.top;
            // const width = boundingRect.width;
            // const height = boundingRect.height;

            const left = rectangleRef.current.left
            const top = rectangleRef.current.top
            const width = rectangleRef.current.width
            const height = rectangleRef.current.height

            // 生成devicePixel倍的图片
            const base64Data = canvas.current.toDataURL({ quality: .95, left, top, width, height, multiplier: devicePixel, format: 'webp' })

            // 将图片缩小devicePixel倍
            if (devicePixel == 1) return resolve(base64Data)
            else Utils.resizeModelScale(base64Data, devicePixel).then(res => resolve(res))
        })
    };

    return (
        <div className='modelScale_mirror'>
            <canvas ref={canvasRef} ></canvas>
            <div style={{background: 'red'}} onClick={handleClick}>获取</div>
        </div>
    );
});

export default ModelScaleMirror;