import React, {useState, useEffect, useMemo, useContext} from 'react';
import '../aiEdit.scss'
import ReactCompareImage from 'react-compare-image';
import { AIEditContext } from '../AIEditContext';

const ImgComparer = (props) => {
    const {getImgWithWatermark} = useContext(AIEditContext)
    let scrollValue = 100 //缩放比例
    let initialX = 0 // 鼠标按下初始位置
    let initialY = 0
    let clipDistance = 0 //拖动条距离左边框位置
    let distanceX = 0 //img移动的距离
    let distanceY = 0
    const {
        leftImage,
        rightImage
    } = props
    const [leftImageWithWatermark, setLeftImageWithWatermark] = useState(leftImage)

    useEffect(() => {
        getImgWithWatermark(leftImage).then(base64 => setLeftImageWithWatermark(base64))
    }, [leftImage])

    useEffect(() => {
        if(leftImage) {
            const element = document.querySelector('[data-testid="container"]');
            const img1 = element.childNodes[0];
            const img2 = element.childNodes[1];
            const dragBar = element.childNodes[2];
            const dragBartip = document.getElementsByClassName('dragBartip')[0]
            dragBar.classList.add("dragBar");
            setTimeout(() => {
                dragBartip.style.left = `${parseInt(dragBar.style.left) - 33}px`
                dragBartip.style.visibility = 'visible'
            }, 150)

            element.addEventListener("mousedown", function(event) {
                // 禁用原来的事件监听
                event.stopPropagation();
                event.preventDefault();


                // 拖动条绑定新的监听
                if(event.target.closest(".dragBar")?.classList[0] == 'dragBar') {
                    event.preventDefault();
                    element.addEventListener("mousemove", handleMouseMove)
                } else {
                    // 点击图片
                    if (event.button === 0) {
                        const elementRect = element.getBoundingClientRect();
                        // 鼠标距离相对于图片左顶点位置
                        initialX = (event.clientX - elementRect.left) - parseInt(img1.style.left? img1.style.left: 0);
                        initialY = (event.clientY - elementRect.top) - parseInt(img1.style.top? img1.style.top: 0);

                        clipDistance = parseInt(dragBar.style.left) + 20;

                        // img1.style.removeProperty('clip')
                        // img2.style.removeProperty('clip')
                        element.style.cursor = 'grabbing';
                        element.addEventListener('mousemove', picMouseMove);
                    }
                }

                element.addEventListener("mouseup", handleMouseUp)
                document.addEventListener('mouseup', handleMouseUp);
                // element.addEventListener("mouseleave", handleMouseUp)
            }, true);

            function handleMouseMove(event) {
                event.preventDefault()
                const elementRect = element.getBoundingClientRect();
                
                // 拖动框距离展示区域左边距离
                clipDistance = event.clientX - elementRect.left;

                dragBar.style.left =  `${clipDistance - 20}px`;
                dragBartip.style.left = `${clipDistance - 20 -33}px`
                let clipLeft = clipDistance - parseInt(img1.style.left? img1.style.left: 0);
                img1.style.clip = `rect(auto, auto, auto, ${clipLeft}px)`; // (top, right, bottom, left)
                img2.style.clip = `rect(auto, ${clipLeft}px, auto, auto)`;
            }

            function picMouseMove(event) {
                event.preventDefault();
                const elementRect = element.getBoundingClientRect();
                // 鼠标当前相对于展示框位置 - initialX = 鼠标移动距离
                distanceX = (event.clientX - elementRect.left) - initialX;
                distanceY = (event.clientY - elementRect.top) - initialY;
        
                img1.style.left = `${distanceX}px`;
                img1.style.top = `${distanceY}px`;
                img2.style.left = `${distanceX}px`;
                img2.style.top = `${distanceY}px`;

                // console.log(clipDistance - distanceX)

                // 鼠标抬起时候才能赋值img的clip，若在移动中不断赋值会出现残影
                let clipLeft = clipDistance - parseInt(img1.style.left? img1.style.left: 0)
                img1.style.clip = `rect(auto, auto, auto, ${clipLeft}px)`;
                img2.style.clip = `rect(auto, ${clipLeft}px, auto, auto)`;
            }
                
            function handleMouseUp() {
                element.removeEventListener("mousemove", handleMouseMove);
                element.removeEventListener("mouseup", handleMouseUp);
                // element.removeEventListener("mouseleave", handleMouseUp);
                document.removeEventListener('mouseup', handleMouseUp);

                element.style.cursor = 'default';
                element.removeEventListener("mousemove", picMouseMove);
            }

            // 滚轮监听
            element.addEventListener('wheel', handleScroll)
            function handleScroll(event) {
                if (event.deltaY > 0) {
                    if(scrollValue > 100) {
                        scrollValue -= 10
                        scaleFun()
                    }
                } else {
                    if(scrollValue < 800) {
                        scrollValue += 10
                        scaleFun()
                    }
                }
                // if(scrollValue == 100) {
                //     scrollValue = 1
                // }
            }

            function scaleFun() {
                img1.style.width = `${scrollValue}%`
                img1.style.height = `${scrollValue}%`
                img2.style.width = `${scrollValue}%`
                img2.style.height = `${scrollValue}%`
            }
        }
    }, [leftImage])
   
    return (
        <div className='ImgComparer' onContextMenu={e => e.preventDefault()}>
            <div className="dragBartip">
                <img  src={require('@assets/images/original@2x.png')} alt="" />
                <img  src={require('@assets/images/effect@2x.png')} alt="" />
            </div>
           <ReactCompareImage
                leftImage={leftImageWithWatermark}
                rightImage={rightImage}
                sliderPositionPercentage={0.4}
                handleSize={40}
           />
        </div>
    )
}

export default ImgComparer