import { Button, Form, Input, message } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { useState, useEffect } from "react";
import Utils from '@utils/utils'
import eventBus from "@utils/eventBus";
import API from '@api/api'
import styles from './index.module.scss'
import PublicKey from '@utils/publicKey'
import VerityCode from "../VerifyCode";

export default function ForgotPwdForm (props) {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const mobile = Form.useWatch(['mobile'], form)
    
    const initialValues = {
        mobile: props.mobile || '',
        verifyCode: "",
        password: "",
        repeat: "",
    }

    const handleValidateMobile = () => {
        return form.validateFields(['mobile'])
    }

    const onSubmit = () => {
        return new Promise((resolve) => {
            form.validateFields().then(values => {
                setLoading(true)
                API.forgetPassword({
                    password: PublicKey.JSEncryptChange(values.password),
                    repeat: PublicKey.JSEncryptChange(values.repeat),
                    verifyCode: values.verifyCode,
                    mobile: values.mobile,
                }).then((res) => {
                    if(res.code != 0) throw new Error(res.message)
                    message.success('密码修改成功，请重新登录！')
                    props.onBack()
                }).catch(err => {
                    message.error(err.message)
                }).finally(() => {
                    setLoading(false)
                    resolve(true)
                })
            })
        })
    }
    const placeholderMessage = Utils.getCurrentDomainAndCheckTiktok() ? '请输入手机号或者邮箱' : '请输入手机号';
    return (
        <div>
            <div className={styles.GoBack} onClick={() => props.onBack()}>忘记密码</div>
            <Form
                autoComplete="off"
                form={form}
                initialValues={initialValues}
                onFinish={onSubmit}
            >
                <Form.Item
                    name="mobile"
                    validateFirst
                    validateTrigger="onBlur"
                    rules={[
                        { required: true, message: placeholderMessage, },
                        () => ({
                            validator(_, value) {
                                const isTikTokDomain = Utils.getCurrentDomainAndCheckTiktok();
                                if (isTikTokDomain){
                                    if (!Utils.validateContact(value) && !Utils.validateContact2(value)) return Promise.reject(new Error('您输入的账号格式错误'))
                                } else{
                                    if( !Utils.validateContact(value)) return Promise.reject(new Error('您输入的账号格式错误'))
                                }
                                return Promise.resolve()
                            }
                        })
                    ]}
                >
                    <Input
                        tabIndex="1"
                        size="large"
                        placeholder={placeholderMessage}
                        allowClear
                        prefix={<img style={{width: 16}} src={require('@assets/images/icon_Number.png')} alt="" />}
                    />
                </Form.Item>
                <Form.Item
                    name="verifyCode"
                    rules={[ { required: true, message: '请输入验证码', }, ]}
                >
                    <VerityCode mobile={mobile} onValidate={handleValidateMobile} />
                </Form.Item>
                <Form.Item
                    name="password"
                    validateFirst
                    validateTrigger="onBlur"
                    rules={[
                        { required: true, message: '请输入密码', },
                    ]}
                >
                    <Input.Password
                        tabIndex="4"
                        size="large"
                        placeholder="请输入您的密码"
                        autoComplete="new-password"
                        allowClear
                        prefix={<img style={{width: 16}} src={require('@assets/images/icon_Password.png')} alt="" />}
                        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    />
                </Form.Item>
                <Form.Item
                    name="repeat"
                    validateFirst
                    validateTrigger="onBlur"
                    rules={[
                        { required: true, message: '请再次输入您的密码', },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (getFieldValue('password') === value) return Promise.resolve();
                              return Promise.reject(new Error('两次输入的密码不一致'));
                            },
                        }),
                    ]}
                >
                    <Input.Password
                        tabIndex="5"
                        size="large"
                        placeholder="请再次输入您的密码"
                        autoComplete="new-password"
                        allowClear
                        prefix={<img style={{width: 16}} src={require('@assets/images/icon_Password.png')} alt="" />}
                        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                    />
                </Form.Item>
                <Form.Item 
                    style={{marginBottom: 10}}
                >
                    <Button
                        tabIndex="5"
                        size="large"
                        block
                        type="primary"
                        loading={loading}
                        htmlType="submit"
                    >
                        更新密码
                    </Button>
                </Form.Item>
            </Form>
            <p className={styles.backLogin}>想起密码，返回<span onClick={() => props.onBack()}>登录</span></p>
        </div>
    )
}