import { useEffect, useRef, forwardRef, useImperativeHandle, useState } from "react"
import { fabric } from 'fabric';
import Utils from '@utils/utils'
import useCanvasMove from "./hooks/useCanvasMove";
import useInteractive from './hooks/useInteractive'
import { Spin } from "antd";
import usePaint from "./hooks/usePaint";
import styles from './MattingOpt.module.scss'

const formartNumber = (number, digits=2) => {
    return Number(number.toFixed(digits))
}

const MattingOptCanvas = forwardRef((props, ref) => {
    const bgCanvasRef = useRef()
    const fabricBgCanvasRef = useRef()

    const canvasRef = useRef()
    const fabricCanvasRef = useRef()
    const [fabricCanvas, setFabricCanvas] = useState()
    
    const topCanvasRef = useRef()
    const fabricTopCanvasRef = useRef()
    
    const [loading, setLoading] = useState(false)

    useImperativeHandle(ref, () => ({
        resetCanvas,
        clearCanvas,
        undoCanvas,
        redoCanvas,
        getBlendWhiteMask,
    }))

    const displayScaleRef = useRef(props.displayScale)
    useEffect(() => {
        displayScaleRef.current = props.displayScale
    }, [props.displayScale])
    const getBlendedBlueMaskBase64 = () => {
        // blue mask 重新混合
        const objects = fabricCanvasRef.current.getObjects()
        const tempCanvas = new fabric.StaticCanvas(null, {
            width: fabricCanvasRef.current.width,
            height: fabricCanvasRef.current.height
        })
        tempCanvas.add(...objects)
        const blueRes = tempCanvas.toDataURL({ format: 'png', multiplier: 1 / displayScaleRef.current });
        props.setBlendedBlueMaskBase64(blueRes)
    }

    const addUndoHandle = (action) => {
        props.clearRedo()
        props.addUndo({
            logitsPath: getLogitsPath(),
            whiteMaskUrl: getWhiteMaskUrl(),
            viewportTransform: fabricCanvasRef.current.viewportTransform,
            canvasJson: fabricCanvasRef.current.toJSON([
                '_key', 'is_mask', 'unclearable', 'is_interactive_point', '_label',
                'selectable', 'hasBorders', 'is_circle_mouse', 'hoverCursor',
            ]),
            topCanvasJson: fabricTopCanvasRef.current.toJSON([
                '_key', 'is_mask', 'unclearable', 'is_interactive_point', '_label',
                'selectable', 'hasBorders', 'is_circle_mouse', 'hoverCursor',
            ]),
        })
    }

    const getBlendWhiteMask = (blueMask) => {
        return Utils.convertBlueImageToWhite(blueMask)
    }

    const { zoom, viewportOffset, resetCanvas, resizeCanvas } = useCanvasMove({
        instance: fabricCanvas,
        bgInstance: fabricBgCanvasRef.current,
        topInstance: fabricTopCanvasRef.current,
        moveable: props.moveable,
        zoom: props.zoom,
        maxZoom: props.maxZoom,
        minZoom: props.minZoom,
    })

    const {getLogitsPath, setLogitsPath, getWhiteMaskUrl, setWhiteMaskUrl} = useInteractive({
        instance: fabricCanvas,
        topInstance: fabricTopCanvasRef.current,
        interactiveMode: props.interactiveMode,
        zoom: props.zoom,
        // setInteractiveMode: props.setInteractiveMode,
        setLoading: setLoading,
        inputImageUrl: props.inputImageUrl,
        whiteMaskUrl: props.whiteMaskUrl,
        // setWhiteMaskBase64: props.setWhiteMaskBase64,
        setBlueMaskBase64: props.setBlueMaskBase64,
        displayScale: props.displayScale, // 图像缩小的比例
        addUndo: addUndoHandle, // 添加待撤销记录
        setUpdated: props.setUpdated,
        imageType: props.imageType,
        aiInteractiveNpy: props.aiInteractiveNpy,
    })

    const {setCircleMouse} = usePaint({
        instance: fabricCanvas,
        topInstance: fabricTopCanvasRef.current,
        paintMode: props.paintMode,
        zoom: props.zoom,
        paintSize: props.paintSize,
        setPaintSize: props.setPaintSize,
        eraseSize: props.eraseSize,
        setEraseSize: props.setEraseSize,
        // setWhiteMaskBase64: props.setWhiteMaskBase64,
        getBlendedBlueMaskBase64: getBlendedBlueMaskBase64,
        displayScale: props.displayScale, // 图像缩小的比例
        displayWidth: props.displayWidth,
        addUndo: addUndoHandle, // 添加待撤销记录
        setUpdated: props.setUpdated,
        imageType: props.imageType,
    })
    
    const clearCanvas = () => {
        // 清除logits_path
        setLogitsPath('')
        // 清除canvas path对象
        const objs = fabricCanvas.getObjects()
        const clearable = objs.filter(obj => !obj.get('unclearable'))
        fabricCanvas.remove(...clearable)
        fabricCanvas.renderAll()
        // 清除circleMouse和坐标点
        fabricTopCanvasRef.current.clear()
        // blue mask 重新混合
        getBlendedBlueMaskBase64()
    }

    const _restoreCanvas = (params) => {
        // 重置canvas位置
        const { viewportTransform } = params
        resizeCanvas(viewportTransform)

        setLogitsPath(params.logitsPath)
        setWhiteMaskUrl(params.whiteMaskUrl)
        // 重置canvas        
        // fabricTopCanvasRef.current.clear()
        fabricTopCanvasRef.current.loadFromJSON(params.topCanvasJson, () => {
            fabricTopCanvasRef.current.renderAll(); // 重新渲染 canvas
            const objs = fabricTopCanvasRef.current.getObjects()
            const circle = objs.find(item => item.get('is_circle_mouse'))
            if (circle) setCircleMouse(circle)
        })
        // fabricCanvasRef.current.clear()
        fabricCanvasRef.current.loadFromJSON(params.canvasJson, () => {           
            const objects = fabricCanvasRef.current.getObjects()
            
            // 需要重新scale mask，json会仅保留2位小数
            const maskImg = objects.find(item => item.get('is_mask'))
            maskImg.scale(displayScaleRef.current)
            fabricCanvasRef.current.renderAll()

            // blue mask 重新混合
            getBlendedBlueMaskBase64()
        })
    }

    const redoCanvas = (redo) => {
        props.addUndo({
            logitsPath: getLogitsPath(),
            whiteMaskUrl: getWhiteMaskUrl(),
            viewportTransform: fabricCanvasRef.current.viewportTransform,
            canvasJson: fabricCanvasRef.current.toJSON([
                '_key', 'is_mask', 'unclearable', 'is_interactive_point', '_label',
                'selectable', 'hasBorders', 'is_circle_mouse', 'hoverCursor',
            ]),
            topCanvasJson: fabricTopCanvasRef.current.toJSON([
                '_key', 'is_mask', 'unclearable', 'is_interactive_point', '_label',
                'selectable', 'hasBorders', 'is_circle_mouse', 'hoverCursor',
            ]),
        })

        const {params} = redo

        _restoreCanvas(params)
    }
    const undoCanvas = (undo) => {
        props.addRedo({
            logitsPath: getLogitsPath(),
            whiteMaskUrl: getWhiteMaskUrl(),
            viewportTransform: fabricCanvasRef.current.viewportTransform,
            canvasJson: fabricCanvasRef.current.toJSON([
                '_key', 'is_mask', 'unclearable', 'is_interactive_point', '_label',
                'selectable', 'hasBorders', 'is_circle_mouse', 'hoverCursor',
            ]),
            topCanvasJson: fabricTopCanvasRef.current.toJSON([
                '_key', 'is_mask', 'unclearable', 'is_interactive_point', '_label',
                'selectable', 'hasBorders', 'is_circle_mouse', 'hoverCursor',
            ]),
        })

        const {params} = undo

        _restoreCanvas(params)
    }

    useEffect(() => {
        props.setZoom(zoom)
    }, [zoom])
    useEffect(() => {
        props.setViewportOffset(viewportOffset)
    }, [viewportOffset])

    // 初始化inputImage&mask
    useEffect(() => {
        // 马赛克
        const patternImageBase64 = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAABHNCSVQICAgIfAhkiAAAADBJREFUOE9jfPP69X8GPEBYRASfNAPjqAHDIgz+AwG+iH7z5s1ZfPKMowYwDIMwAACuR1xZvSaHFwAAAABJRU5ErkJggg==';
        const pattern = new fabric.Pattern({
            source: patternImageBase64,
            repeat: 'repeat',
        })
        // 初始化canvas对象
        fabric.Image.fromURL(props.inputImageUrl, (img) => {
            img.scale(props.displayScale) // 背景展示完全
            img.set({
                hoverCursor: 'pointer',
                hasBorders: false,
                selectable: false
            }) // 背景不可选

            // 背景canvas
            const bgInstance = new fabric.Canvas(bgCanvasRef.current, {
                width: props.displayWidth,
                height: props.displayHeight,
                backgroundColor: pattern, // 加载马赛克背景
                backgroundImage: img, // 加载默认背景
                fireRightClick: false, // 启用右键
                stopContextMenu: true, // 禁用右键菜单
                renderOnAddRemove: false,
                isDrawingMode: false, // 禁用绘图模式
                selection: false, // 禁用选中对象
            })
            bgInstance.includeDefaultValues = false
            fabricBgCanvasRef.current = bgInstance

            // 顶部canvas
            const topInstance = new fabric.Canvas(topCanvasRef.current, {
                width: props.displayWidth,
                height: props.displayHeight,
                fireRightClick: false, // 启用右键
                stopContextMenu: true, // 禁用右键菜单
                renderOnAddRemove: true,
                isDrawingMode: false, // 禁用绘图模式
                selection: false, // 禁用选中对象
            })
            topInstance.includeDefaultValues = false
            fabricTopCanvasRef.current = topInstance

            // 主体canvas
            const instance = new fabric.Canvas(canvasRef.current, {
                width: props.displayWidth,
                height: props.displayHeight,
                fireRightClick: false, // 启用右键
                stopContextMenu: true, // 禁用右键菜单
                renderOnAddRemove: false,
            })
            instance.includeDefaultValues = false
            fabricCanvasRef.current = instance
            setFabricCanvas(instance)
        }, { crossOrigin: 'anonymous' })
        
        return () => {
            if (fabricBgCanvasRef.current) fabricBgCanvasRef.current.dispose()
            if (fabricCanvasRef.current) fabricCanvasRef.current.dispose()
            if (fabricTopCanvasRef.current) fabricTopCanvasRef.current.dispose()
        }
    }, [])

    // 重绘mask
    useEffect(() => {
        if (!fabricCanvas) return
        const objs = fabricCanvas.getObjects()
        const img = objs.find(item => item.get('is_mask'))
        if (img) fabricCanvas.remove(img)
        fabric.Image.fromURL(props.blueMaskBase64, (img) => {
            img.scale(displayScaleRef.current) // 背景展示完全
            img.set({
                hoverCursor: 'pointer',
                hasBorders: false,
                selectable: false,
                globalCompositeOperation: 'source-over',
            }) // 背景不可选
            img.set('is_mask', true)
            img.set('unclearable', true)
            fabricCanvas.add(img)
            fabricCanvas.renderAll()
            // blue mask 重新混合
            getBlendedBlueMaskBase64()
        })
    }, [props.blueMaskBase64, fabricCanvas])

    // 跟随页面resize缩放
    useEffect(() => {
        if (!fabricCanvas) return
        const canvasScaleX = props.displayWidth / fabricCanvas.width
        const canvasScaleY = props.displayHeight / fabricCanvas.height
        
        const bgImage = fabricCanvas.get('backgroundImage')
        if (bgImage) bgImage.scale(props.displayScale)
        fabricCanvas.forEachObject((item) => {
            if (item.type == 'image') item.scale(props.displayScale)
            else if (item.type == 'path') {
                item.scale(item.scaleX * canvasScaleX)
                item.set({
                    left: formartNumber(item.left * canvasScaleX),
                    top: formartNumber(item.top * canvasScaleX),
                })
            }
            item.setCoords() 
        })
        fabricCanvas.viewportTransform[4] = fabricCanvas.viewportTransform[4] * canvasScaleX
        fabricCanvas.viewportTransform[5] = fabricCanvas.viewportTransform[5] * canvasScaleY
        fabricCanvas.setDimensions({ width: props.displayWidth, height: props.displayHeight})
        fabricCanvas.renderAll()

        if (!fabricBgCanvasRef.current) return
        const bgImage2 = fabricBgCanvasRef.current.get('backgroundImage')
        if (bgImage2) bgImage2.scale(props.displayScale)
        fabricBgCanvasRef.current.viewportTransform[4] = fabricBgCanvasRef.current.viewportTransform[4] * canvasScaleX
        fabricBgCanvasRef.current.viewportTransform[5] = fabricBgCanvasRef.current.viewportTransform[5] * canvasScaleY
        fabricBgCanvasRef.current.setDimensions({ width: props.displayWidth, height: props.displayHeight})
        fabricBgCanvasRef.current.renderAll()

        if (!fabricTopCanvasRef.current) return
        const bgImage3 = fabricTopCanvasRef.current.get('backgroundImage')
        if (bgImage3) bgImage3.scale(props.displayScale)
        fabricTopCanvasRef.current.forEachObject((item) => {
            item.set({
                width: formartNumber(item.width * canvasScaleX),
                height: formartNumber(item.height * canvasScaleX),
                left: formartNumber(item.left * canvasScaleX),
                top: formartNumber(item.top * canvasScaleX),
            })
            item.setCoords()
        })
        fabricTopCanvasRef.current.viewportTransform[4] = fabricTopCanvasRef.current.viewportTransform[4] * canvasScaleX
        fabricTopCanvasRef.current.viewportTransform[5] = fabricTopCanvasRef.current.viewportTransform[5] * canvasScaleY
        fabricTopCanvasRef.current.setDimensions({ width: props.displayWidth, height: props.displayHeight})
        fabricTopCanvasRef.current.renderAll()
    }, [props.displayScale, props.displayWidth, props.displayHeight])

    return (
        <Spin spinning={loading} onContextMenu={e => e.preventDefault()}>
            <div className={styles.CanvasWrap}>
                <canvas ref={bgCanvasRef} />
                <canvas ref={canvasRef} />
                <canvas ref={topCanvasRef} />
            </div>
        </Spin>        
    )
})

export default MattingOptCanvas