import React, {useState, useEffect, useMemo, useContext} from 'react';
import { message, Col, Row, Select, Carousel, Divider, Checkbox } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { AppContext } from '@utils/AppContext';
import styles from './index.module.scss'
import { GenerateContext } from '@utils/GenerateContext';

const ModelFilterSelect = (props) => {
    const { modelFilter, setModelFilter } = useContext(GenerateContext);
    const [skin, setSkin] = useState([]);
    const [sex, setSex] = useState([]);
    const [age, setAge] = useState([]);
    const [posture, setPosture] = useState([])

    const optFactory = (opt, placeholder) => {
        return {
            mode: "multiple",
            allowClear: true,
            showSearch: false,
            style: {width: props.width || props.showLabel ? 'calc(100% - 60px)' : '100%'},
            size: 'small',
            maxTagCount: 'responsive',
            placeholder: placeholder,
            options: opt
        }
    }

    const sexOpts = [{
        value: 'female',
        label: '女',
    }, {
        value: 'male',
        label: '男',
    }]

    const ageOpts = [{
        value: 'adult',
        label: '成人',
    }, {
        value: 'children',
        label: '儿童',
    // },  {
    //     value: 'oldman',
    //     label: '老年',
    }]

    const skinOpts = [{
        label: '黄',
        value: 'yellow',
    }, {
        label: '白',
        value: 'white',
    }, {
        label: '黑',
        value: 'black',
    // }, {
    //     label: '棕',
    //     value: 'brown',
    }]

    useEffect(() => {
        setSkin([...modelFilter?.skin || []])
        setSex([...modelFilter?.sex || []])
        setAge([...modelFilter?.age || []])
        setPosture([...modelFilter?.posture || []])
    }, [modelFilter])
    
    const updateConfigModelFilter = (filter) => {
        setModelFilter(pre => ({ ...pre, ...filter }))
    }

    const handleChange2 = (value, key) => {
        switch(key) {
            case 1:
                updateConfigModelFilter({sex: [...value]})
                break;
            case 2:
                updateConfigModelFilter({age: [...value]})
                break;
            case 3:
                updateConfigModelFilter({skin: [...value]})
                break;
            case 4:
                updateConfigModelFilter({posture: [...value]})
                break;
        }
    }

    const tagRender = (props) => {
        const { label } = props;
        return <span className={styles.ModelFilterSelect_tag}>{label}</span>
    }
    
    return (
        <Row className={styles.ModelFilterSelect} style={{maxWidth: '800px'}} gutter={10}>
            {
                !props.showLabel &&
                <>
                <Col span={8}>
                    { props.showLabel && <label htmlFor="modal-sex">性别</label>}
                    <Select id='modal-sex' {...optFactory(sexOpts, '性别')} value={sex} onChange={v => handleChange2(v, 1)} tagRender={tagRender} />
                </Col>
                <Col span={8}>
                    { props.showLabel && <label htmlFor="modal-skin">肤色</label>}
                    <Select id='modal-skin' {...optFactory(skinOpts, '肤色')} value={skin} onChange={v => handleChange2(v, 3)} tagRender={tagRender} />
                </Col>
                <Col span={8}>
                    { props.showLabel && <label htmlFor="modal-age">年龄</label>}
                    <Select id='modal-age' {...optFactory(ageOpts, '年龄')} value={age} onChange={v => handleChange2(v, 2)} tagRender={tagRender} />
                </Col>
                
                </>
            }
        </Row>
    )
}

export default ModelFilterSelect