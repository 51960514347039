import { createContext, useEffect, useState } from "react";
import { fabric } from 'fabric';

export const AIEditContext = createContext();

export const AIEditContextProvider = ({children}) => {
    const [needPay, setNeedPay] = useState(true)
    const [aiEditUrl, setAIEditUrl] = useState()
    const [adjustLightFlag, setAdjustLightFlag] = useState(true)
    const [highDefult, setHighDefult] = useState(50)
    const [rangeDefult, setRangeDefult] = useState(50)
    const [initialized, setInitialized] = useState(false)
    const [eraserSize, setEraserSize] = useState(20);
    const [operationKey, setOperationKey] = useState('eraser');
    const [isDraw, setIsDraw] = useState([]);
    const [isLoading2, setIsLoading2] = useState(false);
    const [isLoading1Blur, setIsLoading1Blur] = useState(false)
    const [isLoading2Blur, setIsLoading2Blur] = useState(false)
    const [nFace, setNFace] = useState(1)
    const [eraseType, setEraseType] = useState('draw')

    // 添加水印
    const addWatermark = (canvas) => {
        return new Promise(resolve => {
            fabric.Image.fromURL(require('@assets/images/icon_watermark.png'), img => {
                img.scale(canvas.height * 0.06 / img.height)
                img.set({
                    selectable: false,
                    x: 0,
                    y: 0
                })
                canvas.add(img)
                canvas.renderAll()
                resolve()
            })
        })
    }

    // 获得
    const getImgWithWatermark = (img) => {
        return new Promise(resolve => {
            fabric.Image.fromURL(img, img => {
                const tempCanvas = new fabric.StaticCanvas(null, {
                    width: img.width,
                    height: img.height,
                })
                tempCanvas.setBackgroundImage(img, () => {
                    tempCanvas.renderAll()
                    addWatermark(tempCanvas).then(() => {
                        const base64 = tempCanvas.toDataURL({ format: 'png' })
                        resolve(base64)
                    })
                })
            }, { crossOrigin: 'anonymous' })
        })
    }

    return (
        <AIEditContext.Provider value={{
            needPay, setNeedPay,
            aiEditUrl, setAIEditUrl,
            addWatermark,
            getImgWithWatermark,
            adjustLightFlag, setAdjustLightFlag,
            highDefult, setHighDefult,
            rangeDefult, setRangeDefult,
            initialized, setInitialized,
            eraserSize, setEraserSize,
            operationKey, setOperationKey,
            isDraw, setIsDraw,
            isLoading2, setIsLoading2,
            isLoading1Blur, setIsLoading1Blur,
            isLoading2Blur, setIsLoading2Blur,
            nFace, setNFace,
            eraseType, setEraseType
        }}>
            {children}
        </AIEditContext.Provider>
    )
}