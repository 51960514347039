import React, {useState, useEffect, lazy, useRef, useContext} from 'react';
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import MobileLayout from "../layout/mobile";
import styles from './mobile.module.scss'

const MobileHead = lazy(() => import('@view/mobilePage/components/head'))

const Home = lazy(() => import('@view/mobilePage/Home')) // 首页
const PersonalCenter = lazy(() => import('@view/mobilePage/PersonalCenter')) // 个人中心
const ScanCode = lazy(() => import('@view/mobilePage/ScanCode')) // 体验完整内容
const Payment = lazy(() => import('@view/mobilePage/Payment')) // 支付中心
const Cashback = lazy(() => import('@view/mobilePage/Cashback')) // 返现记录
const Withdrawal = lazy(() => import('@view/mobilePage/Withdrawal')) // 提现记录
const MemberRecord = lazy(() => import('@view/mobilePage/Member')) // 消费记录
const OrderRecord = lazy(() => import('@view/mobilePage/Order')) // 订单记录

function LocalLayout () {
    return (
        <>
        <MobileHead />
        <div className={styles.container}>
            <Outlet />
        </div>
        </>
    )
}

export default function MobileRoute () {

    return (
        <Routes>
            <Route path="/" element={<MobileLayout />}>
                <Route path="" element={<LocalLayout />}>
                    <Route path="" element={<Home />}></Route>
                    <Route path="personalCenter" element={<PersonalCenter />}></Route>
                    <Route path="scanCode" element={<ScanCode />}></Route>
                    <Route path="payment" element={<Payment />}></Route>
                    <Route path="cashback" element={<Cashback />}></Route>
                    <Route path="withdrawal" element={<Withdrawal />}></Route>
                    <Route path="member" element={<MemberRecord />}></Route>
                    <Route path="order" element={<OrderRecord />}></Route>
                    <Route path="*" element={<Navigate replace to="/" />} />
                </Route>
            </Route>
        </Routes>
    )
}