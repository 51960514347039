import { useState, useContext, useEffect, useRef, useMemo, useCallback } from "react"
import { maskPrediction } from "@utils/workflowOfPrediction"
import { AppContext } from '@utils/AppContext';
import { GenerateContext } from "@utils/GenerateContext"
import Utils from "@utils/utils";
import { Modal, Button, message } from "antd";
import MattingOpt from '@view/compoents/MattingOpt/MattingOpt';
import ModelScale from '@view/page/components/modelScale2'
import styles from './MiddleComp.module.scss'
import { CLOTH_TYPE, IMAGE_TYPE, UPLOAD_SOURCE } from "@utils/CONST";
import {forwardRef, useImperativeHandle} from "react"
import SubCompCommodity from "../SubCompCommodity"
import SubCompModel from "../SubCompModel"
import SubCompClothes from "../SubCompClothes"
import {workflowOfUpload} from '@utils/workflowOfUpload'
import PSAIModal from "@PSAIComponents/PSAIModal";
import Example from '@assets/json/example.json'
import SampleSegment from '@assets/json/sample-segment.json'
import API from '@api/api'
import eventBus from "@utils/eventBus";

const MiddleComp = forwardRef((props, ref) => {
    const isMounted = useRef(true) // 判断组件是否已销毁
    const {
        setGlobalLoading,
    } = useContext(AppContext);
    
    const {
        setModelFilter,
        setCommodityFilter,
        initialized, setInitialized, // taskcache初始化完成
        imageType, // model/dressform/clothes...
        inputImage, setInputImage, // 原始输入图： 真人/人台/AI试衣-套装
        inputImageTop, setInputImageTop, // 原始输入图：AI试衣-上装
        inputImageBottom, setInputImageBottom, // 原始输入图：AI试衣-下装
        dragScaleRef, dragScale, setDragScale, // 商品+softedge布局等
        aiInteractiveNpy, setAiInteractiveNpy, // npy-套装
        aiInteractiveNpy1, setAiInteractiveNpy1, // npy-上身
        aiInteractiveNpy2, setAiInteractiveNpy2, // npy-下身
        maskAlgoIdRef, // mask算法id-套装
        maskAlgoId1Ref, // mask算法id-上身
        maskAlgoId2Ref, // mask算法id-下身
        segmentResult, setSegmentResult, // 分割结果列表-套装
        segmentResult1, setSegmentResult1, // 分割结果列表-上身
        segmentResult2, setSegmentResult2, // 分割结果列表-下身
        outputMaxSize, setOutputMaxSize, // 决定商品生成参数upscale_enable是1/0
        projectId, setProjectId, // 生成的项目id，暂时没什么用
        projectName, setProjectName, // 生成的项目名
        createNum, setCreateNum, // 生成数量
        clothType, setClothType, // 来自服装类型的选择，仅用于衣服图的区分
        subBgId, setSubBgId, // 场景内部子index，目前只能为-1
        // 商品自定义尺寸
        imgWidthRef, imgWidth, setImgWidth,
        imgHeightRef, imgHeight, setImgHeight,
        imgRatioRef, imgRatio, setImgRatio,
        originCropParamsRef,
        setOriginCropParams,
        modelScaleRef,
        setModelScale,
        // 清除taskCache
        disableCacheRef,
        setImageAntispamId,
        ltXRef, setLtX,
        ltYRef, setLtY,
        scaleRateRef, setScaleRate,
        coordDragRef, setCoordDrag,
        clothLoadingFlag,
        clothLoadingFlag2,
        setClothLoadingFlag,
        setClothLoadingFlag2,
        setGlobaluploadLoading,
    } = useContext(GenerateContext)

    const [originBase64, setOriginBase64] = useState() // 套装
    const [originBase64_1, setOriginBase64_1] = useState() // 上身
    const [originBase64_2, setOriginBase64_2] = useState() // 下身

    const commodityRef = useRef()

    useImperativeHandle(ref, () => ({
        loadNewEdge: (url) => {
            commodityRef.current.loadNewEdge(url)
        },
        composeOpt,
    }))

    // bodyPart 1-上身 2-下身
    const getComposeInputImage = useCallback((bodyPart=1) => {
        if (clothType == CLOTH_TYPE.SUIT) {
            if (bodyPart == 1) return inputImageTop
            else return inputImageBottom
        } else {
            return inputImage
        } 
    }, [clothType, inputImage, inputImageTop, inputImageBottom])
    const setComposeInputImage = (bodyPart, value) => {
        if (clothType == CLOTH_TYPE.SUIT) {
            if (bodyPart == 1) setInputImageTop(value)
            else setInputImageBottom(value)
        } else {
            setInputImage(value)
        }
    }
    const getComposeOriBase64 = useCallback((bodyPart=1) => {
        if (clothType == CLOTH_TYPE.SUIT) {
            if (bodyPart == 1) return originBase64_1
            else return originBase64_2
        } else {
            return originBase64
        }
    }, [clothType, originBase64_1, originBase64_2, originBase64])
    const setComposeOriBase64 = (bodyPart, value) => {
        if (clothType == CLOTH_TYPE.SUIT) {
            if (bodyPart == 1) setOriginBase64_1(value)
            else setOriginBase64_2(value)
        } else {
            setOriginBase64(value)
        }
    }
    const getCurrentSegmentResult = useCallback((bodyPart) => {
        consoleLog('getCurrentSegmentResult', clothType, bodyPart)
        if (clothType == CLOTH_TYPE.SUIT) {
            if (bodyPart == 1) return segmentResult1
            else return segmentResult2
        } else {
            return segmentResult
        }
    }, [clothType, segmentResult1, segmentResult2, segmentResult])
    const setCurrentSegmentResult = (bodyPart, value) => {
        consoleLog('setCurrentSegmentResult', clothType, bodyPart)
        if (clothType == CLOTH_TYPE.SUIT) {
            if (bodyPart == 1) setSegmentResult1(value)
            else setSegmentResult2(value)
        } else {
            setSegmentResult(value)
        }
    }
    const getCurrentNpy = useCallback((bodyPart) => {
        if (clothType == CLOTH_TYPE.SUIT) {
            if (bodyPart == 1) return aiInteractiveNpy1
            else return aiInteractiveNpy2
        } else {
            return aiInteractiveNpy
        }
    }, [clothType, aiInteractiveNpy1, aiInteractiveNpy2, aiInteractiveNpy])
    const setCurrentNpy = (bodyPart, value) => {
        consoleLog('setCurrentNpy', clothType, bodyPart)
        if (clothType == CLOTH_TYPE.SUIT) {
            if (bodyPart == 1) setAiInteractiveNpy1(value)
            else setAiInteractiveNpy2(value)
        } else {
            setAiInteractiveNpy(value)
        }
    }
    const setCurrentMaskAlgoIdRef = (bodyPart, value) => {
        consoleLog('setCurrentMaskAlgoIdRef', clothType, bodyPart)
        if (clothType == CLOTH_TYPE.SUIT) {
            if (bodyPart == 1) maskAlgoId1Ref.current = value
            else maskAlgoId2Ref.current = value
        } else {
            maskAlgoIdRef.current = value
        }
    }

    const consoleLog = (module, clothType, bodyPart) => {
        // if (clothType == CLOTH_TYPE.SUIT) {
        //     console.log(module, '套装', bodyPart == 1 ? '上装' : '下装')
        // } else {
        //     console.log(module, clothType == 0 ? '上衣' : clothType == 1 ? '下装' : clothType == 2 ? '裙子' : clothType == 3 ? '套装' : clothType)
        // }
    }

    useEffect(() => {
        return () => {
            isMounted.current = false
        }
    }, [])

    const onNpyChange = (npy, bodyPart) => {
        setCurrentNpy(bodyPart, npy)
    }

    const onFileChange = async ({file, url}, filter={}, isTryon, bodyPart) => {
        return new Promise((resolve, reject) => {
            Utils.convertFileToBase64(file).then(base64 => {
                setComposeInputImage(bodyPart, url)
                setComposeOriBase64(bodyPart, base64)
                if (isTryon) {
                    const {output, input, id} = filter
                    onSegmentResultChange(bodyPart, output, input, id)
                    return resolve(true)
                } else {
                    setModelFilter({...filter})
                    setTimeout(async () => {
                        if (originCropParamsRef.current) {
                            const [w, h, x1, y1, x2, y2] = originCropParamsRef.current
                            base64 = await Utils.cropImageBase64(base64, x1, y1, x2-x1, y2-y1)
                        }
                        try {
                            // console.log('======自动构图中======')
                            const res = await getMaskAndNpy(bodyPart, file, base64)
                            if (res) return resolve(true)
                            else return resolve(false)
                        } catch (err) {
                            console.error(err)
                            return resolve(false)
                        }
                    }, 500)
                }
            })
        })
    }

    const getSampleParams = (name, bodyPart) => {
        let type = imageType
        if (imageType == IMAGE_TYPE.CLOTHES) {
            if (clothType == CLOTH_TYPE.SUIT) {
                if (bodyPart == 1) type = 'clothes_short'
                else type = 'clothes_pants'
            } else {
                type = 'clothes_skirt'
            } 
        }
        const prefix = SampleSegment.prefix
        const sample = Example.find(item => item.name == name)
        const sampleSegment = SampleSegment[type]?.find(item => item.name == name)
        if (!sample || !sampleSegment) return false
        const input = sample.source
        const crop = sampleSegment.crop
        const output = sampleSegment.output
        let filter = {}
        if (type == IMAGE_TYPE.MODEL) {
            if (crop.length > 6) {
                // 返回坐标追加一个维度，性别信息，0为male, 1为female，仅用于真人对web端模特的分类
                // 性别后面增加一个维度: age, 如果年龄15岁，判断为儿童，不再筛选性别
                const _ages = crop[7]
                let sex = []
                let age = ['adult']
                let sexAvailable = true
                if (_ages) { // 如果年龄15岁，判断为儿童，不再筛选性别
                    if (_ages[0] > 15) {
                        age = ['adult']
                    } else {
                        age = ['children']
                        sexAvailable = false
                    }
                }
                if (sexAvailable) {
                    const _sex = crop[6]
                    if (_sex == 1) {
                        sex = ['female']
                    } else {
                        sex = ['male']
                    }
                }
                filter = { sex: sex, age: age }
            }
        } else if (type == IMAGE_TYPE.COMMODITY) {
            filter = { commodityType: sampleSegment.category }
        }
        return { input, output, crop, filter }
    }

    // 文件上传前的处理
    const beforeUpload = async (file, sampleName, bodyPart=1) => {
        try {
            if (sampleName) {
                const params = getSampleParams(sampleName, bodyPart)
                if (params) {
                    const { input, output, crop, filter } = params
                    let base64 = await Utils.convertFileToBase64(file)
                    setComposeInputImage(bodyPart, input)
                    setComposeOriBase64(bodyPart, base64)
                    if (imageType == IMAGE_TYPE.COMMODITY) {
                        setCommodityFilter({...filter})
                        onDragChange(dragScaleRef.current.filter(item => item.name != 'ori')) // 清除dragScale中原图的配置，避免重新上传原图尺寸出错
                    } else if (imageType != IMAGE_TYPE.CLOTHES) {
                        setModelFilter({...filter})
                        setImageAntispamId()
                        // 保存抠图优化的推荐区域，清除缓存区域
                        originCropParamsRef.current = crop
                        setOriginCropParams(crop)
                        // 重置构图优化区域
                        modelScaleRef.current = null
                        setModelScale(modelScaleRef.current)
                        // 清除模特偏移
                        ltXRef.current = null
                        setLtX(ltXRef.current)
                        ltYRef.current = null
                        setLtY(ltYRef.current)
                        coordDragRef.current = null
                        setCoordDrag(coordDragRef.current)
                        scaleRateRef.current = 1
                        setScaleRate(scaleRateRef.current)
                    }
                    onSegmentResultChange(bodyPart, [...output], input, `${imageType}_sample`)
                    return true
                }
            }
            // 检查图像是否符合要求
            const validate = await Utils.validateImageFile(file)
            if (!validate) return false
            // 图片最大尺寸重置2048
            const resizeRes = await Utils.resizeImageFileMaxByAliyunBy(file, 2048)
            

            if (!resizeRes || !resizeRes.success) throw new Error(resizeRes?.message)
            file = resizeRes.file
            // 商品图-检查图片是否合规；衣服人台-走算法获得年龄等数据；衣服图-走算法获得算法id等数据
            if(bodyPart == 1) setClothLoadingFlag(true)
            if(bodyPart == 2) setClothLoadingFlag2(true)
            if (imageType == IMAGE_TYPE.COMMODITY) {
                // 检查上传图片是否符合安全规范
                // AAA
                const legalRes = await Utils.checkImageIslegal(file, 1, UPLOAD_SOURCE.COMMODITY) //审核
                if (!legalRes) return false
                if(!legalRes.isLegal) {
                    const res = await illegalAndReuploadHandle(sampleName, bodyPart)
                    return res
                } else {
                    setImageAntispamId(legalRes.imageAntispamId)
                    const res = await onFileChange({ file, url: legalRes.data })
                    return res
                }
            } else {
                // AAA
                const res = await workflowOfUpload(file, imageType, {clothType, bodyPart})
                if (!res) return false
                if(!res.isLegal) {
                    const res = await illegalAndReuploadHandle(sampleName, bodyPart)
                    return res
                }
                const {isTryon, result, output, imageAntispamId, filename} = res
                setImageAntispamId(imageAntispamId)
                const res2 = await onFileChange({ file, url: filename }, result, isTryon, bodyPart)
                // 保存抠图优化的推荐区域，清除缓存区域
                originCropParamsRef.current = output
                setOriginCropParams(output)
                modelScaleRef.current = null
                setModelScale(modelScaleRef.current)
                // 清除模特偏移
                ltXRef.current = null
                setLtX(ltXRef.current)
                ltYRef.current = null
                setLtY(ltYRef.current)
                scaleRateRef.current = 1
                setScaleRate(scaleRateRef.current)
                return res2
            }
        } catch (err) {
            console.error(err.message)
            message.warning(err.message)            
            return false
        }
    }

    const getMaskAndNpy = async (bodyPart, file, base64) => {
        const url = await Utils.uploadFile(file) // 需要改成file转url，不需要走审核
        if (!url) return
        const res0 = await API.personCheck({imageUrl: url})
        if (res0.code != 0) return message.warning(res.message)
        const isPerson = res0.data
        const res = await maskPrediction(url, imageType, () => !isMounted.current, isPerson)
        if (!res) return
        const {output, input, id} = res
        if (imageType == IMAGE_TYPE.COMMODITY) { // 根据mask获取商品分类
            const res = await API.getCommodityCategory({image: url, mask: output[4]})
            if (res.code == 0) {
                setCommodityFilter(pre => ({
                    ...pre,
                    commodityType: res.data[0]
                }))
            }
        }
        // 清除模特偏移
        ltXRef.current = null
        setLtX(ltXRef.current)
        ltYRef.current = null
        setLtY(ltYRef.current)
        coordDragRef.current = null
        setCoordDrag(coordDragRef.current)
        scaleRateRef.current = 1
        setScaleRate(scaleRateRef.current)
        onDragChange(dragScaleRef.current.filter(item => item.name != 'ori')) // 清除dragScale中原图的配置，避免重新上传原图尺寸出错
        onSegmentResultChange(bodyPart, output, input, id)
        return true
    }
    const onSegmentResultChange = (bodyPart, output, input, id) => {
        if (output && output.length > 0) {
            Utils.getLongestSideLength(output[0]).then(({longestSide}) => setOutputMaxSize(longestSide))
            setCurrentMaskAlgoIdRef(bodyPart, id)
            setCurrentNpy(bodyPart, null)
            setCurrentSegmentResult(bodyPart, output)
        } else {
            setCurrentMaskAlgoIdRef(bodyPart, null)
            setCurrentNpy(bodyPart, null)
            setCurrentSegmentResult(bodyPart, [])
        }
    }

    // 抠图优化弹窗
    const mattingOptRef = useRef()
    const [isMattingLoading, setIsMattingLoading] = useState(false)
    const [isMattingOptOpen, setIsMattingOptOpen] = useState(false)
    const mattingOptSave = (bodyPart) => {
        setIsMattingLoading(true)
        return mattingOptRef.current.saveHandle().then(changed => {
            if (changed) {
                const { blueMask, whiteMask, blendedImage } = changed
                updateResultMask(blueMask, whiteMask, blendedImage, bodyPart) // 修改output[1,2,4]
                if (imageType == IMAGE_TYPE.COMMODITY) { // 重新根据mask获取商品分类
                    API.getCommodityCategory({image: getCurrentSegmentResult()[0], mask: whiteMask}).then(res => {
                        if (res.code == 0) {
                            setCommodityFilter(pre => ({
                                ...pre,
                                commodityType: res.data[0]
                            }))
                        }
                    })
                }
            }
            setIsMattingOptOpen(false)
        }).catch(err => {
            message.error(err.message)
        }).finally(() => {
            setIsMattingLoading(false)
        })
    }
    const updateResultMask = (blueMask, whiteMask, blendedImage, bodyPart) => {
        if (!blueMask || !whiteMask || !blendedImage) return
        setGlobalLoading('加载中')
        const mask2Promise = imageType == IMAGE_TYPE.COMMODITY ? 
            Utils.getTrimmedImageBase64(blendedImage).then(({base64}) => Utils.uploadBase64Image(base64)) :
            Utils.uploadBase64Image(blendedImage)
        mask2Promise.then(mask2Url => {
            if (!mask2Url) return false
            // 需要同步替换掉output
            setCurrentSegmentResult(bodyPart, output => {
                output[1] = blueMask
                output[2] = mask2Url
                output[4] = whiteMask
                return [...output]
            })
        }).catch(err => {
            message.error(err.message)
        })
    }
    // 构图优化弹窗
    const composeOptRef = useRef()
    const [isComposeLoading, setIsComposeLoading] = useState(false)
    const [isComposeOptOpen, setIsComposeOptOpen] = useState(false)
    const composeOptClose = () => {
        setIsComposeLoading(false)
        setIsComposeOptOpen(false)
    }
    const composeOptSave = async (bodyPart) => {
        try {
            setIsComposeLoading(true)
            const res = await composeOptRef.current.handleClick()
            if (!res) return
            const validate = await Utils.validateImageFile(res.file)
            if (!validate) return
            setIsComposeOptOpen(false)
            setGlobalLoading('抠图中')
            return await getMaskAndNpy(bodyPart, res.file, res.base64)
        } finally {
            setIsComposeLoading(false)
            setGlobalLoading(false)
        }
    }
    const composeOptReset = () => {
        composeOptRef.current.handleReset()
    }
    const illegalAndReuploadHandle = async (sampleName, bodyPart) => {
        return new Promise((resolve, reject) => {
            PSAIModal.confirm({
                content: '经检测，您上传的内容涉嫌违规，已进行屏蔽处理，人工审核会对图片进行复核。',
                okText: '重新上传',
                cancelText: '取消',
                onOk() {
                    Utils.openFile().then((res) => {
                        if(res) {
                            beforeUpload(res, sampleName, bodyPart).then(resolve)
                        } else {
                            resolve(false)
                        }
                    }).catch(err => {
                        resolve(false)
                    })
                },
                onCancel() {
                    resolve(false)
                },
            })
        })
    }
    const uploadHandle = async (file, sampleName, bodyPart=1) => {
        eventBus.emit('TOUR_PAUSE')
        const res = await beforeUpload(file, sampleName, bodyPart)
        if(bodyPart == 1) setClothLoadingFlag(false)
        if(bodyPart == 2) setClothLoadingFlag2(false)
        setGlobaluploadLoading(false)
        setGlobalLoading(false)
        if (!res) {
            eventBus.emit('TOUR_PLAY')
            return false
        } else {
            eventBus.emit('TOUR_NEXT')
            setProjectId()
            setProjectName('未命名')
            return true
        }
    }
    // 重新上传
    const reUploadHandle = async (file, bodyPart=1) => {
        const res = await beforeUpload(file, null, bodyPart)
        if(bodyPart == 1) setClothLoadingFlag(false)
        if(bodyPart == 2) setClothLoadingFlag2(false)
        setGlobaluploadLoading(false)
        setGlobalLoading(false)
        if (!res) {
            return false
        } else {
            setProjectId()
            setProjectName('未命名')
            return true
        }
    }
    // 删除上传图，重新上传
    const deleteOpt = async (bodyPart) => {
        setComposeInputImage(bodyPart)
        setComposeOriBase64(bodyPart)
        onSegmentResultChange(bodyPart, [])
        setProjectId()
        setProjectName('未命名')
    }
    // 抠图优化
    const mattingOpt = (bodyPart=1) => {
        setIsMattingOptOpen(bodyPart)
    }
    // 构图优化
    const composeOpt = async (bodyPart=1) => {
        if (!segmentResult || segmentResult.length == 0) return false
        if (getComposeOriBase64(bodyPart)) return setIsComposeOptOpen(bodyPart)
        // 首先应该取inputImage，为了兼容取segementResult[0]
        const url = getComposeInputImage(bodyPart) || getCurrentSegmentResult(bodyPart)[0]
        const base64 = await Utils.urlToBase64(url)
        setComposeOriBase64(bodyPart, base64)
        setIsComposeOptOpen(bodyPart)
    }
    const onDragChange = (dragScale) => {
        dragScaleRef.current = dragScale
        setDragScale(dragScaleRef.current)
    }
    const onResetProject = () => {
        setComposeInputImage(1)
        setComposeOriBase64(1)
        onSegmentResultChange(1, [])
        setComposeInputImage(2)
        setComposeOriBase64(2)
        onSegmentResultChange(2, [])
        console.log('===setProjectId null in onResetProject===')
        setProjectId()
        setProjectName('未命名')
    }

    return (
        <div style={{width: '100%', height: '100%', paddingLeft: '4px', paddingRight: `${imageType == IMAGE_TYPE.CLOTHES ? '0px' : '4px'}`}}>
            {
                !initialized ? "" :
                imageType == IMAGE_TYPE.CLOTHES ?
                (
                    <SubCompClothes
                        imageType={imageType}
                        aiInteractiveNpy={aiInteractiveNpy}
                        aiInteractiveNpy1={aiInteractiveNpy1}
                        aiInteractiveNpy2={aiInteractiveNpy2}
                        segmentResult={segmentResult}
                        segmentResult1={segmentResult1}
                        segmentResult2={segmentResult2}
                        reUpload={reUploadHandle}
                        deleteOpt={deleteOpt}
                        mattingOpt={mattingOpt}
                        composeOpt={composeOpt}
                        onBeforeUpload={uploadHandle}
                        onNpyChange={onNpyChange}
                        onResetProject={onResetProject}
                    />
                ) :
                imageType == IMAGE_TYPE.COMMODITY ?
                (
                    <SubCompCommodity
                        ref={commodityRef}
                        imageType={imageType}
                        imgRatio={imgRatio}
                        imgWidth={imgWidth}
                        imgHeight={imgHeight}
                        aiInteractiveNpy={aiInteractiveNpy}
                        segmentResult={segmentResult}
                        dragScale={dragScale}
                        onDragChange={onDragChange}
                        reUpload={reUploadHandle}
                        deleteOpt={deleteOpt}
                        mattingOpt={mattingOpt}
                        composeOpt={composeOpt}
                        onBeforeUpload={uploadHandle}
                        onNpyChange={onNpyChange}
                    />
                ) :
                (
                    <SubCompModel
                        imageType={imageType}
                        aiInteractiveNpy={aiInteractiveNpy}
                        segmentResult={segmentResult}
                        reUpload={reUploadHandle}
                        deleteOpt={deleteOpt}
                        mattingOpt={mattingOpt}
                        composeOpt={composeOpt}
                        onBeforeUpload={uploadHandle}
                        onNpyChange={onNpyChange}
                    />
                )
            }
            <PSAIModal
                title="构图优化"
                maskClosable={false}
                open={isComposeOptOpen}
                confirmLoading={isComposeLoading}
                okText="确定"
                cancelText="使用原图尺寸"
                onCancel={composeOptClose}
                onOk={() => composeOptSave(isComposeOptOpen)}
                cancelButtonProps={{onClick: composeOptReset}}
            >
                <div style={{padding: '20px 0 0'}}>
                    <ModelScale
                        ref={composeOptRef}
                        size={512}
                        modelScaleOriBase64={getComposeOriBase64(isComposeOptOpen)}
                    />
                </div>
            </PSAIModal>
            <PSAIModal
                title="抠图优化"
                maxWidth={1200}
                closable={false}
                maskClosable={false}
                open={isMattingOptOpen}
                confirmLoading={isMattingLoading}
                okText="保存修改"
                cancelText="放弃修改"
                onCancel={() => setIsMattingOptOpen(false)}
                onOk={() => mattingOptSave(isMattingOptOpen)}
            >
                <MattingOpt
                    ref={mattingOptRef}
                    imageType={imageType}
                    aiInteractiveNpy={getCurrentNpy(isMattingOptOpen)}
                    blueMask={getCurrentSegmentResult(isMattingOptOpen)[1]}
                    whiteMask={getCurrentSegmentResult(isMattingOptOpen)[4]}
                    inputImage={getCurrentSegmentResult(isMattingOptOpen)[0]}
                />
            </PSAIModal>
        </div>
    )
})

export default MiddleComp