import { JSEncrypt } from 'jsencrypt';
const publicKey = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAwtuTLrs81qpJc5EE00mq
0GG/Y4D6PRTflLfHo3xRM4Z2SaiwsLp9DWRST3+YpTG7ceJ1v1PMhePrb0lW7dlM
aqT/Zqjg/by5UXae0yRfuJgVJFIOkqjBVaAbgm+jvgkLZC0S2ld31PaGnfQZTL1D
kGCUlWaGyvU2RiqjsN9Od1fqC9hm3eYa3Mh87Xh2++KEOumkV04150HkNRl2tc1G
cbMVOGrn4O4r9NTS1bret8Kgap8+MzZolJCruUPpiieZkZHj90owJQh45+UTX/ua
fMB5bYvGoVUboMiqm7QG/QXhl880vrJO81v/Hc7j3ClV3WYRHfhBOlcueweaCG7w
jQIDAQAB
-----END PUBLIC KEY-----`

function JSEncryptChange(plainText) {
    const crypt = new JSEncrypt({ default_key_size: 2048 });
    crypt.setPublicKey(publicKey);
    let encrypted = crypt.encrypt(plainText);
    return encrypted
}


export default {
    JSEncryptChange: JSEncryptChange
}