import { useState } from 'react'
import styles from './MoreScenePopoverContent.module.scss'
import Utils from '@utils/utils'

const MoreScenePopoverContent = (props) => {
    const {sceneList, selectedName} = props
    const [selected, setSelected] = useState(selectedName)

    function onSelect (item) {
        setSelected(item.name)
        props.onChange && props.onChange(item)
        props.closeFunc && props.closeFunc()
    }

    function formartTitle (title) {
        const arr = title?.split('-')
        return arr && (arr.length > 0 ? arr[arr.length - 1] : title)
    }

    return (
        <div className={`${styles.Container} pop_container`}>
        {
            sceneList.map((item, index) => (
                <div
                    key={index}
                    className={`${styles.Item} ${(selected == item.name) && styles.Item_selected} ${props.tryOn && styles.tryOn}`}
                    onClick={() => onSelect({...item, name: item.name})}
                >
                    <img src={Utils.addVersionParamToURL(item.imageUrl, 'v', item?.attr?.version)} alt="" />
                    { !props.tryOn && <p><span>{formartTitle(item.attr.title)}</span></p> }
                </div>
            ))
        }
        </div>
    )
}

export default MoreScenePopoverContent