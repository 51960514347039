import { Button } from 'antd'
import styles from './index.module.scss'
import { useContext, useState } from 'react'
import { GenerateContext } from '@utils/GenerateContext'

const LeftCompVideo = (props) => {
    const {img4Video, videoResolution, setVideoResolution} = useContext(GenerateContext)
    const [consumAmount] = useState(80)
    const [loading, setLoading] = useState(false)

    const handleClick = async () => {
        setLoading(true)
        await props.onCreate()
        setLoading(false)
    }

    return (
        <div className={styles.Container}>
            <p>视频清晰度</p>
            <div className={`${styles.Resolution} ${videoResolution == 720 && styles.selected}`} onClick={() => setVideoResolution(720)}>高清</div>
            <div className={`${styles.Resolution} ${videoResolution == 1080 && styles.selected}`} onClick={() => setVideoResolution(1080)}>超清</div>
            <div className={styles.GenerateBox}>
                <p className={styles.use_point}>消耗<img src={require('@assets/images/arcbean2.png')}/><span>{consumAmount}</span></p>
                <Button disabled={!img4Video || !videoResolution} loading={loading} type="primary" onClick={handleClick}>生成</Button>
            </div>
        </div>
    )
}

export default LeftCompVideo