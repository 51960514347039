// @ts-ignore
import styles from './index.module.scss'

type SpinDotProps = {
    color?: string
}

const PSAISpinDot = (props: SpinDotProps) => {
    return (
        <div className={`ant-spin-dot ant-spin-dot-spin ${styles.psai_spin_dot}`}>
            <i className="ant-spin-dot-item" style={props.color ? {backgroundColor: props.color} : {}}></i>
            <i className="ant-spin-dot-item" style={props.color ? {backgroundColor: props.color} : {}}></i>
            <i className="ant-spin-dot-item" style={props.color ? {backgroundColor: props.color} : {}}></i>
            <i className="ant-spin-dot-item" style={props.color ? {backgroundColor: props.color} : {}}></i>
            <i className="ant-spin-dot-item" style={props.color ? {backgroundColor: props.color} : {}}></i>
            <i className="ant-spin-dot-item" style={props.color ? {backgroundColor: props.color} : {}}></i>
        </div>
    )
}

export default PSAISpinDot