import { useEffect, useMemo, useRef, useState } from "react"
import ActionGroup from '@view/compoents/MattingOpt/ActionGroup'
import Rcslider from "@view/compoents/RcSlider/RcSlider.jsx"
import IconPaint from '@assets/images/icon_paint.svg';
import IconPaintActived from '@assets/images/icon_paint_actived.svg';
import IconErase from '@assets/images/icon_erase.svg';
import IconEraseActived from '@assets/images/icon_erase_actived.svg';
import IconAdd from '@assets/images/icon_add.svg'
import IconRemove from '@assets/images/icon_remove.svg';
import IconModel from '@assets/images/icon_model.svg'
import IconCommodity from '@assets/images/icon_commodity.svg';
import IconUndo from '@assets/images/icon_undo.svg'
import IconUndoActived from '@assets/images/icon_undo_actived.svg';
import IconReset from '@assets/images/icon_reset.svg'
import IconResetActived from '@assets/images/icon_reset_actived.svg';
import useResizeObserver from "@utils/useResizeObserver";
import MattingOptCanvas from "@view/compoents/MattingOpt/MattingOptCanvas";
import styles from './Matting.module.scss'
import { IMAGE_TYPE } from "@utils/CONST";
import Utils from '@utils/utils'
import {maskPrediction, maskDispose, multiSemantic} from '@utils/workflowOfPrediction'
import { Spin, message } from "antd";
import {forwardRef, useImperativeHandle} from "react"
import API from '@api/api'

const Matting = forwardRef((props, ref) => {

    useImperativeHandle(ref, () => ({
        getMattingResult,
    }))

    const getMattingResult = async () => {
        if (maskIsReady) {
            // 透明底mask转 半透明白色
            const  halfTransparent = await Utils.modifyTransparentMask(blendedBlueMaskBase64, '#ffffffad')
            const  inputImageUrlToBase64 = await Utils.urlToBase64(inputImageUrl)
            const maskWhiteAfter = await Utils.mergeBase64Images(inputImageUrlToBase64, halfTransparent)
            const inputImageCroppedUrl = await Utils.uploadBase64Image(maskWhiteAfter)
        
            // 混合后的黑白mask
            const blendWhiteMaskBase = await canvasRef.current.getBlendWhiteMask(blendedBlueMaskBase64)

            const whiteMask = await Utils.resizeImage(blendWhiteMaskBase, imgWidthRef.current, imgHeightRef.current) // 混合后的黑白mask editedOutput[4]
            const whiteMaskUrl = await Utils.uploadBase64Image(whiteMask)

            // 取mask中物体的宽高及位置
            let maskObjectLeft, maskObjectTop, maskObjectHeight, maskObjectWidth
            const objectInfo = await Utils.getTrimmedImageBase64(blendedBlueMaskBase64)
            if(objectInfo) {
                maskObjectLeft =  objectInfo.leftTop.x
                maskObjectTop =  objectInfo.leftTop.y
                maskObjectHeight = Math.max((objectInfo.rightBottom.y -  objectInfo.leftTop.y + 1), 0)
                maskObjectWidth = Math.max((objectInfo.rightBottom.x -  objectInfo.leftTop.x + 1), 0)
            }
            
            return {
                refImg: inputImageUrl,
                refImgFile: Utils.convertBase64ToFile(inputImageUrlToBase64),
                refMask: whiteMaskUrl,
                refImgCropped: inputImageCroppedUrl,
                width: imgWidthRef.current,
                height: imgHeightRef.current,
                locationInfo: {
                    left: maskObjectLeft,
                    top: maskObjectTop,
                    width: maskObjectWidth,
                    height: maskObjectHeight
                }
            }
        } else {
            return false
        }
    }

    // 交互时分割模式 0-增加 1-减少
    const [interactiveMode, setInteractiveMode] = useState('add')
    const interactiveModeRef = useRef('add')
    useEffect(() => {
        interactiveModeRef.current = interactiveMode
        if (!interactiveMode) return
        setPaintMode()
        setMoveable(false)
    }, [interactiveMode])
    const interactiveItems = [{
        label: '增加选区',
        icon: IconAdd,
        activedIcon: IconAdd,
        value: 'add',
        hover: false
    }, {
        label: '减少选区',
        icon: IconRemove,
        activedIcon: IconRemove,
        value: 'remove',
        hover: false
    }]

    // 涂抹选项
    const [paintMode, setPaintMode] = useState()
    const paintModeRef = useRef()
    const [paintSize, setPaintSize] = useState(20)
    const paintSizeRef = useRef(20)
    const [eraseSize, setEraseSize] = useState(20)
    const eraseSizeRef = useRef(20)
    useEffect(() => {
        paintModeRef.current = paintMode
        if (!paintMode) return
        setInteractiveMode()
        setMoveable(false)
    }, [paintMode])
    useEffect(() => {
        paintSizeRef.current = paintSize
        console.log('paintSize change', paintSize)
    }, [paintSize])
    useEffect(() => {
        eraseSizeRef.current = eraseSize
        console.log('eraseSize change', eraseSize)
    }, [eraseSize])
    const paintItems = [{
        label: '手动涂抹',
        icon: IconPaint,
        activedIcon: IconPaintActived,
        value: 'paint',
        hover: false,
        popover: (
            <Rcslider
                title={"画笔大小"}
                defaultValue={20}
                value={paintSize}
                onChange={setPaintSize}
                min={1}
                max={50}
                disabled={paintMode != 'paint'}
                type={'paint'}
            />
        )
    }, {
        label: '手动擦除',
        icon: IconErase,
        activedIcon: IconEraseActived,
        value: 'erase',
        hover: false,
        popover: (
            <Rcslider
                title={"橡皮大小"}
                defaultValue={20}
                value={eraseSize}
                onChange={setEraseSize}
                min={1}
                max={50}
                disabled={paintMode != 'erase'}
                type={'erase'}
            />
        )
    }]

    // 其他操作
    const undoListRef = useRef([])
    const [undoList, setUndoList] = useState([]) // 待撤销列表
    const redoListRef = useRef([])
    const [redoList, setRedoList] = useState([]) // 重做
    const clearRedo = () => {
        redoListRef.current = []
        setRedoList(redoListRef.current)
    }
    const addUndo = (params) => {
        params = Object.assign(params, {
            interactiveMode: interactiveModeRef.current,
            paintMode: paintModeRef.current,
            paintSize: paintSizeRef.current,
            eraseSize: eraseSizeRef.current,
            moveable: moveableRef.current,
            zoom: zoomRef.current,
        })
        undoListRef.current = [...undoListRef.current, {params}]
        setUndoList(undoListRef.current)
    }
    const addRedo = (params) => {
        params = Object.assign(params, {
            interactiveMode: interactiveModeRef.current,
            paintMode: paintModeRef.current,
            paintSize: paintSizeRef.current,
            eraseSize: eraseSizeRef.current,
            moveable: moveableRef.current,
            zoom: zoomRef.current,
        })
        redoListRef.current = [...redoListRef.current, {params}]
        setRedoList(redoListRef.current)
    }
    const handleUndo = () => {
        if (undoListRef.current.length == 0) return
        const undo = undoListRef.current.pop()
        setUndoList(undoListRef.current)
        const {
            interactiveMode,
            paintMode,
            paintSize,
            eraseSize,
            moveable,
            zoom,
        } = undo.params
        setInteractiveMode(interactiveMode)
        setPaintMode(paintMode)
        setPaintSize(paintSize)
        setEraseSize(eraseSize)
        setMoveable(moveable)
        setZoom(zoom)
        canvasRef.current.undoCanvas(undo)
    }
    const handleRedo = () => {
        if (redoListRef.current.length == 0) return
        const redo = redoListRef.current.pop()
        setRedoList(redoListRef.current)
        const {
            interactiveMode,
            paintMode,
            paintSize,
            eraseSize,
            moveable,
            zoom,
        } = redo.params
        setInteractiveMode(interactiveMode)
        setPaintMode(paintMode)
        setPaintSize(paintSize)
        setEraseSize(eraseSize)
        setMoveable(moveable)
        setZoom(zoom)
        canvasRef.current.redoCanvas(redo)
    }
    const handleReset = async () => {
        const base64 = await Utils.urlToBase64(segmentResult[1])
        setBlueMaskBase64(base64)
        setWhiteMaskUrl(segmentResult[4])
        // Utils.urlToBase64(props.whiteMask).then(setWhiteMaskBase64)
        // 重置按钮
        setInteractiveMode('add')
        setPaintMode()
        setPaintSize(20)
        setEraseSize(20)
        setMoveable()
        setZoom(1)
        // 重置canvas位置，清空canvas内容
        canvasRef.current.resetCanvas()
        canvasRef.current.clearCanvas()
        // 清空撤销和重置
        undoListRef.current = []
        setUndoList(undoListRef.current)
        redoListRef.current = []
        setRedoList(redoListRef.current)
    }
    const actionItems = [{
        label: '撤销',
        icon: IconUndo,
        activedIcon: IconUndoActived,
        value: 0,
        hover: false,
        disabled: undoList.length == 0,
        onClick: () => handleUndo()
    }, {
        label: '恢复',
        icon: IconUndo,
        activedIcon: IconUndoActived,
        iconStyle: {transform: 'rotateY(-180deg)'},
        value: 1,
        hover: false,
        disabled: redoList.length == 0,
        onClick: () => handleRedo()
    }, {
        label: '重置',
        icon: IconReset ,
        activedIcon: IconResetActived,
        iconStyle: {transform: 'rotate3d(1, 1, 1, 15deg)'},
        value: 2,
        hover: false,
        onClick: () => handleReset()
    }]

    // 撤销操作                    
    function handleKeydown(event) {
        if (event.ctrlKey) {
            if (event.key == 'z') { // CTRL + Z
                handleUndo()              
            }
        }
    };
    useEffect(() => {
        document.addEventListener('keydown', handleKeydown);
        return () => {
            document.removeEventListener('keydown', handleKeydown);
        }
    }, [])

    // 移动控制
    const [viewportOffset, setViewportOffset] = useState({x: 0, y: 0})
    // 移动缩放控制
    const [moveable, setMoveable] = useState()
    const moveableRef = useRef()
    const [zoom, setZoom] = useState(1)
    const zoomRef = useRef(1)
    const minZoomRef = useRef(.5)
    const maxZoomRef = useRef(10)
    const paintModeBackup = useRef() // paintMode备份，用于取消移动后恢复操作
    const interactiveModeBackup = useRef('add') // interactiveMode备份，用于取消移动后恢复操作
    useEffect(() => {
        moveableRef.current = moveable
        if (!moveable) {
            setPaintMode(paintModeBackup.current)
            setInteractiveMode(interactiveModeBackup.current)
        } else {
            paintModeBackup.current = paintModeRef.current
            interactiveModeBackup.current = interactiveModeRef.current
            setPaintMode()
            setInteractiveMode()
        }
    }, [moveable])
    useEffect(() => {
        zoomRef.current = zoom
    }, [zoom])
    const zoomOut = () => {
        setZoom(pre => {
            pre -= .1
            return Math.max(Math.min(pre, maxZoomRef.current), minZoomRef.current)
        })
    }
    const zoomIn = () => {
        setZoom(pre => {
            pre += .1
            return Math.max(Math.min(pre, maxZoomRef.current), minZoomRef.current)
        })
    }

    const isMounted = useRef(true) // 判断组件是否已销毁
    const [imageType] = useState(IMAGE_TYPE.COMMODITY)
    const blendedImageRef = useRef() // mask与原图混合后的图像
    const [whiteMaskUrl, setWhiteMaskUrl] = useState(props.whiteMask) // 白底mask segmentResult[4]
    // const whiteMaskBase64Ref = useRef() // 黑白mask的base64格式
    // const [blueMaskUrl, setBlueMaskUrl] = useState(props.blueMask) // 蓝色mask segmentResult[1]（来自算法生成）
    const [blueMaskBase64, setBlueMaskBase64] = useState() // 蓝色mask的base64格式（来自算法生成）
    const [blendedBlueMaskBase64, setBlendedBlueMaskBase64] = useState() // 混合后的蓝色mask的base64格式
    const [inputImageUrl, setInputImageUrl] = useState(props.cropParams.src) // 输入图 segmentResult[0]
    const [aiInteractiveNpy, setAiInteractiveNpy] = useState()
    const [segmentResult, setSegmentResult] = useState([])

    const [imgWidth, setImgWidth] = useState(props.cropParams.width)
    const [imgHeight, setImgHeight] = useState(props.cropParams.height) 
    const imgWidthRef = useRef(props.cropParams.width) // 图片原始宽度
    const imgHeightRef = useRef(props.cropParams.height) // 图片原始高度

    const [displayScale, setDisplayScale] = useState()
    const [displayWidth, setDisplayWidth] = useState() // 图片默认显示宽度
    const [displayHeight, setDisplayHeight] = useState() // 图片默认显示高度
    const canvasRef = useRef()
    const [inited, setInited] = useState(false)
    const [maskIsReady, setMaskIsReady] = useState(false)
    const [npyIsReady, setNpyIsReady] = useState(false)

    const [wrapWidth, setWrapWidth] = useState(0)
    const [wrapHeight, setWrapHeight] = useState(0)
    const canvasWrapRef = useResizeObserver((entries) => {
        for (let entry of entries) {
            // 处理尺寸变化的逻辑
            setWrapWidth(entry.contentRect.width)
            setWrapHeight(entry.contentRect.height)
        }
    })
    useEffect(() => {
        if (!wrapWidth || !wrapHeight) return
        if (!imgWidth || !imgHeight) return
        // 获得canvas尺寸
        const size = Utils.getImgMaxSizeInContainer(
            imgWidth,
            imgHeight,
            wrapWidth,
            wrapHeight
        )
        setDisplayScale(size.width / imgWidth)
        setDisplayWidth(size.width)
        setDisplayHeight(size.height)
        setInited(true)
    }, [imgWidth, imgHeight, wrapWidth, wrapHeight])

    const getMaskAndNpy = async () => {
        const inputImage = await Utils.uploadBase64Image(props.cropParams.src)
        setInputImageUrl(inputImage)
        const res0 = await API.personCheck({imageUrl: inputImage})
        if (res0.code != 0) return message.warning(res.message)
        const isPerson = res0.data
        const res = await maskPrediction(inputImage, imageType, () => !isMounted.current, isPerson)
        if (!res) return
        const output = res.output
        setSegmentResult(output)
        setWhiteMaskUrl(output[4])
        const base64 = await Utils.urlToBase64(output[1])
        // setBlueMaskBase64(base64)
        const res2 = await maskDispose(output, () => !isMounted.current)
        setBlueMaskBase64(base64)
        setTimeout(() => {
            setMaskIsReady(true)
        }, 3000)
        if (!res2) return
        setAiInteractiveNpy(res2.npy)
        setNpyIsReady(true)
    }

    useEffect(() => {
        getMaskAndNpy()
        return () => {
            isMounted.current = false
        }
    }, [])

    const loading = useMemo(() => {
        return !(inited && maskIsReady && npyIsReady)
    }, [inited, maskIsReady, npyIsReady])

    return (
        <Spin spinning={loading} >
            <div className={styles.Container}>
                <p className={styles.Title}>请点选或涂抹不想保留的内容</p>
                <div className={styles.Controller}>
                    <ActionGroup items={interactiveItems} value={interactiveMode} onChange={setInteractiveMode} />
                    <ActionGroup items={paintItems} value={paintMode} onChange={setPaintMode} />
                    <ActionGroup items={actionItems} />
                </div>
                <div className={styles.Content}>
                    <div ref={canvasWrapRef}>
                        {inited &&
                        <MattingOptCanvas
                            ref={canvasRef}
                            inputImageUrl={inputImageUrl}
                            whiteMaskUrl={whiteMaskUrl}
                            blueMaskBase64={blueMaskBase64}
                            setBlueMaskBase64={setBlueMaskBase64}
                            setBlendedBlueMaskBase64={setBlendedBlueMaskBase64}
                            // inputImageBase64={inputImageBase64}
                            // setWhiteMaskBase64={setWhiteMaskBase64}
                            displayScale={displayScale}
                            displayWidth={displayWidth}
                            displayHeight={displayHeight}
                            moveable={moveable}
                            zoom={zoom}
                            minZoom={minZoomRef.current}
                            maxZoom={maxZoomRef.current}
                            setZoom={setZoom}
                            setViewportOffset={setViewportOffset}
                            interactiveMode={interactiveMode}
                            // setInteractiveMode={setInteractiveMode}
                            paintMode={paintMode}
                            paintSize={paintSize}
                            setPaintSize={setPaintSize}
                            eraseSize={eraseSize}
                            setEraseSize={setEraseSize}
                            addUndo={addUndo}
                            addRedo={addRedo}
                            clearRedo={clearRedo}
                            setUpdated={() => {}}
                            imageType={IMAGE_TYPE.COMMODITY}
                            aiInteractiveNpy={aiInteractiveNpy}
                        />}
                    </div>
                </div>
            </div>
        </Spin>
    )
})

export default Matting