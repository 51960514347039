function disabledZoom() {
 
  console.log(window.devicePixelRatio)
  const devicePixelRatio = window.devicePixelRatio 
  if (devicePixelRatio !== 1) {
    // 老ie可以提示
    if (!! window.ActiveXObject || 'ActiveXObject' in window) {
      alert('浏览器不支持, 请使用chrome浏览器')
    } else {
      // const c = document.querySelector('body')
      // c.style.zoom = 1 / devicePixelRatio// 修改页面的缩放比例
      const body = document.querySelector('body')
      body.style.width = `calc(100% * ${devicePixelRatio})`
      body.style.height = `calc(100% * ${devicePixelRatio})`
      body.style.transform = `scale(calc(1 / ${devicePixelRatio}))`
      body.style.transformOrigin = `left top`
      body.style.overflow = `hidden`
      const html = document.querySelector('html')
      html.style.minWidth = `calc(var(--min-font-size) * 19.2 / ${devicePixelRatio})`
      html.style.minHeight = `calc(var(--min-font-size) * 15 / ${devicePixelRatio})`
    }
  }
}

export default {
  disabledZoom: disabledZoom
}