import {useState, useEffect, useContext, useMemo} from 'react';
import { Select, Carousel, Row, Col, Input} from 'antd';
import Utils from '@utils/utils'
import {AppContext} from '@utils/AppContext'
import API from '@api/api'
import styles from './index.module.scss'
import { GenerateContext } from '@utils/GenerateContext';
const { TextArea } = Input;

const SoftEdge = (props) => {
    const { softEdgeList, setSoftEdgeList } = useContext(GenerateContext)
    const [group, setGroup] = useState();
    
    useEffect(() => {
        getShowcaseList(group)
    }, []);

    function getShowcaseList(group) {
        API.commodityShowcaseList({group: group}).then((res) => {
            if(res.code == 0) {
                setSoftEdgeList(res.data)
            }
        }).catch((error) => {
            console.log(error)
        })
    }

    const showList = useMemo(() => {
        if (!softEdgeList) return []
        return softEdgeList.slice(0, 6)
    }, [softEdgeList])
  
    return (
        <div className={styles.Container}>
            <div className={styles.Content}>
            {
                <div className={styles.SelectionItemBox}>
                {
                    showList?.map((item2) => (
                        <div
                            key={item2.name}
                            className={styles.sceneItem}
                            onClick={() => props.getEdgeUrl(item2.imageUrl)}
                        >
                            <img src={Utils.addVersionParamToURL(item2.imageUrl, 'v', item2?.attr?.version)} alt="" />
                        </div>
                    ))
                }
                </div>
            }
            </div>
        </div>
    )
}

export default SoftEdge